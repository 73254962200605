import ToontaskGroup, {getToontaskGroups} from "../../../../data-toontasks/ToontaskGroup";
import React, {CSSProperties} from "react";
import {Row} from "../../../../core-anvil/Layouts";
import ic_ttc from "../../../assets/images/ic_ttc.svg";
import ic_dd from "../../../assets/images/ic_dd.svg";
import ic_dg from "../../../assets/images/ic_dg.svg";
import ic_mml from "../../../assets/images/ic_mml.svg";
import ic_brg from "../../../assets/images/ic_brg.svg";
import ic_ddl from "../../../assets/images/ic_ddl.svg";
import ic_lawbot from "../../../assets/images/ic_lawbot.png";
import ic_bossbot from "../../../assets/images/ic_bossbot.png";

type Props = {
    group: ToontaskGroup,
    onToontaskGroupClick: (group: ToontaskGroup) => void,
    iconSize: number,
    selectedIconSize: number
    style?: CSSProperties
}

export default function ToontaskGroupSelector(props: Props) {
    let toontaskGroupIcons =
        getToontaskGroups().map(group => {
                const size = group === props.group ? props.selectedIconSize : props.iconSize
                const opacity = group === props.group ? 0.7 : 1

                return <img src={getNeighborhoodIcon(group)} alt=""
                            style={{width: size, height: size, marginLeft: 5, marginRight: 5, opacity: opacity}}
                            onClick={() => props.onToontaskGroupClick(group)}
                />
            }
        )

    return (
        <Row verticalAlignment="center" horizontalArrangement="center" style={props.style}>
            {toontaskGroupIcons}
        </Row>
    )
}

function getNeighborhoodIcon(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return ic_ttc
        case ToontaskGroup.DonaldsDock:
            return ic_dd
        case ToontaskGroup.DaisyGardens:
            return ic_dg
        case ToontaskGroup.MinniesMelodyland:
            return ic_mml
        case ToontaskGroup.TheBrrrgh:
            return ic_brg
        case ToontaskGroup.DonaldsDreamland:
            return ic_ddl
        case ToontaskGroup.Lawbot:
            return ic_lawbot
        case ToontaskGroup.Bossbot:
            return ic_bossbot
    }
}