import "./cog-suit-details.scss"

import React from "react"

import LaffProgress from "app/views/laff-progress"
import ProgressBar from "app/views/progress-bar"
import cloneDeep from "lodash.clonedeep"

import UserRepo from "app/data/UserRepository"
import GameDataRepo from "app/data/GameDataRepository"
import Facilities from "app/data/game/Facilities"

import Header from "common/theme/Header"
import Dimensions from "common/theme/Dimensions"


export default class CogSuitDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            cogType: props.type,
            suitIndex: null,
            currentPoints: 0,
            pointsNeeded: 0
        }

        this.onPromoteClicked = this.onPromoteClicked.bind(this)
        this.onDemoteClicked = this.onDemoteClicked.bind(this)
        this.onFacilitiesPointsChange = this.onFacilitiesPointsChange.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState(() => {
                return { width: window.innerWidth, height: window.innerHeight };
            });
        });

        let toon = UserRepo.getUser().getCurrentToon()
        let index = toon.getCogSuitIndexWithType(this.state.cogType)

        const suit = GameDataRepo.getCogSuitsData().getCogSuit(this.state.cogType, index)

        if (suit === null) {
            return
        }

        this.setState({
            suitIndex: index,
            pointsNeeded: suit.points
        })
    }

    render() {
        if (this.state.suitIndex === null) {
            return null
        }

        let backgroundColour = ""
        switch (this.state.cogType) {
            case "sellbot":
                backgroundColour = "#840024"
                break
            case "cashbot":
                backgroundColour = "#428A53"
                break
            case "lawbot":
                backgroundColour = "#6A6A7D"
                break
            case "bossbot":
                backgroundColour = "#816149"
                break
            default:
                throw Error()
        }

        let title = this.state.cogType[0].toUpperCase() + this.state.cogType.substr(1)

        let suitInfoClass = "headline4"

        if (this.state.width >= 720) {
            suitInfoClass = "headline3"
        }

        if (this.state.width >= 1024) {
            suitInfoClass = "headline2"
        }

        let suit = GameDataRepo.getCogSuitsData().getCogSuit(this.state.cogType, this.state.suitIndex)

        let suitCog = suit.name
        let suitLevel = suit.level


        return (
            <div className="cog-suit-details" style={{
                width: `calc(100% - ${Dimensions.getContentHorizontalPadding} * 2)`,
                backgroundColor: backgroundColour,
                padding: `0 ${Dimensions.getContentHorizontalPadding()}`
            }}>
                <Header pageStack={[
                    { title: "Tracking", url: "/tracking" },
                    { title: "Cog Suits", url: "/tracking/cog-suits" },
                    { title: title, url: `/tracking/cog-suits/${this.state.cogType}` }
                ]}>

                </Header>

                <div className="cog-suit-details__laff-progress">
                    {this.renderProgressBar()}
                </div>

                <div>
                    <p className={"cog-suit-details__suit-cog " + suitInfoClass}>{suitCog}</p>
                    <p className={"cog-suit-details__suit-level " + suitInfoClass}>{`Level ${suitLevel}`}</p>
                </div>

                <div className="cog-suit-details-buttons">
                    <div className="cog-suit-details__demote-button" onClick={this.onDemoteClicked}>
                        <span className="material-icons cog-suit-details__demote-icon">arrow_downward</span>
                        <p className="cog-suit-details__demote-label button">DEMOTE</p>
                    </div>
                    <div className="cog-suit-details__promote-button" onClick={this.onPromoteClicked}>
                        <span className="material-icons cog-suit-details__promote-icon" style={{ color: backgroundColour }}>arrow_upward</span>
                        <p className="cog-suit-details__promote-label button" style={{ color: backgroundColour }}>PROMOTE</p>
                    </div>
                </div>

                <div className="cog-suit-details-facilities">
                    {this.renderFacilities()}
                </div>

                <div className="cog-suit-details-facilities-calculator">
                    {this.renderFacilitiesCalculator()}
                </div>


            </div>
        )
    }

    renderProgressBar() {
        let progressUnfilledColour = ""
        switch (this.state.cogType) {
            case "sellbot":
                progressUnfilledColour = "#ad546c"
                break
            case "cashbot":
                progressUnfilledColour = "#80b18c"
                break
            case "lawbot":
                progressUnfilledColour = "#9b9ba8"
                break
            case "bossbot":
                progressUnfilledColour = "#a38b7a"
                break
            default:
                break
        }

        if (this.state.width >= 600) {
            let fontSize = 18

            if (this.state.width >= 720) {
                fontSize = 22
            }

            return (
                <LaffProgress progress={this.state.suitIndex}
                    maxProgress={78}
                    laffMarkers={[43, 48, 58, 68, 78]}
                    unfilledColour={progressUnfilledColour}
                    markerWidth={6}
                    markerValues={[15, 20, 30, 40, 50]}
                    progressHeight={20}
                    fontSize={fontSize}
                    hideLabels={true}
                />
            )
        } else {
            return (
                <ProgressBar
                    progress={this.state.suitIndex}
                    maxProgress={78}
                    style={{ width: "100%", height: "100%" }}
                />
            )
        }
    }

    onPromoteClicked() {
        if (this.state.suitIndex < 78) {
            this.setState(state => {
                let suitIndex = state.suitIndex + 1

                let user = UserRepo.getUser()
                let toon = user.getCurrentToon()

                toon.setCogSuit(this.state.cogType, suitIndex)
                UserRepo.updateUser(user)

                const suit = GameDataRepo.getCogSuitsData().getCogSuit(this.state.cogType, suitIndex)

                return {
                    suitIndex: suitIndex,
                    currentPoints: Math.min(state.currentPoints, suit.points),
                    pointsNeeded: suit.points
                }
            })
        }
    }

    onDemoteClicked() {
        if (this.state.suitIndex > 0) {
            this.setState(state => {
                let suitIndex = state.suitIndex - 1

                let user = UserRepo.getUser()
                let toon = user.getCurrentToon()

                toon.setCogSuit(this.state.cogType, suitIndex)
                UserRepo.updateUser(user)

                const suit = GameDataRepo.getCogSuitsData().getCogSuit(this.state.cogType, suitIndex)

                return {
                    suitIndex: suitIndex,
                    currentPoints: Math.min(state.currentPoints, suit.points),
                    pointsNeeded: suit.points
                }
            })
        }
    }

    renderFacilities() {
        let cogType = this.state.cogType
        let facilityViews = []

        let facilities = []

        if (cogType === "sellbot") {
            facilities = cloneDeep(Facilities.SELLBOT_FACILITIES).reverse()
        }

        if (cogType === "cashbot") {
            facilities = cloneDeep(Facilities.CASHBOT_FACILITIES).reverse()
        }

        if (cogType === "lawbot") {
            facilities = cloneDeep(Facilities.LAWBOT_FACILITIES).reverse()
        }

        if (cogType === "bossbot") {
            facilities = cloneDeep(Facilities.BOSSBOT_FACILITIES).reverse()
        }


        for (const facility of facilities) {
            facilityViews.push(
                <p className="cog-suit-details__facility-name headline4" style={{ fontWeight: 300 }}>{facility.name}</p>
            )

            facilityViews.push(
                <div>
                    <p className="cog-suit-details__facility-amount headline4" style={{ fontWeight: 300 }}>{facility.points}</p>
                    <p className="cog-suit-details__facility-amount-type subtitle2" style={{ fontWeight: 300 }}>{facility.pointsType}</p>
                </div>
            )
        }

        return facilityViews
    }

    renderFacilitiesCalculator() {
        let type = ""
        switch (this.state.cogType) {
            case "sellbot":
                type = "Merits"
                break
            case "cashbot":
                type = "Cog Bucks"
                break
            case "lawbot":
                type = "Jury Notices"
                break
            case "bossbot":
                type = "Stock Options"
                break
            default:
                break
        }

        const pointsNeeded = GameDataRepo.getCogSuitsData().getCogSuit(this.state.cogType, this.state.suitIndex).points

        return ([
            <p className="cog-suit-details-facilities-calculator__title headline5">Which Facilities Do I Need?</p>,
            <div className="cog-suit-details-facilities-calculator-current">
                <p className="cog-suit-details-facilities-calculator__current-title body1">Current {type}</p>
                <input type="text" className="cog-suit-details-facilities-calculator__points-input" value={this.state.currentPoints} onChange={this.onFacilitiesPointsChange} style={{
                    fontSize: this.getPointsFontSize(this.state.currentPoints)
                }} />
            </div>,
            <div className="cog-suit-details-facilities-calculator-needed">
                <p className="cog-suit-details-facilities-calculator__needed-title body1">{type} Needed</p>
                <p className="cog-suit-details-facilities-calculator__points-needed" style={{
                    fontSize: this.getPointsFontSize(pointsNeeded)
                }}>{pointsNeeded}</p>
            </div>,
            <p className="cog-suit-details-facilities-calculator__facilities-needed-label body1">Facilities Needed</p>,
            <p className="cog-suit-details-facilities-calculator-facilities-needed ">{this.getFacilitiesNeededText()}</p>
        ])
    }

    onFacilitiesPointsChange(event) {
        /** @type {String} */
        const text = event.target.value

        let currentPoints = parseInt(text.replace(/\D/g, ''))
        if (currentPoints > this.state.pointsNeeded) {
            currentPoints = this.state.pointsNeeded
        }

        if (currentPoints < 0 || text === "") {
            currentPoints = 0
        }

        this.setState({ currentPoints: currentPoints })

    }

    getPointsFontSize(points) {
        let size = "calc(55 * 0.0625rem)"

        if (points >= 10000) {
            size = "calc(43 * 0.0625rem)"
        }

        return size
    }

    getFacilitiesNeededText() {
        const facilities = Facilities.getFacilitiesForPromotion(this.state.cogType, this.state.pointsNeeded - this.state.currentPoints)

        let needed = []
        for (const facility of facilities) {
            needed.push(<p className="cog-suit-details-facilities-calculator__facility headline5">
                {`${facility.amount} x ${facility.name}`}
            </p>)
        }

        return needed
    }
}