import './index.scss';
import "firebase"

import * as serviceWorker from './serviceWorker';

import App from "./app/App"
import React from 'react';
import ReactDOM from "react-dom";

import "reflect-metadata";

// let df =new firebase.auth.GoogleAuthProvider()
// df.setCustomParameters

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.register()