import { Headline6 } from "app/theme/Typography"
import styled from "styled-components"

type Props = {
    icon: any,
    overallStatusText: string,
    onStatusInfoClick: () => any
}

export default function OverallServerStatusInfo(props: Props) {
    return(
        <StyledOverallServerStatusInfo>
            <StyledStatusIcon className="material-icons">{props.icon}</StyledStatusIcon>
            <StyledOverallStatus>{props.overallStatusText}</StyledOverallStatus>
            <StyledInfoIcon onClick={props.onStatusInfoClick} className="material-icons">info_outlined</StyledInfoIcon>
        </StyledOverallServerStatusInfo>
    )
}

const StyledOverallServerStatusInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 4px;
`

const StyledStatusIcon = styled.span`
    width: 24px;
    height: 24px;
    color: white;
`

const StyledOverallStatus = styled(Headline6)`
    width: 200px;
    color: white;
    margin-top: 2px;
    text-align: center;
`

const StyledInfoIcon = styled.span`
    width: 24px;
    height: 24px;
    color: white;
    opacity: 0.6;
`