import { ServerStatus } from "./ServerStatus";

export default class OverallServerStatus {

    mainSite: ServerStatus
    accountServer: ServerStatus
    gameServer1: ServerStatus
    gameServer2: ServerStatus
    gameServer3: ServerStatus
    updateServer1: ServerStatus
    updateServer2: ServerStatus
    updateServer3: ServerStatus

    constructor(
        mainSite: ServerStatus,
        accountServer: ServerStatus,
        gameServer1: ServerStatus,
        gameServer2: ServerStatus,
        gameServer3: ServerStatus,
        updateServer1: ServerStatus,
        updateServer2: ServerStatus,
        updateServer3: ServerStatus
    ) {
        this.mainSite = mainSite
        this.accountServer = accountServer
        this.gameServer1 = gameServer1
        this.gameServer2 = gameServer2
        this.gameServer3 = gameServer3
        this.updateServer1 = updateServer1
        this.updateServer2 = updateServer2 
        this.updateServer3 = updateServer3
    }

}