import {injectable} from "tsyringe";
import {BehaviorSubject} from "rxjs";
import GetFishBestSpots from "../../../../application-game/usecases/fishing/getfishbestspots/GetFishBestSpots";
import NeighborhoodResult from "../../../../application-game/usecases/NeighborhoodResult";
import FishingSpeciesScreenModel from "../../FishingSpeciesScreenModel";
import {
    GetFishBestSpotRodResult
} from "../../../../application-game/usecases/fishing/getfishbestspots/GetFishBestSpotsResult";

@injectable()
export default class FishBestSpotsTableViewModel {

    uiState = new BehaviorSubject<FishBestSpotsTableState>(emptyState())

    constructor(private screenModel: FishingSpeciesScreenModel, private getFishBestSpots: GetFishBestSpots) {
        screenModel.state.subscribe(screenState => {
            if(screenState.dialog.fishId !== null) {
                this.refresh(screenState.dialog.fishId)
            }
        })
    }

    private refresh(fishId: number) {
        const result = this.getFishBestSpots.invoke(fishId)

        if (result === null) return

        this.uiState.next({
            ...this.uiState.value,
            twig: this.createFishBestSpotsTableRowState(result.twig),
            bamboo: this.createFishBestSpotsTableRowState(result.bamboo),
            hardwood: this.createFishBestSpotsTableRowState(result.hardwood),
            steel: this.createFishBestSpotsTableRowState(result.steel),
            gold: this.createFishBestSpotsTableRowState(result.gold)
        })
    }

    private createFishBestSpotsTableRowState(result: GetFishBestSpotRodResult | null): FishBestSpotsTableRowState {
        if(result === null) {
            return {
                bestSpotArea: "", bestSpotNeighborhood: "-", odds: "-"
            }
        }

        return {
            bestSpotNeighborhood: this.getNeighborhoodDisplayName(result.neighborhood),
            bestSpotArea: result.area,
            odds: this.getOdds(result.catchRate)
        }
    }

    private getOdds(catchRate: number): string {
        const odds = (1 / catchRate)

        let displayedOdds = `${odds.toFixed(0)}`

        if(odds > 10000) {
            displayedOdds = (parseInt((odds / 1000).toFixed(0)) * 1000).toString()
        } else if(odds > 1000) {
            displayedOdds = (parseInt((odds / 100).toFixed(0)) * 100).toString()
        } else if(odds > 100) {
            displayedOdds = (parseInt((odds / 10).toFixed(0)) * 10).toString()
        }

        return `1 in ${displayedOdds}`
    }

    private getNeighborhoodDisplayName(neighborhood: NeighborhoodResult): string {
        switch (neighborhood) {
            case NeighborhoodResult.TOONTOWN_CENTRAL:
                return "Toontown Central"
            case NeighborhoodResult.DONALDS_DOCK:
                return "Donald's Dock"
            case NeighborhoodResult.DAISY_GARDENS:
                return "Daisy Gardens"
            case NeighborhoodResult.MINNIES_MELODYLAND:
                return "Minnie's Melodyland"
            case NeighborhoodResult.THE_BRRRGH:
                return "The Brrrgh"
            case NeighborhoodResult.DONALDS_DREAMLAND:
                return "Donald's Dreamland"
            case NeighborhoodResult.ESTATE:
                return "The Estate"
            case NeighborhoodResult.ACORN_ACRES:
                return "Acorn Acres"
        }
    }
}

export type FishBestSpotsTableState = {
    twig: FishBestSpotsTableRowState,
    bamboo: FishBestSpotsTableRowState,
    hardwood: FishBestSpotsTableRowState,
    steel: FishBestSpotsTableRowState,
    gold: FishBestSpotsTableRowState,
}

type FishBestSpotsTableRowState = {
    bestSpotNeighborhood: string,
    bestSpotArea: string,
    odds: string
}

function emptyState(): FishBestSpotsTableState {
    return {
        twig: {
            bestSpotNeighborhood: "Loading...",
            bestSpotArea: "",
            odds: "Loading..."
        },
        bamboo: {
            bestSpotNeighborhood: "Loading...",
            bestSpotArea: "",
            odds: "Loading..."
        },
        hardwood: {
            bestSpotNeighborhood: "Loading...",
            bestSpotArea: "",
            odds: "Loading..."
        },
        steel: {
            bestSpotNeighborhood: "Loading...",
            bestSpotArea: "",
            odds: "Loading..."
        },
        gold: {
            bestSpotNeighborhood: "Loading...",
            bestSpotArea: "",
            odds: "Loading..."
        }
    }
}