import {BehaviorSubject} from "rxjs";
import {useEffect, useState} from "react";

export default function useRxState<T>(subject: BehaviorSubject<T>): T {
    const [state, setState] = useState<T>(subject.value)
    useEffect(() => {
        const sub = subject.subscribe(setState)
        return () => sub.unsubscribe()
    }, [subject])

    return state
}