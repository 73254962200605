import GameDataRepo from "app/data/GameDataRepository"

/**
 * @typedef {{toontaskId: string, option: number, lastCompletedSteps: [number]}} AssignedToontask
 */

/**
 * @typedef {{0: AssignedToontask, 1: AssignedToontask, 2: AssignedToontask, 3: AssignedToontask}} AssignedToontasks
 */

/**
 * @typedef {Object} Toon
 * @property {String} id UUID of the toon
 * @property {Number} laff The amount of laff in total this toon has
 * @property {Object} toontasks A map of all toontask ids to a boolean for for whether the task was completed or not
 * @property {Object} cogSuits A map of cog suit ids to cog suit indexes
 * - 0: Sellbot
 * - 1: Cashbot
 * - 2: Lawbot
 * - 3: Bossbot  
 */
export default class LegacyToon {
    #id
    #laff
    #name

    #toontasks
    /** @type {AssignedToontasks} */
    #assignedToontasks
    #cogSuits
    #fish
    #racingTrophies
    #golfTrophies
    #flowers

    constructor(id, name, laff) {
        this.#id = id
        this.#name = name
        this.#laff = laff

        if (GameDataRepo.getToontasksData() == null) {
            return
        }

        this.#toontasks = GameDataRepo.getToontasksData().getEmptySet()

        this.#assignedToontasks = {
            0: null,
            1: null,
            2: null,
            3: null,
        }

        this.#cogSuits = GameDataRepo.getCogSuitsData().getEmptySet()

        this.#fish = GameDataRepo.getFishData().getEmptySet()

        this.#racingTrophies = {}
        this.#golfTrophies = {}
        this.#flowers = GameDataRepo.getFlowersData().getEmptySet()
    }

    /** @returns {String} */
    getId() {
        return this.#id
    }

    /** @returns {String} */
    getName() {
        return this.#name
    }

    getLaff() {
        return this.#laff
    }

    isToontaskCompleted(taskId) {
        if (this.#toontasks === undefined) {
            this.#toontasks = GameDataRepo.getToontasksData().getEmptySet()
        }

        return this.#toontasks[`${taskId}`] === true
    }

    setToontaskCompleted(taskId, isCompleted) {
        const toontask = GameDataRepo.getToontasksData().getToontaskById(taskId)

        if (this.isToontaskAssigned(taskId)) {
            this.unassignToontask(taskId)
        }

        for (const relatedTaskId of toontask.relatedTasks) {
            this.#toontasks[relatedTaskId.toString()] = isCompleted
        }

        this.#toontasks[`${taskId}`] = isCompleted
    }

    getToontasksProgress() {
        const toontasks = GameDataRepo.getToontasksData().getToontasks()
        const size = toontasks.length
        let completed = 0
        for (const value of Object.values(this.#toontasks)) {
            if (value === true) {
                completed++
            }
        }

        return completed / size
    }

    getToontasksMap() {
        return this.#toontasks
    }

    setToontasksMap(map) {
        if (map === undefined || map === null) return
        this.#toontasks = map

        for (const toontaskId in GameDataRepo.getToontasksData().getToontasks()) {
            if (this.#toontasks[`${toontaskId}`] === undefined) {
                this.#toontasks[`${toontaskId}`] = false
            }
        }
    }

    getCurrentNeighbourhood() {
        const toontasksData = GameDataRepo.getToontasksData()

        for (const neighbourhood of ["ttc", "dd", "dg", "mml", "brg", "ddl", "law", "boss"]) {
            for (const toontask of toontasksData.getToontasks(neighbourhood)) {
                if (!this.isToontaskCompleted(toontask.id)) {
                    return neighbourhood
                }
            }
        }

        // all neighbourhoods are done
        return null
    }

    isToontaskAssigned(id) {
        for (const key in this.#assignedToontasks) {
            const value = this.#assignedToontasks[key]
            if (value !== null && value.toontaskId === id) {
                return true
            }
        }

        return false
    }

    getAssignedToontasksMap() {
        if (this.#assignedToontasks === undefined) {
            this.#assignedToontasks = {
                0: null,
                1: null,
                2: null,
                3: null,
            }
        }

        return this.#assignedToontasks
    }

    /**
     * 
     * @returns {AssignedToontask} 
     */
    getAssignedToontask(taskId) {
        for (const key in this.#assignedToontasks) {
            const assigned = this.#assignedToontasks[key]

            if (assigned !== null && taskId === assigned.toontaskId) {
                return assigned
            }
        }

        throw Error("No assigned toontask found with id: " + taskId)
    }

    /**
     * 
     * @param {Number} index 
     * @param {AssignedToontask} assigned 
     */
    setAssignedToontask(index, assigned) {
        this.#assignedToontasks[index.toString()] = assigned
    }

    assignToontask(taskId) {
        const toontask = GameDataRepo.getToontasksData().getToontaskById(taskId)

        for (const relatedId of toontask.relatedTasks) {
            if (this.isToontaskAssigned(relatedId)) return
        }


        const firstStepIsVisit = toontask.steps[0][0].includes("Visit")

        let initialData = {
            toontaskId: taskId,
            option: 0,
            lastCompletedSteps: []
        }

        if (firstStepIsVisit) {
            initialData.lastCompletedSteps.push(0)
        }

        for (const key of ["0", "1", "2", "3"]) {
            if (this.#assignedToontasks[key] == null) {
                this.setAssignedToontask(parseInt(key), initialData)
                return
            }
        }

        throw Error("Attempted to assign toontask with no remaining slots")
    }

    unassignToontask(taskId) {
        for (const key of ["0", "1", "2", "3"]) {
            if (this.#assignedToontasks[key] !== null && this.#assignedToontasks[key].toontaskId === taskId) {
                this.#assignedToontasks[key] = null
                return
            }
        }

        throw Error("Could not delete toontask with id: " + taskId)
    }

    setAssignedToontaskOption(taskId, option) {
        for (const key of ["0", "1", "2", "3"]) {
            /** @type {AssignedToontask} */
            const assigned = this.#assignedToontasks[key]

            if (assigned !== null && assigned.toontaskId === taskId) {
                assigned.option = option

                this.setAssignedToontask(key, assigned)

                return
            }
        }
    }

    progressAssignedToontask(taskId) {
        for (const key in this.#assignedToontasks) {
            /** @type {AssignedToontask} */
            const assigned = this.#assignedToontasks[key]
            if (assigned !== null && assigned.toontaskId === taskId) {
                const toontask = GameDataRepo.getToontasksData().getToontaskById(taskId)

                const optionDone = assigned.option
                assigned.lastCompletedSteps.push(optionDone)
                assigned.option = 0

                this.#assignedToontasks[key] = assigned

                const numberOfStepsInToontask = toontask.steps.length


                const numberOfStepsCompleted = assigned.lastCompletedSteps.length
                if (numberOfStepsCompleted === numberOfStepsInToontask) {
                    this.setToontaskCompleted(taskId, true)
                    return
                }

                const nextStep = toontask.steps[numberOfStepsCompleted]
                if (nextStep[0].includes("Visit")) {
                    this.progressAssignedToontask(taskId)
                }
            }
        }
    }

    unprogressAssignedToontask(taskId) {
        const toontask = GameDataRepo.getToontasksData().getToontaskById(taskId)

        for (const key in this.#assignedToontasks) {
            const value = this.#assignedToontasks[key]
            if (value !== null && value.toontaskId === taskId) {
                this.#assignedToontasks[key].lastCompletedSteps.pop()
                this.#assignedToontasks[key].option = 0

                const numberOfStepsCompleted = this.getAssignedToontask(taskId).lastCompletedSteps.length
                const currentStep = toontask.steps[numberOfStepsCompleted][0]

                if (currentStep.includes("Visit") && numberOfStepsCompleted > 0) {
                    this.unprogressAssignedToontask(taskId)
                }
            }
        }
    }

    setAssignedToontasksMap(map) {
        if (map == null) {
            return
        }

        for (const key in map) {
            const assigned = map[key]

            if (assigned !== null) {
                const toontask = GameDataRepo.getToontasksData().getToontaskById(assigned.toontaskId)

                if (assigned.option === undefined) {
                    assigned.option = 0
                }
                this.setAssignedToontask(parseInt(key), assigned)
                let stepsCompleted = this.getAssignedToontask(assigned.toontaskId).lastCompletedSteps.length
                const steps = toontask.steps

                if (stepsCompleted >= steps.length) {
                    this.setToontaskCompleted(toontask.id)
                    continue
                }

                const options = steps[stepsCompleted]
                const option = options[0]
                if (option.includes("Visit")) {
                    this.progressAssignedToontask(assigned.toontaskId)
                }
            }
        }

        this.#assignedToontasks = map
    }

    getCogSuitsMap() {
        return this.#cogSuits
    }

    setCogSuit(type, index) {
        let key = ""

        switch (type) {
            case "sellbot":
                key = "0"
                break
            case "cashbot":
                key = "1"
                break
            case "lawbot":
                key = "2"
                break
            case "bossbot":
                key = "3"
                break
            default:
                throw Error()
        }

        this.#cogSuits[key] = index
    }

    getCogSuitIndexWithType(type) {
        if (this.#cogSuits === undefined) {
            this.#cogSuits = GameDataRepo.getCogSuitsData().getEmptySet()
        }

        switch (type) {
            case "sellbot":
                return this.#cogSuits["0"]
            case "cashbot":
                return this.#cogSuits["1"]
            case "lawbot":
                return this.#cogSuits["2"]
            case "bossbot":
                return this.#cogSuits["3"]
            default:
                throw Error()
        }
    }

    setCogSuitsMap(map) {
        for (const key in map) {
            this.#cogSuits[key] = map[key]
        }
    }

    isFishCaught(index) {
        return this.#fish[`${index}`]
    }

    setFish(index, isCaught) {
        this.#fish[`${index}`] = isCaught
    }

    getFishMap() {
        return this.#fish
    }

    setFishMap(map) {
        this.#fish = map
    }

    getTotalFishCaught() {
        let sum = 0
        for (const key in this.#fish) {
            if (this.#fish[key]) sum++
        }

        return sum
    }

    getRacingTrophiesMap() {
        return this.#racingTrophies
    }

    getRacingTrophiesUnlockedCount() {
        let count = 0

        for (const key in this.#racingTrophies) {
            const unlocked = this.#racingTrophies[key]

            if (unlocked) count++
        }

        return count
    }

    isRacingTrophyUnlocked(id) {
        return this.#racingTrophies[id.toString()]
    }

    setRacingTrophyUnlocked(id, isUnlocked) {
        this.#racingTrophies[id.toString()] = isUnlocked
    }

    setRacingTrophiesMap(racingTrophies) {
        this.#racingTrophies = racingTrophies
    }

    getGolfTrophiesMap() {
        return this.#golfTrophies
    }

    getGolfTrophiesUnlockedCount() {
        let count = 0

        for (const key in this.#golfTrophies) {
            const unlocked = this.#golfTrophies[key]

            if (unlocked) count++
        }

        return count
    }

    setGolfTrophiesMap(golfTrophies) {
        this.#golfTrophies = golfTrophies
    }

    getFlowersMap() {
        return this.#flowers
    }

    getTotalFlowersCollected() {
        let count = 0

        for (const key in this.#flowers) {
            const unlocked = this.#flowers[key]

            if (unlocked) count++
        }

        return count
    }

    isFlowerUnlocked(index) {
        return this.#flowers[index]
    }

    setFlowerUnlocked(index, isUnlocked) {
        this.#flowers[index] = isUnlocked
    }

    setFlowersMap(flowers) {
        this.#flowers = flowers
    }



    /**
     * 
     * @param {LegacyToon} other Another toon to compare to
     * @returns {Boolean} true if this toon is equal to the other toon
     */
    isEqualTo(other) {
        if (this.#id !== other.#id) {
            return false
        }

        for (const key in this.#toontasks) {
            if (this.#toontasks[key] !== other.#toontasks[key]) {
                return false
            }
        }

        for (const key in this.#cogSuits) {
            if (this.#cogSuits[key] !== other.#cogSuits[key]) {
                return false
            }
        }

        for (const key in this.#fish) {
            if (this.#fish[key] !== other.#fish[key]) {
                return false
            }
        }

        for (const key in this.#racingTrophies) {
            if (this.#racingTrophies[key] !== other.#racingTrophies[key]) {
                return false
            }
        }

        for (const key in this.#golfTrophies) {
            if (this.#golfTrophies[key] !== other.#golfTrophies[key]) {
                return false
            }
        }

        for (const key in this.#flowers) {
            if (this.#flowers[key] !== other.#flowers[key]) {
                return false
            }
        }

        return true
    }
}