import FeatureCard from "app/ui/home/FeatureCard";
import styled, { ThemeContext } from "styled-components";
import { useContext } from "react";

type Props = {
    width: number,
    onClick: () => void
}

export default function SillyMeterCard(props: Props) {
    const theme = useContext(ThemeContext)

    return (
        <FeatureCard
            title="Silly Meter"
            titleColor="#000000cc"
            smallWidth={props.width}
            mediumWidth={props.width}
            largeWidth={props.width}
            headerBackgroundColor={theme.colors.sillyMeter}
            contentBackgroundColor={theme.colors.sillyMeterDark}
            onClick={props.onClick}
        >

            <SillyMeterContent />

        </FeatureCard>
    )
}

const SillyMeterContent = styled.div`
    height: 100px;

    @media (min-width: 1440px) {
		height: 220px;
	}
`