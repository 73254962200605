import { Body1 } from "app/theme/Typography"
import styled from "styled-components"

type Props = {
    laff: number
}

export default function LaffText(props: Props) {
    return(
        <StyledLaffText>{props.laff}</StyledLaffText>
    )
}

const StyledLaffText = styled(Body1)`
    margin-left: 18px;
    font-family: ImpressBT;
    font-size: 16px;
`