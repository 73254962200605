import type ToontasksRepository from "data-toontasks/ToontasksRepository";
import { inject, injectable } from "tsyringe";
import GetToontasks, { GetToontasksResult } from "./GetToontasks";

@injectable()
export default class GetToontasksUseCase implements GetToontasks {

    constructor(
        @inject("ToontasksRepository") private toontasksRepository: ToontasksRepository
    ) {

    }

    async invoke(): Promise<GetToontasksResult> {
        const toontasks = (await this.toontasksRepository.getToontasks()).map(toontask => {
            return {
                id: toontask.id,
                title: toontask.title,
                reward: toontask.reward,
                steps: toontask.steps
            }
        })

        return {
            toontasks: toontasks
        }
    }
}