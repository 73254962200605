import {Column, Row, Spacer} from "../../../core-anvil/Layouts";
import React, {CSSProperties} from "react";
import {PointsEntry, PointsGroup} from "../CogSuitsPointsTableProps";
import {BodyMedium, Subtitle2} from "../../../app/theme/Typography";
import useScreenSize from "../../../core-theme/hooks/useScreenSize";
import ScreenSize from "../../../common/ScreenSize";

type TableGroupsProps = {
    entries: PointsEntry[],
    pointsLabel: string,
    cogTypeColor: string
}

export default function TableGroups(props: TableGroupsProps) {
    const screenSize = useScreenSize()
    switch (screenSize) {
        case ScreenSize.Large:
            return <LargeTableGroups {...props}/>
        case ScreenSize.Medium:
            return <MediumTableGroups {...props} />
        case ScreenSize.Small:
            return <SmallTableGroups {...props} />
    }
}

function SmallTableGroups(props: TableGroupsProps) {
    const groupElements = createSmallTableGroups(props.entries).map(group => {
        return (
            <Column style={{width: "100%"}}>
                <Row style={{paddingLeft: 20, backgroundColor: "#00000044", borderRadius: 12}}>
                    <GroupHeaderLabel groupName={group.cogName}/>
                </Row>
                <Row style={{width: "100%", marginLeft: 20, marginRight: 20, marginTop: 6}}>
                    <GroupLabels pointsLabel={props.pointsLabel} style={{gap: 4, marginTop: 6}}/>
                    <GroupEntries group={group} style={{marginLeft: 8}}/>
                </Row>
            </Column>
        )
    })

    return (
        <Column style={{
            width: "100%",
            paddingLeft: 24,
            paddingRight: 24,
            marginTop: 20,
            paddingBottom: 24,
            display: "flex",
            gap: 12
        }}>
            {groupElements}
        </Column>
    )
}

function MediumTableGroups(props: TableGroupsProps) {
    const groupElements = createMediumTableGroups(props.entries).map(group => {
        return (
            <Row verticalAlignment="center"
                 style={{width: "100%", height: 60, backgroundColor: "#00000066", borderRadius: 12}}>
                <Column verticalArrangement="center" style={{
                    width: 312,
                    height: "100%",
                    paddingLeft: 20,
                    backgroundColor: `${props.cogTypeColor}55`,
                    borderRadius: 12
                }}>
                    <GroupHeaderLabel groupName={group.cogName}/>
                    <BodyMedium style={{fontSize: 12, fontFamily: "VTPortableRemington"}}>{props.pointsLabel}</BodyMedium>
                </Column>
                <Row style={{width: "100%", marginLeft: 20, marginRight: 20}}>
                    <GroupEntries group={group} style={{marginLeft: 8}}/>
                </Row>
            </Row>
        )
    })

    return (
        <Column style={{
            width: 460,
            marginLeft: 24,
            marginRight: 24,
            marginTop: 20,
            paddingBottom: 24,
            display: "flex",
            gap: 12
        }}>
            {groupElements}
        </Column>
    )
}

function LargeTableGroups(props: TableGroupsProps) {
    const groups = createLargeTableGroups(props.entries)

    let shortGroups: PointsGroup[] = []
    let longGroups: PointsGroup[] = []

    groups.forEach(group => {
        if (group.entries.length === 5) {
            shortGroups.push(group)
        } else {
            longGroups.push(group)
        }
    })

    const shortGroupElements = shortGroups.map(group => {
        return <LargeShortGroup group={group} pointsLabel={props.pointsLabel} cogTypeColor={props.cogTypeColor}/>
    })

    const longGroupElements = longGroups.map(group => {
        return <LargeLongGroup group={group} pointsLabel={props.pointsLabel} cogTypeColor={props.cogTypeColor}/>
    })

    return (
        <Row style={{
            width: 900,
            marginLeft: 24,
            marginRight: 24,
            marginTop: 24,
            display: "flex",
            flexWrap: "wrap",
            gap: 6
        }}>
            {shortGroupElements}
            <Spacer style={{height: 8}}/>
            {longGroupElements}
        </Row>
    )
}

type LargeGroupProps = {
    group: PointsGroup,
    pointsLabel: string,
    cogTypeColor: string
}

function LargeShortGroup(props: LargeGroupProps) {
    return (
        <Row verticalAlignment="center"
             style={{width: 446, height: 68, backgroundColor: "#00000066", borderRadius: 12}}>
            <Column verticalArrangement="center" style={{
                width: 310,
                height: "100%",
                paddingLeft: 20,
                backgroundColor: `${props.cogTypeColor}55`,
                borderRadius: 12
            }}>
                <GroupHeaderLabel groupName={props.group.cogName}/>
                <BodyMedium style={{fontSize: 12, fontFamily: "VTPortableRemington"}}>{props.pointsLabel}</BodyMedium>
            </Column>
            <Row style={{width: "100%", marginLeft: 20, marginRight: 20}}>
                <GroupEntries group={props.group} style={{marginLeft: 8}}/>
            </Row>
        </Row>
    )
}

function LargeLongGroup(props: LargeGroupProps) {
    return (
        <Row verticalAlignment="center"
             style={{width: "100%", height: 68, backgroundColor: "#00000066", borderRadius: 12}}>
            <Column verticalArrangement="center" style={{
                width: 220,
                height: "100%",
                paddingLeft: 20,
                backgroundColor: `${props.cogTypeColor}55`,
                borderRadius: 12
            }}>
                <GroupHeaderLabel groupName={props.group.cogName}/>
                <BodyMedium style={{fontSize: 12, fontFamily: "VTPortableRemington"}}>{props.pointsLabel}</BodyMedium>
            </Column>
            <Row style={{width: "100%", marginLeft: 20, marginRight: 20}}>
                <GroupEntries group={props.group} style={{marginLeft: 8, gap: 9}}/>
            </Row>
        </Row>
    )
}

type GroupHeaderProps = {
    groupName: string
}

function GroupHeaderLabel(props: GroupHeaderProps) {
    return (
        <Subtitle2 style={{
            marginTop: 2,
            marginBottom: 2,
            fontSize: 15,
            fontFamily: "VTPortableRemington"
        }}>
            {props.groupName}
        </Subtitle2>
    )
}

type GroupLabelsProps = {
    pointsLabel: string
    style?: CSSProperties
}

function GroupLabels(props: GroupLabelsProps) {
    return (
        <Column style={props.style}>
            <BodyMedium style={{fontSize: 12, fontFamily: "VTPortableRemington"}}>Level</BodyMedium>
            <BodyMedium style={{fontSize: 12, fontFamily: "VTPortableRemington"}}>{props.pointsLabel}</BodyMedium>
        </Column>
    )
}


type GroupEntriesProps = {
    group: PointsGroup,
    style?: CSSProperties
}

function GroupEntries(props: GroupEntriesProps) {
    let elements: JSX.Element[] = []
    const screenSize = useScreenSize()
    let lastEntryWidth = 0
    let lastEntryMarginLeft = 0
    switch (screenSize) {
        case ScreenSize.Small:
            lastEntryWidth = 132
            lastEntryMarginLeft = 8
            break
        case ScreenSize.Medium:
            lastEntryWidth = 128
            lastEntryMarginLeft = 20
            break
        case ScreenSize.Large:
            lastEntryWidth = 100
            lastEntryMarginLeft = 8
            break
    }

    props.group.entries.forEach(entry => {
        if (entry.cogLevel === 50) {
            elements.push(
                <Column verticalArrangement="center" horizontalAlignment="center" style={{
                    width: lastEntryWidth,
                    height: 48,
                    marginLeft: lastEntryMarginLeft,
                    backgroundColor: "#000000aa",
                    borderRadius: 16,
                }}>
                    <BodyMedium style={{fontSize: 15, fontFamily: "VTPortableRemington"}}>{entry.cogLevel}</BodyMedium>
                    <BodyMedium
                        style={{fontSize: 13, fontFamily: "VTPortableRemington", marginTop: 4}}>THE END</BodyMedium>
                </Column>
            )
        } else {
            elements.push(
                <Column verticalArrangement="center" horizontalAlignment="center" style={{width: 43, height: 48}}>
                    <BodyMedium style={{fontSize: 15, fontFamily: "VTPortableRemington"}}>{entry.cogLevel}</BodyMedium>
                    <BodyMedium
                        style={{
                            fontSize: 13,
                            fontFamily: "VTPortableRemington",
                            marginTop: 4
                        }}>{entry.points}</BodyMedium>
                </Column>
            )
        }
    })

    return (
        <Row style={props.style}>
            {elements}
        </Row>
    )
}

function createSmallTableGroups(entries: PointsEntry[]): PointsGroup[] {
    let groups: PointsGroup[] = []
    entries.forEach(entry => {
        if (groups.length === 0) {
            groups.push({cogName: entry.cogName, entries: [entry]})
            return
        }

        let group = groups.pop()!!
        if (group.entries.length >= 5) {
            groups.push(group)
            groups.push({cogName: entry.cogName, entries: [entry]})
            return
        }

        group.entries.push(entry)
        groups.push(group)
    })

    return groups
}

function createMediumTableGroups(entries: PointsEntry[]): PointsGroup[] {
    return createSmallTableGroups(entries)
}

function createLargeTableGroups(entries: PointsEntry[]): PointsGroup[] {
    let groups: PointsGroup[] = []
    entries.forEach(entry => {
        const lastEntry = entries[entries.length - 1]
        const isLong = entry.cogLevel > 13 && entry.cogName === lastEntry.cogName

        if (groups.length === 0) {
            groups.push({cogName: entry.cogName, entries: [entry]})
            return
        }

        let maxGroupSize = 5
        if (isLong) maxGroupSize = 13

        let group = groups.pop()!!
        if (group.entries.length >= maxGroupSize) {
            groups.push(group)
            groups.push({cogName: entry.cogName, entries: [entry]})
            return
        }

        group.entries.push(entry)
        groups.push(group)
    })

    return groups
}