import FirestoreUser, {FirestoreToon} from "./firestore/FirestoreUser";
import User from "../../application-users/model/User";
import Toon from "../../application-users/model/toons/Toon";
import FirestoreToonConverter from "./FirestoreToonConverter";
import {injectable} from "tsyringe";

@injectable()
export default class FirestoreUserConverter {

    constructor(
        private firestoreToonConverter: FirestoreToonConverter
    ) {
    }

    convertToUser(firestoreUser: FirestoreUser, firestoreToons: FirestoreToon[]): User {
        let toons: Toon[] = []

        firestoreToons.forEach(firestoreToon => {
            const toon = this.firestoreToonConverter.convertToToon(firestoreToon)
            toons.push(toon)
        })

        return {
            id: firestoreUser.accountId,
            email: firestoreUser.email,
            toons: toons
        }
    }

    convertFromUser(user: User): FirestoreUser {
        throw Error("Not implemented")
    }

}