import {useTheme} from "styled-components";
import CogType from "../../app/entities/invasions/cogs/CogType";
import Card from "../../common/theme/components/Card";
import {Box, Column, Row, Spacer} from "../../core-anvil/Layouts";
import ic_cog_bossbot_icon from "../../common/resources/images/ic_cog_bossbot_icon.png";
import ic_cog_lawbot_icon from "../../common/resources/images/ic_cog_lawbot_icon.png";
import ic_cog_cashbot_icon from "../../common/resources/images/ic_cog_cashbot_icon.png";
import ic_cog_sellbot_icon from "../../common/resources/images/ic_cog_sellbot_icon.png";
import {LabelLarge, LabelSmall, TitleLarge} from "../../app/theme/Typography";
import ProgressBar from "../../common/theme/components/ProgressBar";
import {CSSProperties} from "react";

type Props = {
    cogType: CogType,
    cogName: string,
    progress: number,
    levelText: string,
    onClick: () => void,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function LargeCogTypeCard(props: Props) {
    const theme = useTheme()

    const backgroundColor =
        props.cogType === CogType.BOSSBOT ? theme.colors.bossbot :
            props.cogType === CogType.LAWBOT ? theme.colors.lawbot :
                props.cogType === CogType.CASHBOT ? theme.colors.cashbot :
                    theme.colors.sellbot

    return (
        <Card style={{width: 264, backgroundColor: backgroundColor, ...props.style}} onClick={props.onClick}>
            <Column>
                <CogTypeCardHeader cogType={props.cogType}/>
                <Spacer style={{height: 12}}/>
                <CogTypeCardDetails cogName={props.cogName} progress={props.progress} levelText={props.levelText}/>
                <Spacer style={{height: 8}}/>
                <CogTypeCardLevelButtons onPromoteClick={props.onPromoteClick} onDemoteClick={props.onDemoteClick}/>
                <Spacer style={{height: 10}}/>
            </Column>
        </Card>
    )
}

type CogTypeCardHeaderProps = {
    cogType: CogType
}

function CogTypeCardHeader(props: CogTypeCardHeaderProps) {
    const theme = useTheme()

    const backgroundColor =
        props.cogType === CogType.BOSSBOT ? theme.colors.bossbotDark :
            props.cogType === CogType.LAWBOT ? theme.colors.lawbotDark :
                props.cogType === CogType.CASHBOT ? theme.colors.cashbotDark :
                    theme.colors.sellbotDark

    const cogTypeIcon =
        props.cogType === CogType.BOSSBOT ? ic_cog_bossbot_icon :
            props.cogType === CogType.LAWBOT ? ic_cog_lawbot_icon :
                props.cogType === CogType.CASHBOT ? ic_cog_cashbot_icon :
                    ic_cog_sellbot_icon

    const label =
        props.cogType === CogType.BOSSBOT ? "Bossbot" :
            props.cogType === CogType.LAWBOT ? "Lawbot" :
                props.cogType === CogType.CASHBOT ? "Cashbot" :
                    "Sellbot"

    return (
        <Card style={{height: 100, width: "100%", backgroundColor: backgroundColor}}>
            <Row verticalAlignment="center" style={{height: "100%", marginLeft: 16}}>
                <img src={cogTypeIcon} alt="" style={{width: 60, height: 60}}/>
                <TitleLarge style={{marginLeft: 16, fontWeight: 600, marginTop: 4}}>{label}</TitleLarge>
            </Row>
        </Card>
    )
}

type CogTypeCardDetailsProps = {
    cogName: string,
    progress: number,
    levelText: string
}

function CogTypeCardDetails(props: CogTypeCardDetailsProps) {
    return (
        <Column style={{marginLeft: 32, marginRight: 32}}>
            <LabelLarge>{props.cogName}</LabelLarge>
            <ProgressBar progress={props.progress} max={1}/>
            <LabelSmall style={{marginTop: 4}}>{props.levelText}</LabelSmall>
        </Column>
    )
}

type CogTypeCardLevelButtonsProps = {
    onPromoteClick: () => void,
    onDemoteClick: () => void
}

function CogTypeCardLevelButtons(props: CogTypeCardLevelButtonsProps) {
    return (
        <Row style={{marginLeft: 12, marginRight: 12, userSelect: "none"}}>
            <Box style={{width: 28, height: 28, backgroundColor: "#00000044", borderRadius: 28, cursor: "pointer"}}
                 onClick={props.onDemoteClick}>
                <span className="material-icons" style={{color: "white", fontSize: 22}}>remove</span>
            </Box>
            <Spacer style={{flex: 1}}/>
            <Box style={{width: 28, height: 28, backgroundColor: "#ffffff33", borderRadius: 28, cursor: "pointer"}}
                 onClick={props.onPromoteClick}>
                <span className="material-icons" style={{color: "white", fontSize: 22}}>add</span>
            </Box>
        </Row>
    )
}