import GetInvasions from "app/application/invasions/getInvasions/GetInvasions"
import StatusRepository from "app/data/status/StatusRepository"
import LegacyToon from "app/data/user/LegacyToon"
import UserRepository from "app/data/users/UserRepository"
import HomeScreen from "app/ui/home/HomeScreen"
import Invasions, {InvasionsDestination} from "app/ui/invasions/invasions"
import CogSuitDetails from "app/ui/tracking/cogsuits/details/CogSuitDetails"
import Flowers from "app/ui/tracking/flowers/Flowers"
import Racing from "app/ui/tracking/racing/Racing"
import GetTrackingProgressUseCase from "application-users/usecases/getTrackingProgress/GetTrackingProgressUseCase"
import {ScreenSize} from "common/ScreenSize"
import Toontasks from "feat-toontasks/ToontasksNavigation"
import React from "react"
import {Redirect, Route, Switch, useHistory} from "react-router-dom"
import styled from "styled-components"
import DemoteCogSuit from "feat-tracking/application/demoteCogSuit/DemoteCogSuit"
import GetAssignedToontasks from "feat-tracking/application/getAssignedToontasks/GetAssignedToontasks"
import GetCogSuitsProgress from "feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgress"
import PromoteCogSuit from "feat-tracking/application/promoteCogSuit/PromoteCogSuit"
import CogSuitProgressEvaluator from "feat-tracking/entities/cogsuits/CogSuitProgressEvaluator"
import Tracking, {TrackingDestination} from "feat-tracking/ui/overview/TrackingScreen"
import TrackingViewModel from "feat-tracking/ui/overview/TrackingViewModel"
import CogSuitsScreen, {CogSuitsDestination} from "../../feat-cogsuits/CogSuitsScreen";
import FishingNavigation, {FishingNavigationDestination} from "../../feat-fishing/FishingNavigation";
import ToontaskGroup from "../../data-toontasks/ToontaskGroup";
import {ToontasksOverviewDestination} from "../../feat-toontasks/routes/ToontasksOverviewRoute";

type Props = {
    toon: LegacyToon,
    sideNavigationShown: boolean,
    userRepository: UserRepository,
    statusRepository: StatusRepository,
    getInvasions: GetInvasions,
    getAssignedToontasks: GetAssignedToontasks,
    getCogSuitsProgress: GetCogSuitsProgress,
    getTrackingProgress: GetTrackingProgressUseCase,
    promoteCogSuit: PromoteCogSuit,
    demoteCogSuit: DemoteCogSuit,
    screenSize: ScreenSize
}

export default function MainContent(props: Props) {
    const history = useHistory()

    return (
        <StyledMainContent>

            <Switch>
                <Route path="/tracking/cog-suits/bossbot">
                    <CogSuitDetails type="bossbot"/>
                </Route>

                <Route path="/tracking/cog-suits/lawbot">
                    <CogSuitDetails type="lawbot"/>
                </Route>

                <Route path="/tracking/cog-suits/cashbot">
                    <CogSuitDetails type="cashbot"/>
                </Route>

                <Route path="/tracking/cog-suits/sellbot">
                    <CogSuitDetails type="sellbot"/>
                </Route>

                <Route path="/tracking/toontasks">
                    <Toontasks screenSize={props.screenSize}/>
                </Route>

                <Route path={CogSuitsDestination.route}>
                    <CogSuitsScreen screenSize={props.screenSize}/>
                </Route>

                <Route path={FishingNavigationDestination.route}>
                    <FishingNavigation screenSize={props.screenSize}/>
                </Route>

                <Route path="/tracking/racing">
                    <Racing/>
                </Route>


                <Route path="/tracking/flowers">
                    <Flowers/>
                </Route>

                <Route path="/tracking">
                    <Tracking
                        screenSize={props.screenSize}
                        setPage={undefined}
                        viewModel={
                            new TrackingViewModel(
                                props.userRepository,
                                new CogSuitProgressEvaluator(),
                                props.getTrackingProgress,
                                props.getAssignedToontasks,
                                props.getCogSuitsProgress,
                                props.promoteCogSuit,
                                props.demoteCogSuit
                            )
                        }
                        userRepository={props.userRepository}
                        cogSuitsProgressEvaluator={new CogSuitProgressEvaluator()}
                        getTrackingProgress={props.getTrackingProgress}
                        getAssignedToontasks={props.getAssignedToontasks}
                    />
                </Route>

                <Route path="/invasions">
                    <Invasions/>
                </Route>

                <Route path="/home">
                    <HomeScreen
                        userRepository={props.userRepository}
                        statusRepository={props.statusRepository}
                        getInvasions={props.getInvasions}
                        screenSize={props.screenSize}
                        onTrackingClick={() => history.push(TrackingDestination.createRoute())}
                        onToontasksClick={() => history.push(ToontasksOverviewDestination.createRoute(ToontaskGroup.ToontownCentral))}
                        onCogSuitsClick={() => history.push(CogSuitsDestination.createRoute())}
                        onFishingClick={() => history.push(FishingNavigationDestination.createRoute())}
                        onRacingClick={() => {}}
                        onGolfClick={() => {}}
                        onFlowersClick={() => {}}
                        onInvasionsClick={() => history.push(InvasionsDestination.createRoute())}
                        onSillyMeterClick={() => {}}
                        onToonGuideClick={() => {}}
                    />
                </Route>

                <Route path="/">
                    <Redirect to="/home"/>
                </Route>
            </Switch>

        </StyledMainContent>
    )
}

const StyledMainContent = styled.div`
  width: auto;
  height: auto;
  flex-grow: 1;
`