import {injectable} from "tsyringe";

const TOTAL_GOLF_TROPHIES = 30

export type GolfProgressEvaluatorResult ={
    cups: number
    progress: number,
    trophies: number,
    nextLaffProgress: number | null
}

@injectable()
export default class GolfProgressEvaluator {

    evaluate(golf: Map<number, boolean>): GolfProgressEvaluatorResult {
        let count = 0

        golf.forEach((isCollected, index) => {
            if(isCollected) {
                count++
            }  
        })
    
        return {
            progress: count / TOTAL_GOLF_TROPHIES,
            trophies: count,
            cups: Math.floor(count / 10),
            nextLaffProgress: count < TOTAL_GOLF_TROPHIES ? (Math.floor(count / 10) + 1) * 10 / TOTAL_GOLF_TROPHIES : null
        }
    }

}