import {injectable, Lifecycle, scoped} from "tsyringe";
import {BehaviorSubject} from "rxjs";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export default class FishingSpeciesScreenModel {

    state = new BehaviorSubject<FishingSpeciesScreenState>(createEmptyState())

    num = Math.random()

    showFishInsightsDialog(fishId: number) {
        this.state.next({
            ...this.state.value,
            dialog: {
                isVisible: true,
                fishId: fishId
            }
        })
    }

    hideFishInsightsDialog() {
        this.state.next({
            ...this.state.value,
            dialog: {
                isVisible: false,
                fishId: null
            }
        })
    }

}

type FishingSpeciesScreenState = {
    dialog: FishingSpeciesScreenDialogState
}

type FishingSpeciesScreenDialogState = {
    isVisible: boolean,
    fishId: number | null
}

function createEmptyState(): FishingSpeciesScreenState {
    return {
        dialog: {
            isVisible: false,
            fishId: null
        }
    }
}