import CogType from "../../app/entities/invasions/cogs/CogType";
import {CogTypeCardState} from "../CogSuitsScreenState";
import {CSSProperties} from "react";
import ScreenSize from "../../common/ScreenSize";
import SmallCogSuitTypesGrid from "./SmallCogSuitTypesGrid";
import MediumCogSuitTypesGrid from "./MediumCogSuitTypesGrid";
import LargeCogSuitTypesGrid from "./LargeCogSuitTypesGrid";

type Props = {
    screenSize: ScreenSize,
    bossbot: CogTypeCardState,
    lawbot: CogTypeCardState,
    cashbot: CogTypeCardState,
    sellbot: CogTypeCardState,
    onCogTypeClick: (cogType: CogType) => void,
    onPromoteClick: (cogType: CogType) => void,
    onDemoteClick: (cogType: CogType) => void
    style?: CSSProperties
}

export default function CogSuitTypesGrid(props: Props) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallCogSuitTypesGrid {...props} />
        case ScreenSize.Medium:
            return <MediumCogSuitTypesGrid {...props} />
        case ScreenSize.Large:
            return <LargeCogSuitTypesGrid {...props} />
    }
}