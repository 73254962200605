import AssignedToontask from "application-users/model/toons/toontasks/AssignedToontask";
import ToontaskGroup from "data-toontasks/ToontaskGroup";
import type ToontasksRepository from "data-toontasks/ToontasksRepository";
import UserRepository from "data-users/UserRepository";
import {BehaviorSubject} from "rxjs";
import {inject, injectable} from "tsyringe";
import GetToontasksProgress, {AssignedToontaskProgress, GetToontasksProgressResult} from "./GetToontasksProgress";

@injectable()
export default class GetToontasksProgressUseCase implements GetToontasksProgress {
    private result = new BehaviorSubject<GetToontasksProgressResult>({
        started: {
            topLeft: null,
            topRight: null,
            bottomLeft: null,
            bottomRight: null
        },
        completedIds: [],
        toontownCentral: {
            notStartedIds: [],
            completedIds: []
        },
        donaldsDock: {
            notStartedIds: [],
            completedIds: []
        },
        daisyGardens: {
            notStartedIds: [],
            completedIds: []
        },
        minniesMelodyland: {
            notStartedIds: [],
            completedIds: []
        },
        theBrrrgh: {
            notStartedIds: [],
            completedIds: []
        },
        donaldsDreamland: {
            notStartedIds: [],
            completedIds: []
        },
        lawbot: {
            notStartedIds: [],
            completedIds: []
        },
        bossbot: {
            notStartedIds: [],
            completedIds: []
        }
    })

    constructor(
        private userRepository: UserRepository,
        @inject("ToontasksRepository") private toontasksRepository: ToontasksRepository
    ) {
        this.listenForUser()
    }

    invoke(): BehaviorSubject<GetToontasksProgressResult> {
        return this.result
    }

    private listenForUser() {
        this.userRepository.currentToon.subscribe(async toon => {
            if (toon === null) return

            const assigned = toon.toontasks.getAssignedToontasks()

            this.result.next({
                started: {
                    topLeft: await this.createAssignedToontaskProgress(assigned.topLeft),
                    topRight: await this.createAssignedToontaskProgress(assigned.topRight),
                    bottomLeft: await this.createAssignedToontaskProgress(assigned.bottomLeft),
                    bottomRight: await this.createAssignedToontaskProgress(assigned.bottomRight)
                },
                completedIds: toon.toontasks.getCompletedToontaskIds(),
                toontownCentral: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.ToontownCentral),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.ToontownCentral)
                },
                donaldsDock: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.DonaldsDock),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.DonaldsDock)
                },
                daisyGardens: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.DaisyGardens),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.DaisyGardens)
                },
                minniesMelodyland: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.MinniesMelodyland),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.MinniesMelodyland)
                },
                theBrrrgh: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.TheBrrrgh),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.TheBrrrgh)
                },
                donaldsDreamland: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.DonaldsDreamland),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.DonaldsDreamland)
                },
                lawbot: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.Lawbot),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.Lawbot)
                },
                bossbot: {
                    notStartedIds: await this.filterToontaskIds(toon.toontasks.getNotStartedToontaskIds(), ToontaskGroup.Bossbot),
                    completedIds: await this.filterToontaskIds(toon.toontasks.getCompletedToontaskIds(), ToontaskGroup.Bossbot)
                }
            })
        })
    }

    private async filterToontaskIds(toontaskIds: number[], group: ToontaskGroup): Promise<number[]> {
        const toontasks = await this.toontasksRepository.getToontasks()

        return toontaskIds.filter(toontaskId => {
            const toontask = toontasks.find(toontask => toontask.id === toontaskId)

            if(toontask === undefined) {
                return false
            }

            return toontask.group === group
        })
    }

    private async createAssignedToontaskProgress(assigned: AssignedToontask | null): Promise<AssignedToontaskProgress | null> {
        if (assigned === null) return null

        const toontask = await this.toontasksRepository.getToontask(assigned.toontaskId)

        return {
            toontaskId: assigned.toontaskId,
            progress: assigned.stepsCompleted.length / toontask.steps.length,
            stepsCompleted: assigned.stepsCompleted.length
        }
    }
}