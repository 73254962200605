import styled from "styled-components";
import Card from "../../../common/theme/components/Card";
import {Body1, Headline6} from "../../../app/theme/Typography";
import {CSSProperties} from "react";

type Props = {
    title: string,
    reward: string,
    color: string,
    onClick: () => void,
    style?: CSSProperties
}

export function ToontaskCard(props: Props) {
    return(
        <StyledToontaskCard style={{ backgroundColor: props.color, ...props.style}} onClick={props.onClick}>
            <ToontaskCardTitle>{props.title}</ToontaskCardTitle>
            <ToontaskCardReward>{props.reward}</ToontaskCardReward>
        </StyledToontaskCard>
    )
}

const StyledToontaskCard = styled(Card)`
  padding-top: 10px;
  padding-bottom: 14px;
  cursor: pointer;
  border-radius: 12px;
`

const ToontaskCardTitle = styled(Headline6)`
  color: white;
  margin-left: 26px;
`

const ToontaskCardReward = styled(Body1)`
  color: white;
  margin-left: 26px;
  font-weight: 300;
`