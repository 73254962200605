import type AuthManager from "auth/AuthManager";
import Toon from "application-users/model/toons/Toon";
import {firestore} from "firebase";
import {BehaviorSubject, combineLatestWith, mergeMap} from "rxjs";
import {inject, injectable} from "tsyringe";
import UserDataSource from "../UserDataSource";
import FirestoreToonConverter from "./FirestoreToonConverter";
import UserFirestoreMediator from "./firestore/UserFirestoreMediator";
import User from "application-users/model/User";
import FirestoreUserConverter from "./FirestoreUserConverter";

@injectable()
export default class RemoteUserDataSource implements UserDataSource {

    #authManager: AuthManager
    #firestoreToonConverter: FirestoreToonConverter

    user = new BehaviorSubject<User | null>(null)

    constructor(
        @inject("AuthManager") authManager: AuthManager,
        private firestoreMediator: UserFirestoreMediator,
        firestoreToonConverter: FirestoreToonConverter,
        private firestoreUserConverter: FirestoreUserConverter
    ) {
        this.#authManager = authManager
        this.#firestoreToonConverter = firestoreToonConverter

        const firestoreToons = this.#authManager.userId.pipe(mergeMap(userId => {
            if (userId !== null) {
                return this.firestoreMediator.listenForToons(userId)
            } else {
                return new BehaviorSubject(null)
            }
        }))

        const firestoreUser = this.#authManager.userId.pipe(mergeMap(userId => {
            if (userId !== null) {
                return this.firestoreMediator.listenForUser(userId)
            } else {
                return new BehaviorSubject(null)
            }
        }))

        firestoreUser.pipe(combineLatestWith(firestoreToons)).subscribe(([firestoreUser, firestoreToons]) => {
            if(firestoreUser === null || firestoreToons === null) return

            const user = this.firestoreUserConverter.convertToUser(firestoreUser, firestoreToons)
            this.user.next(user)
        })
    }

    async updateToon(userId: string, toon: Toon): Promise<void> {
        const document = await firestore().collection("users")
        .doc(userId)
        .collection("toons")
        .doc(toon.id)

        const firestoreToon = this.#firestoreToonConverter.covertFromToon(toon)

        document.set(firestoreToon)
    }
}