import {Box, Column, Row} from "../../../../core-anvil/Layouts";
import {BodyMedium} from "../../../../app/theme/Typography";
import {CSSProperties} from "react";
import {useTheme} from "styled-components";
import useViewModel from "../../../../core-theme/hooks/useViewModel";
import {DependencyContainer} from "tsyringe";
import FishBestSpotsTableViewModel from "./FishBestSpotsTableViewModel";
import useRxState from "../../../../core-theme/hooks/useRxState";
import ScreenSize from "../../../../common/ScreenSize";
import useScreenSize from "../../../../core-theme/hooks/useScreenSize";

export type Props = {
    container: DependencyContainer,
    style?: CSSProperties
}

type SizedProps = {
    rowHeight: number,
    rowGap: number,
    headerMarginBottom: number,
    columnHeadingFontSize: number,
    rodColumn: ColumnItemSizedProps,
    bestSpotColumn: ColumnItemSizedProps,
    catchRateColumn: ColumnItemSizedProps
}

type ColumnItemSizedProps = {
    width: number,
    fontSize: number
}

export default function FishBestSpotsTable(props: Props) {
    const theme = useTheme()

    const viewModel = useViewModel(props.container, FishBestSpotsTableViewModel)
    const uiState = useRxState(viewModel.uiState)

    const sizedProps = getSizedProps(useScreenSize())

    return (
        <Column style={{gap: sizedProps.rowGap, ...props.style}}>
            <HeaderTableRow style={{height: sizedProps.rowHeight, marginBottom: sizedProps.headerMarginBottom}}/>
            <RodTableRow rod="Twig" bestSpotNeighborhood={uiState.twig.bestSpotNeighborhood}
                         bestSpotArea={uiState.twig.bestSpotArea}
                         catchRate={uiState.twig.odds}
                         rodBackgroundColor={theme.colors.twigRod} style={{height: sizedProps.rowHeight}}/>
            <RodTableRow rod="Bamboo" bestSpotNeighborhood={uiState.bamboo.bestSpotNeighborhood}
                         bestSpotArea={uiState.bamboo.bestSpotArea}
                         catchRate={uiState.bamboo.odds}
                         rodBackgroundColor={theme.colors.bambooRod} style={{height: sizedProps.rowHeight}}/>
            <RodTableRow rod="Hardwood" bestSpotNeighborhood={uiState.hardwood.bestSpotNeighborhood}
                         bestSpotArea={uiState.hardwood.bestSpotArea}
                         catchRate={uiState.hardwood.odds}
                         rodBackgroundColor={theme.colors.hardwoodRod} style={{height: sizedProps.rowHeight}}/>
            <RodTableRow rod="Steel" bestSpotNeighborhood={uiState.steel.bestSpotNeighborhood}
                         bestSpotArea={uiState.steel.bestSpotArea}
                         catchRate={uiState.steel.odds}
                         rodBackgroundColor={theme.colors.steelRod} style={{height: sizedProps.rowHeight}}/>
            <RodTableRow rod="Gold" bestSpotNeighborhood={uiState.gold.bestSpotNeighborhood}
                         bestSpotArea={uiState.gold.bestSpotArea}
                         catchRate={uiState.gold.odds}
                         rodBackgroundColor={theme.colors.goldRod} style={{height: sizedProps.rowHeight}}/>
        </Column>
    )
}

type HeaderTableRowProps = {
    style?: CSSProperties
}

function HeaderTableRow(props: HeaderTableRowProps) {
    return (
        <TableRow rod="Rod" bestSpotNeighborhood="Best Spot" bestSpotArea="" catchRate="Catch Rate" isHeader={true}
                  style={props.style}/>
    )
}

type RodTableRowProps = {
    rod: string,
    bestSpotNeighborhood: string,
    bestSpotArea: string,
    catchRate: string,
    rodBackgroundColor: string,
    style?: CSSProperties
}

function RodTableRow(props: RodTableRowProps) {
    return (
        <TableRow rod={props.rod}
                  bestSpotNeighborhood={props.bestSpotNeighborhood}
                  bestSpotArea={props.bestSpotArea}
                  catchRate={props.catchRate}
                  rodBackgroundColor={props.rodBackgroundColor} style={{
            backgroundColor: "#ffffff14",
            borderRadius: 100,
            ...props.style
        }}/>
    )
}

type TableRowProps = {
    rod: string,
    bestSpotNeighborhood: string,
    bestSpotArea: string,
    catchRate: string,
    isHeader?: boolean,
    rodBackgroundColor?: string,
    style?: CSSProperties
}

function TableRow(props: TableRowProps) {
    const sizedProps = getSizedProps(useScreenSize())

    return (
        <Row horizontalArrangement="space-between" style={props.style}>
            <RodColumnItem isHeader={props.isHeader ?? false} rod={props.rod} rodColor={props.rodBackgroundColor}
                           style={{width: sizedProps.rodColumn.width, height: "100%"}}/>
            <BestSpotColumnItem isHeader={props.isHeader ?? false} bestSpotNeighborhood={props.bestSpotNeighborhood}
                                bestSpotArea={props.bestSpotArea}
                                style={{width: sizedProps.bestSpotColumn.width, height: "100%"}}/>
            <CatchRateColumnItem isHeader={props.isHeader ?? false} catchRate={props.catchRate}
                                 style={{width: sizedProps.catchRateColumn.width, height: "100%"}}/>
        </Row>
    )
}

type RodColumnItemProps = {
    isHeader: boolean,
    rod: string,
    rodColor?: string,
    style?: CSSProperties
}

function RodColumnItem(props: RodColumnItemProps) {
    const sizedProps = getSizedProps(useScreenSize())

    const fontSize = props.isHeader ? sizedProps.columnHeadingFontSize : sizedProps.rodColumn.fontSize

    return (
        <Box style={{...props.style, backgroundColor: props.rodColor, borderRadius: 100}}>
            <BodyMedium style={{fontSize: fontSize, fontWeight: 500}}>
                {props.rod}
            </BodyMedium>
        </Box>
    )
}

type BestColumnItemProps = {
    bestSpotNeighborhood: string,
    bestSpotArea: string,
    isHeader: boolean,
    style?: CSSProperties
}

function BestSpotColumnItem(props: BestColumnItemProps) {
    const sizedProps = getSizedProps(useScreenSize())

    const fontWeight = props.isHeader ? 500 : 300
    const fontSize = props.isHeader ? sizedProps.columnHeadingFontSize : sizedProps.bestSpotColumn.fontSize

    return (
        <Column style={props.style} horizontalAlignment="center" verticalArrangement="center">
            <BodyMedium style={{fontSize: fontSize, fontWeight: fontWeight, textAlign: "center"}}>
                {props.bestSpotNeighborhood}
            </BodyMedium>
            <BodyMedium style={{
                fontSize: fontSize,
                fontWeight: fontWeight,
                textAlign: "center",
                textTransform: "capitalize",
                marginTop: 2
            }}>
                {props.bestSpotArea}
            </BodyMedium>
        </Column>
    )
}

type CatchRateColumnItemProps = {
    catchRate: string,
    isHeader: boolean,
    style?: CSSProperties
}

function CatchRateColumnItem(props: CatchRateColumnItemProps) {
    const sizedProps = getSizedProps(useScreenSize())

    const fontSize = props.isHeader ? sizedProps.columnHeadingFontSize : sizedProps.catchRateColumn.fontSize

    return (
        <Box style={props.style}>
            <BodyMedium style={{fontSize: fontSize, fontWeight: 500}}>
                {props.catchRate}
            </BodyMedium>
        </Box>
    )
}

function getSizedProps(screenSize: ScreenSize): SizedProps {
    switch (screenSize) {
        case ScreenSize.Large:
            return {
                rowHeight: 52,
                rowGap: 12,
                headerMarginBottom: -12,
                columnHeadingFontSize: 17,
                rodColumn: {
                    width: 100,
                    fontSize: 18
                },
                bestSpotColumn: {
                    width: 148,
                    fontSize: 16
                },
                catchRateColumn: {
                    width: 100,
                    fontSize: 18
                }
            }
        case ScreenSize.Medium:
            return {
                rowHeight: 48,
                rowGap: 12,
                headerMarginBottom: -12,
                columnHeadingFontSize: 17,
                rodColumn: {
                    width: 92,
                    fontSize: 17
                },
                bestSpotColumn: {
                    width: 148,
                    fontSize: 15
                },
                catchRateColumn: {
                    width: 92,
                    fontSize: 17
                }
            }
        case ScreenSize.Small:
            return {
                rowHeight: 40,
                rowGap: 8,
                headerMarginBottom: -4,
                columnHeadingFontSize: 16,
                rodColumn: {
                    width: 88,
                    fontSize: 16
                },
                bestSpotColumn: {
                    width: 140,
                    fontSize: 14
                },
                catchRateColumn: {
                    width: 80,
                    fontSize: 15
                }
            }
    }
}