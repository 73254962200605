import GameDataRepository from "app/data/GameDataRepository";
import Toontask, { StepOption } from "feat-tracking/entities/toontasks/Toontask";
import ToontaskGroup from "./ToontaskGroup";
import ToontasksRepository from "./ToontasksRepository";

export default class TTRToontasksRepository implements ToontasksRepository {
    async getToontask(toontaskId: number): Promise<Toontask> {
        const toontask = (await this.getToontasks()).find(toontask => toontask.id === toontaskId)
        if(!toontask) {
            throw Error("Could not find toontask with id: " + toontaskId)
        }
        return toontask
    }

    async getToontasks(): Promise<Toontask[]> {
        const toontasksData = GameDataRepository.getToontasksData()

        const toontasks = [
            ...toontasksData.getToontasks("ttc"),
            ...toontasksData.getToontasks("dd"),
            ...toontasksData.getToontasks("dg"),
            ...toontasksData.getToontasks("mml"),
            ...toontasksData.getToontasks("brgh"),
            ...toontasksData.getToontasks("ddl"),
            ...toontasksData.getToontasks("law"),
            ...toontasksData.getToontasks("boss")
        ]

        return toontasks.map(toontask => {
            let newToontask: Toontask = {
                id: toontask.id,
                title: toontask.title,
                group: this.parseToontaskGroup(toontask.neighbourhood),
                reward: toontask.reward,
                steps: []
            }

            toontask.steps.forEach(step => {
                const options: StepOption[] = []

                for (let optionId = 0; optionId < step.length; optionId++) {
                    options.push({
                        id: optionId,
                        name: step[optionId]
                    })
                }

                newToontask.steps.push({
                    options: options
                })
            })

            return newToontask
        })
    }

    private parseToontaskGroup(group: string): ToontaskGroup {
        switch(group.toLowerCase()) {
            case "ttc": return ToontaskGroup.ToontownCentral
            case "dd": return ToontaskGroup.DonaldsDock
            case "dg": return ToontaskGroup.DaisyGardens
            case "mml": return ToontaskGroup.MinniesMelodyland
            case "brg": return ToontaskGroup.TheBrrrgh
            case "ddl": return ToontaskGroup.DonaldsDreamland
            case "law": return ToontaskGroup.Lawbot
            case "boss": return ToontaskGroup.Bossbot
            default: throw Error("Could not parse toontask group string: " + group)
        }
    }
}