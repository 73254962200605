import {injectable} from "tsyringe";
import UserRepository from "../../../data-users/UserRepository";

@injectable()
export default class SetCurrentToon {

    constructor(private userRepository: UserRepository) {
    }

    async invoke(toonId: string) {
        await this.userRepository.updateCurrentToonId(toonId)
    }

}