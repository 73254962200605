import "./toontask-info.scss";
import LegacyToon from "app/data/user/LegacyToon";
import bossbot_playground_image from "common/resources/images/playground_boss.png";
import brg_playground_image from "common/resources/images/playground_brg.png";
import dd_playground_image from "common/resources/images/playground_dd.png";
import ddl_playground_image from "common/resources/images/playground_ddl.png";
import dg_playground_image from "common/resources/images/playground_dg.png";
import lawbot_playground_image from "common/resources/images/playground_law.png";
import mml_playground_image from "common/resources/images/playground_mml.png";
import ttc_playground_image from "common/resources/images/playground_ttc.png";
import {NEIGHBOURHOOD_COLOURS} from "common/theme/NeighbourhoodResources";
import ToontaskGroup from "data-toontasks/ToontaskGroup";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {container} from "tsyringe";
import ToontaskInfoCard from "./info/ToontaskInfoCard";
import ToontaskInfoViewModel from "./ToontaskInfoViewModel";
import ToontaskState from "./ToontaskState";
import {Box, Column, Row, Spacer} from "core-anvil/Layouts";
import {ScreenSize} from "../../common/ScreenSize";
import ToontaskStepsPath, {StepsPathStep} from "./steps/ToontaskStepsPath";
import {StepsCardStep} from "./info/steps/StepsCard";
import NavigationBanner from "../../core-theme/components/navigation/NavigationBanner";
import {useTheme} from "styled-components";

export type ToontaskInfoScreenState = {
    group: ToontaskGroup,
    toontaskTitle: string,
    toontaskReward: string,
    toontaskProgress: number,
    toontaskProgressText: string,
    currentStep: number,
    currentStepText: string,
    stepsCardSteps: StepsCardStep[],
    stepsPathSteps: StepsPathStep[],
    toontaskState: ToontaskState
}

interface ToontaskInfoState {
    toon: LegacyToon,
    width: number,
    height: number
}

type Props = {
    screenSize: ScreenSize,
    onBackClick: () => void
}

export default function ToontaskInfoScreen(props: Props) {
    const {id}: any = useParams()

    const viewModel = useRef(container.resolve(ToontaskInfoViewModel))

    viewModel.current.onToontaskSelected(parseInt(id))

    const [uiState, setUIState] = useState<ToontaskInfoScreenState>(viewModel.current.uiState.value)

    useEffect(() => {
        const sub = viewModel.current.uiState.subscribe(setUIState)
        return () => sub.unsubscribe()
    }, [])

    const theme = useTheme()

    return <ToontaskInfoScreenContent
        {...uiState} {...props}
        onToontaskStarted={() => viewModel.current.onToontaskStarted()}
        onToontaskCompleted={() => viewModel.current.onToontaskCompleted()}
        onToontaskDropped={() => viewModel.current.onToontaskDropped()}
        onNextStepClick={() => viewModel.current.onNextStepClick()}
        onUndoStepClick={() => viewModel.current.onUndoStepClick()}
        onToontaskReset={() => viewModel.current.onResetClick()}
        navBannerColor={theme.colors.getToontaskGroupDarkColor(uiState.group)}
    />
}

type ContentProps = {
    screenSize: ScreenSize,
    toontaskTitle: string,
    toontaskReward: string,
    toontaskState: ToontaskState,
    group: ToontaskGroup,
    toontaskProgress: number,
    toontaskProgressText: string,
    currentStep: number,
    currentStepText: string,
    stepsCardSteps: StepsCardStep[],
    stepsPathSteps: StepsPathStep[],
    navBannerColor: string,
    onToontaskStarted: () => void,
    onToontaskCompleted: () => void,
    onToontaskDropped: () => void,
    onNextStepClick: () => void,
    onUndoStepClick: () => void,
    onBackClick: () => void,
    onToontaskReset: () => void
}

export function ToontaskInfoScreenContent(props: ContentProps) {
    return <ToontaskInfo {...props} />
}

class ToontaskInfo extends React.Component<ContentProps, ToontaskInfoState> {
    constructor(props: Readonly<ContentProps>) {
        super(props)

        this.state = {
            toon: new LegacyToon(),
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    componentDidUpdate(prevProps: Readonly<ContentProps>, _prevState: Readonly<ToontaskInfoState>, _snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({})
        }
    }

    render() {
        return (
            <Box alignment="topCenter" style={{width: "100%", height: "100%"}}>
                {this.renderBackground(this.props.group)}
                {this.renderContent(
                    this.props.screenSize,
                    this.props.toontaskTitle,
                    this.props.toontaskReward,
                    this.props.group,
                    this.props.toontaskState,
                    this.props.toontaskProgress,
                    this.props.toontaskProgressText,
                    this.props.currentStep,
                    this.props.currentStepText,
                    this.props.stepsCardSteps,
                    this.props.stepsPathSteps,
                    this.props.navBannerColor,
                    this.props.onToontaskStarted,
                    this.props.onToontaskCompleted,
                    this.props.onToontaskDropped,
                    this.props.onNextStepClick,
                    this.props.onUndoStepClick,
                    this.props.onBackClick,
                    this.props.onToontaskReset
                )}
            </Box>
        )
    }

    renderBackground(group: ToontaskGroup): JSX.Element {
        const backgroundImage = getBackgroundImage(group)
        const tintColour = getTintColour(group)

        return (
            <div style={{width: "100%", height: "100%", position: "absolute", zIndex: -1}}>
                <img className="toontask-info-page__background-image" alt="" src={backgroundImage}/>
                <div className="toontask-info-page__background-tint" style={{backgroundColor: tintColour}}/>
            </div>
        )
    }

    renderContent(
        screenSize: ScreenSize,
        toontaskTitle: string,
        toontaskReward: string,
        group: ToontaskGroup,
        toontaskState: ToontaskState,
        toontaskProgress: number,
        toontaskProgressText: string,
        currentStep: number,
        currentStepText: string,
        stepsCardStep: StepsCardStep[],
        toontaskStepsPathSteps: StepsPathStep[],
        navBannerColor: string,
        onToontaskStarted: () => void,
        onToontaskCompleted: () => void,
        onToontaskDropped: () => void,
        onNextStepClick: () => void,
        onUndoStepClick: () => void,
        onBackClick: () => void,
        onToontaskReset: () => void
    ): JSX.Element {
        switch (screenSize) {
            case ScreenSize.Small:
                return (
                    <Column style={{width: "100%", paddingBottom: 60}}>
                        <NavigationBanner
                            screenSize={screenSize}
                            backgroundColor={navBannerColor}
                            icon={"check_box"}
                            featureName={"Tracking"}
                            screenName={"Toontask Info"}
                            previousScreenName={"Toontasks"}
                            onBackClick={onBackClick}/>
                        <Column horizontalAlignment="center" style={{width: "100%", marginTop: 40}}>
                            <ToontaskInfoCard
                                screenSize={screenSize}
                                toontaskTitle={toontaskTitle}
                                toontaskReward={toontaskReward}
                                group={group}
                                toontaskState={toontaskState}
                                toontaskProgress={toontaskProgress}
                                toontaskProgressText={toontaskProgressText}
                                currentStep={currentStep}
                                currentStepText={currentStepText}
                                steps={stepsCardStep}
                                onToontaskStarted={onToontaskStarted}
                                onToontaskDropped={onToontaskDropped}
                                onToontaskCompleted={onToontaskCompleted}
                                onToontaskReset={onToontaskReset}
                                onNextStepClick={onNextStepClick}
                                onUndoStepClick={onUndoStepClick}/>
                            <Spacer style={{height: 36}}/>
                            <ToontaskStepsPath
                                steps={toontaskStepsPathSteps}
                                currentStep={currentStep}
                                group={group}
                                style={{width: 260}}
                            />
                        </Column>
                    </Column>
                )
            case ScreenSize.Medium:
            case ScreenSize.Large:
                const spacerWidth = screenSize === ScreenSize.Medium ? 40 : 120
                const stepsPathWidth = screenSize === ScreenSize.Medium ? 260 : 360
                const rootPaddingRight = screenSize === ScreenSize.Medium ? 20 : 160
                return (
                    <Column style={{width: "100%", paddingBottom: 60}}>
                        <NavigationBanner
                            screenSize={screenSize}
                            backgroundColor={navBannerColor}
                            icon={"check_box"}
                            featureName={"Tracking"}
                            screenName={"Toontask Info"}
                            previousScreenName={"Toontasks"}
                            onBackClick={onBackClick}/>
                        <Column horizontalAlignment="center"
                                style={{paddingLeft: 20, paddingRight: rootPaddingRight, marginTop: 40}}>
                            <Row>
                                <ToontaskInfoCard
                                    screenSize={screenSize}
                                    toontaskTitle={toontaskTitle}
                                    toontaskReward={toontaskReward}
                                    group={group}
                                    toontaskState={toontaskState}
                                    toontaskProgress={toontaskProgress}
                                    toontaskProgressText={toontaskProgressText}
                                    currentStep={currentStep}
                                    currentStepText={currentStepText}
                                    steps={stepsCardStep}
                                    onToontaskStarted={onToontaskStarted}
                                    onToontaskDropped={onToontaskDropped}
                                    onToontaskCompleted={onToontaskCompleted}
                                    onToontaskReset={onToontaskReset}
                                    onNextStepClick={onNextStepClick}
                                    onUndoStepClick={onUndoStepClick}
                                />
                                <Spacer style={{width: spacerWidth}}/>
                                <ToontaskStepsPath
                                    steps={toontaskStepsPathSteps}
                                    currentStep={currentStep}
                                    group={group}
                                    style={{width: stepsPathWidth}}
                                />
                            </Row>
                        </Column>
                    </Column>
                )
        }
    }
}

function getBackgroundImage(group: ToontaskGroup) {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return ttc_playground_image

        case ToontaskGroup.DonaldsDock:
            return dd_playground_image

        case ToontaskGroup.DaisyGardens:
            return dg_playground_image

        case ToontaskGroup.MinniesMelodyland:
            return mml_playground_image

        case ToontaskGroup.TheBrrrgh:
            return brg_playground_image

        case ToontaskGroup.DonaldsDreamland:
            return ddl_playground_image

        case ToontaskGroup.Lawbot:
            return lawbot_playground_image

        case ToontaskGroup.Bossbot:
            return bossbot_playground_image
    }
}

function getTintColour(group: ToontaskGroup) {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return NEIGHBOURHOOD_COLOURS.ttc.tint

        case ToontaskGroup.DonaldsDock:
            return NEIGHBOURHOOD_COLOURS.dd.tint

        case ToontaskGroup.DaisyGardens:
            return NEIGHBOURHOOD_COLOURS.dg.tint

        case ToontaskGroup.MinniesMelodyland:
            return NEIGHBOURHOOD_COLOURS.mml.tint

        case ToontaskGroup.TheBrrrgh:
            return NEIGHBOURHOOD_COLOURS.brg.tint

        case ToontaskGroup.DonaldsDreamland:
            return NEIGHBOURHOOD_COLOURS.ddl.tint

        case ToontaskGroup.Lawbot:
            return NEIGHBOURHOOD_COLOURS.lawbot.tint

        case ToontaskGroup.Bossbot:
            return NEIGHBOURHOOD_COLOURS.bossbot.tint
    }
}