import { Headline5 } from "app/theme/Typography"
import Card from "common/theme/components/Card"
import styled from "styled-components"
import ToonRadioGroup from "../../ToonRadioGroup"
import BaseToonSelector from "./BaseToonSelector"
import {ToonListItem} from "../../../../../RootScreenState";

type Props = {
    selectedToonId: string
    laff: number,
    toonName: string,
    toonList: ToonListItem[],
    onSelectorClick: () => void,
    onToonSelected: (toonName: string) => any
}

export default function BaseExpandedToonSelector(props: Props) {
    return (
        <BaseToonSelectorCard>
            <BaseToonSelectorHeaderCard>
                <BaseToonSelector laff={props.laff} toonName={props.toonName} onClick={props.onSelectorClick}/>
                <ExpandedToonSelectorTitle>My Toons</ExpandedToonSelectorTitle>
            </BaseToonSelectorHeaderCard>
            <ToonRadioGroup toons={props.toonList} selectedToonId={props.selectedToonId} onToonSelected={props.onToonSelected} />
        </BaseToonSelectorCard>
    )
}

const BaseToonSelectorHeaderCard = styled(Card)`
    height: 90px;
    border-radius: 20px 20px 0px 0px;
    background: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
`

const ExpandedToonSelectorTitle = styled(Headline5)`
    margin-top: 12px;
    color: white;
    text-align: center;
`

const BaseToonSelectorCard = styled(Card)`
    width: 260px;
    height: fit-content;
    background: #6129B2;
    border-radius: 20px;
`