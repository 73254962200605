import styled from "styled-components"
import BaseExpandedToonSelector from "../base/BaseExpandedToonSelector"
import BaseToonSelector from "../base/BaseToonSelector"
import {ToonListItem} from "../../../../../RootScreenState";

type Props = {
    isExpanded: boolean,
    laff: number,
    toonName: string,
    toonList: ToonListItem[],
    selectedToonId: string,
    onSelectorClick: () => any,
    onToonSelected: (toonName: string) => any
}

export default function SmallToonSelector(props: Props) {
    return (
        <StyledSmallToonSelector>
            {getBaseToonSelector(props.isExpanded, props.laff, props.toonName, props.toonList, props.selectedToonId, props.onSelectorClick, props.onToonSelected)}
        </StyledSmallToonSelector>
    )
}

function getBaseToonSelector(
    isExpanded: boolean,
    laff: number,
    toonName: string,
    toonList: ToonListItem[],
    selectedToonId: string,
    onSelectorClick: () => void,
    onToonSelected: (toonName: string) => any
) {
    if (isExpanded) {
        return <BaseExpandedToonSelector
            selectedToonId={selectedToonId}
            laff={laff}
            toonName={toonName}
            toonList={toonList}
            onToonSelected={onToonSelected}
            onSelectorClick={onSelectorClick}/>
    } else {
        return <BaseToonSelector laff={laff} toonName={toonName} onClick={onSelectorClick}/>
    }
}

const StyledSmallToonSelector = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
`