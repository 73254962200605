import CogType from "../../app/entities/invasions/cogs/CogType";
import {CSSProperties} from "react";
import ScreenSize from "../../common/ScreenSize";
import SmallCogTypeCard from "./SmallCogTypeCard";
import LargeCogTypeCard from "./LargeCogTypeCard";

type Props = {
    screenSize: ScreenSize,
    cogType: CogType,
    cogName: string,
    progress: number,
    levelText: string,
    onClick: () => void,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function CogTypeCard(props: Props) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallCogTypeCard {...props} />
        case ScreenSize.Medium:
            return <SmallCogTypeCard {...props} />
        case ScreenSize.Large:
            return <LargeCogTypeCard {...props} />
    }
}