import type GetToontasks from "application-game/usecases/getToontasks/GetToontasks"
import type GetToontasksProgress from "application-users/usecases/getToontasksProgress/GetToontasksProgress"
import {GetToontasksProgressResult} from "application-users/usecases/getToontasksProgress/GetToontasksProgress"
import ToontaskGroup from "data-toontasks/ToontaskGroup"
import {BehaviorSubject, combineLatestWith} from "rxjs"
import {inject, injectable} from "tsyringe"
import {AssignedToontasksState} from "./toontasks/started/StartedToontasks"

export type NeighborhoodScreenState = {
    isNotStartedToontasksVisible: boolean,
    toontasks: ToontaskListState
}

export type ToontaskListState = {
    started: AssignedToontasksState,
    notStarted: ToontaskCardState[],
    completed: ToontaskCardState[]
}

export type ToontaskCardState = {
    id: number,
    title: string,
    reward: string
}

@injectable()
export default class NeighborhoodViewModel {

    private currentToontaskGroup = new BehaviorSubject<ToontaskGroup>(ToontaskGroup.ToontownCentral)

    uiState = new BehaviorSubject<NeighborhoodScreenState>({
        isNotStartedToontasksVisible: false,
        toontasks: {
            started: {
                topLeft: null,
                topRight: null,
                bottomLeft: null,
                bottomRight: null
            },
            completed: [],
            notStarted: []
        }
    })

    constructor(
        @inject("GetToontasksProgress") private getToontasksProgress: GetToontasksProgress,
        @inject("GetToontasks") private getToontasks: GetToontasks
    ) {
        this.listenForToontasksProgress()
    }

    onToontaskGroupSelected(group: ToontaskGroup) {
        if(group !== this.currentToontaskGroup.value) {
            this.currentToontaskGroup.next(group)
        }
    }

    private listenForToontasksProgress() {
        this.getToontasksProgress.invoke()
        .pipe(combineLatestWith(this.currentToontaskGroup))
        .subscribe(async ([result, group]) => {
            if (group === null) return

            const toontasks = (await this.getToontasks.invoke()).toontasks

            const startedTopLeft = toontasks.find(toontask => toontask.id === result.started.topLeft?.toontaskId) ?? null
            const startedTopRight = toontasks.find(toontask => toontask.id === result.started.topRight?.toontaskId) ?? null
            const startedBottomLeft = toontasks.find(toontask => toontask.id === result.started.bottomLeft?.toontaskId) ?? null
            const startedBottomRight = toontasks.find(toontask => toontask.id === result.started.bottomRight?.toontaskId) ?? null

            const notStartedIds = await this.getNotStartedToontaskCardStatesFromResult(result, group)

            this.uiState.next({
                isNotStartedToontasksVisible: notStartedIds.length > 0,
                toontasks: {
                    started: {
                        topLeft: startedTopLeft !== null ? {
                            id: startedTopLeft.id,
                            title: startedTopLeft.title,
                            reward: startedTopLeft.reward,
                            progress: result.started.topLeft!!.progress,
                            progressText: `${(result.started.topLeft!!.progress * 100).toFixed(0)}%`,
                            step: startedTopLeft.steps[result.started.topLeft!!.stepsCompleted].options[0].name
                        } : null,
                        topRight: startedTopRight !== null ? {
                            id: startedTopRight.id,
                            title: startedTopRight.title,
                            reward: startedTopRight.reward,
                            progress: result.started.topRight!!.progress,
                            progressText: `${(result.started.topRight!!.progress * 100).toFixed(0)}%`,
                            step: startedTopRight.steps[result.started.topRight!!.stepsCompleted].options[0].name
                        } : null,
                        bottomLeft: startedBottomLeft !== null ? {
                            id: startedBottomLeft.id,
                            title: startedBottomLeft.title,
                            reward: startedBottomLeft.reward,
                            progress: result.started.bottomLeft!!.progress,
                            progressText: `${(result.started.bottomLeft!!.progress * 100).toFixed(0)}%`,
                            step: startedBottomLeft.steps[result.started.bottomLeft!!.stepsCompleted].options[0].name
                        } : null,
                        bottomRight: startedBottomRight !== null ? {
                            id: startedBottomRight.id,
                            title: startedBottomRight.title,
                            reward: startedBottomRight.reward,
                            progress: result.started.bottomRight!!.progress,
                            progressText: `${(result.started.bottomRight!!.progress * 100).toFixed(0)}%`,
                            step: startedBottomRight.steps[result.started.bottomRight!!.stepsCompleted].options[0].name
                        } : null,
                    },
                    completed: await this.getCompletedToontaskCardStatesFromResult(result, group),
                    notStarted: notStartedIds
                }
            })
        })
    }

    private async getCompletedToontaskCardStatesFromResult(result: GetToontasksProgressResult, group: ToontaskGroup): Promise<ToontaskCardState[]> {
        switch (group) {
            case ToontaskGroup.ToontownCentral:
                return await this.createToontaskCardStates(result.toontownCentral.completedIds)
            case ToontaskGroup.DonaldsDock:
                return await this.createToontaskCardStates(result.donaldsDock.completedIds)
            case ToontaskGroup.DaisyGardens:
                return await this.createToontaskCardStates(result.daisyGardens.completedIds)
            case ToontaskGroup.MinniesMelodyland:
                return await this.createToontaskCardStates(result.minniesMelodyland.completedIds)
            case ToontaskGroup.TheBrrrgh:
                return await this.createToontaskCardStates(result.theBrrrgh.completedIds)
            case ToontaskGroup.DonaldsDreamland:
                return await this.createToontaskCardStates(result.donaldsDreamland.completedIds)
            case ToontaskGroup.Lawbot:
                return await this.createToontaskCardStates(result.lawbot.completedIds)
            case ToontaskGroup.Bossbot:
                return await this.createToontaskCardStates(result.bossbot.completedIds)
        }
    }

    private async getNotStartedToontaskCardStatesFromResult(result: GetToontasksProgressResult, group: ToontaskGroup): Promise<ToontaskCardState[]> {
        switch (group) {
            case ToontaskGroup.ToontownCentral:
                return await this.createToontaskCardStates(result.toontownCentral.notStartedIds)
            case ToontaskGroup.DonaldsDock:
                return await this.createToontaskCardStates(result.donaldsDock.notStartedIds)
            case ToontaskGroup.DaisyGardens:
                return await this.createToontaskCardStates(result.daisyGardens.notStartedIds)
            case ToontaskGroup.MinniesMelodyland:
                return await this.createToontaskCardStates(result.minniesMelodyland.notStartedIds)
            case ToontaskGroup.TheBrrrgh:
                return await this.createToontaskCardStates(result.theBrrrgh.notStartedIds)
            case ToontaskGroup.DonaldsDreamland:
                return await this.createToontaskCardStates(result.donaldsDreamland.notStartedIds)
            case ToontaskGroup.Lawbot:
                return await this.createToontaskCardStates(result.lawbot.notStartedIds)
            case ToontaskGroup.Bossbot:
                return await this.createToontaskCardStates(result.bossbot.notStartedIds)
        }
    }

    private async createToontaskCardStates(toontaskIds: number[]): Promise<ToontaskCardState[]> {
        const toontasks = (await this.getToontasks.invoke()).toontasks

        return toontaskIds.map(toontaskId => {
            const toontask = toontasks.find(toontask => toontask.id === toontaskId)

            if (!toontask) {
                throw Error("Could not find toontask with id: " + toontaskId)
            }

            return {
                id: toontaskId,
                title: toontask.title,
                reward: toontask.reward
            }
        })
    }
}