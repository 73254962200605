import "./app.scss";

import * as firebase from "firebase";

import styled from "styled-components";

import GameDataRepo from "app/data/GameDataRepository";
import Login from "./login/login";
import MainContent from "app/ui/MainContent";
import MainViewModel from "app/ui/MainViewModel";
import NavigationDrawer from "./app/navigation/NavigationDrawer";
import {NavigationScreen} from "app/ui/app/navigation/NavigationDrawer";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {ScreenSize} from "common/ScreenSize";
import {container} from "tsyringe";
import UserRepository from "../../data-users/UserRepository";

export default class MainScreenComponent extends React.Component {

	/** @type {UserRepository} */
	userRepository

	viewModel

	constructor(props) {
		super(props)

		this.viewModel = new MainViewModel(props.userRepository, container.resolve(UserRepository))

		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.debug("Analytics disabled");
		} else {
			firebase.analytics();
			console.debug("Analytics enabled")
		}

		this.state = {
			signInMessage: null,
			user: undefined,
			userExpanded: false,
			isReady: false,
			navItemSelected: NavigationScreen.HOME,
			toontownTime: "",
			lastScreenSize: props.screenSize,
			isBlackScreenVisible: false
		};

		this.onMenuClicked = this.onMenuClicked.bind(this)
		this.onUserClicked = this.onUserClicked.bind(this)
		this.onSignOutClicked = this.onSignOutClicked.bind(this)
		this.toggleSignOutHover = this.toggleSignOutHover.bind(this)
		this.setPage = this.setPage.bind(this)
	}

	componentDidMount() {
		(async () => {
			await GameDataRepo.init()
			this.viewModel.observeUser().subscribe({
				next: user => {
					this.setState({ isReady: true, user: user })
				}
			})


			this.viewModel.toontownTime.subscribe({
				next: time => {
					this.setState({ toontownTime: time })
				}
			})


		})()

		if (window.location.href.indexOf("tracking") !== -1) {
			this.setState({ navItemSelected: "tracking" })
		}

		if (window.location.href.indexOf("invasions") !== -1) {
			this.setState({ navItemSelected: "invasions" })
		}
	}

	render() {
		if (this.props.screenSize !== this.state.lastScreenSize) {
			this.setState({ sideNavigationShown: this.props.screenSize === ScreenSize.Large, lastScreenSize: this.props.screenSize })
		}

		if (this.state.isReady) {

			if (this.state.user.getId() == null) {
				return <Login />
			}

			if (this.state.user !== undefined) {

				return (
						<Router key={this.props}>
							<StyledContainer>

								<MainContentContainer>
									<NavigationDrawer
										isVisible={this.props.isSideNavVisible}
										currentScreen={this.state.navItemSelected}
										onNavigationItemClick={
											screen => this.setState({ navItemSelected: screen, sideNavigationShown: false })
										}
										screenSize={this.props.screenSize}
										toontownTime={this.state.toontownTime}
										onMenuClick={this.onMenuClicked}
									/>
									<MainContent
										sideNavigationShown={this.state.sideNavigationShown || this.props.screenSize === ScreenSize.Large}
										userRepository={this.props.userRepository}
										statusRepository={this.props.statusRepository}
										screenSize={this.props.screenSize}
										getInvasions={this.props.getInvasions}
										screenSizeWatcher={this.props.screenSizeWatcher}
										getAssignedToontasks={this.props.getAssignedToontasks}
										getCogSuitsProgress={this.props.getCogSuitsProgress}
										promoteCogSuit={this.props.promoteCogSuit}
										demoteCogSuit={this.props.demoteCogSuit}
										getTrackingProgress={this.props.getTrackingProgress}
									/>
								</MainContentContainer>

								{BlackScreen(this.state.sideNavigationShown, this.state.isToonSelectorExpanded, this.props.screenSize, () => {
									this.setState({ isToonSelectorExpanded: false, sideNavigationShown: false })
								})}
							</StyledContainer>
						</Router>
				)
			}
		}

		return null

	}

	setPage(page) {
		this.setState({ currentPage: page })
	}

	onMenuClicked() {
		this.setState(state => { return { sideNavigationShown: !state.sideNavigationShown } })
	}

	onUserClicked() {
		this.setState(state => {
			return { userExpanded: !state.userExpanded }
		})
	}

	async onSignOutClicked() {
		if (this.state.userExpanded) {
			await this.userRepository.signOut()
			this.setState({ userExpanded: false, signOutHovered: false })
		}
	}

	toggleSignOutHover() {
		if (this.state.userExpanded) {
			this.setState(state => {
				return { signOutHovered: !state.signOutHovered }
			})
		}
	}

}

const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`

const MainContentContainer = styled.div`
	height: calc(100% - 60px);
	margin-top: 60px;
	display: flex;
	flex-direction: row;
`

function BlackScreen(isNavigationVisible, isToonSelectorVisible, screenSize, onBlackScreenClick) {
	if (isNavigationVisible && screenSize === ScreenSize.Small) {
		return <StyledBlackScreen onClick={onBlackScreenClick} zIndex={1} />
	} else if (isToonSelectorVisible) {
		return <StyledBlackScreen onClick={onBlackScreenClick} zIndex={2} />
	} else {
		return null
	}
}

const StyledBlackScreen = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: black;
	opacity: 0.4;
	z-index: ${props => props.zIndex};
`