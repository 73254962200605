export default class Facilities {
    /** @type {Array.<{name: String, points: Number, pointsType: String}>} */
    static SELLBOT_FACILITIES = [
        {
            name: "Short Factory",
            points: 480,
            pointsType: "Merits",
        },
        {
            name: "Side Entrance",
            points: 584,
            pointsType: "Merits",
        },
        {
            name: "Long Factory",
            points: 776,
            pointsType: "Merits",
        }
    ]

    /** @type {Array.<{name: String, points: Number, pointsType: String}>} */
    static CASHBOT_FACILITIES = [
        {
            name: "Coin Mint",
            points: 356,
            pointsType: "Cogbucks",
        },
        {
            name: "Dollar Mint",
            points: 679,
            pointsType: "Cogbucks",
        },
        {
            name: "Bullion Mint",
            points: 1202,
            pointsType: "Cogbucks",
        }
    ]

    /** @type {Array.<{name: String, points: Number, pointsType: String}>} */
    static LAWBOT_FACILITIES = [
        {
            name: "DA Office A",
            points: 564,
            pointsType: "Jury Notices",
        },
        {
            name: "DA Office B",
            points: 944,
            pointsType: "Jury Notices",
        },
        {
            name: "DA Office C",
            points: 1370,
            pointsType: "Jury Notices",
        },
        {
            name: "DA Office D",
            points: 1842,
            pointsType: "Jury Notices",
        }
    ]

    /** @type {Array.<{name: String, points: Number, pointsType: String}>} */
    static BOSSBOT_FACILITIES = [
        {
            name: "Front Three",
            points: 764,
            pointsType: "Stock Options",
        },
        {
            name: "Middle Six",
            points: 1874,
            pointsType: "Stock Options",
        },
        {
            name: "Back Nine",
            points: 3350,
            pointsType: "Stock Options",
        }
    ]

    /**
     * 
     * @param {String} cogType 
     * @param {Number} pointsNeeded 
     */
    static getFacilitiesForPromotion(cogType, pointsNeeded) {
        switch (cogType) {
            case "sellbot":
                return this.calculateBaseFacilitiesForPromotion(pointsNeeded,
                    null, this.SELLBOT_FACILITIES[2], this.SELLBOT_FACILITIES[1], this.SELLBOT_FACILITIES[0])

            case "cashbot":
                return this.calculateBaseFacilitiesForPromotion(pointsNeeded,
                    null, this.CASHBOT_FACILITIES[2], this.CASHBOT_FACILITIES[1], this.CASHBOT_FACILITIES[0])

            case "lawbot":
                return this.calculateBaseFacilitiesForPromotion(pointsNeeded,
                    this.LAWBOT_FACILITIES[3], this.LAWBOT_FACILITIES[2], this.LAWBOT_FACILITIES[1], this.LAWBOT_FACILITIES[0])

            case "bossbot":
                return this.calculateBaseFacilitiesForPromotion(pointsNeeded,
                    null, this.BOSSBOT_FACILITIES[2], this.BOSSBOT_FACILITIES[1], this.BOSSBOT_FACILITIES[0])
            default:
                throw new Error()
         }

    }

    /**
     * @param {Number} pointsNeeded Points needed to promote to next suit
     * @param {{name: String, points: Number}} longerFacility 
     * @param {{name: String, points: Number}} longFacility 
     * @param {{name: String, points: Number}} midFacility 
     * @param {{name: String, points: Number}} shortFacility 
     * 
     * Arguments should be in the following order per cog type:
     * - Sellbot: null, Long Factory, Side Entrance, Short Factory
     * - Cashbot; null, Bullion, Dollar, Coin
     * - Lawbot: D, C, B, A
     * - Bossbot, null, Back Nine, Middle Six, Front Three
     */
    static calculateBaseFacilitiesForPromotion(pointsNeeded, longerFacility, longFacility, midFacility, shortFacility) {
        let longer = 0
        let long = 0
        let mid = 0
        let short = 0

        while (pointsNeeded > 0) {
            if (longerFacility !== null && pointsNeeded > longerFacility.points) {
                longer++
                pointsNeeded -= longerFacility.points

            } else if (pointsNeeded > midFacility.points) {
                long++
                pointsNeeded -= longFacility.points

            } else if (pointsNeeded > shortFacility.points) {
                mid++
                pointsNeeded -= midFacility.points
            } else {
                short++
                pointsNeeded -= shortFacility.points
            }
        }

        let result = []

        if (longer > 0) {
            result.push({
                name: longerFacility.name,
                points: longerFacility.points,
                amount: longer
            })
        }

        if (long > 0) {
            result.push({
                name: longFacility.name,
                points: longFacility.points,
                amount: long
            })
        }

        if (mid > 0) {
            result.push({
                name: midFacility.name,
                points: midFacility.points,
                amount: mid
            })
        }

        if (short > 0) {
            result.push({
                name: shortFacility.name,
                points: shortFacility.points,
                amount: short
            })
        }

        return result
    }
}