import {Row} from "../../core-anvil/Layouts";
import {CSSProperties} from "react";
import ic_trophy_locked from "feat-fishing/assets/ic_trophy_locked.svg"
import ic_trophy_unlocked from "feat-fishing/assets/ic_trophy_unlocked.svg"

export type Props = {
    progressCount: number,
    max: number,
    style?: CSSProperties
}

export default function TrophiesProgress(props: Props) {
    let items: JSX.Element[] = []

    for (let i = 1; i <= props.max; i++) {
        items.push(<Trophy isUnlocked={props.progressCount >= i}/>)
    }

    return (
        <Row style={{gap: 16}}>
            {items}
        </Row>
    )
}

type TrophyProps = {
    isUnlocked: boolean
}

function Trophy(props: TrophyProps) {
    const icon = props.isUnlocked ? ic_trophy_unlocked : ic_trophy_locked

    return (
        <img src={icon} alt="" style={{width: 36, height: 40}}/>
    )
}