import {Column} from "../../core-anvil/Layouts";
import {useParams} from "react-router-dom";
import {FishingSpeciesDestination} from "../FishingNavigation";
import NavigationBanner from "../../core-theme/components/navigation/NavigationBanner";
import ScreenSize from "../../common/ScreenSize";
import {useTheme} from "styled-components";
import {useEffect, useRef, useState} from "react";
import {FishingSpeciesScreenState, FishItem} from "./FishingSpeciesScreenState";
import {TitleLarge} from "../../app/theme/Typography";
import {container, DependencyContainer} from "tsyringe";
import FishingSpeciesViewModel from "./FishingSpeciesViewModel";
import FishList from "./list/FishList";
import FishInsightsDialog from "./insights/FishInsightsDialog";

type Props = {
    screenSize: ScreenSize,
    navigateToFishingOverview: () => void
}

export default function FishingSpeciesScreen(props: Props) {
    const args = FishingSpeciesDestination.getArgs(useParams())

    const screenContainer = useRef(container.createChildContainer())

    const viewModel = useRef(container.resolve(FishingSpeciesViewModel))

    const [uiState, setUIState] = useState<FishingSpeciesScreenState>(viewModel.current.uiState.value)

    useEffect(() => {
        const sub = viewModel.current.uiState.subscribe(setUIState)
        viewModel.current.onInit(args.speciesId)
        return () => sub.unsubscribe()
    }, [args.speciesId])

    return (
        <FishingSpeciesContent {...props} {...uiState} speciesId={args.speciesId}
                               container={screenContainer.current}
                               onFishClick={(fishId, isCaught) => viewModel.current.onFishClick(fishId, isCaught)}/>
    )
}

type ContentProps = {
    container: DependencyContainer,
    screenSize: ScreenSize,
    speciesId: number,
    speciesName: string,
    fishItems: FishItem[],
    navigateToFishingOverview: () => void,
    onFishClick: (fishId: number, isCaught: boolean) => void
}

function FishingSpeciesContent(props: ContentProps) {
    const theme = useTheme()

    const speciesColor = theme.colors.getFishSpeciesColor(props.speciesId)
    const speciesDarkColor = theme.colors.getFishSpeciesDarkColor(props.speciesId)

    return (
        <Column style={{width: "100%", height: "100%", backgroundColor: speciesColor}}>
            <Column horizontalAlignment="center">
                <NavigationBanner
                    screenSize={props.screenSize}
                    backgroundColor={theme.colors.trackingDark}
                    icon="check_box"
                    featureName="Tracking"
                    screenName={props.speciesName}
                    previousScreenName="Fishing"
                    onBackClick={props.navigateToFishingOverview}
                />
                <TitleLarge style={{marginTop: 32}}>{props.speciesName}</TitleLarge>
                <FishList
                    container={props.container}
                    style={{marginTop: 24}}
                    fishItems={props.fishItems}
                    checkboxUncheckedColor={speciesColor}
                    cardBackgroundColor={speciesDarkColor}
                    onClick={props.onFishClick}
                />
            </Column>
            <FishInsightsDialog container={props.container} dialogColor={speciesColor}/>
        </Column>
    )
}
