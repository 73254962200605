import {injectable} from "tsyringe";
import {BehaviorSubject} from "rxjs";
import FishingSpeciesScreenState, {FishItem} from "./FishingSpeciesScreenState";
import GetFishSpecies from "../../application-game/usecases/getFishSpecies/GetFishSpecies";
import GetFishSpeciesResult from "../../application-game/usecases/getFishSpecies/GetFishSpeciesResult";
import GetFishingProgress from "../../application-users/usecases/getFishingProgress/GetFishingProgress";
import SetFishCaught from "../../application-users/usecases/set-fish-caught/SetFishCaught";

@injectable()
export default class FishingSpeciesViewModel {

    uiState = new BehaviorSubject<FishingSpeciesScreenState>({
        speciesName: "",
        fishItems: []
    })

    constructor(private getFishSpecies: GetFishSpecies, private getFishingProgress: GetFishingProgress, private setFishCaught: SetFishCaught) {
    }

    onInit(speciesId: number) {
        const result = this.getFishSpecies.invoke(speciesId)

        this.uiState.next({
            ...this.uiState.value,
            speciesName: result.name,
            fishItems: this.createFishItems(result, new Map<number, boolean>())
        })

        this.listenForFishingProgress(speciesId)
    }

    onFishClick(fishId: number, isCaught: boolean) {
        this.setFishCaught.invoke(fishId, isCaught)
    }

    private listenForFishingProgress(speciesId: number) {
        this.getFishingProgress.invoke().subscribe(result => {
            const fishSpecies = this.getFishSpecies.invoke(speciesId)
            const fishItems = this.createFishItems(fishSpecies, result.fish)
            this.uiState.next({
                ...this.uiState.value,
                speciesName: fishSpecies.name,
                fishItems: fishItems
            })
        })
    }

    private createFishItems(result: GetFishSpeciesResult, fishCaught: Map<number, boolean>): FishItem[] {
        return result.fish.map(item => {
            return {
                id: item.id,
                name: item.name,
                isChecked: fishCaught.get(item.id) ?? false
            }
        })
    }
}