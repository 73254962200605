// Themes

import "common/theme/theme.scss";
import "./invasions.scss";

import InvasionCard from "./invasion/invasion-card";
import React from "react";
import background_invasions from "common/resources/images/background_invasions.png";

// Images


// Classes


// Components


const axios = require("axios");

export const InvasionsDestination = {
	route: "/invasions",
	createRoute: () => "/invasions"
}

class Invasions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			width: window.innerWidth,
			height: window.innerHeight,
			isRefreshJobRunning: true,
			secondsToRefresh: 0
		};
	}

	componentDidMount() {
		window.addEventListener("resize", () => {
			this.setState(() => {
				return {width: window.innerWidth, height: window.innerHeight};
			});
		});

		this.startRefreshJob();
	}

	async startRefreshJob() {
		this.refresh();
		const refresh = 60;

		let timeToRefresh = refresh;

		while(true) {
			timeToRefresh--;
			// eslint-disable-next-line no-loop-func
			this.setState(() => {return {secondsToRefresh: timeToRefresh}});

			if(timeToRefresh === 0) {
				this.refresh();
				timeToRefresh = refresh;
			}

			await sleep(1000);

			if(!this.state.isRefreshJobRunning) {
				break;
			}
		}
	}

	refresh() {
		axios({
			method: "GET",
			url: "https://cors-anywhere.herokuapp.com/https://us-central1-toontracker-171e6.cloudfunctions.net/fetchInvasions",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
				"content-type": "application/json"
			}
		}).then(response => {
			this.setState(() => {
				console.log("Refreshed invasions");
				return {invasions: response.data}
			});
		});
	}

	invasions() {
		if(this.state.invasions === undefined) {
			return null;
		}

		let invasions = [];

		let columnCount = 1;
		if(this.state.width >= 720) {
			columnCount = 2;
		}

		if(this.state.width >= 1024) {
			columnCount = 3;
		}

		for(let c = 0; c < columnCount; c++) {
			invasions.push([]);
		}


		for (let [index, invasion] of this.state.invasions.entries()) {
			let column = index % columnCount;

			invasions[column].push(<InvasionCard
				cog={invasion.cog}
				currentRate={invasion.currentRate}
				overallRate={invasion.overallRate}
				historicalRate={invasion.historicalRate}
				district={invasion.district}
				estimatedFinish={invasion.estimatedFinish}
				cogsRemaining={invasion.cogsRemaining}
			/>);
		}

		let columnDivs = [];
		for(let c = 0; c < columnCount; c++) {
			columnDivs.push(<div className="invasions-column">{invasions[c]}</div>);
		}

		return columnDivs;
	}

	secondsToRefresh() {
		let timeToRefresh = this.state.secondsToRefresh;

		if(timeToRefresh >= 10) {
			return `0:${this.state.secondsToRefresh}`;
		} else {
			return `0:0${this.state.secondsToRefresh}`;
		}
	}

	render() {
		return (
			<div id="invasions-panel">
				<img src={background_invasions} alt="" id="background" />

				<div className="header">
					<p className="headline3">Invasions</p>
					<div id="divider"/>
				</div>


				<p className="headline4 section-title">Active Invasions</p>

				<div id="active-layout">

					{this.invasions()}
				</div>

			</div>
		);
	}
}

export default Invasions;

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
