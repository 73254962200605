import ScreenSize from "../../common/ScreenSize";
import {SpeciesState} from "./FishingOverviewScreenState";
import {Column, Row} from "../../core-anvil/Layouts";
import {CSSProperties} from "react";
import SpeciesItem from "./SpeciesItem";

type SpeciesGridProps = {
    screenSize: ScreenSize,
    species: SpeciesState[],
    onSpeciesClick: (id: number) => void
}

export default function SpeciesGrid(props: SpeciesGridProps) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallSpeciesGrid {...props} />
        case ScreenSize.Medium:
            return <MediumSpeciesGrid {...props} />
        case ScreenSize.Large:
            return <LargeSpeciesGrid {...props} />
    }
}

function SmallSpeciesGrid(props: SpeciesGridProps) {
    return (
        <Column style={{gap: 16}}>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(0, 3)} style={{gap: 16}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(3, 6)} style={{gap: 16}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(6, 9)} style={{gap: 16}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(9, 12)} style={{gap: 16}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(12, 15)} style={{gap: 16}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(15, 18)} style={{gap: 16}}
                        onSpeciesClick={props.onSpeciesClick}/>
        </Column>
    )
}

function MediumSpeciesGrid(props: SpeciesGridProps) {
    return (
        <Column style={{gap: 40}}>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(0, 3)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(3, 6)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(6, 9)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(9, 12)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(12, 15)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(15, 18)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
        </Column>
    )
}

function LargeSpeciesGrid(props: SpeciesGridProps) {
    return (
        <Column style={{gap: 40}}>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(0, 6)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(6, 12)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
            <SpeciesRow screenSize={props.screenSize} species={props.species.slice(12, 18)} style={{gap: 40}}
                        onSpeciesClick={props.onSpeciesClick}/>
        </Column>
    )
}

type SpeciesRowProps = {
    screenSize: ScreenSize,
    species: SpeciesState[],
    style?: CSSProperties,
    onSpeciesClick: (id: number) => void
}

function SpeciesRow(props: SpeciesRowProps) {
    let items: JSX.Element[] = []

    props.species.forEach((state) => {
        items.push(<SpeciesItem screenSize={props.screenSize} {...state}
                                onClick={() => props.onSpeciesClick(state.id)}/>)
    })

    return (
        <Row horizontalArrangement="center" style={{...props.style}}>
            {items}
        </Row>
    )
}