import { Body1, Body2 } from "app/theme/Typography"

import { Server } from "./ServerStatusPanel"
import styled from "styled-components"

type Props = {
    servers: Server[]
}

export default function ServerStatusTable(props: Props) {
    return (
        <StyledServerStatusTable>
            {TableHeading(props.servers)}
        </StyledServerStatusTable>
    )
}

const StyledServerStatusTable = styled.div`
    width: 320px;
    background: rgba(0, 0, 0, 0.21);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding-bottom: 12px;

    @media (min-width: 1440px) {
        width: 340px;
    }
`

function TableHeading(servers: Server[]) {
    let serverRows: any[] = []

    servers.forEach(server => serverRows.push(TableRow(server.isUp, server.name, server.lastChange)))

    return (
        <StyledTableHeading>
            <StyledTableRow>
                <StyledStatusColumn>
                    <Body2>Status</Body2>
                </StyledStatusColumn>
                <StyledServerColumn>
                    <Body2>Server</Body2>
                </StyledServerColumn>
                <StyledLastChangeColumn>
                    <Body2>Since</Body2>
                </StyledLastChangeColumn>
            </StyledTableRow>
            {serverRows}
        </StyledTableHeading>
    )
}

const StyledTableHeading = styled.div`
    margin-top: 12px;
`

function TableRow(isUp: boolean, serverName: string, lastChange: string) {
    return (
        <StyledTableRow>
            <StyledStatusColumn>{StatusIcon(isUp)}</StyledStatusColumn>
            <StyledServerColumn>{ServerColumn(serverName)}</StyledServerColumn>
            <StyledLastChangeColumn>{LastChangeColumn(lastChange)}</StyledLastChangeColumn>
        </StyledTableRow>
    )
}


const StyledTableRow = styled.div`
    width: 300px;
    display: grid;
    grid-template-columns: 48px 148px 1fr;
    align-items: center;
    margin-top: 6px;
`

const StyledStatusColumn = styled.div`
    width: 48px;
    margin-left: 24px;
    display: flex;
    justify-content: center;
`
const StyledServerColumn = styled.div`
    width: 100px;
    margin-left: 48px;
`

const StyledLastChangeColumn = styled.div`
    margin-left: 40px;
`

function ServerColumn(name: string) {
    return <Body1>{name}</Body1>
}

function LastChangeColumn(lastChange: string) {
    return <Body2>{lastChange}</Body2>
}

function StatusIcon(isUp: boolean) {
    if (isUp) {
        return (
            <StyledIcon className="material-icons unselectable">done</StyledIcon>
        )
    } else {
        return (
            <StyledIcon className="material-icons unselectable">close</StyledIcon>
        )
    }
}

const StyledIcon = styled.span`
    color: white;
`