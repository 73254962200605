import "firebase/firestore"
import "firebase/functions"

import * as Auth from "app/data/remote/Auth"
import * as firebase from "firebase/app"

import LocalUserData from "app/data/local/LocalUserData"
import RemoteUserData from "app/data/remote/RemoteUserData"
import { Subject } from "rxjs"
import User from "app/data/user/LegacyUser"
import sleep from "sleep-promise"

const FETCH_JOB_INTERVAL = 10000

/**
 * @deprecated Use newer UserRepository instead
 */
export default class UserRepository {
	#user: User
	#userSubject: Subject<User>

	#pendingUpdatesTimes: number[]

	#isUserFetchJobRunning: boolean

	constructor() {
		this.#user = new User(null, null, false, [])
		this.#userSubject = new Subject<User>()
		this.#pendingUpdatesTimes = []
		this.#isUserFetchJobRunning = false
	}

	init() {
		Auth.observeAuthUser((currentUser: any) => {
			this.#user = new User(null, null, false, [])
			this.startUserFetchJob()
		})
	}

	observeUser(): Subject<User> {
		return this.#userSubject
	}

	getUser(): User {
		return this.#user
	}

	async updateUser(user: User) {
		this.stopUserFetchJob()

		this.#user = user
		this.#userSubject.next(user)

		const updateTime = new Date().getMilliseconds()
		this.#pendingUpdatesTimes.push(updateTime)

		await sleep(5000)
		this.#pendingUpdatesTimes.shift()

		if (this.#pendingUpdatesTimes.length === 0) {
			this.updateUserData(this.#user)
			this.startUserFetchJob()
		}
	}

	async fetchUser() {
		if (Auth.isSignedIn()) {
			const remoteUser = await this.fetchRemoteUserData()
			if (this.#isUserFetchJobRunning) {
				this.#user = remoteUser
				this.#userSubject.next(this.#user)
			}
		} else {
			const localUser = this.fetchLocalUserData()
			if (this.#isUserFetchJobRunning) {
				this.#user = localUser
				this.#userSubject.next(this.#user)
			}
		}
	}

	fetchRemoteUserData(): Promise<User> {
		return RemoteUserData.fetchUser()
	}

	fetchLocalUserData(): User {
		return LocalUserData.fetchUser()
	}

	async updateUserData(user: User) {
		if (Auth.isSignedIn()) {
			RemoteUserData.updateUser(user)
		} else {
			LocalUserData.updateUser(user)
		}
	}

	async startUserFetchJob() {
		if (this.#isUserFetchJobRunning) {
			return
		}

		this.#isUserFetchJobRunning = true

		while (this.#isUserFetchJobRunning) {
			this.fetchUser()
			await sleep(FETCH_JOB_INTERVAL)
		}
	}

	stopUserFetchJob() {
		this.#isUserFetchJobRunning = false
	}

	async signInWithGoogle() {
		return new Promise(async (resolve, reject) => {
			const googleUser = await Auth.signInGoogleUser()

			const isAllowed = await this.isUserAllowed(googleUser.email)

			if (isAllowed) {
				let user = await this.fetchUser()
				resolve(user)

			} else {
				await this.signOut()
			}
		})
	}

	async signInByEmail(email: string, pass: string) {
		return new Promise(async (resolve, reject) => {
			const emailUser = await Auth.signInEmailUser(email, pass)
				.catch(reason => {
					reject(reason)
				})

			if (emailUser === undefined) return

			const isAllowed = await this.isUserAllowed(email)

			if (isAllowed) {
				let user = await this.fetchUser()
				resolve(user)

			} else {
				await this.signOut()
				reject("user/not-alpha")

			}
		})
	}

	async userExists(email: string) {
		return (await Auth.fetchSignInMethod(email)) !== ""
	}

	async resetPassword(email: string) {
		await Auth.sendPasswordReset(email)
	}

	async isUserAllowed(email: string) {
		return new Promise((resolve, reject) => {
			firebase.functions().httpsCallable("isAlphaUser")({ email: email })
				.then(result => {
					resolve(result.data["isAllowed"])
				})
				.catch(err => {
					console.error(err);
					reject(err);
				});
		});
	}

	async signOut() {
		await Auth.signOut()
		LocalUserData.clearUser()
		this.#user = new User(null, null, false, [])
		this.#userSubject.next(this.#user)
	}

}