import FishingProgressEvaluatorResult from "./FishingProgressEvaluatorResult"
import {injectable} from "tsyringe";

const TOTAL_FISH = 70

@injectable()
export default class FishingProgressEvaluator {
    invoke(fishCaught: Map<number, boolean>): FishingProgressEvaluatorResult {
        let count = 0

        fishCaught.forEach((isCaught, index) => {
            if(isCaught) {
                count++
            }  
        })

        const nextLaffAtCount = count < TOTAL_FISH ? (Math.floor(count / 10) + 1) * 10 : null
        const nextLaffAtProgress = nextLaffAtCount ? nextLaffAtCount / TOTAL_FISH : null
        
        return {
            fishingProgress: count / TOTAL_FISH,
            fishCaught: count,
            trophies: Math.floor(count / 10),
            nextLaffProgress: nextLaffAtProgress
        }
    }
}