import { Body2, Subtitle1, Subtitle2 } from 'app/theme/Typography';
import { useContext } from 'react';
import styled, { ThemeContext } from "styled-components";
import ActivityCard from './ActivityCard';

type Props = {
    progress: number,
    onClick: () => void
}

export default function GolfCard(props: Props) {
    const theme = useContext(ThemeContext)
    return (
        <ActivityCard title="Golf" backgroundColor={theme.colors.golf} onClick={props.onClick}>
            <StyledGolfCardContent>
                <StyledProgressLayout>
                    <StyledGolfValue>{props.progress}</StyledGolfValue>
                    <StyledMax>/ 30</StyledMax>
                </StyledProgressLayout>
                <StyledProgressLabel>Trophies</StyledProgressLabel>
            </StyledGolfCardContent>
        </ActivityCard>
    )

}

const StyledGolfCardContent = styled.div`
    width: 100%;
    height: 100%;
    diplay: flex;
    flex-direction: column;
    align-items: center;
`


const StyledProgressLayout = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
`

const StyledGolfValue = styled(Subtitle1)`
    
`

const StyledMax = styled(Body2)`
    margin-top: 8px;
    margin-left: 4px;
`

const StyledProgressLabel = styled(Subtitle2)`
    width: min-content;
    font-weight: 300;
    margin-top: -6px;
    margin-left: auto;
    margin-right: auto;
`