import React from "react"

export default class ProgressBar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            progress: props.progress,
            maxProgress: props.maxProgress
        }
    }

    render() {
        let style = this.props.style
        if(style === undefined) style = {}
        style.position = "absolute"

        return (
            <div className="progress-bar" style={style}>
                <div className="progress-bar__background" style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 100,
                    backgroundColor: "#fff4",
                    position: "absolute"
                }} />

                <div className="progress-bar__progress" style={{
                    width: `${this.props.progress / this.props.maxProgress * 100}%`,
                    height: "100%",
                    borderRadius: 100,
                    backgroundColor: "white"
                }} />
            </div>
        )
    }
}