import {Link} from "react-router-dom";
import {useTheme} from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";
import ToontaskGroup from "../../../../../data-toontasks/ToontaskGroup";
import {ToontasksOverviewDestination} from "../../../../../feat-toontasks/routes/ToontasksOverviewRoute";

type Props = {
    progressDecimal: number
    progressText: string,
    group: ToontaskGroup,
    groupText: string,
}

export default function SmallToontasksCard(props: Props) {
    const theme = useTheme()

    return (
        <Link to={ToontasksOverviewDestination.createRoute(props.group)} style={{textDecoration: "none"}}>
            <ActivityHeader
                width={310}
                progressDecimal={props.progressDecimal}
                progressText={props.progressText}
                title={"Toontasks"}
                subtitle={`You are currently in ${props.groupText}`}
                backgroundColor={theme.colors.toontasks}
            />
        </Link>
    )
}
