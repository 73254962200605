import {firestore} from "firebase";
import FirestoreUser, {FirestoreToon} from "./FirestoreUser";
import {BehaviorSubject} from "rxjs";

const userCollectionName = "users"
const toonsCollectionName = "toons"

export default class UserFirestoreMediator {

    listenForUser(userId: string): BehaviorSubject<FirestoreUser | null> {
        const subject = new BehaviorSubject<FirestoreUser | null>(null)

        firestore()
        .collection(userCollectionName)
        .doc(userId)
        .onSnapshot(async doc => {
            const firestoreUser = doc.data() as FirestoreUser
            subject.next(firestoreUser)
        })

        return subject
    }

    listenForToons(userId: string): BehaviorSubject<FirestoreToon[] | null> {
        const subject = new BehaviorSubject<FirestoreToon[] | null>(null)

        firestore()
        .collection(userCollectionName)
        .doc(userId)
        .collection(toonsCollectionName)
        .onSnapshot(result => {
            let firestoreToons: FirestoreToon[] = []

            result.forEach(doc => {
                const firestoreToon = doc.data() as FirestoreToon
                firestoreToons.push(firestoreToon)
            })

            subject.next(firestoreToons)
        })


        return subject
    }

}