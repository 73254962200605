import {injectable, Lifecycle, scoped} from "tsyringe";
import CogSuitsScreenModel from "../CogSuitsScreenModel";
import {BehaviorSubject} from "rxjs";
import CogSuitsViewModel from "../CogSuitsViewModel";
import CogType from "../../app/entities/invasions/cogs/CogType";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export default class PointsTableButtonViewModel {

    state = new BehaviorSubject<PointsTableButtonState>({
        label: "Points Table"
    })

    constructor(private cogSuitsScreenModel: CogSuitsScreenModel, private cogSuitsViewModel: CogSuitsViewModel) {
        cogSuitsViewModel.uiState.subscribe(s => {
            if(s.cogSuitDetails !== null) {
                this.state.next({label: this.getLabel(s.cogSuitDetails.cogType)})
            }
        })
    }

    onClick() {
        this.cogSuitsScreenModel.showPointsTableDialog()
    }

    private getLabel(cogType: CogType): string {
        switch (cogType) {
            case CogType.SELLBOT:
                return "Merits Table"
            case CogType.CASHBOT:
                return "Cogbucks Table"
            case CogType.LAWBOT:
                return "Jury Notices Table"
            case CogType.BOSSBOT:
                return "Stock Options Table"
            default:
                return "Points Table"
        }
    }

}

export type PointsTableButtonState = {
    label: string
}