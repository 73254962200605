import Toon from "application-users/model/toons/Toon";
import User from "application-users/model/User";
import type AuthManager from "auth/AuthManager";
import {BehaviorSubject, combineLatestWith} from "rxjs";
import {inject, injectable, singleton} from "tsyringe";
import type UserDataSource from "./UserDataSource";

@singleton()
@injectable()
export default class UserRepository {
    user = new BehaviorSubject<User | null>(null)
    currentToon = new BehaviorSubject<Toon | null>(null)

    private currentToonId = new BehaviorSubject<string | null>(null)

    constructor(
        @inject("AuthManager") private auth: AuthManager,
        @inject("RemoteUserDataSource") private remoteDataSource: UserDataSource
    ) {
        this.#listenForUser()

        this.currentToonId.pipe(combineLatestWith(this.user)).subscribe(([currentToonId, user]) => {
            if (user === null) return

            if(currentToonId === null) {
                currentToonId = user.toons[0].id
            }

            const toon = user.toons.find(t => t.id === currentToonId) ?? null
            this.currentToon.next(toon)
        })
    }

    async updateToon(toon: Toon) {
        const userId = this.auth.userId.value
        if (userId !== null) {
            this.remoteDataSource.updateToon(userId, toon)
        }
    }

    async updateCurrentToonId(toonId: string) {
        this.currentToonId.next(toonId)
    }

    async #listenForUser() {
        this.remoteDataSource.user.subscribe(user => {
            this.user.next(user)
        })
    }
}