import Fish from "app/data/game/fish.json"

/** @typedef {Object} FishingSpot
 *  @property neighbourhood The neighbourhood this spot is in
 *  @property spot The name of the street (or "Playground") this spot is in
 *  @property rodIndex Index of the fishing rod to achive the fishing rate in this object (starting form Twig to Gold)
 *  @property rate Chance to catch the fish given the other data in this object 
 */

export default class FishData {

    constructor() {
        /** @type {Object.<String, Array.<String>>} */
        this.fishingRates = {}
    }

    async init() {
        for (const species of Fish) {
            for (const fish of species.fish) {
                this.fishingRates[fish.name] = []

                for (let rod = 0; rod < 5; rod++) {    // cycle through fishing rods

                    let bestNeighbourhood = null
                    let bestSpot = null
                    let bestRate = 0

                    for (const neighbourHood in fish.fishingSpots) {

                        for (const spot in fish.fishingSpots[neighbourHood]) {

                            if (fish.fishingSpots[neighbourHood][spot][rod] > bestRate) {
                                bestRate = fish.fishingSpots[neighbourHood][spot][rod]
                                bestSpot = spot
                                bestNeighbourhood = neighbourHood
                            }
                        }
                    }

                    this.fishingRates[fish.name][rod] = {
                        neighbourhood: bestNeighbourhood,
                        spot: bestSpot,
                        rate: bestRate,
                        rodIndex: rod
                    }

                }
            }
        }

    }

    /**
     * 
     * @param {String} fishName Name of the fish to get fishing rates for
     * @returns {Array.<FishingSpot>} Array of the best fishing spots with the given fish. First element is Twig, last is Gold 
     */
    getFishRates(fishName) {
        return this.fishingRates[fishName]
    }

    getEmptySet() {
        let set = {}

        for (const species of Fish) {
            for (const f of species.fish) {
                set[`${f.index}`] = false
            }
        }

        return set
    }
}