import "reflect-metadata";
import AuthManager from "auth/AuthManager";
import FirebaseAuthManager from "auth/FirebaseAuthManager";
import RemoteUserDataSource from "data-users/remote/RemoteUserDataSource";
import UserFirestoreMediator from "data-users/remote/firestore/UserFirestoreMediator";
import UserDataSource from "data-users/UserDataSource";
import UserRepository from "data-users/UserRepository";
import firebase from "firebase";
import {useEffect, useState} from "react";
import {container} from "tsyringe";
import DemoteCogSuitUseCase from "feat-tracking/application/demoteCogSuit/DemoteCogSuitUseCase";
import GetAssignedToontasks from "feat-tracking/application/getAssignedToontasks/GetAssignedToontasks";
import GetAssignedToontasksUseCase from "feat-tracking/application/getAssignedToontasks/GetAssignedToontasksUseCase";
import GetCogSuitsProgress from "feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgress";
import GetCogSuitsProgressUseCase from "feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgressUseCase";
import PromoteCogSuitUseCase from "feat-tracking/application/promoteCogSuit/PromoteCogSuitUseCase";
import CogSuitsRepository from "data-cogsuits/cogsuits/CogSuitsRepository";
import TTRCogSuitsRepository from "data-cogsuits/cogsuits/TTRCogSuitsRepository";
import ToontasksRepository from "data-toontasks/ToontasksRepository";
import CogSuitProgressEvaluator from "feat-tracking/entities/cogsuits/CogSuitProgressEvaluator";
import GetInvasionsUseCase from "./application/invasions/getInvasions/GetInvasionsUseCase";
import InvasionsAPIService from "./data/invasions/api/InvasionsAPIService";
import TTRInvasionsRepository from "./data/invasions/TTRInvasionsRepository";
import StatusRepository from "./data/status/StatusRepository";
import LegacyUserRepository from "./data/users/UserRepository";
import FirestoreToonConverter from "data-users/remote/FirestoreToonConverter";
import GetToontasksProgressUseCase from "application-users/usecases/getToontasksProgress/GetToontasksProgressUseCase";
import GetToontasksUseCase from "application-game/usecases/getToontasks/GetToontasksUseCase";
import TTRToontasksRepository from "data-toontasks/TTRToontasksRepository";
import ScreenSize from "common/ScreenSize";
import GetToontaskUseCase from "../application-game/usecases/getToontask/GetToontaskUseCase";
import GetTrackingProgressUseCase from "../application-users/usecases/getTrackingProgress/GetTrackingProgressUseCase";
import FirestoreUserConverter from "../data-users/remote/FirestoreUserConverter";
import RootScreen from "./ui/RootScreen";


const firebaseConfig = {
    apiKey: "AIzaSyAcOGIuW7Qg8nfd-Hd6YkqYUQ5hFc9VcG0",
    authDomain: "toontracker-171e6.firebaseapp.com",
    databaseURL: "https://toontracker-171e6.firebaseio.com",
    projectId: "toontracker-171e6",
    storageBucket: "toontracker-171e6.appspot.com",
    messagingSenderId: "627013850610",
    appId: "1:627013850610:web:18fb259c687a487cbe876b",
    measurementId: "G-1M75FTL381"
}

firebase.initializeApp(firebaseConfig)

container.register("AuthManager", {
    useClass: FirebaseAuthManager
})

container.register("UserDataSource", {
    useClass: RemoteUserDataSource
})

container.register("GetToontasksProgress", {
    useClass: GetToontasksProgressUseCase
})

container.register("GetCogSuitsProgress", {
    useClass: GetCogSuitsProgressUseCase
})

container.register("GetToontasks", {
    useClass: GetToontasksUseCase
})

container.register("GetToontask", {
    useClass: GetToontaskUseCase
})

container.register("ToontasksRepository", {
    useClass: TTRToontasksRepository
})

container.register("CogSuitsRepository", {
    useClass: TTRCogSuitsRepository
})

container.register("PromoteCogSuit", {
    useClass: PromoteCogSuitUseCase
})

container.register("DemoteCogSuit", {
    useClass: DemoteCogSuitUseCase
})

container.register("RemoteUserDataSource", {
    useClass: RemoteUserDataSource
})

export default function App() {

    const [screenSize, setScreenSize] = useState(getScreenSize(window.innerWidth))

    const invasionsAPI = new InvasionsAPIService()

    const invasionsRepository = new TTRInvasionsRepository(invasionsAPI)
    const legacyUserRepository = new LegacyUserRepository()
    const cogSuitsRepository = new TTRCogSuitsRepository()
    const statusRepository = new StatusRepository()
    invasionsRepository.start()
    statusRepository.start()

    const getInvasions = new GetInvasionsUseCase(invasionsRepository)

    const authManager = createAuthManager()
    const userFirestoreMediator = createUserFirestoreMediator()
    const remoteUserDataSource = createRemoteUserDataSource(authManager, userFirestoreMediator)

    const userRepository = createUserRepository(authManager, remoteUserDataSource)

    const toontasksRepository = createToontasksRepository()

    const cogSuitProgressEvaluator = new CogSuitProgressEvaluator()

    const getAssignedToontasks = createGetAssignedToontasks(userRepository, toontasksRepository)

    const getCogSuitsProgress = createGetCogSuitsProgress(userRepository, cogSuitsRepository, cogSuitProgressEvaluator)

    const promoteCogSuit = new PromoteCogSuitUseCase(userRepository)
    const demoteCogSuit = new DemoteCogSuitUseCase(userRepository)

    useEffect(() => {
        function onWindowResize() {
            const width = window.innerWidth
            let currentScreenSize = getScreenSize(width)

            if (currentScreenSize !== screenSize) {
                setScreenSize(currentScreenSize)
            }
        }

        window.addEventListener("resize", onWindowResize)
        return () => {
            window.removeEventListener("resize", onWindowResize)
        }
    })

    return <RootScreen
        screenSize={screenSize}
        legacyUserRepository={legacyUserRepository}
        statusRepository={statusRepository}
        getInvasions={getInvasions}
        getAssignedToontasks={getAssignedToontasks}
        getCogSuitsProgress={getCogSuitsProgress}
        promoteCogSuit={promoteCogSuit}
        demoteCogSuit={demoteCogSuit}
        getTrackingProgress={container.resolve(GetTrackingProgressUseCase)}
    />
}

function getScreenSize(width: ScreenSize): ScreenSize {
    if (width < ScreenSize.Medium) return ScreenSize.Small
    if (width < ScreenSize.Large) return ScreenSize.Medium
    return ScreenSize.Large
}

function createGetAssignedToontasks(userRepository: UserRepository, toontasksRepository: ToontasksRepository): GetAssignedToontasks {
    return new GetAssignedToontasksUseCase(userRepository, toontasksRepository)
}

function createGetCogSuitsProgress(userRepository: UserRepository, cogSuitsRepository: CogSuitsRepository, cogSuitProgressEvaluator: CogSuitProgressEvaluator): GetCogSuitsProgress {
    return new GetCogSuitsProgressUseCase(userRepository, cogSuitsRepository, cogSuitProgressEvaluator)
}

function createUserRepository(authManager: AuthManager, remoteUserDataSource: UserDataSource): UserRepository {
    return new UserRepository(authManager, remoteUserDataSource)
}

function createAuthManager(): AuthManager {
    return new FirebaseAuthManager()
}

function createRemoteUserDataSource(authManager: AuthManager, firestoreMediator: UserFirestoreMediator): UserDataSource {
    return new RemoteUserDataSource(authManager, firestoreMediator, container.resolve(FirestoreToonConverter), container.resolve(FirestoreUserConverter))
}

function createUserFirestoreMediator(): UserFirestoreMediator {
    return new UserFirestoreMediator()
}

function createToontasksRepository(): ToontasksRepository {
    return new TTRToontasksRepository()
}