import Card from "common/theme/components/Card"
import { Subtitle2 } from "app/theme/Typography"
import styled from "styled-components"

type Props = {
    title: string,
    backgroundColor: string,
    onClick: () => void,
    children: React.ReactNode
}

export default function ActivityCard(props: Props) {
    return (
        <StyledActivityCard backgroundColor={props.backgroundColor} onClick={props.onClick}>
            <StyledTitle>{props.title}</StyledTitle>
            {props.children}
        </StyledActivityCard>
    )
}

type StyledProps = {
    backgroundColor: string
}

const StyledActivityCard = styled(Card)<StyledProps>`
    width: 140px;
    height: 120px;
    background-color: ${props => props.backgroundColor};
`

const StyledTitle = styled(Subtitle2)`
    color: white;
    position: relative;
    top: 10px;
    left: 15px;
`