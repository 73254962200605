import { Body1, Headline1 } from "app/theme/Typography"

import styled from "styled-components"

type Props = {
    laff: number
}

export default function LaffText(props: Props) {
    return <StyledLaffText>
        <LaffAmountText>{props.laff}</LaffAmountText>
        <LaffLabel>LAFF</LaffLabel>
    </StyledLaffText>
}

const StyledLaffText = styled.div`
    grid-column: 1;
    grid-row: 1;
`

const LaffAmountText = styled(Headline1)`
    width: 100%;
    text-align: center;
    margin-top: 36px;
    color: white;
`

const LaffLabel = styled(Body1)`
    width: 100%;
    margin-top: -14px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: white;
`