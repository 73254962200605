import {injectable} from "tsyringe";

const TOTAL_RACING_TROPHIES = 30

export type RacingProgressEvaluatorResult ={
    cups: number
    racingProgress: number,
    racingTrophies: number,
    nextLaffAtProgress: number | null
}

@injectable()
export default class RacingProgressEvaluator {

    evaluate(racing: Map<number, boolean>): RacingProgressEvaluatorResult {
        let count = 0

        racing.forEach((isCollected, index) => {
            if(isCollected) {
                count++
            }  
        })
    
        return {
            racingProgress: count / TOTAL_RACING_TROPHIES,
            racingTrophies: count,
            cups: Math.floor(count / 10),
            nextLaffAtProgress: count < TOTAL_RACING_TROPHIES ? (Math.floor(count / 10) + 1) * 10 / TOTAL_RACING_TROPHIES : null
        }
    }

}