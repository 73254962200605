import {CSSProperties} from "react";
import CogType from "../../app/entities/invasions/cogs/CogType";
import {FacilityRow} from "../CogSuitsScreenState";
import ScreenSize from "../../common/ScreenSize";
import SmallFacilitiesTable from "./small/SmallFacilitiesTable";
import MediumFacilitiesTable from "./medium/MediumFacilitiesTable";

type Props = {
    screenSize: ScreenSize,
    cogType: CogType,
    pointsName: string,
    rows: FacilityRow[],
    style?: CSSProperties
}

export default function FacilitiesTable(props: Props) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallFacilitiesTable {...props} />
        case ScreenSize.Medium:
            return <MediumFacilitiesTable {...props} />
        case ScreenSize.Large:
            return <MediumFacilitiesTable {...props} style={{height: 240}} />

    }
}