import MainScreenComponent from "./MainScreenComponent";
import {container} from "tsyringe";
import GetTrackingProgressUseCase
    from "../../application-users/usecases/getTrackingProgress/GetTrackingProgressUseCase";
import UserRepository from "../data/users/UserRepository";
import StatusRepository from "../data/status/StatusRepository";
import GetInvasions from "../application/invasions/getInvasions/GetInvasions";
import GetAssignedToontasks from "../../feat-tracking/application/getAssignedToontasks/GetAssignedToontasks";
import GetCogSuitsProgress from "../../feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgress";
import PromoteCogSuit from "../../feat-tracking/application/promoteCogSuit/PromoteCogSuit";
import DemoteCogSuit from "../../feat-tracking/application/demoteCogSuit/DemoteCogSuit";
import {ThemeProvider} from "styled-components";
import theme from "../theme/Theme";
import {Column} from "../../core-anvil/Layouts";
import Toolbar from "./app/toolbar/Toolbar";
import {useEffect, useRef, useState} from "react";
import RootViewModel from "./RootViewModel";
import RootScreenState, {SideNavigationState, ToolbarState} from "./RootScreenState";
import ScreenSize from "../../common/ScreenSize";

type Props = {
    screenSize: ScreenSize,
    legacyUserRepository: UserRepository,
    statusRepository: StatusRepository,
    getInvasions: GetInvasions,
    getAssignedToontasks: GetAssignedToontasks,
    getCogSuitsProgress: GetCogSuitsProgress,
    promoteCogSuit: PromoteCogSuit,
    demoteCogSuit: DemoteCogSuit,
    getTrackingProgress: GetTrackingProgressUseCase
}

export default function RootScreen(props: Props) {
    const viewModel = useRef(container.resolve(RootViewModel))

    const [uiState, setUIState] = useState<RootScreenState>(viewModel.current.uiState.value)

    useEffect(() => {
        const sub = viewModel.current.uiState.subscribe(setUIState)
        return () => sub.unsubscribe()
    }, [])

    return <RootScreenContent
        {...props}
        toolbar={uiState.toolbar}
        sideNav={uiState.sideNav}
        onMenuClick={() => viewModel.current.onMenuClick()}
        onToonSelectorClick={() => viewModel.current.onToonSelectorClick()}
        onToonSelected={toonName => viewModel.current.onToonSelected(toonName)}
    />
}

type ContentProps = {
    screenSize: ScreenSize,
    legacyUserRepository: UserRepository,
    statusRepository: StatusRepository,
    getInvasions: GetInvasions,
    getAssignedToontasks: GetAssignedToontasks,
    getCogSuitsProgress: GetCogSuitsProgress,
    promoteCogSuit: PromoteCogSuit,
    demoteCogSuit: DemoteCogSuit,
    getTrackingProgress: GetTrackingProgressUseCase,
    toolbar: ToolbarState,
    sideNav: SideNavigationState
    onMenuClick: () => void,
    onToonSelected: (toonName: string) => void,
    onToonSelectorClick: () => void
}

function RootScreenContent(props: ContentProps) {
    return (
        <ThemeProvider theme={theme}>
            <Column style={{width: "100%", height: "100%"}}>
                <Toolbar
                    selectedToonId={props.toolbar.currentToonId}
                    laff={props.toolbar.currentToonLaff}
                    currentToonName={props.toolbar.currentToonName}
                    toons={props.toolbar.toons}
                    email={props.toolbar.email}
                    screenSize={props.screenSize}
                    isToonSelectorExpanded={props.toolbar.isToonSelectorExpanded}
                    onMenuClick={props.onMenuClick}
                    onToonSelected={props.onToonSelected}
                    onToonSelectorClick={props.onToonSelectorClick}
                />
                <MainScreenComponent
                    screenSize={props.screenSize}
                    userRepository={props.legacyUserRepository}
                    statusRepository={props.statusRepository}
                    getInvasions={props.getInvasions}
                    getAssignedToontasks={props.getAssignedToontasks}
                    getCogSuitsProgress={props.getCogSuitsProgress}
                    promoteCogSuit={props.promoteCogSuit}
                    demoteCogSuit={props.demoteCogSuit}
                    getTrackingProgress={container.resolve(GetTrackingProgressUseCase)}
                    isSideNavVisible={props.sideNav.isExpanded || props.screenSize === ScreenSize.Large}
                />
            </Column>
        </ThemeProvider>
    )
}