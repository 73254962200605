import Card from "common/theme/components/Card"
import { Headline5 } from "app/theme/Typography"
import styled from "styled-components"

type Props = {
    title: string,
    titleColor?: string,
    headerBackgroundColor: string,
    contentBackgroundColor: string,
    smallWidth: number,
    mediumWidth: number,
    largeWidth: number,
    onClick: () => void,
    children: React.ReactNode
}

export default function FeatureCard(props: Props) {
    return (
        <StyledFeatureCard
            backgroundColor={props.contentBackgroundColor}
            smallWidth={props.smallWidth}
            mediumWidth={props.mediumWidth}
            largeWidth={props.largeWidth}
            onClick={props.onClick}
        >
            <StyledFeatureCardHeader backgroundColor={props.headerBackgroundColor}><Title color={props.titleColor !== undefined ? props.titleColor : "white"}>{props.title}</Title></StyledFeatureCardHeader>
            {props.children}
        </StyledFeatureCard>
    )
}

type StyledProps = {
    backgroundColor: string,
    smallWidth: number,
    mediumWidth: number,
    largeWidth: number
}

const StyledFeatureCard = styled(Card) <StyledProps>`
    width: ${props => props.smallWidth}px;
    height: auto;
    background-color: ${props => props.backgroundColor};

    @media (min-width: 720px) {
        width: ${props => props.mediumWidth}px;
        border-radius: 20px;
    }

    @media (min-width: 1440px) {
        width: ${props => props.largeWidth}px;
    }
`

type StyledFeatureCardHeaderProps = {
    backgroundColor: string
}

const StyledFeatureCardHeader = styled.div<StyledFeatureCardHeaderProps>`
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: ${props => props.backgroundColor};

    @media (min-width: 720px) {
        height: 60px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`

type TitleProps = {
    color: string
}

const Title = styled(Headline5) <TitleProps>`
	margin: auto;
	color: ${props => props.color};

    @media (min-width: 720px) {
        font-size: 25px;
    }
`