import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const styles = theme => ({
	root: {
		width: 340,
		fontSize: 17,
		borderRadius: 12,
		color: "#ffff",
		"&$focused $notchedOutline": {
			borderColor: "#fffd",
			borderWidth: "2px"
		},
		"&:hover $notchedOutline": {
			borderColor: "#47039d",
		}
	},
	rootLabel: {
		color: "#fff9",
		"&$focusedLabel" : {
			color: "#fffd"
		}
	},
	focused: {},
	focusedLabel: {},
	notchedOutline: {
		borderColor: "#fffb",
	}
});

class LoginTextField extends React.Component {
	constructor(props) {
		super(props);
		const { classes } = props;
		const { label } = props;
		const { type } = props;
		const { onChange } = props;

		this.state = {
			classes: classes,
			label: label,
			type: type,
			onChange: onChange,
			value: ""
		}

	}

	render() {

		return (
			<TextField
				InputLabelProps={{
					classes: {
						root: this.state.classes.rootLabel,
						focused: this.state.classes.focusedLabel
					},
				}}
				InputProps={{
					classes: {
						root: this.state.classes.root,
						focused: this.state.classes.focused,
						notchedOutline: this.state.classes.notchedOutline,
					},
				}}
				label={this.state.label}
				type={this.state.type}
				onChange={this.state.onChange}
				variant="outlined"
				id="custom-css-outlined-input"
			/>
		);
	}
}

LoginTextField.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginTextField);