import { Body2, Subtitle2 } from "app/theme/Typography"

import Card from "common/theme/components/Card"
import CogType from "app/entities/invasions/cogs/CogType"
import bossbot_icon from "common/resources/images/ic_cog_bossbot_icon.png"
import cashbot_icon from "common/resources/images/ic_cog_cashbot_icon.png"
import lawbot_icon from "common/resources/images/ic_cog_lawbot_icon.png"
import sellbot_icon from "common/resources/images/ic_cog_sellbot_icon.png"
import styled from "styled-components"

type Props = {
    cogName: string,
    cogType: CogType,
    district: string,
    width: number
}

export default function InvasionItem(props: Props) {
    const textWidth = props.width - 48

    return (
        <StyledInvasionItem width={props.width} >
            <InfoColumn width={textWidth}>
                <StyledCogName>{props.cogName}</StyledCogName>
                <StyledDistrict>{props.district}</StyledDistrict>
            </InfoColumn>
            <StyledCogTypeIcon src={getCogTypeImage(props.cogType)} />

        </StyledInvasionItem >
    )
}

type StyledInvasionItemProps = {
    width: number
}

const StyledInvasionItem = styled(Card) <StyledInvasionItemProps>`
    width: ${props => props.width}px;
    height: 60px;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.colors.invasions};
`

const StyledCogName = styled(Subtitle2)`
    width: wrap;
    color: white;
    font-size: 15px;
    margin-left: 10px;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
`

const StyledCogTypeIcon = styled.img`
    width: 36px;
    height: 36px;
    margin: auto;
    margin-right: 6px;
`
function getCogTypeImage(cogType: CogType) {
    switch (cogType) {
        case CogType.SELLBOT: return sellbot_icon
        case CogType.CASHBOT: return cashbot_icon
        case CogType.LAWBOT: return lawbot_icon
        case CogType.BOSSBOT: return bossbot_icon
    }
}

const StyledDistrict = styled(Body2)`
    margin-left: 10px;
`

type InfoColumnProps = {
    width: number
}

const InfoColumn = styled.div<InfoColumnProps>`
    width: ${props => props.width}px;
    display: flex;
    flex-direction: column;
    flex: 1;
`