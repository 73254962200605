import Neighborhood from "../../entities/Neighborhood";

export enum NeighborhoodResult {
    TOONTOWN_CENTRAL,
    DONALDS_DOCK,
    DAISY_GARDENS,
    MINNIES_MELODYLAND,
    THE_BRRRGH,
    DONALDS_DREAMLAND,
    ESTATE,
    ACORN_ACRES
}

export function neighborhoodEntityToResult(neighborhood: Neighborhood): NeighborhoodResult {
    switch(neighborhood) {
        case Neighborhood.TOONTOWN_CENTRAL: return NeighborhoodResult.TOONTOWN_CENTRAL
        case Neighborhood.DONALDS_DOCK: return NeighborhoodResult.DONALDS_DOCK
        case Neighborhood.DAISY_GARDENS: return NeighborhoodResult.DAISY_GARDENS
        case Neighborhood.MINNIES_MELODYLAND: return NeighborhoodResult.MINNIES_MELODYLAND
        case Neighborhood.THE_BRRRGH: return NeighborhoodResult.THE_BRRRGH
        case Neighborhood.DONALDS_DREAMLAND: return NeighborhoodResult.DONALDS_DREAMLAND
        case Neighborhood.ESTATE: return NeighborhoodResult.ESTATE
        case Neighborhood.ACORN_ACRES: return NeighborhoodResult.ACORN_ACRES
    }
}

export default NeighborhoodResult