import {CSSProperties} from "react";
import ScreenSize from "../../common/ScreenSize";
import SmallCogSuitPoints from "./small/SmallCogSuitPoints";
import MediumCogSuitPoints from "./medium/MediumCogSuitPoints";

type Props = {
    screenSize: ScreenSize,
    pointsLabel: string,
    points: string,
    requiredPoints: string,
    onPointsChange: (points: string) => void,
    style?: CSSProperties
}

export default function CogSuitPoints(props: Props) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallCogSuitPoints {...props}/>
        case ScreenSize.Medium:
            return <MediumCogSuitPoints {...props}/>
        case ScreenSize.Large:
            return <SmallCogSuitPoints {...props}/>

    }
}