import {injectable} from "tsyringe";
import {BehaviorSubject} from "rxjs";
import RootScreenState, {ToonListItem} from "./RootScreenState";
import GetToons from "../../application-users/usecases/get-toons/GetToons";
import SetCurrentToon from "../../application-users/usecases/set-current-toon/SetCurrentToon";

@injectable()
export default class RootViewModel {

    uiState = new BehaviorSubject<RootScreenState>({
        toolbar: {
            currentToonId: "",
            currentToonLaff: 0,
            currentToonName: "-",
            toons: [{id: "", name: "-", laff: "-"}],
            email: "medz967@gmail.com",
            isToonSelectorExpanded: false
        },
        sideNav: {
            isExpanded: false
        }
    })

    constructor(
        private getToons: GetToons,
        private setCurrentToon: SetCurrentToon
    ) {
        this.listenToGetToons()
    }

    onMenuClick() {
        this.uiState.next({
            ...this.uiState.value,
            sideNav: {
                isExpanded: !this.uiState.value.sideNav.isExpanded
            }
        })
    }

    onToonSelectorClick() {
        this.uiState.next({
            ...this.uiState.value,
            toolbar: {
                ...this.uiState.value.toolbar,
                isToonSelectorExpanded: !this.uiState.value.toolbar.isToonSelectorExpanded
            }
        })
    }

    onToonSelected(toonId: string) {
        this.uiState.next({
            ...this.uiState.value,
            toolbar: {
                ...this.uiState.value.toolbar,
                isToonSelectorExpanded: false
            }
        })
        this.setCurrentToon.invoke(toonId)
    }

    private listenToGetToons() {
        this.getToons.invoke().subscribe(result => {
            if(result === null) return

            const toons: ToonListItem[] = result.toons.map(toon => {
                return {
                    id: toon.id,
                    name: toon.name,
                    laff: toon.laff.toString()
                }
            })

            this.uiState.next({
                ...this.uiState.value,
                toolbar: {
                    ...this.uiState.value.toolbar,
                    currentToonId: result.currentToon.id,
                    currentToonLaff: result.currentToon.laff,
                    currentToonName: result.currentToon.name,
                    toons: toons,
                    email: "medz967@gmail.com"
                }
            })
        })
    }

}