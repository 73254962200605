import ToontasksData from "app/data/game/ToontasksData"
import FishData from "app/data/game/fish-data"
import CogSuitsData from "app/data/game/CogSuitsData"
import FlowersData from "./game/FlowersData"
import RacingData from "app/data/game/RacingData"

/** @typedef {import(main/data/game/CogSuitsData).CogSuit} CogSuit */

class GameDataRepository {
    /** @private @type {ToontasksData} */
    #toontasks

    /** @private @type {FishData} */
    #fish
    /** @private @type {CogSuitsData} */
    #cogSuits

    /** @private @type {FlowersData} */
    #flowers

    /** @private @type {RacingData} */
    #racing

    constructor() {
        this.#toontasks = null
        this.#fish = null
        this.#cogSuits = null
        this.#flowers = null
        this.#racing = null
    }

    async init() {
        return new Promise(resolve => {
            const toontasksData = new ToontasksData()
            const cogSuitsData = new CogSuitsData()
            const fishData = new FishData()
            const flowersData = new FlowersData()
            const racingData = new RacingData()

            Promise.all([
                toontasksData.init(),
                cogSuitsData.init(),
                fishData.init(),
                flowersData.init(),
                racingData.init()
            ]).then(() => {
                this.#toontasks = toontasksData
                this.#cogSuits = cogSuitsData
                this.#fish = fishData
                this.#flowers = flowersData
                this.#racing = racingData
                resolve()
            })
        })
    }

    /** @returns {ToontasksData} */
    getToontasksData() {
        return this.#toontasks
    }

    getFishData() {
        return this.#fish
    }

    /** @returns {CogSuitsData} */
    getCogSuitsData() {
        return this.#cogSuits
    }

    getFlowersData() {
        return this.#flowers
    }

    getRacingData() {
        return this.#racing
    }
}

const singleton = new GameDataRepository()
export default singleton