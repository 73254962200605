import FeatureCard from "../FeatureCard"
import InvasionCardState from "./InvasionCardState"
import InvasionItem from "./InvasionItem"
import {ThemeContext} from "styled-components"
import styled from "styled-components"
import {useContext} from "react"
import {ScreenSize} from "common/ScreenSize"

type Props = {
    invasions: InvasionCardState,
    screenSize: ScreenSize,
    onClick: () => void
}

export default function InvasionsCard(props: Props) {
    const theme = useContext(ThemeContext)

    let invasionItemWidth = 0
    switch (props.screenSize) {
        case ScreenSize.Small:
            invasionItemWidth = 130
            break
        case ScreenSize.Medium:
            invasionItemWidth = 240
            break
        case ScreenSize.Large:
            invasionItemWidth = 150
            break
    }

    return (
        <FeatureCard title="Invasions"
                     smallWidth={320}
                     mediumWidth={280}
                     largeWidth={190}
                     headerBackgroundColor={theme.colors.invasions}
                     contentBackgroundColor={theme.colors.invasionsDark}
                     onClick={props.onClick}
        >
            <StyledInvasionsCardContent>
                {createInvasionItems(props.invasions, invasionItemWidth)}
            </StyledInvasionsCardContent>
        </FeatureCard>
    )
}

function createInvasionItems(invasions: InvasionCardState, width: number): JSX.Element[] {
    let invasionItems: JSX.Element[] = []

    invasions.forEach(invasion => {
        invasionItems.push(
            <InvasionItem
                cogName={invasion.cogName}
                cogType={invasion.cogType}
                district={invasion.district}
                width={width}
            />
        )
    })
    return invasionItems
}

const StyledInvasionsCardContent = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`