import {injectable} from "tsyringe";
import GetToonsResult, {ToonResult} from "./GetToonsResult";
import {BehaviorSubject, combineLatestWith} from "rxjs";
import UserRepository from "../../../data-users/UserRepository";

@injectable()
export default class GetToons {
    constructor(private userRepository: UserRepository) {
    }

    invoke(): BehaviorSubject<GetToonsResult | null> {
        const subject = new BehaviorSubject<GetToonsResult | null>(null)

        this.userRepository.currentToon.pipe(combineLatestWith(this.userRepository.user)).subscribe(([currentToon, user]) => {
            if(currentToon === null || user === null) return

            const toons: ToonResult[] = user.toons.map(toon => {
                return {
                    id: toon.id,
                    name: toon.name,
                    laff: toon.laff
                }
            })

            subject.next({
                currentToon: {
                    id: currentToon.id,
                    name: currentToon.name,
                    laff: currentToon.laff
                },
                toons: toons
            })
        })

        return subject
    }

}