import styled, { ThemeContext } from "styled-components"

import ActivityCard from "./ActivityCard"
import { useContext } from 'react';
import CogSuitsLevelLayout from "./CogSuitsLevelLayout";

import bossbot_icon from "common/resources/images/ic_cog_bossbot_icon.png"
import lawbot_icon from "common/resources/images/ic_cog_lawbot_icon.png"
import cashbot_icon from "common/resources/images/ic_cog_cashbot_icon.png"
import sellbot_icon from "common/resources/images/ic_cog_sellbot_icon.png"

type Props = {
    bossbotLevel: number,
    lawbotLevel: number,
    cashbotLevel: number,
    sellbotLevel: number,
    onClick: () => void
}

export default function CogSuitsCard(props: Props) {
    const theme = useContext(ThemeContext)
    return (
        <ActivityCard title="Cog Suits" backgroundColor={theme.colors.cogSuits} onClick={props.onClick}>
            <StyledCogSuitsCardContent>
                <CogSuitsLevelLayout backgroundColor={theme.colors.bossbot} cogSuitIcon={bossbot_icon} cogSuitLevel={props.bossbotLevel} />
                <CogSuitsLevelLayout backgroundColor={theme.colors.lawbot} cogSuitIcon={lawbot_icon} cogSuitLevel={props.lawbotLevel} />
                <CogSuitsLevelLayout backgroundColor={theme.colors.cashbot} cogSuitIcon={cashbot_icon} cogSuitLevel={props.cashbotLevel} />
                <CogSuitsLevelLayout backgroundColor={theme.colors.sellbot} cogSuitIcon={sellbot_icon} cogSuitLevel={props.sellbotLevel} />
            </StyledCogSuitsCardContent>
        </ActivityCard>
    )
}

const StyledCogSuitsCardContent = styled.p`
    width: fit-content;
    height: fit-content;
    margin-top: 18px;
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 9px;
`