import {injectable} from "tsyringe";
import UserRepository from "../../../data-users/UserRepository";

@injectable()
export default class SetFishCaught {

    constructor(private userRepository: UserRepository) {
    }

    async invoke(fishId: number, isCaught: boolean) {
        const toon = this.userRepository.currentToon.value
        if (!toon) {
            throw Error("Toon was null")
        }

        toon.fishCaught.set(fishId, isCaught)

        await this.userRepository.updateToon(toon)
    }

}