import { Body2, Subtitle1, Subtitle2 } from 'app/theme/Typography';
import { useContext } from 'react';
import styled, { ThemeContext } from "styled-components";
import ActivityCard from './ActivityCard';

type Props = {
    progress: number,
    onClick: () => void
}

export default function FishingCard(props: Props) {
    const theme = useContext(ThemeContext)
    return (
        <ActivityCard title="Flowers" backgroundColor={theme.colors.flowers} onClick={props.onClick}>
            <StyledFlowersCardContent>
                <StyledProgressLayout>
                <StyledFlowersValue>{props.progress}</StyledFlowersValue>
                <StyledMax>/ 40</StyledMax>
                </StyledProgressLayout>
                <StyledProgressLabel>Unlocked</StyledProgressLabel>
            </StyledFlowersCardContent>
        </ActivityCard>
    )

}

const StyledFlowersCardContent = styled.div`
    width: 100%;
    height: 100%;
    diplay: flex;
    flex-direction: column;
    align-items: center;
`

const StyledProgressLayout = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
`

const StyledFlowersValue = styled(Subtitle1)``

const StyledMax = styled(Body2)`
    margin-top: 8px;
    margin-left: 4px;
`

const StyledProgressLabel = styled(Subtitle2)`
    width: min-content;
    font-weight: 300;
    margin-top: -6px;
    margin-left: auto;
    margin-right: auto;
`