import { CircularProgressbar } from 'react-circular-progressbar';
import LaffText from './LaffText';
import styled from "styled-components";

type Props = {
    laff: number
}

export default function Laff(props: Props) {
    return <StyledLaff>
        <Ring />
        <ProgressCircle laff={props.laff} />
        <LaffText laff={props.laff} />
    </StyledLaff>
}

function Ring() {
    return <StyledRing><CircularProgressbar value={100} strokeWidth={2.5} styles={{
        path: {
            stroke: "#ffffff7f",
        },
        trail: {
            stroke: "#ffffff00",
        }
    }} />
    </StyledRing>
}

type ProgressCircleProps = {
    laff: number
}

function ProgressCircle(props: ProgressCircleProps) {
    return <StyledProgressCircle>
        <CircularProgressbar value={props.laff} minValue={16} maxValue={137} strokeWidth={10} styles={{
            path: {
                stroke: "#47039D",
                strokeLinecap: 'butt'
            },
            trail: {
                stroke: "#ffffff00",
            }
        }} />
    </StyledProgressCircle>
}

const StyledLaff = styled.div`
    margin-top: 20px;
    display: grid;
    justify-items: center;
`

const StyledRing = styled.div`
    width: 212px;
    height: 212px;
    grid-column: 1;
    grid-row: 1;
    margin-top: 4px;
`

const StyledProgressCircle = styled.div`
    width: 220px;
    height: 220px;    
    grid-column: 1;
    grid-row: 1;
`