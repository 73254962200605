import styled from "styled-components";

export const Headline1 = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 79px;
  font-weight: bold;
`

export const Headline2 = styled.p`
  font-family: 'ImpressBT';
  font-size: 48px;
`

export const Headline5 = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`

export const Headline6 = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 18px;
  font-weight: 500;
`

export const Subtitle1 = styled.p`
  font-size: 27px;
  font-family: Catamaran;
  font-weight: 800;
  color: white;
  text-align: center;
`

export const Subtitle2 = styled.p`
  font-size: 16px;
  font-family: Catamaran;
  font-weight: 500;
  color: white;
  line-height: 20px;
`

export const Body1 = styled.p`
  font-size: 15px;
  font-family: Catamaran;
  color: white;
  line-height: 14px;
`

export const Body2 = styled.p`
  font-size: 13px;
  font-family: Catamaran;
  font-weight: 300;
  color: white;
  line-height: 18px;
`

export const HeadlineMedium = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: white;
`

export const TitleLarge = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  color: white;
`

export const TitleMedium = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: white;
`

export const TitleSmall = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 18px;
  color: white;
`

export const BodyMedium = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 15px;
  color: white;
  line-height: 16px;
`

export const LabelLarge = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: white;
`

export const LabelMedium = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 15px;
  line-height: 16px;
  color: white;
`

export const LabelSmall = styled.p`
  font-family: 'Catamaran', sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: white;
`