import {ToonCogSuits} from "application-users/model/toons/cogsuits/ToonCogSuits"
import Toon from "application-users/model/toons/Toon"
import ToonToontasks, {AssignedToontaskPosition} from "application-users/model/toons/toontasks/ToonToontasks"
import {injectable} from "tsyringe"
import AssignedToontask from "../../application-users/model/toons/toontasks/AssignedToontask";
import {FirestoreAssignedToontask, FirestoreToon} from "./firestore/FirestoreUser";

@injectable()
export default class FirestoreToonConverter {

    convertToToon(firestoreToon: FirestoreToon): Toon {
        return {
            id: firestoreToon.id,
            name: firestoreToon.name,
            laff: firestoreToon.laff,
            toontasks: this.#createToonToontasks(firestoreToon),
            cogSuits: this.#createToonCogSuits(firestoreToon),
            fishCaught: this.#createFishMap(firestoreToon.fish),
            flowers: this.#createFlowersMap(firestoreToon.flowers),
            racingTrophies: this.#createRacingTrophiesMap(firestoreToon.racingTrophies),
            golfTrophies: this.#createGolfTrophiesMap(firestoreToon.golfTrophies)
        }
    }

    covertFromToon(toon: Toon): FirestoreToon {
        return {
            id: toon.id,
            name: toon.name,
            laff: toon.laff,
            toontasks: this.#createObject(toon.toontasks.toontasks),
            cogSuits: {
                0: toon.cogSuits.sellbot.index,
                1: toon.cogSuits.cashbot.index,
                2: toon.cogSuits.lawbot.index,
                3: toon.cogSuits.bossbot.index
            },
            assignedToontasks: {
                0: this.#createFirestoreAssignedToontask(toon.toontasks.getAssignedToontasks().topLeft),
                1: this.#createFirestoreAssignedToontask(toon.toontasks.getAssignedToontasks().topRight),
                2: this.#createFirestoreAssignedToontask(toon.toontasks.getAssignedToontasks().bottomLeft),
                3: this.#createFirestoreAssignedToontask(toon.toontasks.getAssignedToontasks().bottomRight)
            },
            fish: this.#createObject(toon.fishCaught),
            flowers: this.#createObject(toon.flowers),
            racingTrophies: this.#createObject(toon.racingTrophies),
            golfTrophies: this.#createObject(toon.golfTrophies)
        }
    }

    #createFirestoreAssignedToontask(assigned: AssignedToontask | null): FirestoreAssignedToontask | null {
        if (assigned === null) {
            return null
        }

        return {
            toontaskId: assigned.toontaskId,
            option: assigned.currentOption,
            lastCompletedSteps: assigned.stepsCompleted.map(step => step.completedOption)
        }
    }

    #createObject(map: Map<number, boolean>): Object {
        let object: { [k: string]: boolean } = {}
        map.forEach((value: boolean, key: number) => {
            object[key.toString()] = value
        })

        return object
    }

    #createToonToontasks(firestoreToon: FirestoreToon): ToonToontasks {
        const toonToontasks = new ToonToontasks()

        toonToontasks.toontasks = this.#createToontasksMap(firestoreToon.toontasks)

        if (firestoreToon.assignedToontasks === undefined) {
            return toonToontasks
        }

        const topLeft = firestoreToon.assignedToontasks["0"] as FirestoreAssignedToontask | undefined
        if (topLeft) {
            toonToontasks.setAssignedToontask(AssignedToontaskPosition.TOP_LEFT, topLeft.toontaskId)
            for (let stepId = 0; stepId < topLeft.lastCompletedSteps.length; stepId++) {
                toonToontasks.addCompletedStep(AssignedToontaskPosition.TOP_LEFT, {completedOption: topLeft.lastCompletedSteps[stepId]})
            }
            toonToontasks.setCurrentOption(AssignedToontaskPosition.TOP_LEFT, topLeft.option)
        }

        const topRight = firestoreToon.assignedToontasks["1"] as FirestoreAssignedToontask | undefined
        if (topRight) {
            toonToontasks.setAssignedToontask(AssignedToontaskPosition.TOP_RIGHT, topRight.toontaskId)
            for (let stepId = 0; stepId < topRight.lastCompletedSteps.length; stepId++) {
                toonToontasks.addCompletedStep(AssignedToontaskPosition.TOP_RIGHT, {completedOption: topRight.lastCompletedSteps[stepId]})
            }
            toonToontasks.setCurrentOption(AssignedToontaskPosition.TOP_RIGHT, topRight.option)
        }

        const bottomLeft = firestoreToon.assignedToontasks["2"] as FirestoreAssignedToontask | undefined
        if (bottomLeft) {
            toonToontasks.setAssignedToontask(AssignedToontaskPosition.BOTTOM_LEFT, bottomLeft.toontaskId)
            for (let stepId = 0; stepId < bottomLeft.lastCompletedSteps.length; stepId++) {
                toonToontasks.addCompletedStep(AssignedToontaskPosition.BOTTOM_LEFT, {completedOption: bottomLeft.lastCompletedSteps[stepId]})
            }
            toonToontasks.setCurrentOption(AssignedToontaskPosition.BOTTOM_LEFT, bottomLeft.option)
        }

        const bottomRight = firestoreToon.assignedToontasks["3"] as FirestoreAssignedToontask | undefined
        if (bottomRight) {
            toonToontasks.setAssignedToontask(AssignedToontaskPosition.BOTTOM_RIGHT, bottomRight.toontaskId)
            for (let stepId = 0; stepId < bottomRight.lastCompletedSteps.length; stepId++) {
                toonToontasks.addCompletedStep(AssignedToontaskPosition.BOTTOM_RIGHT, {completedOption: bottomRight.lastCompletedSteps[stepId]})
            }
            toonToontasks.setCurrentOption(AssignedToontaskPosition.BOTTOM_RIGHT, bottomRight.option)
        }

        return toonToontasks
    }

    #createToonCogSuits(firestoreToon: FirestoreToon): ToonCogSuits {
        if (firestoreToon.cogSuits === undefined) {
            return {
                bossbot: {index: 0},
                lawbot: {index: 0},
                cashbot: {index: 0},
                sellbot: {index: 0}
            }
        }

        return {
            bossbot: {
                index: firestoreToon.cogSuits[3]
                    ? firestoreToon.cogSuits[3]
                    : 0
            },
            lawbot: {
                index: firestoreToon.cogSuits[2]
                    ? firestoreToon.cogSuits[2]
                    : 0
            },
            cashbot: {
                index: firestoreToon.cogSuits[1]
                    ? firestoreToon.cogSuits[1]
                    : 0
            },
            sellbot: {
                index: firestoreToon.cogSuits[0]
                    ? firestoreToon.cogSuits[0]
                    : 0
            },
        }
    }

    #createToontasksMap(toontasks: Object | undefined): Map<number, boolean> {
        if (!toontasks) {
            return new Map<number, boolean>()
        }

        const map = new Map<number, boolean>()

        Object.entries(toontasks).forEach(([index, isCompleted]) => {
            map.set(parseInt(index), Boolean(isCompleted))
        })

        return map
    }

    #createFishMap(fish: Object | undefined): Map<number, boolean> {
        if (!fish) {
            return new Map<number, boolean>()
        }

        const map = new Map<number, boolean>()

        Object.entries(fish).forEach(([index, isCaught]) => {
            map.set(parseInt(index), Boolean(isCaught))
        })

        return map
    }

    #createFlowersMap(flowers: Object | undefined): Map<number, boolean> {
        if (!flowers) {
            return new Map<number, boolean>()
        }

        const map = new Map<number, boolean>()

        Object.entries(flowers).forEach(([index, isCollected]) => {
            map.set(parseInt(index), Boolean(isCollected))
        })

        return map
    }

    #createRacingTrophiesMap(racing: Object | undefined): Map<number, boolean> {
        if (!racing) {
            return new Map<number, boolean>()
        }

        const map = new Map<number, boolean>()

        Object.entries(racing).forEach(([index, isCollected]) => {
            map.set(parseInt(index), Boolean(isCollected))
        })

        return map
    }

    #createGolfTrophiesMap(golf: Object | undefined): Map<number, boolean> {
        if (!golf) {
            return new Map<number, boolean>()
        }

        const map = new Map<number, boolean>()

        Object.entries(golf).forEach(([index, isCollected]) => {
            map.set(parseInt(index), Boolean(isCollected))
        })

        return map
    }
}