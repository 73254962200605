import ToontaskGroup from "data-toontasks/ToontaskGroup"
import CogType from "../entities/invasions/cogs/CogType";

declare module "styled-components" {
    export interface DefaultTheme extends ToonTrackerTheme {
    }
}

export interface ToonTrackerTheme {
    colors: {
        primary: string,
        primaryDark: string,
        tracking: string,
        trackingDark: string,
        invasions: string,
        invasionsDark: string,
        matchmaking: string,
        matchmakingDark: string,
        sillyMeter: string,
        sillyMeterDark: string,
        toonGuide: string,
        toonGuideDark: string,
        toontasks: string,
        toontasksDark: string,
        cogSuits: string,
        cogSuitsDark: string,
        sellbot: string,
        sellbotDark: string,
        cashbot: string,
        cashbotDark: string,
        lawbot: string,
        lawbotDark: string,
        bossbot: string,
        bossbotDark: string,
        fishing: string,
        flowers: string,
        racing: string,
        golf: string,
        sos: string,
        toontownCentral: string
        toontownCentralDark: string,
        donaldsDock: string,
        donaldsDockDark: string,
        daisyGardens: string,
        daisyGardensDark: string,
        minniesMelodyland: string,
        minniesMelodylandDark: string,
        thebrrgh: string,
        thebrrghDark: string,
        donaldsDreamland: string,
        donaldsDreamlandDark: string,
        twigRod: string,
        bambooRod: string,
        hardwoodRod: string,
        steelRod: string,
        goldRod: string,
        getToontaskGroupColor(group: ToontaskGroup): string
        getToontaskGroupDarkColor(group: ToontaskGroup): string,
        getCogTypeColor(cogType: CogType): string,
        getCogTypeDarkColor(cogType: CogType): string
        getFishSpeciesColor(id: number): string
        getFishSpeciesDarkColor(id: number): string
    },
    dimens: {
        cardCornerRadius: string
    }
}

const theme: ToonTrackerTheme = {
    colors: {
        primary: "#8337f1",
        primaryDark: "#47039d",
        tracking: "#6200ee",
        trackingDark: "#3700b3",
        invasions: "#d12424",
        invasionsDark: "#b71414",
        matchmaking: "#44e0ff",
        matchmakingDark: "#21b5d2",
        sillyMeter: "#fd9d17",
        sillyMeterDark: "#c97f16",
        toonGuide: "#00dec8",
        toonGuideDark: "#00a393",
        toontasks: "#7300a2",
        toontasksDark: "#4f158f",
        cogSuits: "#687fa2",
        cogSuitsDark: "#495971",
        sellbot: "#950029",
        sellbotDark: "#63001b",
        cashbot: "#428a53",
        cashbotDark: "#2c5c37",
        lawbot: "#6a6a7d",
        lawbotDark: "#474753",
        bossbot: "#816149",
        bossbotDark: "#564131",
        fishing: "#4943ff",
        flowers: "#d32579",
        racing: "#616161",
        golf: "#53aa3e",
        sos: "#810000",
        toontownCentral: "#b97400",
        toontownCentralDark: "#885601",
        donaldsDock: "#b74f00",
        donaldsDockDark: "#8d3d00",
        daisyGardens: "#f35fb3",
        daisyGardensDark: "#c23781",
        minniesMelodyland: "#ff8098",
        minniesMelodylandDark: "#c85369",
        thebrrgh: "#23a3d5",
        thebrrghDark: "#0074a2",
        donaldsDreamland: "#7300a2",
        donaldsDreamlandDark: "#4a0068",
        twigRod: "#613625",
        bambooRod: "#909e12",
        hardwoodRod: "#8b742c",
        steelRod: "#2b3439",
        goldRod: "#c78c1e",
        getToontaskGroupColor: getToontaskGroupColor,
        getToontaskGroupDarkColor: getToontaskGroupDarkColor,
        getCogTypeColor: getCogTypeColor,
        getCogTypeDarkColor: getCogTypeDarkColor,
        getFishSpeciesColor: getFishSpeciesColor,
        getFishSpeciesDarkColor: getFishSpeciesDarkColor
    },
    dimens: {
        cardCornerRadius: "20px"
    }
}

function getToontaskGroupColor(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return theme.colors.toontownCentral
        case ToontaskGroup.DonaldsDock:
            return theme.colors.donaldsDock
        case ToontaskGroup.DaisyGardens:
            return theme.colors.daisyGardens
        case ToontaskGroup.MinniesMelodyland:
            return theme.colors.minniesMelodyland
        case ToontaskGroup.TheBrrrgh:
            return theme.colors.thebrrgh
        case ToontaskGroup.DonaldsDreamland:
            return theme.colors.donaldsDreamland
        case ToontaskGroup.Lawbot:
            return theme.colors.lawbot
        case ToontaskGroup.Bossbot:
            return theme.colors.bossbot
    }

    throw Error("Could not find color for " + group)
}

function getToontaskGroupDarkColor(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return theme.colors.toontownCentralDark
        case ToontaskGroup.DonaldsDock:
            return theme.colors.donaldsDockDark
        case ToontaskGroup.DaisyGardens:
            return theme.colors.daisyGardensDark
        case ToontaskGroup.MinniesMelodyland:
            return theme.colors.minniesMelodylandDark
        case ToontaskGroup.TheBrrrgh:
            return theme.colors.thebrrghDark
        case ToontaskGroup.DonaldsDreamland:
            return theme.colors.donaldsDreamlandDark
        case ToontaskGroup.Lawbot:
            return theme.colors.lawbotDark
        case ToontaskGroup.Bossbot:
            return theme.colors.bossbotDark
    }
}

// @ts-ignore
function getCogTypeColor(cogType: CogType): string {
    switch(cogType) {
        case CogType.BOSSBOT:
            return theme.colors.bossbot
        case CogType.LAWBOT:
            return theme.colors.lawbot
        case CogType.CASHBOT:
            return theme.colors.cashbot
        case CogType.SELLBOT:
            return theme.colors.sellbot
    }
}

// @ts-ignore
function getCogTypeDarkColor(cogType: CogType): string {
    switch(cogType) {
        case CogType.BOSSBOT:
            return theme.colors.bossbotDark
        case CogType.LAWBOT:
            return theme.colors.lawbotDark
        case CogType.CASHBOT:
            return theme.colors.cashbotDark
        case CogType.SELLBOT:
            return theme.colors.sellbotDark
    }
}

function getFishSpeciesColor(id: number): string {
    switch(id) {
        case 0: return "#D1412A"
        case 1: return "#F3DDBA"
        case 2: return "#8E6A35"
        case 3: return "#51D845"
        case 4: return "#6CC6AA"
        case 5: return "#D1CD25"
        case 6: return "#384465"
        case 7: return "#5A1925"
        case 8: return "#AB1B10"
        case 9: return "#E6739F"
        case 10: return "#636C80"
        case 11: return "#5C0495"
        case 12: return "#A3A3B0"
        case 13: return "#147C10"
        case 14: return "#3A6C9A"
        case 15: return "#B86520"
        case 16: return "#122B6A"
        case 17: return "#2C2C2C"
        default: return "#FFFFFF"
    }
}

function getFishSpeciesDarkColor(id: number): string {
    switch(id) {
        case 0: return "#963423"
        case 1: return "#b4a279"
        case 2: return "#6c532a"
        case 3: return "#39912f"
        case 4: return "#4c937e"
        case 5: return "#a4a01c"
        case 6: return "#2a354d"
        case 7: return "#3a1017"
        case 8: return "#70160f"
        case 9: return "#b9557b"
        case 10: return "#454b5d"
        case 11: return "#3f0564"
        case 12: return "#767683"
        case 13: return "#0f520d"
        case 14: return "#2b5273"
        case 15: return "#8a4c18"
        case 16: return "#0a1c46"
        case 17: return "#1c1c1c"
        default: return "#FFFFFF"
    }
}

export default theme;