import boss_icon from "common/resources/images/ic_cog_bossbot_icon.png"
import law_icon from "common/resources/images/ic_cog_lawbot_icon.png"
import brg_icon from "common/resources/images/ic_neighbourhood_brg.png"
import dd_icon from "common/resources/images/ic_neighbourhood_dd.png"
import ddl_icon from "common/resources/images/ic_neighbourhood_ddl.png"
import dg_icon from "common/resources/images/ic_neighbourhood_dg.png"
import mml_icon from "common/resources/images/ic_neighbourhood_mml.png"
import ttc_icon from "common/resources/images/ic_neighbourhood_ttc.png"
import bossbot_playground_image from "common/resources/images/playground_boss.png"
import brg_playground_image from "common/resources/images/playground_brg.png"
import dd_playground_image from "common/resources/images/playground_dd.png"
import ddl_playground_image from "common/resources/images/playground_ddl.png"
import dg_playground_image from "common/resources/images/playground_dg.png"
import lawbot_playground_image from "common/resources/images/playground_law.png"
import mml_playground_image from "common/resources/images/playground_mml.png"
import ttc_playground_image from "common/resources/images/playground_ttc.png"


export const NEIGHBOURHOOD_COLOURS = {
    ttc: {
        primary: "#b97400",
        secondary: "#885601",
        tint: "#955e00bb"
    },
    dd: {
        primary: "#b74f00",
        secondary: "#8d3d00",
        tint: "#984200aa"
    },
    dg: {
        primary: "#f35fb3",
        secondary: "#c23781",
        tint: "#b74073bb"
    },
    mml: {
        primary: "#ff8098",
        secondary: "#c85369",
        tint: "#c95269bb"
    },
    brg: {
        primary: "#23a3d5",
        secondary: "#0074a2",
        tint: "#096d94bb"
    },
    ddl: {
        primary: "#7300a2",
        secondary: "#4a0068",
        tint: "#4c006bbb"
    },
    lawbot: {
        primary: "#6a6a7d",
        secondary: "#474754",
        tint: "#5d5d6ebb"
    },
    bossbot: {
        primary: "#816149",
        secondary: "#724929",
        tint: "#996035aa"
    }
}

class NeighbourhoodResources {

    getPrimaryColour(neighbourhood: string): string {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return NEIGHBOURHOOD_COLOURS.ttc.primary

            case "dd":
            case "donalds-dock":
                return NEIGHBOURHOOD_COLOURS.dd.primary

            case "dg":
            case "daisy-gardens":
                return NEIGHBOURHOOD_COLOURS.dg.primary

            case "mml":
            case "minnies-melodyland":
                return NEIGHBOURHOOD_COLOURS.mml.primary

            case "brg":
            case "the-brrrgh":
                return NEIGHBOURHOOD_COLOURS.brg.primary

            case "ddl":
            case "donalds-dreamland":
                return NEIGHBOURHOOD_COLOURS.ddl.primary

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return NEIGHBOURHOOD_COLOURS.lawbot.primary

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return NEIGHBOURHOOD_COLOURS.bossbot.primary
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getSecondaryColour(neighbourhood: string): string {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return NEIGHBOURHOOD_COLOURS.ttc.secondary

            case "dd":
            case "donalds-dock":
                return NEIGHBOURHOOD_COLOURS.dd.secondary

            case "dg":
            case "daisy-gardens":
                return NEIGHBOURHOOD_COLOURS.dg.secondary

            case "mml":
            case "minnies-melodyland":
                return NEIGHBOURHOOD_COLOURS.mml.secondary

            case "brg":
            case "the-brrrgh":
                return NEIGHBOURHOOD_COLOURS.brg.secondary

            case "ddl":
            case "donalds-dreamland":
                return NEIGHBOURHOOD_COLOURS.ddl.secondary

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return NEIGHBOURHOOD_COLOURS.lawbot.secondary

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return NEIGHBOURHOOD_COLOURS.bossbot.secondary
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getTintColour(neighbourhood: string): string {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return NEIGHBOURHOOD_COLOURS.ttc.tint

            case "dd":
            case "donalds-dock":
                return NEIGHBOURHOOD_COLOURS.dd.tint

            case "dg":
            case "daisy-gardens":
                return NEIGHBOURHOOD_COLOURS.dg.tint

            case "mml":
            case "minnies-melodyland":
                return NEIGHBOURHOOD_COLOURS.mml.tint

            case "brg":
            case "the-brrrgh":
                return NEIGHBOURHOOD_COLOURS.brg.tint

            case "ddl":
            case "donalds-dreamland":
                return NEIGHBOURHOOD_COLOURS.ddl.tint

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return NEIGHBOURHOOD_COLOURS.lawbot.tint

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return NEIGHBOURHOOD_COLOURS.bossbot.tint
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getUrlName(neighbourhood: string): string {
        switch (neighbourhood) {
            case "ttc":
                return "toontown-central"

            case "dd":
                return "donalds-dock"

            case "dg":
                return "daisy-gardens"

            case "mml":
                return "minnies-melodyland"

            case "brg":
                return "the-brrrgh"

            case "ddl":
                return "donalds-dreamland"

            case "law":
            case "lawbot":
                return "lawbot-parts"

            case "boss":
            case "bossbot":
                return "bossbot-parts"
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getStylisedName(neighbourhood: string): string {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return "Toontown Central"

            case "dd":
            case "donalds-dock":
                return "Donald's Dock"

            case "dg":
            case "daisy-gardens":
                return "Daisy Gardens"

            case "mml":
            case "minnies-melodyland":
                return "Minnie's Melodyland"

            case "brg":
            case "the-brrrgh":
                return "The Brrrgh"

            case "ddl":
            case "donalds-dreamland":
                return "Donald's Dreamland"

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return "Lawbot Parts"

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return "Bossbot Parts"
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getAbbreviation(neighbourhood: string): string {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return "ttc"

            case "dd":
            case "donalds-dock":
                return "dd"

            case "dg":
            case "daisy-gardens":
                return "dg"

            case "mml":
            case "minnies-melodyland":
                return "mml"

            case "brg":
            case "the-brrrgh":
                return "brg"

            case "ddl":
            case "donalds-dreamland":
                return "ddl"

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return "law"

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return "boss"
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getBackgroundImage(neighbourhood: string) {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return ttc_playground_image

            case "dd":
            case "donalds-dock":
                return dd_playground_image

            case "dg":
            case "daisy-gardens":
                return dg_playground_image

            case "mml":
            case "minnies-melodyland":
                return mml_playground_image

            case "brg":
            case "the-brrrgh":
                return brg_playground_image

            case "ddl":
            case "donalds-dreamland":
                return ddl_playground_image

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return lawbot_playground_image

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return bossbot_playground_image
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }

    getIcon(neighbourhood: string) {
        switch (neighbourhood) {
            case "ttc":
            case "toontown-central":
                return ttc_icon

            case "dd":
            case "donalds-dock":
                return dd_icon

            case "dg":
            case "daisy-gardens":
                return dg_icon

            case "mml":
            case "minnies-melodyland":
                return mml_icon

            case "brg":
            case "the-brrrgh":
                return brg_icon

            case "ddl":
            case "donalds-dreamland":
                return ddl_icon

            case "law":
            case "lawbot":
            case "lawbot-parts":
                return law_icon

            case "boss":
            case "bossbot":
            case "bossbot-parts":
                return boss_icon
        }

        throw Error("Invalid neighbourhood " + neighbourhood)
    }
}

const singleton = new NeighbourhoodResources()
export default singleton