import CogType from "app/entities/invasions/cogs/CogType";
import Card from "common/theme/components/Card";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";
import MediumCogSuitSlot from "./MediumCogSuitSlot";

type Props = {
    progress: number,
    progressText: string,
    suitsUnlocked: number,
    bossbotSlot: CogSuitSlotState,
    lawbotSlot: CogSuitSlotState,
    cashbotSlot: CogSuitSlotState,
    sellbotSlot: CogSuitSlotState,
    onPromoteClick: (cogType: CogType) => void,
    onDemoteClick: (cogType: CogType) => void
}

export type CogSuitSlotState = {
    progress: number,
    cogText: string,
    pointsText: string
}

export default function MediumToontasksCard(props: Props) {
    const theme = useTheme()

    return (
        <StyledMediumCogSuitsCard>
            
            <Link to="/tracking/cog-suits" style={{ textDecoration: "none" }}>
            <ActivityHeader
                width={300}
                progressDecimal={props.progress}
                progressText={props.progressText}
                title={"Cog Suits"}
                subtitle={`You have ${props.suitsUnlocked} suits`}
                backgroundColor={theme.colors.cogSuitsDark}
            />
            </Link>
            <StyledCogSuitSlotGrid>
                <MediumCogSuitSlot cogType={CogType.BOSSBOT} progress={props.bossbotSlot.progress} cogText={props.bossbotSlot.cogText} onPromoteClick={props.onPromoteClick} onDemoteClick={props.onDemoteClick} />
                <MediumCogSuitSlot cogType={CogType.LAWBOT} progress={props.lawbotSlot.progress} cogText={props.lawbotSlot.cogText} onPromoteClick={props.onPromoteClick} onDemoteClick={props.onDemoteClick} />
                <MediumCogSuitSlot cogType={CogType.CASHBOT} progress={props.cashbotSlot.progress} cogText={props.cashbotSlot.cogText} onPromoteClick={props.onPromoteClick} onDemoteClick={props.onDemoteClick} />
                <MediumCogSuitSlot cogType={CogType.SELLBOT} progress={props.sellbotSlot.progress} cogText={props.sellbotSlot.cogText} onPromoteClick={props.onPromoteClick} onDemoteClick={props.onDemoteClick} />
            </StyledCogSuitSlotGrid>
        </StyledMediumCogSuitsCard>
    )
}

const StyledMediumCogSuitsCard = styled(Card)`
    width: 340px;
    height: 350px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.colors.cogSuits};
    border-radius: ${props => props.theme.dimens.cardCornerRadius}
`

const StyledCogSuitSlotGrid = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
`