import {Box, Column} from "../core-anvil/Layouts";
import NavigationBanner from "../core-theme/components/navigation/NavigationBanner";
import ScreenSize from "../common/ScreenSize";
import {useTheme} from "styled-components";
import {useHistory} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import CogType from "../app/entities/invasions/cogs/CogType";
import {DependencyContainer} from "tsyringe";
import CogSuitsViewModel from "./CogSuitsViewModel";
import {CogSuitDetailsState, CogSuitsScreenState, CogTypeCardState} from "./CogSuitsScreenState";
import CogSuitTypesGrid from "./overview/CogSuitTypesGrid";
import CogSuitDetails from "./details/CogSuitDetails";
import CogSuitsPointsTable from "./points/CogSuitsPointsTable";
import useContainer from "../core-theme/hooks/useContainer";

export const CogSuitsDestination = {
    route: "/tracking/cog-suits",
    createRoute: () => "/tracking/cog-suits"
}

type Props = {
    screenSize: ScreenSize
}

export default function CogSuitsScreen(props: Props) {
    const screenContainer = useContainer()
    const viewModel = useRef(screenContainer.resolve(CogSuitsViewModel))

    const [uiState, setUIState] = useState<CogSuitsScreenState>(viewModel.current.uiState.value)

    useEffect(() => {
        const sub = viewModel.current.uiState.subscribe(setUIState)
        return () => sub.unsubscribe()
    }, [])

    return (
        <CogSuitsScreenContent
            container={screenContainer}
            screenSize={props.screenSize}
            bossbot={uiState.bossbot}
            lawbot={uiState.lawbot}
            cashbot={uiState.cashbot}
            sellbot={uiState.sellbot}
            cogSuitDetails={uiState.cogSuitDetails}
            onCogTypeClick={cogType => viewModel.current.onCogTypeClick(cogType)}
            onPromoteClick={cogType => viewModel.current.onPromoteClick(cogType)}
            onDemoteClick={cogType => viewModel.current.onDemoteClick(cogType)}
            onPointsChange={points => viewModel.current.onPointsChange(points)}
        />
    )
}

type ContentProps = {
    container: DependencyContainer,
    screenSize: ScreenSize,
    bossbot: CogTypeCardState,
    lawbot: CogTypeCardState,
    cashbot: CogTypeCardState,
    sellbot: CogTypeCardState,
    cogSuitDetails: CogSuitDetailsState | null,
    onCogTypeClick: (cogType: CogType) => void,
    onPromoteClick: (cogType: CogType) => void,
    onDemoteClick: (cogType: CogType) => void,
    onPointsChange: (points: string) => void,
}

function CogSuitsScreenContent(props: ContentProps) {
    const theme = useTheme()
    const history = useHistory()

    const cogTypeColor = theme.colors.getCogTypeColor(props.cogSuitDetails?.cogType!!)
    const cogTypeDarkColor = theme.colors.getCogTypeDarkColor(props.cogSuitDetails?.cogType!!)

    const cogSuitDetails = props.cogSuitDetails !== null
        ? <CogSuitDetails
            container={props.container}
            cogName={props.cogSuitDetails.cogName}
            cogType={props.cogSuitDetails.cogType}
            progress={props.cogSuitDetails.progress}
            levelText={props.cogSuitDetails.levelText}
            facilities={props.cogSuitDetails.facilities}
            points={props.cogSuitDetails.points}
            pointsLabel={props.cogSuitDetails.pointsLabel}
            requiredPoints={props.cogSuitDetails.requiredPoints}
            screenSize={props.screenSize}
            onPointsChange={props.onPointsChange}
            onPromoteClick={() => props.onPromoteClick(props.cogSuitDetails!!.cogType)}
            onDemoteClick={() => props.onDemoteClick(props.cogSuitDetails!!.cogType)}
            style={{marginTop: 32}}
        />
        : null

    return (
        <Box
            alignment="topCenter"
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: theme.colors.cogSuitsDark,
                overflowY: "scroll"
            }}>
            <CogSuitsPointsTable
                container={props.container}
                cogType={props.cogSuitDetails?.cogType ?? null}
                cogTypeColor={cogTypeColor}
                cogTypeDarkColor={cogTypeDarkColor}
            />
            <Column
                horizontalAlignment="center"
                style={{
                    width: "100%",
                    paddingBottom: 32
                }}>
                <NavigationBanner
                    screenSize={props.screenSize}
                    backgroundColor={theme.colors.cogSuits}
                    icon="check_box"
                    featureName="Tracking"
                    screenName="Cog Suits"
                    previousScreenName="Tracking"
                    onBackClick={() => {
                        history.push("/feat-tracking")
                    }}
                />
                <CogSuitTypesGrid
                    screenSize={props.screenSize}
                    bossbot={props.bossbot}
                    lawbot={props.lawbot}
                    cashbot={props.cashbot}
                    sellbot={props.sellbot}
                    onCogTypeClick={props.onCogTypeClick}
                    onPromoteClick={props.onPromoteClick}
                    onDemoteClick={props.onDemoteClick}
                />
                {cogSuitDetails}
            </Column>
        </Box>
    )
}