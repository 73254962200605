import ScreenSize from "../../common/ScreenSize";
import {CSSProperties} from "react";
import SmallCogSuitDetails from "./small/SmallCogSuitDetails";
import CogType from "../../app/entities/invasions/cogs/CogType";
import MediumCogSuitDetails from "./medium/MediumCogSuitDetails";
import LargeCogSuitDetails from "./large/LargeCogSuitDetails";
import {FacilitiesTableState} from "../CogSuitsScreenState";
import {DependencyContainer} from "tsyringe";

type Props = {
    container: DependencyContainer,
    screenSize: ScreenSize,
    pointsLabel: string,
    cogType: CogType,
    cogName: string,
    progress: number,
    levelText: string,
    points: string,
    requiredPoints: string,
    facilities: FacilitiesTableState,
    onPointsChange: (points: string) => void,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function CogSuitDetails(props: Props) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallCogSuitDetails
                {...props}
                style={{...props.style, width: 340}}
            />
        case ScreenSize.Medium:
            return <MediumCogSuitDetails
                {...props}
                style={{...props.style, width: 700}}
            />
        case ScreenSize.Large:
            return <LargeCogSuitDetails
                {...props}
                style={{...props.style, width: 1116}}
            />
    }
}