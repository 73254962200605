export enum Neighborhood {
    TOONTOWN_CENTRAL,
    DONALDS_DOCK,
    DAISY_GARDENS,
    MINNIES_MELODYLAND,
    THE_BRRRGH,
    DONALDS_DREAMLAND,
    ESTATE,
    ACORN_ACRES
}

export default Neighborhood