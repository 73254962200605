import ToontaskGroup from "data-toontasks/ToontaskGroup"

import styled from "styled-components"
import {Headline5} from "app/theme/Typography"
import SmallStartedToontasksGrid from "./small/SmallStartedToontasksGrid"
import {ScreenSize} from "common/ScreenSize"
import MediumStartedToontasksGrid from "./medium/MediumStartedToontasksGrid"
import LargeStartedToontasksGrid from "./large/LargeStartedToontasksGrid"

export type AssignedToontasksState = {
    topLeft: AssignedToontaskCardState | null,
    topRight: AssignedToontaskCardState | null,
    bottomLeft: AssignedToontaskCardState | null,
    bottomRight: AssignedToontaskCardState | null
}

export type AssignedToontaskCardState = {
    id: number,
    title: string,
    reward: string,
    progress: number,
    progressText: string,
    step: string
}

export enum ToontaskSlotPosition {
    TOP_LEFT = 0,
    TOP_RIGHT = 1,
    BOTTOM_LEFT = 2,
    BOTTOM_RIGHT = 3
}

export type Props = {
    screenSize: ScreenSize
    group: ToontaskGroup,
    assigned: AssignedToontasksState
}

type SizedProps = {
    alignSelf?: string
}

export default function StartedToontasks(props: Props) {
    const sizedProps = getSizedProps(props.screenSize)

    return (
        <StyledStartedToontasks style={{alignSelf: sizedProps.alignSelf}}>
            <Heading>Started</Heading>
            <StartedToontasksGrid screenSize={props.screenSize} group={props.group} assigned={props.assigned}/>
        </StyledStartedToontasks>
    )
}

type StartedToontaksGridProps = {
    screenSize: ScreenSize
    group: ToontaskGroup,
    assigned: AssignedToontasksState
}

export function StartedToontasksGrid(props: StartedToontaksGridProps) {
    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallStartedToontasksGrid group={props.group} assigned={props.assigned} />
        case ScreenSize.Medium:
            return <MediumStartedToontasksGrid group={props.group} assigned={props.assigned}/>
        case ScreenSize.Large:
            return <LargeStartedToontasksGrid group={props.group} assigned={props.assigned} />
    }
}

function getSizedProps(screenSize:ScreenSize): SizedProps {
    if(screenSize === ScreenSize.Large) {
        return {
            alignSelf: undefined
        }
    } else {
        return {
            alignSelf: "center"
        }
    }
}

const StyledStartedToontasks = styled.div`
    display: flex;
    flex-direction: column;
`

const Heading = styled(Headline5)`
    color: white;
`