import LegacyToon from "./LegacyToon";
import { v4 as uuidv4 } from "uuid";

/**
 * @typedef {Object} User
 * @property {String} id UUID of the User
 * @property {String} The User's email address linked
 * @property {String} signInMethod "password" if the user signed up via email, or "google.com" if using Google account
 * @property {Boolean} isVerified true if the user's email address is verified. This is always true for Google accounts

 */
export default class User {
	#id: string | null
	#email: string | null
	#isVerified: boolean
	#toons: LegacyToon[]
	#currentToonId: string

	constructor(id: string | null, email: string | null, isVerified: boolean, toons: LegacyToon[]) {
		this.#id = id
		this.#email = email
		this.#isVerified = isVerified
		this.#toons = toons

		if (this.#toons.length === 0) {
			this.addToon()
		}

		this.#currentToonId = this.#toons[0].getId()
	}

	getId() {
		return this.#id
	}

	getEmail() {
		return this.#email
	}

	isVerified() {
		return this.#isVerified
	}

	/**
	 * @return {LegacyToon} 
	 */
	getCurrentToon() {
		if (this.#currentToonId === null) {
			let highestLaff = 0

			for (const toon of this.#toons) {
				if (toon.getLaff() > highestLaff) {
					highestLaff = toon.getLaff()
					this.#currentToonId = toon.getId()
				}
			}
		}

		return this.getToonWithId(this.#currentToonId)
	}

	setCurrentToon(toonName: string) {
		this.#toons.forEach(toon => {
			if(toon.getName() === toonName) {
				this.#currentToonId = toon.getId()
				return
			}
		})
	}

	getToonWithId(id: String): LegacyToon {
		for (const toon of this.#toons) {
			if (toon.getId() === id) {
				return toon
			}
		}

		throw Error(`No toon with id exists: ${id}`)
	}

	getToons(): LegacyToon[] {
		return this.#toons
	}

	addToon() {
		let toonId = uuidv4()
		const toon = new LegacyToon(
			toonId,
			"Toon #" + Math.round(Math.random() * 100),
			16
		)

		this.#currentToonId = toonId
		this.#toons.push(toon)
	}

	containsToonWithId(id: String) {
		for (const toon of this.#toons) {
			if (toon.getId() === id) return true
		}

		return false
	}


	isEqualTo(other: User) {
		if (this.#id !== other.#id) return false

		if (this.#email !== other.#email) return false

		if (this.#isVerified !== other.#isVerified) return false

		if (this.#toons.length !== other.#toons.length) return false

		for (const toon of this.#toons) {
			if (!other.containsToonWithId(toon.getId())) return false

			const otherToon = other.getToonWithId(toon.getId())
			if (!toon.isEqualTo(otherToon)) return false
		}

		return true
	}
}