import ScreenSize from "common/ScreenSize";
import styled from "styled-components";

type Props = {
    email: string,
    screenSize: ScreenSize
}

export default function AccountInfo(props: Props) {
    const initial = props.email[0].toUpperCase()
    
    return (
    <StyledAccountInfo screenSize={props.screenSize}>
        <StyledAccountInitial>{initial}</StyledAccountInitial>
    </StyledAccountInfo>
    )
}

type StyledAccountInfoProps = {
    screenSize: ScreenSize
}

const StyledAccountInfo = styled.div<StyledAccountInfoProps>`
    width: 40px;
    height: 40px;
    margin-right: ${props => getStyledAccountInfoMarginRight(props.screenSize)}px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`

function getStyledAccountInfoMarginRight(screenSize: ScreenSize) {
    if(screenSize === ScreenSize.Large) {
        return 40
    } else {
        return 20
    }
}

const StyledAccountInitial = styled.p`
    margin-top: 2px;
    font-family: 'Catamaran';
    font-size: 20px;
    font-weight: 700;
    color: white;
`