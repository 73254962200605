import GetFishResult from "./GetFishResult";
import {injectable} from "tsyringe";
import FishRepository from "../../../../data-fishing/FishRepository";

@injectable()
export default class GetFish {

    constructor(private fishRepository: FishRepository) {
    }

    invoke(fishId: number): GetFishResult | null {
        const fish = this.fishRepository.getFish(fishId)

        if(fish === null) return null

        return {
            name: fish.name,
            rarity: fish.rarity
        }
    }

}