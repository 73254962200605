import Card from "../../../common/theme/components/Card";
import {CSSProperties} from "react";
import {TitleLarge, TitleMedium} from "../../../app/theme/Typography";
import CogType from "../../../app/entities/invasions/cogs/CogType";
import {useTheme} from "styled-components";
import {Column, Row, Spacer} from "../../../core-anvil/Layouts";
import ProgressBar from "../../../common/theme/components/ProgressBar";
import CogSuitPoints from "../CogSuitPoints";
import FacilitiesTable from "../FacilitiesTable";
import {FacilitiesTableState} from "../../CogSuitsScreenState";
import PromotionButtons from "../PromotionButtons";
import PointsTableButton from "../PointsTableButton";
import {DependencyContainer} from "tsyringe";
import useScreenSize from "../../../core-theme/hooks/useScreenSize";

type Props = {
    container: DependencyContainer,
    pointsLabel: string,
    cogType: CogType,
    cogName: string,
    progress: number,
    levelText: string,
    points: string,
    requiredPoints: string,
    facilities: FacilitiesTableState,
    onPointsChange: (points: string) => void,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function MediumCogSuitDetails(props: Props) {
    const theme = useTheme()
    const screenSize = useScreenSize()

    const backgroundColor = theme.colors.getCogTypeColor(props.cogType)

    return (
        <Card style={{...props.style, backgroundColor: backgroundColor}}>
            <Column style={{marginLeft: 32, marginRight: 32, marginTop: 24, marginBottom: 48}}>
                <TitleLarge>{props.cogName}</TitleLarge>
                <ProgressBar progress={props.progress} max={1} style={{height: 8, marginTop: 10}}/>
                <TitleMedium style={{marginTop: 12}}>{props.levelText}</TitleMedium>
                <Row verticalAlignment="center" horizontalArrangement="center" style={{width: "100%", marginTop: 40}}>
                    <PromotionButtons
                        cogType={props.cogType}
                        onPromoteClick={props.onPromoteClick}
                        onDemoteClick={props.onDemoteClick}
                    />
                    <PointsTableButton container={props.container} style={{justifySelf: "center", marginLeft: 16}}/>
                </Row>
                <Row>
                    <CogSuitPoints screenSize={screenSize} pointsLabel={props.pointsLabel} points={props.points}
                                   requiredPoints={props.requiredPoints} onPointsChange={props.onPointsChange}
                                   style={{marginTop: 48, marginLeft: 12, marginBottom: 16}}/>
                    <Spacer/>
                    <FacilitiesTable
                        screenSize={screenSize}
                        cogType={props.cogType}
                        pointsName={props.facilities.pointsName}
                        rows={props.facilities.rows}
                        style={{marginTop: 32, marginLeft: 36}}/>
                </Row>
            </Column>
        </Card>
    )
}