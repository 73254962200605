import {Body1, Body2, Headline6, Subtitle2} from "app/theme/Typography"
import Card from "common/theme/components/Card"
import ProgressBar from "common/theme/components/ProgressBar"
import ToontaskGroup from "data-toontasks/ToontaskGroup"
import styled, {useTheme} from "styled-components"
import {ToontaskSlotPosition} from "../StartedToontasks"
import {Box} from "../../../../../core-anvil/Layouts";

type Props = {
    position: ToontaskSlotPosition,
    isFilled: Boolean,
    progress: number,
    progressText: string,
    toontaskTitleText: string,
    rewardText: string,
    stepText: string,
    toontaskGroup: ToontaskGroup
}

export default function MediumToontaskSlot(props: Props) {
    const theme = useTheme()

    if (props.isFilled) {
        return (
            <StyledMediumToontaskSlot
                style={{backgroundColor: theme.colors.getToontaskGroupColor(props.toontaskGroup)}}>
                <PositionDots highlightedPosition={props.position}/>
                <StyledDetails>
                    <ToontaskTitle>{props.toontaskTitleText}</ToontaskTitle>
                    <ProgressBar progress={props.progress} max={1} style={{marginTop: 2}}/>
                    <RewardText>{props.rewardText}</RewardText>
                    <StyledStepText>{props.stepText}</StyledStepText>
                </StyledDetails>
            </StyledMediumToontaskSlot>
        )
    } else {
        return (
            <Card style={{
                width: 262,
                height: 92,
                padding: 24,
                backgroundColor: theme.colors.getToontaskGroupDarkColor(props.toontaskGroup),
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box alignment="center" style={{width: "100%", height: "100%"}}>
                    <EmptySlotText>Empty slot</EmptySlotText>
                </Box>
            </Card>
        )
    }
}

const StyledMediumToontaskSlot = styled(Card)`
  width: 262px;
  height: 92px;
  background-color: #7a20de;
  display: flex;
  flex-direction: row;
  padding: 24px;
`

const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  flex: 1;
`

const ToontaskTitle = styled(Subtitle2)`
  margin-top: -4px;
`

type PositionDotsProps = {
    highlightedPosition: ToontaskSlotPosition
}

function PositionDots(props: PositionDotsProps) {

    let positionDots = []
    for (let i = 0; i < 4; i++) {
        const isHighlighted = i === props.highlightedPosition.valueOf()
        positionDots.push(
            <StyledPositionDot
                color={isHighlighted ? "white" : "black"}
                opacity={isHighlighted ? "1" : "0.2"}
            />
        )
    }


    return (
        <StyledPositionDots>
            {positionDots}
        </StyledPositionDots>
    )
}

const StyledPositionDots = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
`

type StyledPositionDotProps = {
    color: string,
    opacity: string
}

const StyledPositionDot = styled.div<StyledPositionDotProps>`
  width: 10px;
  height: 10px;
  background-color: ${props => props.color};
  opacity: ${props => props.opacity};
  border-radius: 4px;
`

const RewardText = styled(Body2)`
  margin-top: 4px;
`

const StyledStepText = styled(Body1)`
  margin-top: 12px;
`

const EmptySlotText = styled(Headline6)`
  color: white;
  position: absolute;
  font-weight: 300;
  opacity: 0.6;
`