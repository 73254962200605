import {TitleSmall} from "../../app/theme/Typography";
import Card from "../../common/theme/components/Card";
import {Box, Column} from "../../core-anvil/Layouts";
import fish_amore_eel from "../assets/fish_amore_eel_animated.gif"
import fish_balloon_fish from "../assets/fish_balloon_fish_animated.gif"
import fish_bear_acuda from "../assets/fish_bear_acuda_animated.gif"
import fish_cat_fish from "../assets/fish_cat_fish_animated.gif"
import fish_clown_fish from "../assets/fish_clown_fish_animated.gif"
import fish_cutthroat_trout from "../assets/fish_cutthroat_trout_animated.gif"
import fish_devil_ray from "../assets/fish_devil_ray_animated.gif"
import fish_dog_fish from "../assets/fish_dog_fish_animated.gif"
import fish_frozen_fish from "../assets/fish_frozen_fish_animated.gif"
import fish_holey_mackerel from "../assets/fish_holey_mackerel_animated.gif"
import fish_king_crab from "../assets/fish_king_crab_animated.gif"
import fish_moon_fish from "../assets/fish_moon_fish_animated.gif"
import fish_nurse_shark from "../assets/fish_nurse_shark_animated.gif"
import fish_pbj_fish from "../assets/fish_pbj_fish_animated.gif"
import fish_piano_tuna from "../assets/fish_piano_tuna_animated.gif"
import fish_pool_shark from "../assets/fish_pool_shark_animated.gif"
import fish_sea_horse from "../assets/fish_sea_horse_animated.gif"
import fish_star_fish from "../assets/fish_stat_fish_animated.gif"
import {useTheme} from "styled-components";
import {CSSProperties} from "react";
import ScreenSize from "../../common/ScreenSize";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

export type Props = {
    screenSize: ScreenSize,
    id: number,
    name: string,
    progress: number,
    hasDarkText: boolean,
    onClick: () => void
}

type SizedProps = {
    speciesNameCardWidth: number,
    speciesNameCardHeight: number,
    speciesIconCardSize: number,
    speciesIconSize: number,
    progressCircleWidth: number
}

export default function SpeciesItem(props: Props) {
    const theme = useTheme()
    const fishColor = theme.colors.getFishSpeciesColor(props.id)

    const sizedProps = getSizedProps(props.screenSize)

    return (
        <Column horizontalAlignment="center" onClick={props.onClick} style={{cursor: "pointer"}}>
            <SpeciesName fishColor={fishColor} name={props.name} hasDarkText={props.hasDarkText}
                         style={{width: sizedProps.speciesNameCardWidth, height: sizedProps.speciesNameCardHeight}}/>
            <SpeciesIcon fishColor={fishColor} icon={getFishGif(props.id)} iconSize={sizedProps.speciesIconSize}
                         progressCircleWidth={sizedProps.progressCircleWidth} fishProgress={props.progress}
                         style={{
                             width: sizedProps.speciesIconCardSize,
                             height: sizedProps.speciesIconCardSize,
                             marginTop: -40
                         }}/>
        </Column>
    )
}

type SpeciesNameProps = {
    fishColor: string,
    name: string,
    hasDarkText: boolean,
    style?: CSSProperties
}

function SpeciesName(props: SpeciesNameProps) {
    const textColor = props.hasDarkText ? "black" : "white"

    return (
        <Card style={{backgroundColor: props.fishColor, ...props.style}}>
            <Column>
                <TitleSmall
                    style={{
                        color: textColor,
                        textAlign: "center",
                        marginTop: 6,
                        marginLeft: 6,
                        marginRight: 6
                    }}>{props.name}</TitleSmall>
            </Column>
        </Card>
    )
}

type SpeciesIconProps = {
    fishColor: string,
    fishProgress: number,
    icon: string,
    iconSize: number,
    progressCircleWidth: number,
    style?: CSSProperties
}

function SpeciesIcon(props: SpeciesIconProps) {

    return (
        <Card style={{borderRadius: 100, backgroundColor: props.fishColor, ...props.style}}>
            <Box
                style={{width: "100%", height: "100%"}}>
                <img src={props.icon} alt=""
                     style={{width: props.iconSize, height: props.iconSize, borderRadius: 100}}/>
                <CircularProgressbar
                    value={props.fishProgress}
                    maxValue={1}
                    strokeWidth={props.progressCircleWidth}
                    styles={buildStyles({
                        pathColor: "white",
                        trailColor: "#00000000"
                    })}
                />
            </Box>
        </Card>
    )
}

function getSizedProps(screenSize: ScreenSize): SizedProps {
    switch (screenSize) {
        case ScreenSize.Small:
            return getSmallSizedProps()
        case ScreenSize.Medium:
            return getMediumSizedProps()
        case ScreenSize.Large:
            return getMediumSizedProps()
    }
}

function getSmallSizedProps(): SizedProps {
    return {
        speciesNameCardWidth: 80,
        speciesNameCardHeight: 80,
        speciesIconCardSize: 100,
        speciesIconSize: 88,
        progressCircleWidth: 6
    }
}

function getMediumSizedProps(): SizedProps {
    return {
        speciesNameCardWidth: 100,
        speciesNameCardHeight: 80,
        speciesIconCardSize: 156,
        speciesIconSize: 140,
        progressCircleWidth: 5
    }
}

function getFishGif(id: number): string {
    switch (id) {
        case 0:
            return fish_balloon_fish
        case 1:
            return fish_pbj_fish
        case 2:
            return fish_cat_fish
        case 3:
            return fish_clown_fish
        case 4:
            return fish_frozen_fish
        case 5:
            return fish_star_fish
        case 6:
            return fish_holey_mackerel
        case 7:
            return fish_dog_fish
        case 8:
            return fish_devil_ray
        case 9:
            return fish_amore_eel
        case 10:
            return fish_nurse_shark
        case 11:
            return fish_king_crab
        case 12:
            return fish_moon_fish
        case 13:
            return fish_sea_horse
        case 14:
            return fish_pool_shark
        case 15:
            return fish_bear_acuda
        case 16:
            return fish_cutthroat_trout
        case 17:
            return fish_piano_tuna
    }
    return ""
}
