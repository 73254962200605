import TTDialog from "../../../core-theme/components/dialog/TTDialog";
import useViewModel from "../../../core-theme/hooks/useViewModel";
import {DependencyContainer} from "tsyringe";
import FishInsightsDialogViewModel from "./FishInsightsDialogViewModel";
import useRxState from "../../../core-theme/hooks/useRxState";
import {Box, Column, Row} from "../../../core-anvil/Layouts";
import {BodyMedium, TitleLarge} from "../../../app/theme/Typography";
import {CSSProperties} from "react";
import FishBestSpotsTable from "./table/FishBestSpotsTable";
import useScreenSize from "../../../core-theme/hooks/useScreenSize";
import ScreenSize from "../../../common/ScreenSize";

export type Props = {
    container: DependencyContainer,
    dialogColor: string
}

type SizedProps = {
    dialogWidth: number,
    dialogHeight: number,
    fishNameMaxWidth: number
}

export default function FishInsightsDialog(props: Props) {

    const viewModel = useViewModel(props.container, FishInsightsDialogViewModel)
    const uiState = useRxState(viewModel.uiState)
    const screenSize = useScreenSize()

    const sizedProps = getSizedProps(screenSize)

    return <TTDialog
        isVisible={uiState.isVisible}
        title=""
        isTitleVisible={false}
        onDismiss={() => viewModel.onDismiss()}
        style={{
            width: sizedProps.dialogWidth,
            height: sizedProps.dialogHeight,
            backgroundColor: props.dialogColor
        }}>
        <Column>
            <DialogHeader rarity={uiState.rarity} rarityLabel={uiState.rarityLabel} fishName={uiState.fishName}/>
            <FishBestSpotsTable container={props.container} style={{marginTop: 12, marginLeft: 20, marginRight: 20}}/>
        </Column>
    </TTDialog>
}

type DialogHeaderProps = {
    rarity: number,
    rarityLabel: string,
    fishName: string
}

function DialogHeader(props: DialogHeaderProps) {
    return (
        <Row horizontalArrangement="center" verticalAlignment="center" style={{width: "100%", height: 70}}>
            <RarityRing rarity={props.rarity}/>
            <Column style={{marginLeft: 20}}>
                <FishName name={props.fishName}/>
                <RarityLabel rarity={props.rarityLabel} style={{marginTop: 4}}/>
            </Column>
        </Row>
    )
}

type RarityRingProps = {
    rarity: number
}

function RarityRing(props: RarityRingProps) {
    return (
        <Box style={{
            width: 60,
            height: 60,
            borderRadius: 100,
            borderColor: "white",
            borderWidth: 3,
            borderStyle: "solid"
        }}>
            <TitleLarge>{props.rarity}</TitleLarge>
        </Box>
    )
}

type FishNameProps = {
    name: string
}

function FishName(props: FishNameProps) {
    const screenSize = useScreenSize()
    const sizedProps = getSizedProps(screenSize)

    return (
        <BodyMedium style={{maxWidth: sizedProps.fishNameMaxWidth, fontSize: 20, lineHeight: 1, fontWeight: 500}}>
            {props.name}
        </BodyMedium>
    )
}

type RarityLabelProps = {
    rarity: string,
    style?: CSSProperties
}

function RarityLabel(props: RarityLabelProps) {
    return (
        <BodyMedium style={{
            ...props.style,
            fontWeight: 300,
        }}>
            {props.rarity}
        </BodyMedium>
    )
}

function getSizedProps(screenSize: ScreenSize): SizedProps {

    switch (screenSize) {
        case ScreenSize.Small:
            return {
                dialogWidth: 340,
                dialogHeight: 408,
                fishNameMaxWidth: 120
            }
        case ScreenSize.Medium:
            return {
                dialogWidth: 488,
                dialogHeight: 468,
                fishNameMaxWidth: 180
            }
        case ScreenSize.Large:
            return {
                dialogWidth: 540,
                dialogHeight: 494,
                fishNameMaxWidth: 200
            }
    }
}