import "firebase/analytics"
import "firebase/firestore"

import * as Auth from "app/data/remote/Auth"
import * as firebase from "firebase/app"

import LegacyToon from "app/data/user/LegacyToon"
import User from "app/data/user/LegacyUser"

interface StoredUser {
	id: string
	email: string
	isVerified: boolean
	toons: StoredToon[]
	currentToonId: string
}

interface StoredToon {
	id: string,
	name: string,
	laff: string,
    toontasks: { [key: string]: boolean } | undefined,
    assignedToontasks: Object,
    cogSuits: { "0": number; "1": number; "2": number; "3": number; } | undefined,
    fish: { } | undefined,
	flowers: Object,
	racingTrophies: { } | undefined,
	golfTrophies: { } | undefined
}

class RemoteUserData {

	async fetchUser() {
		const authUser = Auth.getAuthUser()

		if (authUser == null) {
			throw Error("Attempted to retrieve signed-in user when not signed-in")
		}

		const id = authUser.uid
		const email = authUser.email
		const isVerified = authUser.emailVerified

		const toons = await this.fetchToons(id)

		const user = new User(id, email, isVerified, toons)

		return user
	}

	async fetchToons(userId: string) {
		const db = firebase.firestore()

		let snapshots = await db.collection("users")
			.doc(userId)
			.collection("toons")
			.get()

		const toons = []

		for (const snapshot of snapshots.docs) {
			let storedToon = snapshot.data()

			const toon = new LegacyToon(storedToon.id, storedToon.name, storedToon.laff)
			toon.setToontasksMap(storedToon.toontasks)
			toon.setAssignedToontasksMap(storedToon.assignedToontasks)
			toon.setCogSuitsMap(storedToon.cogSuits)
			toon.setFishMap(storedToon.fish)
			toon.setFlowersMap(storedToon.flowers)
			toon.setRacingTrophiesMap(storedToon.racingTrophies)
			toon.setGolfTrophiesMap(storedToon.golfTrophies)

			toons.push(toon)

		}

		return toons
	}

	async updateUser(user: User) {
		if (user.getId() == null) {
			throw Error("Attempted to upload user not signed in")
		}

		let storedToons: StoredToon[] = []

		for (const toon of user.getToons()) {
			const storedToon: StoredToon = {
				id: toon.getId(),
				name: toon.getName(),
				laff: toon.getLaff(),
				toontasks: toon.getToontasksMap(),
				assignedToontasks: toon.getAssignedToontasksMap(),
				cogSuits: toon.getCogSuitsMap(),
				fish: toon.getFishMap(),
				racingTrophies: toon.getRacingTrophiesMap(),
				golfTrophies: toon.getGolfTrophiesMap(),
				flowers: toon.getFlowersMap()
			}

			storedToons.push(storedToon)
		}



		let storedUser: StoredUser = {
			id: user.getId()!!,
			email: user.getEmail()!!,
			isVerified: user.isVerified(),
			toons: storedToons,
			currentToonId: user.getCurrentToon().getId()
		}

		firebase.firestore().collection("users").doc(storedUser.id).set({
			accountId: storedUser.id,
			email: storedUser.email
		})

		for (const storedToon of storedUser.toons) {
			firebase.firestore().collection("users").doc(storedUser.id).collection("toons").doc(storedToon.id).set(
				storedToon
			)
		}

	}
}

const singleton = new RemoteUserData()
export default singleton