import { Body1, Body2 } from "app/theme/Typography";
import { Link } from "react-router-dom";
import NavigationItem from "app/ui/app/navigation/NavigationItem";
import Paper from "@material-ui/core/Paper";
import ic_app_icon from "app/res/images/ic_app_icon.svg"
import styled from "styled-components";
import ScreenSize from "common/ScreenSize";

type Props = {
    isVisible: boolean,
    currentScreen: NavigationScreen,
    toontownTime: string,
    onMenuClick: () => any,
    screenSize: ScreenSize,
    onNavigationItemClick: (screen: NavigationScreen) => any
}

export enum NavigationScreen {
    HOME = "/home",
    TRACKING = "/tracking",
    INVASIONS = "/invasions"
}

export default function NavigationDrawer(props: Props) {
    return (
        <StyledNavigationDrawer elevation={1} square isVisible={props.isVisible} screenSize={props.screenSize}>
            {Header(props.screenSize, props.onMenuClick)}
            <StyledNavigationItemContainer>
                <Link to={NavigationScreen.HOME} style={{ textDecoration: "none" }}>
                    <NavigationItem type={NavigationScreen.HOME}
                        enabled={props.currentScreen === NavigationScreen.HOME}
                        onClick={() => { props.onNavigationItemClick(NavigationScreen.HOME) }}
                    />
                </Link>
                <Space />
                <Link to={NavigationScreen.TRACKING} style={{ textDecoration: "none" }}>
                    <NavigationItem type={NavigationScreen.TRACKING}
                        enabled={props.currentScreen === NavigationScreen.TRACKING}
                        onClick={() => { props.onNavigationItemClick(NavigationScreen.TRACKING) }}
                    />
                </Link>
                <Space />
                <Link to={NavigationScreen.INVASIONS} style={{ textDecoration: "none" }}>
                    <NavigationItem type={NavigationScreen.INVASIONS}
                        enabled={props.currentScreen === NavigationScreen.INVASIONS}
                        onClick={() => { props.onNavigationItemClick(NavigationScreen.INVASIONS) }}
                    />
                </Link>
            </StyledNavigationItemContainer>
            <StyledToontownTime>
                <ToontownTimeLabel>Toontown Time</ToontownTimeLabel>
                <ToontownTimeValue>{props.toontownTime}</ToontownTimeValue>
            </StyledToontownTime>
            <ContactUsLabel>Contact Us</ContactUsLabel>
        </StyledNavigationDrawer>
    )
}

type StyledNavigationDrawerProps = {
    isVisible: boolean,
    screenSize: ScreenSize
}

const StyledNavigationDrawer = styled(Paper) <StyledNavigationDrawerProps>`
&& {
    width: ${props => getStyledNavigationDrawerWidth(props.screenSize)};
    height: ${props => getHeight(props.screenSize)};
    background-color: #46029C;
    border-radius: ${props => getStyledNavigationBorderRadius(props.screenSize)};
    position: ${props => getStyledNavigationDrawerPosition(props.screenSize)};
    display: flex;
    flex-direction: column;
    margin-top: -60px;
    margin-left: ${props => getNavDrawerMarginLeft(props.isVisible, props.screenSize)};
    transition: 100ms ease-in-out;
    z-index: ${props => getStyledNavigationDrawerZIndex(props.screenSize)};
}
`

function getHeight(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Large) {
        return "auto"
    } else {
        return "100%"
    }
}

function getStyledNavigationDrawerZIndex(screenSize: ScreenSize) {
    if(screenSize === ScreenSize.Small) {
        return "2"
    } else {
        return "1"
    }
}

function getStyledNavigationDrawerWidth(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Small) {
        return "280px"
    } else {
        return "240px"
    }
}

function getStyledNavigationBorderRadius(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Small) {
        return "0 20px 20px 0"
    } else {
        return "0"
    }
}

function getStyledNavigationDrawerPosition(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Large) {
        return "static"
    } else {
        return "fixed"
    }
}

function getNavDrawerMarginLeft(isVisible: boolean, screenSize: ScreenSize) {
    if (isVisible) {
        return "0px"
    } else if (screenSize === ScreenSize.Small) {
        return "-280px"
    } else {
        return "-240px"
    }
}

const StyledNavigationItemContainer = styled.div`
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Space = styled.div`
    margin-bottom: 20px;
`

function Header(screenSize: ScreenSize, onMenuClick: () => any) {
    if (screenSize === ScreenSize.Small) {
        return (
            <StyledHeader elevation={2} square>
                <StyledLogo src={ic_app_icon} />
                <StyledAppNameText>ToonTracker</StyledAppNameText>
                <StyledTLDText>.app</StyledTLDText>
                <StyledMenuIcon id="menu-button" className="material-icons" onClick={onMenuClick}>menu</StyledMenuIcon>
            </StyledHeader>
        )
    } else {
        return
    }
}

const StyledHeader = styled(Paper)`
&& {
    height: 60px;
    width: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 0 20px 0 0;
    position: fixed;
}
`

const StyledLogo = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 10px;
`

const StyledAppNameText = styled.p`
    font-family: 'ImpressBT';
    font-size: 22px;
    color: white;
    margin-left: 10px;
    margin-bottom: 3px;
`

const StyledTLDText = styled.p`
    font-family: 'ImpressBT';
    font-size: 22px;
    color: white;
    margin-left: 0px;
    margin-bottom: 3px;
    opacity: 40%;
`

const StyledMenuIcon = styled.span`
    margin-left: 28px;
    color: white;
`

const StyledToontownTime = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
`

const ToontownTimeLabel = styled(Body1)`
    color: white;
    font-weight: 600;
`

const ToontownTimeValue = styled(Body1)`
    color: white;
`

const ContactUsLabel = styled(Body2)`  
    color: white;
    margin-top: 60px;
    margin-bottom: 40px;
    text-align: center;
`