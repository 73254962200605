import {injectable} from "tsyringe";
import UserRepository from "../../../data-users/UserRepository";
import {AssignedToontaskPosition} from "../../model/toons/toontasks/ToonToontasks";
import {CompletedStep} from "../../../feat-tracking/entities/toontasks/Toontask";

@injectable()
export default class ProgressToontaskStep {

    constructor(private userRepository: UserRepository) {
    }

    async invoke(toontaskId: number, optionId: number) {
        const toon = this.userRepository.currentToon.value
        if (!toon) {
            throw Error("Toon was null")
        }

        const completedStep: CompletedStep = {
            completedOption: optionId
        }

        const assignedToontasks = toon.toontasks.getAssignedToontasks()
        if (assignedToontasks.topLeft?.toontaskId === toontaskId) {
            toon.toontasks.addCompletedStep(AssignedToontaskPosition.TOP_LEFT, completedStep)
        } else if (assignedToontasks.topRight?.toontaskId === toontaskId) {
            toon.toontasks.addCompletedStep(AssignedToontaskPosition.TOP_RIGHT, completedStep)
        } else if (assignedToontasks.bottomLeft?.toontaskId === toontaskId) {
            toon.toontasks.addCompletedStep(AssignedToontaskPosition.BOTTOM_LEFT, completedStep)
        } else if (assignedToontasks.bottomRight?.toontaskId === toontaskId) {
            toon.toontasks.addCompletedStep(AssignedToontaskPosition.BOTTOM_RIGHT, completedStep)
        }

        // noinspection ES6MissingAwait
        this.userRepository.updateToon(toon)
    }

}