import styled from "styled-components";
import {CSSProperties} from "@material-ui/core/styles/withStyles";

export type Props = {
    value: string
    onTextChange: (value: string) => void,
    style?: CSSProperties
}

export default function TextField(props: Props) {
    return (
        <StyledTextField style={{...props.style}} value={props.value}
                         onChange={event => props.onTextChange(event.target.value)}/>
    )
}

const StyledTextField = styled.input`
  background-color: #0000;
  border-style: none;
  border-width: 0;
  border-color: #0000;
  border-bottom: 1px solid white;
  outline-width: 0;
  color: white;
  text-align: center;
  font-family: 'Catamaran', sans-serif;
`