import {BehaviorSubject} from "rxjs";
import UserRepository from "data-users/UserRepository";
import FishingProgressEvaluator from "feat-tracking/entities/fishing/FishingProgressEvaluator";
import FlowersProgressEvaluator from "feat-tracking/entities/flowers/FlowersProgressEvaluator";
import GolfProgressEvaluator from "feat-tracking/entities/golf/GolfProgressEvaluator";
import RacingProgressEvaluator from "feat-tracking/entities/racing/RacingProgressEvaluator";
import GetToonTrackingProgressResult from "./GetTrackingProgressResult";
import {injectable} from "tsyringe";

@injectable()
export default class GetTrackingProgressUseCase {

    #fishingProgressEvaluator: FishingProgressEvaluator
    #flowersProgressEvaluator: FlowersProgressEvaluator
    #racingProgressEvaluator: RacingProgressEvaluator
    #golfProgressEvaluator: GolfProgressEvaluator

    #subject = new BehaviorSubject<GetToonTrackingProgressResult>(
        {
            fishingProgress: 0,
            fishCaught: 0,
            fishingTrophies: 0,
            nextFishingLaffAtProgress: null,
            flowersProgress: 0,
            flowersCollected: 0,
            nextFlowersLaffAtProgress: null,
            racingProgress: 0,
            racingTrophies: 0,
            flowersTrophies: 0,
            racingCups: 0,
            nextRacingLaffAtProgress: null,
            golf: {
                progress: 0,
                trophies: 0,
                cups: 0,
                nextLaffProgress: null
            }
        }
    )

    constructor(
        private userRepository: UserRepository,
        fishingProgressEvaluator: FishingProgressEvaluator,
        flowersProgressEvaluator: FlowersProgressEvaluator,
        racingProgressEvaluator: RacingProgressEvaluator,
        golfProgressEvaluator: GolfProgressEvaluator
    ) {
        this.#fishingProgressEvaluator = fishingProgressEvaluator
        this.#flowersProgressEvaluator = flowersProgressEvaluator
        this.#racingProgressEvaluator = racingProgressEvaluator
        this.#golfProgressEvaluator = golfProgressEvaluator

        this.userRepository.currentToon.subscribe(toon => {
            if (toon === null) return

            const fishing = this.#fishingProgressEvaluator.invoke(toon.fishCaught)
            const flowers = this.#flowersProgressEvaluator.evaluate(toon.flowers)
            const racing = this.#racingProgressEvaluator.evaluate(toon.racingTrophies)
            const golf = this.#golfProgressEvaluator.evaluate(toon.golfTrophies)

            this.#subject.next({
                fishingProgress: fishing.fishingProgress,
                fishCaught: fishing.fishCaught,
                fishingTrophies: fishing.trophies,
                nextFishingLaffAtProgress: fishing.nextLaffProgress,
                flowersProgress: flowers.flowersProgress,
                flowersCollected: flowers.flowersCollected,
                nextFlowersLaffAtProgress: flowers.nextLaffAtProgress,
                flowersTrophies: flowers.trophies,
                racingProgress: racing.racingProgress,
                racingTrophies: racing.racingTrophies,
                racingCups: racing.cups,
                nextRacingLaffAtProgress: racing.nextLaffAtProgress,
                golf: {
                    progress: golf.progress,
                    trophies: golf.trophies,
                    cups: golf.cups,
                    nextLaffProgress: golf.nextLaffProgress
                }
            })
        })
    }

    invoke(): BehaviorSubject<GetToonTrackingProgressResult> {
        return this.#subject
    }
}