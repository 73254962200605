import ScreenSize from "common/ScreenSize"
import DefaultToonSelector from "./default/DefaultToonSelector"
import SmallToonSelector from "./small/SmallToonSelector"
import {ToonListItem} from "../../../../RootScreenState";


type Props = {
    laff: number,
    toonName: string,
    selectedToonId: string,
    toonList: ToonListItem[],
    isExpanded: boolean,
    onSelectorClick: () => any,
    onToonSelected: (toonName: string) => any
    screenSize: ScreenSize
}


export default function ToonSelector(props: Props) {
    const extraMargin = props.screenSize === ScreenSize.Large ? 20 : 0

    if (props.screenSize === ScreenSize.Small) {
        return (
            <SmallToonSelector
                isExpanded={props.isExpanded}
                laff={props.laff}
                toonName={props.toonName}
                toonList={props.toonList}
                selectedToonId={props.selectedToonId}
                onSelectorClick={props.onSelectorClick}
                onToonSelected={props.onToonSelected}
            />
        )
    } else {
        return (
            <DefaultToonSelector
                isExpanded={props.isExpanded}
                laff={props.laff}
                toonName={props.toonName}
                toonList={props.toonList}
                selectedToonId={props.selectedToonId}
                marginRight={extraMargin}
                onSelectorClick={props.onSelectorClick}
                onToonSelected={props.onToonSelected} />
        )
    }
}
