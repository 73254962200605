import React from "react"
import styled from "styled-components"

import Card from "common/theme/components/Card"
import LegacyToon from "app/data/user/LegacyToon"

import GameRepo from "app/data/GameDataRepository"
import FlowerRow from "app/ui/tracking/flowers/cards/FlowerRow"

interface Props {
    jbCount: number
    toon: LegacyToon
}

export default class FlowerGroupCard extends React.Component<Props> {

    render(): JSX.Element {
        let name = "1 Jellybean"

        if (this.props.jbCount > 1) {
            name = `${this.props.jbCount} Jellybeans`
        }

        return (
            <StyledFlowerGroupCard>
                <Header>
                    <Title className="headline4">{name}</Title>
                </Header>

                <Table>
                    <p className="overline" style={{ textAlign: "center" }}></p>
                    <p className="overline">FLOWER</p>
                    <div />
                    <p className="overline" style={{ textAlign: "center" }}></p>
                    {this.getFlowerRows(this.props.jbCount)}
                </Table>
            </StyledFlowerGroupCard>
        )
    }

    getFlowerRows(jbCount: number): JSX.Element[] {
        let rows = [];

        for (const flower of GameRepo.getFlowersData().getFlowersWithJbCount(jbCount)) {

            let isUnlocked = this.props.toon.isFlowerUnlocked(flower.index)

            rows.push(
                <FlowerRow index={flower.index} isUnlocked={isUnlocked} />
            )

        }
        return rows
    }

}

const StyledFlowerGroupCard = styled(Card)`
    width: auto;
    height: fit-content;
    margin: 16px 0;
`

const Header = styled.div`
    width: 100%;
    height: 72px;
`

const Title = styled.p`
    margin-left: 72px;
    margin-top: 20px;
    font-family: ImpressBT, serif;
    display: inline-block;
    color: #444;
`

const Table = styled.div`
    padding: 20px 12px;
    display: grid;
    grid-template-columns: 60px 168px 1fr 60px;
    grid-row-gap: 16px;
    grid-auto-rows: fit-content(1rem);
`