import Card from "common/theme/components/Card"
import React from "react"
import cup_large from "common/resources/images/trophy_cup_large.png"
import cup_medium from "common/resources/images/trophy_cup_medium.png"
import cup_small from "common/resources/images/trophy_cup_small.png"
import styled from "styled-components"

interface CupsViewProps {
    trophyCount: number
}

export default class CupsView extends React.Component<CupsViewProps> {
    render(): JSX.Element {
        let isSmallUnlocked = false
        let isMediumUnlocked = false
        let isLargeUnlocked = false

        if (this.props.trophyCount >= 10) {
            isSmallUnlocked = true
        }

        if (this.props.trophyCount >= 20) {
            isMediumUnlocked = true
        }

        if (this.props.trophyCount === 30) {
            isLargeUnlocked = true
        }

        return (
            <CupsCard>
                <Title className="headline4 ">Cups Unlocked</Title>
                <CupsGrid>
                    <CupImage src={cup_small} isUnlocked={isSmallUnlocked} />
                    <CupImage src={cup_medium} isUnlocked={isMediumUnlocked} />
                    <CupImage src={cup_large} isUnlocked={isLargeUnlocked} />
                </CupsGrid>
            </CupsCard>
        )
    }
}

const CupsCard = styled(Card)`
    width: 420px;
    max-width: 100%;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
`

const Title = styled.p`
    text-align: center;
    font-family: ImpressBT, serif;
`

const CupsGrid = styled.div`
    width: 90%;
    height: fit-content;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
`

interface CupsImageProps {
    isUnlocked: boolean
}

const CupImage = styled.img`
    height: 110px;
    opacity: ${(props: CupsImageProps) => props.isUnlocked ? 1 : 0.4}
`