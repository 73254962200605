import styled from "styled-components";
import ExpandArrow from "./ExpandArrow";
import LaffText from "./LaffText";
import ToonNameText from "./ToonNameText";

type Props = {
    laff: number,
    toonName: string,
    onClick: () => void
}

export default function BaseToonSelector(props: Props) {
    return (
        <StyledBaseToonSelector onClick={props.onClick}>
            <LaffText laff={props.laff} />
            <ToonNameText name={props.toonName} />
            <ExpandArrow />
        </StyledBaseToonSelector>
    )
}

const StyledBaseToonSelector = styled.div`
    width: 260px;
    height: 40px;
    background: ${props => props.theme.colors.primary};
    border-radius: 20px;
    border: 2px solid #EEEEEE;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
`