import { Body1 } from "app/theme/Typography"
import styled from "styled-components"

type Props = {
    name: string
}

export default function ToonNameText(props: Props) {
    return <StyledToonNameText>{props.name}</StyledToonNameText>
}

const StyledToonNameText = styled(Body1)`
    margin-left: 20px;
    margin-bottom: 2px;
    margin-right: 12px;
    font-family: ImpressBT;
    font-size: 20px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
`
