import Card from "../../common/theme/components/Card";
import {Row, Spacer} from "../../core-anvil/Layouts";
import {LabelLarge} from "../../app/theme/Typography";
import {CSSProperties} from "react";
import useViewModel from "../../core-theme/hooks/useViewModel";
import PointsTableButtonViewModel from "./PointsTableButtonViewModel";
import {DependencyContainer} from "tsyringe";
import useRxState from "../../core-theme/hooks/useRxState";

export type Props = {
    container: DependencyContainer,
    style?: CSSProperties
}

export default function PointsTableButton(props: Props) {
    const viewModel = useViewModel(props.container, PointsTableButtonViewModel)
    const state = useRxState(viewModel.state)

    return (
        <Card
            onClick={() => viewModel.onClick()}
            style={{
                width: "fit-content",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 100,
                backgroundColor: "transparent",
                boxShadow: "none",
                border: "2px solid white",
                cursor: "pointer",
                ...props.style
            }}>
            <Row verticalAlignment="center" style={{userSelect: "none"}}>
                <span className="material-icons-outlined" style={{color: "white"}}>info</span>
                <Spacer style={{margin: 0, width: 4}}/>
                <LabelLarge style={{fontSize: 18, marginTop: 2}}>{state.label}</LabelLarge>
            </Row>
        </Card>
    )
}