import { BehaviorSubject } from "rxjs"
import Cog from "app/entities/invasions/cogs/Cog"
import GetInvasions from "app/application/invasions/getInvasions/GetInvasions"
import InvasionCardState from "./invasions/InvasionCardState"
import OverallServerStatus from "app/data/status/OverallServerStatus"
import { Server } from "./info/status/ServerStatusPanel"
import { ServerStatus } from "app/data/status/ServerStatus"
import StatusRepository from "app/data/status/StatusRepository"
import LegacyToon from "app/data/user/LegacyToon"
import UserRepository from "app/data/users/UserRepository"
import HomeUIState from "./HomeUIState"
import GameDataRepository from "app/data/GameDataRepository"
import { ScreenSize } from "common/ScreenSize"

export default class HomeViewModel {

    uiState = new BehaviorSubject<HomeUIState>({
        trackingCard: {
            cogSuits: {
                sellbotLevel: 0,
                cashbotLevel: 0,
                lawbotLevel: 0,
                bossbotLevel: 0,
            },
            fishingProgress: "0",
            flowersUnlocked: 0,
            racingTrophies: 0,
            golfTrophies: 0
        },
        matchmakingCardWidth: 0,
        sillyMeterCardWidth: 0,
        toonGuideCardWidth: 0
    })

    toonName = new BehaviorSubject("")

    toontasksProgress = new BehaviorSubject("")

    laff = new BehaviorSubject(0)

    servers: BehaviorSubject<Server[]> = new BehaviorSubject(Array<Server>())

    overallStatusIcon: BehaviorSubject<any> = new BehaviorSubject(null)

    overallStatusText: BehaviorSubject<string> = new BehaviorSubject("")

    isExpandedServerStatusVisible = new BehaviorSubject<boolean>(false)

    invasionCardState = new BehaviorSubject<InvasionCardState>([])

    constructor(userRepository: UserRepository, statusRepository: StatusRepository, getInvasions: GetInvasions) {
        userRepository.observeUser().subscribe(user => {
            const toon = user.getCurrentToon()

            this.toonName.next(toon.getName())
            this.laff.next(toon.getLaff())
            this.toontasksProgress.next(this.getToontasksProgress(toon))

            const sellbot = GameDataRepository.getCogSuitsData().getCogSuit("sellbot", toon.getCogSuitIndexWithType("sellbot")!!)
            const cashbot = GameDataRepository.getCogSuitsData().getCogSuit("cashbot", toon.getCogSuitIndexWithType("cashbot")!!)
            const lawbot = GameDataRepository.getCogSuitsData().getCogSuit("lawbot", toon.getCogSuitIndexWithType("lawbot")!!)
            const bossbot = GameDataRepository.getCogSuitsData().getCogSuit("bossbot", toon.getCogSuitIndexWithType("bossbot")!!)

            let uiState = this.uiState.value
            uiState.trackingCard.cogSuits.sellbotLevel = sellbot.level
            uiState.trackingCard.cogSuits.cashbotLevel = cashbot.level
            uiState.trackingCard.cogSuits.lawbotLevel = lawbot.level
            uiState.trackingCard.cogSuits.bossbotLevel = bossbot.level

            console.log(toon.getTotalFishCaught())

            uiState.trackingCard.fishingProgress = toon.getTotalFishCaught().toString()
            uiState.trackingCard.flowersUnlocked = toon.getTotalFlowersCollected()
            uiState.trackingCard.racingTrophies = toon.getRacingTrophiesUnlockedCount()
            uiState.trackingCard.golfTrophies = toon.getGolfTrophiesUnlockedCount()

            this.uiState.next(uiState)
        })

        statusRepository.serverStatus.subscribe(status => {
            if (!(status instanceof OverallServerStatus)) return

            let array: Server[] = []
            array.push(convertToServer(status.mainSite))
            array.push(convertToServer(status.accountServer))

            const areGameServersUp = status.gameServer1?.isUp === true &&
                status.gameServer2?.isUp === true &&
                status.gameServer3?.isUp === true

            const gameServersLastChange = Math.min(
                status.gameServer1.minutesSince,
                status.gameServer2.minutesSince,
                status.gameServer3.minutesSince)

            const gameServersLatency = Math.max(
                status.gameServer1.latency,
                status.gameServer2.latency,
                status.gameServer3.latency)

            const gameServerStatus: ServerStatus = {
                name: "Game Servers",
                isUp: areGameServersUp,
                minutesSince: gameServersLastChange,
                latency: gameServersLatency
            }

            array.push(convertToServer(gameServerStatus))


            const areUpdateServersUp = status.updateServer1.isUp === true &&
                status.updateServer2.isUp === true &&
                status.updateServer3.isUp === true

            const updateServersLastChange = Math.min(
                status.updateServer1.minutesSince,
                status.updateServer2.minutesSince,
                status.updateServer3.minutesSince)

            const updateServersLatency = Math.max(
                status.updateServer1.latency,
                status.updateServer2.latency,
                status.updateServer3.latency)

            const updateServerStatus: ServerStatus = {
                name: "Update Servers",
                isUp: areUpdateServersUp,
                minutesSince: updateServersLastChange,
                latency: updateServersLatency
            }

            array.push(convertToServer(updateServerStatus))

            this.servers.next(array)

            if (status.mainSite.isUp && status.accountServer.isUp &&
                areGameServersUp && areUpdateServersUp) {
                this.overallStatusIcon.next("done")
                this.overallStatusText.next("Toontown is up!")
            } else {
                this.overallStatusIcon.next("close")
                this.overallStatusText.next("Toontown is down :(")
            }

        })

        getInvasions.invoke().subscribe(invasions => {
            const state: InvasionCardState = []
            invasions.forEach(invasion => state.push({ cogName: invasion.cog, cogType: Cog.findCog(invasion.cog)!!.cogType, district: invasion.district }))
            this.invasionCardState.next(state)
        })
    }

    getToontasksProgress(toon: LegacyToon): string {
        const progress = toon.getToontasksProgress()
        const percentage = Math.trunc(progress * 100)
        return `${percentage}%`
    }

    onStatusInfoClick() {
        const lastValue = this.isExpandedServerStatusVisible.value
        this.isExpandedServerStatusVisible.next(!lastValue)
    }

    onScreenSizeChange(screenSize: ScreenSize) {
        let state = this.uiState.value

        switch (screenSize) {
            case ScreenSize.Small:
                state.matchmakingCardWidth = 320
                state.sillyMeterCardWidth = 320
                state.toonGuideCardWidth = 320
                break
            case ScreenSize.Medium:
                state.matchmakingCardWidth = 340
                state.sillyMeterCardWidth = 280
                state.toonGuideCardWidth = 280
                break
            case ScreenSize.Large:
                state.matchmakingCardWidth = 190
                state.sillyMeterCardWidth = 340
                state.toonGuideCardWidth = 340
                break
        }


        if (state !== this.uiState.value) {
            this.uiState.next(state)
        }
    }
}

function convertToServer(status: ServerStatus): Server {
    let lastChange: string

    if (status.minutesSince > 60 * 24 * 30) {
        lastChange = `${Math.trunc(status.minutesSince / 60 / 24 / 30)} months`
    } else if (status.minutesSince > 60 * 24) {
        lastChange = `${Math.trunc(status.minutesSince / 60 / 24)} days`
    } else if (status.minutesSince > 60) {
        lastChange = `${Math.trunc(status.minutesSince / 60)} hours`
    } else {
        lastChange = `${Math.trunc(status.minutesSince)} minutes`
    }

    return {
        isUp: status.isUp,
        name: status.name,
        lastChange: lastChange
    }
}