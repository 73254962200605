import FeatureCard from "app/ui/home/FeatureCard";
import styled, { ThemeContext } from "styled-components";
import { useContext } from "react";

type Props = {
    width: number,
    onClick: () => void
}

export default function ToonGuideCard(props: Props) {
    const theme = useContext(ThemeContext)

    return (
        <FeatureCard
            title="Toon Guide"
            titleColor="#000000cc"
            smallWidth={props.width}
            mediumWidth={props.width}
            largeWidth={props.width}
            headerBackgroundColor={theme.colors.toonGuide}
            contentBackgroundColor={theme.colors.toonGuideDark}
            onClick={props.onClick}
        >

            <ToonGuideContent />

        </FeatureCard>
    )
}

const ToonGuideContent = styled.div`
    height: 100px;

    @media (min-width: 1440px) {
		height: 200px;
	}
`