import {injectable} from "tsyringe";
import UserRepository from "../../../data-users/UserRepository";

@injectable()
export default class DropToontask {

    constructor(private userRepository: UserRepository) {
    }

    async invoke(toontaskId: number) {
        const toon = this.userRepository.currentToon.value
        if (!toon) {
            throw Error("Toon was null")
        }

        toon.toontasks.unassignToontask(toontaskId)

        // noinspection ES6MissingAwait
        this.userRepository.updateToon(toon)
    }

}