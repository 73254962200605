import React, {CSSProperties} from "react";
import Card from "../../../common/theme/components/Card";
import {Box, Column} from "../../../core-anvil/Layouts";
import ScreenSize from "../../../common/ScreenSize";
import useScreenSize from "../../hooks/useScreenSize";
import styled, {useTheme} from "styled-components";
import {Subtitle2} from "../../../app/theme/Typography";

export type Props = {
    isVisible: boolean,
    title: string,
    isHeaderBorderVisible?: boolean,
    isTitleVisible?: boolean,
    style?: CSSProperties,
    onDismiss: () => void
}

type SizedProps = {
    dialogWidth: number,
    dialogHeight: number,
    backdropWidth: string,
    headerHeight: number,
    contentNoTitleMarginTop: number,
    titleFontSize: number
}

export default function TTDialog(props: React.PropsWithChildren<Props>) {
    const screenSize = useScreenSize()
    const sizedProps = getSizedProps(screenSize)
    const theme = useTheme()

    const headerBorderColor = props.isHeaderBorderVisible ? "#00000066" : "transparent"
    const containerHeight = (props.isTitleVisible ?? true) ? `calc(100% - ${sizedProps.headerHeight}px)` : `calc(100% - ${sizedProps.contentNoTitleMarginTop}px)`

    if (props.isVisible) {
        return (
            <Box style={{width: sizedProps.backdropWidth, height: "calc(100% - 60px)", zIndex: 1, position: "fixed"}}>
                <Box style={{width: "100%", height: "100%", backgroundColor: "#00000044", zIndex: 1}}
                     onClick={() => props.onDismiss()}/>
                <Card style={{
                    width: sizedProps.dialogWidth,
                    height: sizedProps.dialogHeight,
                    maxHeight: "80%",
                    marginBottom: 40,
                    backgroundColor: theme.colors.primaryDark,
                    zIndex: 2,
                    ...props.style
                }}>
                    <Box alignment="topStart" style={{width: "100%", height: "100%"}}>
                        <Box alignment="centerStart" style={{
                            width: "100%",
                            height: sizedProps.headerHeight,
                            display: "flex",
                            paddingLeft: 20,
                            paddingRight: 20,
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            borderBottomColor: headerBorderColor,
                            boxSizing: "border-box"
                        }}>
                            <DialogTitle isVisible={props.isTitleVisible ?? true} title={props.title}
                                         titleFontSize={sizedProps.titleFontSize}/>
                            <span className="material-icons" style={{
                                color: "white",
                                marginTop: "auto",
                                marginBottom: "auto",
                                zIndex: 1,
                                cursor: "pointer"
                            }}
                                  onClick={props.onDismiss}>close</span>
                        </Box>
                        <StyledContainer style={{
                            width: "100%",
                            height: containerHeight,
                            marginTop: props.isTitleVisible ?? true ? sizedProps.headerHeight : sizedProps.contentNoTitleMarginTop,
                            overflowY: "auto",
                        }}>
                            {props.children}
                        </StyledContainer>
                    </Box>
                </Card>
            </Box>
        )
    } else {
        return null
    }
}

type DialogTitleProps = {
    isVisible: boolean,
    titleFontSize: number,
    title: string
}

function DialogTitle(props: DialogTitleProps) {
    if (!props.isVisible) return null

    return (
        <Box style={{width: "calc(100% - 40px)", marginTop: 3}} alignment="center">
            <Subtitle2 style={{
                fontSize: props.titleFontSize,
                fontWeight: 600
            }}>{props.title}</Subtitle2>
        </Box>
    )
}

function getSizedProps(screenSize: ScreenSize): SizedProps {
    switch (screenSize) {
        case ScreenSize.Large:
            return {
                dialogWidth: 1120,
                backdropWidth: "calc(100% - 240px)",
                dialogHeight: 600,
                titleFontSize: 25,
                headerHeight: 72,
                contentNoTitleMarginTop: 28,
            }
        case ScreenSize.Medium:
            return {
                dialogWidth: 700,
                backdropWidth: "100%",
                dialogHeight: 300,
                titleFontSize: 25,
                headerHeight: 72,
                contentNoTitleMarginTop: 28
            }
        case ScreenSize.Small:
            return {
                dialogWidth: 340,
                backdropWidth: "100%",
                dialogHeight: 300,
                titleFontSize: 19,
                headerHeight: 56,
                contentNoTitleMarginTop: 16
            }
    }
}

const StyledContainer = styled(Column)`
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb { /* WebKit */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`