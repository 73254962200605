import React from "react";
import TTDialog from "../../core-theme/components/dialog/TTDialog";
import {Column} from "../../core-anvil/Layouts";
import {CogSuitPointsTableProps} from "./CogSuitsPointsTableProps";
import TableGroups from "./small/TableGroups";
import useViewModel from "../../core-theme/hooks/useViewModel";
import CogSuitsPointsTableViewModel from "./CogSuitsPointsTableViewModel";
import useRxState from "../../core-theme/hooks/useRxState";

export default function CogSuitsPointsTable(props: CogSuitPointsTableProps) {
    const viewModel = useViewModel(props.container, CogSuitsPointsTableViewModel)
    const state = useRxState(viewModel.state)

    viewModel.onCogTypeSelected(props.cogType)

    return (
        <TTDialog isVisible={state.isVisible}
                  title={state.dialogTitle}
                  isHeaderBorderVisible={true}
                  onDismiss={() => viewModel.onDismiss()}
                  style={{height: 660, backgroundColor: props.cogTypeColor}}>
            <Column horizontalAlignment="center" style={{width: "100%", height: "100%"}}>
                <TableGroups pointsLabel={state.pointsLabel} entries={state.pointsEntries} cogTypeColor={props.cogTypeColor}/>
            </Column>
        </TTDialog>
    )
}
