import FloatingActionButton from "common/theme/components/FloatingActionButton"
import { RacingTrophyGroup } from "app/data/game/RacingData"
import React from "react"
import LegacyToon from "app/data/user/LegacyToon"
import UserRepository from "app/data/UserRepository"
import bronze_trophy from "common/resources/images/trophy_racing_bronze.png"
import gold_trophy from "common/resources/images/trophy_racing_gold.png"
import premier_trophy from "common/resources/images/trophy_racing_premier.png"
import silver_trophy from "common/resources/images/trophy_racing_silver.png"
import styled from "styled-components"

interface TrophySelectorProps {
    toon: LegacyToon
    trophyGroup: RacingTrophyGroup
}

export default class TrophySelector extends React.Component<TrophySelectorProps> {

    render(): JSX.Element {
        const group = this.props.trophyGroup

        return (
            <StyledTrophySelector trophyCount={group.trophies.length}>
                {this.renderTrophyImages()}
                {this.renderUnlockButtons()}
            </StyledTrophySelector>
        )
    }

    renderTrophyImages(): JSX.Element[] {
        const toon = this.props.toon
        const group = this.props.trophyGroup

        let trophyImages: JSX.Element[] = []

        for (const trophy of group.trophies) {
            const isUnlocked: boolean = toon.isRacingTrophyUnlocked(trophy.id)

            let image = ""
            switch (trophy.type) {
                case "bronze":
                    image = bronze_trophy
                    break
                case "silver":
                    image = silver_trophy
                    break
                case "gold":
                    image = gold_trophy
                    break
                case "premier":
                    image = premier_trophy
                    break
            }

            trophyImages.push(
                <TrophyImage unlocked={isUnlocked} src={image} />
            )
        }

        return trophyImages
    }

    renderUnlockButtons(): JSX.Element[] {
        const toon = this.props.toon
        const group = this.props.trophyGroup

        let buttons: JSX.Element[] = []

        let position = 0
        for (const trophy of group.trophies) {
            const hasPrevious = position > 0
            const hasNext = group.trophies.length > position + 1
            const isUnlocked = toon.isRacingTrophyUnlocked(trophy.id)
            let isPreviousUnlocked = false
            let isNextUnlocked = false

            if (hasPrevious) {
                isPreviousUnlocked = toon.isRacingTrophyUnlocked(trophy.id - 1)
            }

            if (hasNext) {
                isNextUnlocked = toon.isRacingTrophyUnlocked(trophy.id + 1)
            }

            const doneIcon = (
                <span className="material-icons" style={{
                    color: "white"
                }}>done</span>
            )

            const lockIcon = (
                <span className="material-icons" style={{
                    color: "white"
                }}>lock</span>
            )

            let buttonIcon = null

            if (!isUnlocked && !hasPrevious) {
                buttonIcon = doneIcon
            }

            if (isUnlocked && !hasNext) {
                buttonIcon = lockIcon
            }

            if (isUnlocked && hasNext && !isNextUnlocked) {
                buttonIcon = lockIcon
            }

            if (!isUnlocked && hasPrevious && isPreviousUnlocked) {
                buttonIcon = doneIcon
            }



            if (buttonIcon !== null) {
                buttons.push(
                    <FloatingActionButton backgroundColor={this.getTrophyColour(trophy.type)}
                        onClick={() => this.onTrophyToggled(trophy.id, !isUnlocked)}>
                        {buttonIcon}
                    </FloatingActionButton>
                )
            } else {
                buttons.push(
                    <div />
                )
            }



            position++
        }

        return buttons
    }

    getTrophyColour(type: string) {
        switch (type) {
            case "bronze": return "#94470c"
            case "silver": return "#85849e"
            case "gold": return "#b8a604"
            case "premier": return "#4cbee0"
        }

        return ""
    }

    onTrophyToggled(trophyId: number, isUnlocked: boolean) {
        const user = UserRepository.getUser()
        const toon = user.getCurrentToon()

        toon.setRacingTrophyUnlocked(trophyId, isUnlocked)
        UserRepository.updateUser(user)
    }

}

interface StyledTrophySelectorProps {
    trophyCount: number
}

const StyledTrophySelector = styled.div`
    width: 90%;
    margin: 0 auto;
    display: grid;
    row-gap: 22px;
    justify-items: center;
    align-items: center;
    grid-template-rows: auto auto;
    grid-template-columns: ${(props: StyledTrophySelectorProps) => props.trophyCount === 3 ? "auto auto auto" : "auto"}
`

interface TrophyImageProps {
    unlocked: boolean
}

const TrophyImage = styled.img`
    height: 110px;
    opacity: ${(props: TrophyImageProps) => props.unlocked ? 1 : 0.5}
`