import {Route, useHistory} from "react-router-dom";
import ScreenSize from "../../common/ScreenSize";
import Neighbourhood from "../neighbourhood/NeighborhoodScreen";
import ToontaskGroup from "../../data-toontasks/ToontaskGroup";

export const ToontasksOverviewDestination = {
    route: "/feat-tracking/toontasks/:group",
    createRoute: (group: ToontaskGroup) => `/tracking/toontasks/${groupMap.get(group)}`,
    getToontaskGroup: (arg: string): ToontaskGroup => {
        for(let [key, value] of groupMap) {
            if(value === arg) {
                return key
            }
        }
        throw new Error("Could not find arg: " + arg)
    }
}

const groupMap = new Map<ToontaskGroup, string>([
    [ToontaskGroup.ToontownCentral, "ttc"],
    [ToontaskGroup.DonaldsDock, "dd"],
    [ToontaskGroup.DaisyGardens, "dg"],
    [ToontaskGroup.MinniesMelodyland, "mml"],
    [ToontaskGroup.TheBrrrgh, "brg"],
    [ToontaskGroup.DonaldsDreamland, "ddl"],
    [ToontaskGroup.Lawbot, "lawbot"],
    [ToontaskGroup.Bossbot, "bossbot"]
])

type Props = {
    screenSize: ScreenSize
}

export default function ToontasksOverviewRoute(props: Props) {
    const history = useHistory()

    return (
        <Route path={ToontasksOverviewDestination.route}>
            <Neighbourhood
                screenSize={props.screenSize}
                onToontaskGroupClick={group => history.push(ToontasksOverviewDestination.createRoute(group))}
                onCompletedToontaskClick={(toontaskId) => history.push(`/tracking/toontasks/toontask/${toontaskId}`)}
                onBackClick={() => history.push("/feat-tracking")}
            />
        </Route>
    )
}