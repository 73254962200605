import InvasionsAPI from "./InvasionsAPI";
import InvasionsResponse from "./InvasionsResponse";
import axios from "axios";

const INVASIONS_URL = "https://us-central1-toontracker-171e6.cloudfunctions.net/fetchInvasions"

export default class InvasionsAPIService implements InvasionsAPI {
    async getInvasions(): Promise<InvasionsResponse> {
        const response = await axios.get(INVASIONS_URL)
        return response.data
    }
}