import {inject, injectable} from "tsyringe";
import CogType from "../../../app/entities/invasions/cogs/CogType";
import FacilitiesRepository from "../../../data-cogsuits/FacilitiesRepository";
import type CogSuitsRepository from "../../../data-cogsuits/cogsuits/CogSuitsRepository";
import FacilitiesRequiredEvaluator from "../../../entities/FacilitiesRequiredEvaluator";
import GetFacilitiesForPromoResult from "./GetFacilitiesForPromoResult";

@injectable()
export default class GetFacilitiesForPromo {

    constructor(
        @inject("CogSuitsRepository") private cogSuitsRepository: CogSuitsRepository,
        private facilitiesRepository: FacilitiesRepository,
        private facilitiesRequiredEvaluator: FacilitiesRequiredEvaluator
    ) {
    }

    async invoke(cogType: CogType, cogSuitIndex: number, points: number): Promise<GetFacilitiesForPromoResult> {
        const pointsRequired = (await this.cogSuitsRepository.getCogSuit(cogType, cogSuitIndex)).pointsForPromo
        const pointsRemaining = pointsRequired - points

        const facilities = this.facilitiesRepository.getFacilities(cogType)

        return this.facilitiesRequiredEvaluator.evaluate(pointsRemaining, facilities)
    }

}