import {BehaviorSubject} from "rxjs";
import FishingOverviewScreenState, {SpeciesState} from "./FishingOverviewScreenState";
import {SpeciesResult} from "../../application-users/usecases/getFishingProgress/GetFishingProgressResult";
import {injectable} from "tsyringe";
import GetFishingProgress from "../../application-users/usecases/getFishingProgress/GetFishingProgress";

@injectable()
export default class FishingOverviewViewModel {

    uiState = new BehaviorSubject<FishingOverviewScreenState>({
        trophiesUnlocked: 0,
        maxTrophies: 7,
        species: this.createSpeciesStates(null),
        progress: 0,
        progressText: "0",
        nextLaffProgress: 1/7
    })

    constructor(private getFishingProgress: GetFishingProgress) {
        this.listenForFishingProgress()
    }

    private listenForFishingProgress() {
        this.getFishingProgress.invoke().subscribe(result => {
            this.uiState.next({
                ...this.uiState.value,
                trophiesUnlocked: result.trophies,
                species: this.createSpeciesStates(result.species),
                progress: result.progress,
                progressText: result.fishCaught.toString(),
                nextLaffProgress: result.nextLaffAtProgress
            })
        })
    }

    private createSpeciesStates(
        speciesResults: Map<number, SpeciesResult> | null
    ): SpeciesState[] {
        const defaultProgress = 0

        return [
            {
                id: 0,
                name: "Balloon Fish",
                hasDarkText: false,
                progress: speciesResults?.get(0)?.progress ?? defaultProgress
            },
            {
                id: 1,
                name: "PB&J Fish",
                hasDarkText: true,
                progress: speciesResults?.get(1)?.progress ?? defaultProgress
            },
            {
                id: 2,
                name: "Cat Fish",
                hasDarkText: false,
                progress: speciesResults?.get(2)?.progress ?? defaultProgress
            },
            {
                id: 3,
                name: "Clown Fish",
                hasDarkText: true,
                progress: speciesResults?.get(3)?.progress ?? defaultProgress
            },
            {
                id: 4,
                name: "Frozen Fish",
                hasDarkText: false,
                progress: speciesResults?.get(4)?.progress ?? defaultProgress
            },
            {
                id: 5,
                name: "Star Fish",
                hasDarkText: true,
                progress: speciesResults?.get(5)?.progress ?? defaultProgress
            },
            {
                id: 6,
                name: "Holey Mackerel",
                hasDarkText: false,
                progress: speciesResults?.get(6)?.progress ?? defaultProgress
            },
            {
                id: 7,
                name: "Dog Fish",
                hasDarkText: false,
                progress: speciesResults?.get(7)?.progress ?? defaultProgress
            },
            {
                id: 8,
                name: "Devil Ray",
                hasDarkText: false,
                progress: speciesResults?.get(8)?.progress ?? defaultProgress
            },
            {
                id: 9,
                name: "Amore Eel",
                hasDarkText: false,
                progress: speciesResults?.get(9)?.progress ?? defaultProgress
            },
            {
                id: 10,
                name: "Nurse Shark",
                hasDarkText: false,
                progress: speciesResults?.get(10)?.progress ?? defaultProgress
            },
            {
                id: 11,
                name: "King Crab",
                hasDarkText: false,
                progress: speciesResults?.get(11)?.progress ?? defaultProgress
            },
            {
                id: 12,
                name: "Moon Fish",
                hasDarkText: false,
                progress: speciesResults?.get(12)?.progress ?? defaultProgress
            },
            {
                id: 13,
                name: "Sea Horse",
                hasDarkText: false,
                progress: speciesResults?.get(13)?.progress ?? defaultProgress
            },
            {
                id: 14,
                name: "Pool Shark",
                hasDarkText: false,
                progress: speciesResults?.get(14)?.progress ?? defaultProgress
            },
            {
                id: 15,
                name: "Bear Acuda",
                hasDarkText: false,
                progress: speciesResults?.get(15)?.progress ?? defaultProgress
            },
            {
                id: 16,
                name: "Cutthroat Trout",
                hasDarkText: false,
                progress: speciesResults?.get(16)?.progress ?? defaultProgress
            },
            {
                id: 17,
                name: "Piano Tuna",
                hasDarkText: true,
                progress: speciesResults?.get(17)?.progress ?? defaultProgress
            }
        ]
    }
}