import { Body2, Subtitle2 } from "app/theme/Typography"
import Card from "common/theme/components/Card"
import ProgressBar from "common/theme/components/ProgressBar"
import styled from "styled-components"

export enum ToontaskSlotPosition {
    TOP_LEFT = 0,
    TOP_RIGHT = 1,
    BOTTOM_LEFT = 2,
    BOTTOM_RIGHT = 3
}

export type ToontaskSlotState = {
    position: ToontaskSlotPosition,
    isFilled: Boolean,
    progress: number,
    progressText: string,
    toontaskTitleText: string,
    rewardText: string,
    stepText: string
}

type Props = {
    uiState: ToontaskSlotState
}

export default function MediumToontaskSlot(props: Props) {
    if(props.uiState.isFilled) {
        return (
            <StyledMediumToontaskSlot>
                <StyledTopRow>
                <PositionDots highlightedPosition={props.uiState.position} />
                <StyledProgressText>{props.uiState.progressText}</StyledProgressText>
                </StyledTopRow>
                <ProgressBar progress={props.uiState.progress} max={1} style={{marginTop: 10}}/>
                <StyledStepText>{props.uiState.stepText}</StyledStepText>
            </StyledMediumToontaskSlot>
        )
    } else {
        return (
            <StyledEmptyMediumToontaskSlot/>
        )
    }


}

const StyledMediumToontaskSlot = styled(Card)`
    width: 107px;
    height: 82px;
    background-color: #7a20de;
    display: flex;
    flex-direction: column;
    padding: 18px;
`

const StyledEmptyMediumToontaskSlot = styled.div`
    width: 104px;
    height: 79px;
    padding: 18px;
    border-radius: ${props => props.theme.dimens.cardCornerRadius};
    border-style: solid;
    border-color: #ffffff44;
    border-width: 2px;
`

const StyledTopRow = styled.div`
    display: flex;
    flex-direction: row;
`

type PositionDotsProps = {
    highlightedPosition: ToontaskSlotPosition
}

function PositionDots(props: PositionDotsProps) {

    let positionDots = []
    for (let i = 0; i < 4; i++) {
        const isHighlighted = i === props.highlightedPosition.valueOf()
        positionDots.push(
            <StyledPositionDot
                color={isHighlighted ? "white" : "black"}
                opacity={isHighlighted ? "1" : "0.2"}
            />
        )
    }


    return (
        <StyledPositionDots>
            {positionDots}
        </StyledPositionDots>
    )
}

const StyledPositionDots = styled.div`
    width: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
`

type StyledPositionDotProps = {
    color: string,
    opacity: string
}

const StyledPositionDot = styled.div<StyledPositionDotProps>`
    width: 8px;
    height: 8px;
    background-color: ${props => props.color};
    opacity: ${props => props.opacity};
    border-radius: 4px;
`

const StyledProgressText = styled(Subtitle2)`
    flex: 1;
    text-align: end;
`

const StyledStepText = styled(Body2)`
    margin-top: 8px;
`