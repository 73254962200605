import ScreenSize from "common/ScreenSize"
import ToontaskGroup from "data-toontasks/ToontaskGroup"
import {CSSProperties} from "react"
import styled, {useTheme} from "styled-components"
import NotStartedToontasks from "./notstarted/NotStartedToontasks"
import StartedToontasks, {AssignedToontasksState} from "./started/StartedToontasks"
import {ToontaskCard} from "./ToontaskCard";

export type ToontaskListState = {
    started: AssignedToontasksState,
    notStarted: ToontaskCardState[],
    completed: ToontaskCardState[]
}

export type ToontaskCardState = {
    id: number,
    title: string,
    reward: string
}

type Props = {
    screenSize: ScreenSize,
    toontaskGroup: ToontaskGroup,
    toontasks: ToontaskListState,
    isNotStartedToontasksVisible: boolean,
    onNotStartedToontaskClick: (toontaskId: number) => void,
    onCompletedToontaskClick: (toontaskId: number) => void,
    style?: CSSProperties
}

export default function NeighborhoodToontasksList(props: Props) {
    const flexDirection = props.screenSize === ScreenSize.Large ? "row" : "column"
    const notInProgressListMarginLeft = props.screenSize === ScreenSize.Large ? 40 : 0
    const notStartedToontasksMarginTop = props.screenSize === ScreenSize.Large ? 0 : 40

    const theme = useTheme()

    const finishedColor = theme.colors.getToontaskGroupDarkColor(props.toontaskGroup)

    return (
        <StyledNeighborhoodToontasksList style={{...props.style, flexDirection: flexDirection}}>
            <StartedToontasks screenSize={props.screenSize} group={props.toontaskGroup}
                              assigned={props.toontasks.started}/>
            <NotInProgressTasksList style={{marginLeft: notInProgressListMarginLeft}}>
                {getNotStartedToontasks(props, notStartedToontasksMarginTop)}
                {renderToontasksFinished(props.screenSize, finishedColor, props.toontasks.completed, props.onCompletedToontaskClick)}
            </NotInProgressTasksList>
        </StyledNeighborhoodToontasksList>
    )
}

function getNotStartedToontasks(props: Props, notStartedToontasksMarginTop: number) {
    if (props.isNotStartedToontasksVisible) {
        return <NotStartedToontasks
            toontasks={props.toontasks.notStarted}
            group={props.toontaskGroup}
            onToontaskClick={props.onNotStartedToontaskClick}
            style={{marginTop: notStartedToontasksMarginTop}}
        />
    } else {
        return null
    }
}

function renderToontasksFinished(screenSize: ScreenSize, color: string, toontasks: ToontaskCardState[], onToontaskClick: (toontaskId: number) => void) {
    return (
        <div className="neighbourhood-page__toontasks-finished">

            <p className="neighbourhood-page__toontasks-finished-title headline5">Finished</p>
            <div className="neighbourhood-page__toontasks-grid">
                {renderToontasksFinishedCards(screenSize, color, toontasks, onToontaskClick)}
            </div>
        </div>
    )
}

function renderToontasksFinishedCards(screenSize: ScreenSize, color: string, toontasks: ToontaskCardState[], onClick: (toontaskId: number) => void) {
    let cards = []

    const cardWidth = screenSize === ScreenSize.Small ? 320
        : 310

    for (const toontask of toontasks) {
        cards.push(
            <ToontaskCard style={{width: cardWidth}} title={toontask.title} reward={toontask.reward} color={color}
                          onClick={() => onClick(toontask.id)}/>
        )
    }

    return cards
}

const StyledNeighborhoodToontasksList = styled.div`
  margin-top: 20px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
`

const NotInProgressTasksList = styled.div`

`