import { BehaviorSubject } from "rxjs";
import ToontasksRepository from "data-toontasks/ToontasksRepository";
import UserRepository from "data-users/UserRepository";
import AssignedToontask from "application-users/model/toons/toontasks/AssignedToontask";
import ToonAssignedToontasks from "application-users/model/toons/toontasks/ToonAssignedToontasks";
import GetAssignedToontasks, { AssignedToontaskSlot, Result } from "./GetAssignedToontasks";

export default class GetAssignedToontasksUseCase implements GetAssignedToontasks {

    #userRepository: UserRepository
    #toontasksRepository: ToontasksRepository
    #result = new BehaviorSubject<Result | null>(null)

    constructor(userRepository: UserRepository, toontasksRepository: ToontasksRepository) {
        this.#userRepository = userRepository
        this.#toontasksRepository = toontasksRepository

        this.#userRepository.currentToon.subscribe(async toon => {
            if(toon === null) {
                return
            }
            const assignedToontasks = toon.toontasks.getAssignedToontasks()
            this.#result.next(await this.#createResult(assignedToontasks))
        })
    }

    invoke(): BehaviorSubject<Result | null> {
        return this.#result
    }

    async #createResult(assignedToontasks: ToonAssignedToontasks): Promise<Result> {
        let topLeft = null
        let topRight = null
        let bottomLeft = null
        let bottomRight = null

        if(assignedToontasks.topLeft !== null) {
            const assigned = assignedToontasks.topLeft!
            topLeft = await this.#createAssignedToontaskResult(assigned)
        }

        if(assignedToontasks.topRight !== null) {
            const assigned = assignedToontasks.topRight!
            topRight = await this.#createAssignedToontaskResult(assigned)
        }

        if(assignedToontasks.bottomLeft !== null) {
            const assigned = assignedToontasks.bottomLeft!
            bottomLeft = await this.#createAssignedToontaskResult(assigned)
        }
        
        if(assignedToontasks.bottomRight !== null) {
            const assigned = assignedToontasks.bottomRight!
            bottomRight = await this.#createAssignedToontaskResult(assigned)
        }

        return {
            topLeft: topLeft,
            topRight: topRight,
            bottomLeft: bottomLeft,
            bottomRight: bottomRight
        }
    }

    async #createAssignedToontaskResult(assignedToontask: AssignedToontask): Promise<AssignedToontaskSlot> {
        const toontask = await this.#toontasksRepository.getToontask(assignedToontask.toontaskId)
        const totalSteps = toontask.steps.length
        const progress = assignedToontask.stepsCompleted.length / totalSteps

        const currentStep = toontask.steps[assignedToontask.stepsCompleted.length]
        const currentOption = currentStep.options[assignedToontask.currentOption]

        return {
            title: toontask.title,
            reward: toontask.reward,
            progress: progress,
            currentStepName: currentOption.name
        }
    }
}
