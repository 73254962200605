import { Body1 } from "app/theme/Typography";
import styled from "styled-components";

type Props = {
    backgroundColor: string,
    cogSuitIcon: string,
    cogSuitLevel: number
}

export default function CogSuitsLevelLayout(props: Props) {

    return (
        <StyledCogSuitsLevelLayout backgroundColor={props.backgroundColor}>
            <StyledCogSuitIcon src={props.cogSuitIcon} />
            <StyledCogSuitLevel>{props.cogSuitLevel}</StyledCogSuitLevel>
        </StyledCogSuitsLevelLayout>
    )

}

type StyledCogSuitsLevelLayoutProps = {
    backgroundColor: string
}

const StyledCogSuitsLevelLayout = styled.div<StyledCogSuitsLevelLayoutProps>`
    width: 48px;
    height: 26px;
    background-color: ${props => props.backgroundColor};
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledCogSuitIcon = styled.img`
    width: 18px;
    height: 18px;
    margin-left: 4px;
`

const StyledCogSuitLevel = styled(Body1)`
    width: 22px;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    color: white;
`