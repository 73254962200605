import Card from "../../../../common/theme/components/Card";
import {CSSProperties} from "react";
import {Column, Row, Spacer} from "../../../../core-anvil/Layouts";
import {Body1, Body2, LabelMedium} from "../../../../app/theme/Typography";
import ToontaskGroup from "../../../../data-toontasks/ToontaskGroup";
import {useTheme} from "styled-components";

export type StepsCardStep = {
    options: string[]
}

type Props = {
    currentStepIndex: number,
    currentStepText: string,
    steps: StepsCardStep[],
    group: ToontaskGroup,
    onNextStepClick: () => void,
    onUndoStepClick: () => void,
    style?: CSSProperties
}

export default function StepsCard(props: Props) {
    const theme = useTheme()

    return (
        <Card style={{...props.style, backgroundColor: theme.colors.getToontaskGroupColor(props.group)}}>
            <Column style={{marginTop: 52, marginLeft: 24, marginRight: 24, marginBottom: 20}}>
                <Body2>{`Current step (${props.currentStepIndex + 1}/${props.steps.length})`}</Body2>
                <Spacer style={{height: 2}}/>
                <Body1>{props.currentStepText}</Body1>
                <Spacer style={{height: 32}}/>
                <ActionButtons group={props.group} onNextStepClick={props.onNextStepClick} onUndoStepClick={props.onUndoStepClick}/>
            </Column>
        </Card>
    )
}

type ActionButtonsProps = {
    group: ToontaskGroup,
    onNextStepClick: () => void,
    onUndoStepClick: () => void,
    style?: CSSProperties
}

function ActionButtons(props: ActionButtonsProps) {
    const theme = useTheme()

    return (
        <Row style={props.style}>
            <UndoStepButton style={{backgroundColor: theme.colors.getToontaskGroupDarkColor(props.group)}}
                            onClick={props.onUndoStepClick}/>
            <Spacer/>
            <NextStepButton style={{backgroundColor: theme.colors.getToontaskGroupDarkColor(props.group)}}
                            onClick={props.onNextStepClick}/>
        </Row>
    )
}

type ImplementedActionButtonProps = {
    onClick?: () => void,
    style?: CSSProperties
}

function NextStepButton(props: ImplementedActionButtonProps) {
    return <ActionButton materialIcon="arrow_forward" label="Next Step" onClick={props.onClick} style={props.style}/>
}

function UndoStepButton(props: ImplementedActionButtonProps) {
    return <ActionButton materialIcon="undo" label="Undo Step" onClick={props.onClick} style={props.style}/>
}

type ActionButtonProps = {
    materialIcon: string,
    label: string,
    onClick?: () => void,
    style?: CSSProperties
}

function ActionButton(props: ActionButtonProps) {
    return (
        <Card onClick={props.onClick} style={{...props.style, height: 30, width: "fit-content"}}>
            <Row verticalAlignment="center" style={{height: "100%", paddingLeft: 8, paddingRight: 12}}>
                <span className="material-icons" style={{fontSize: 20, color: "white"}}>{props.materialIcon}</span>
                <LabelMedium style={{marginLeft: 4, marginTop: 1}}>{props.label}</LabelMedium>
            </Row>
        </Card>
    )
}