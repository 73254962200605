import GetFishSpeciesResult from "./GetFishSpeciesResult";
import {injectable} from "tsyringe";
import FishRepository from "../../../data-fishing/FishRepository";

@injectable()
export default class GetFishSpecies {

    constructor(private fishRepository: FishRepository) {
    }

    invoke(id: number): GetFishSpeciesResult {
        const result = this.fishRepository.getAllSpecies()
        const species = result.filter(species => {
            return species.id === id
        })[0]

        const fishResult = species.fish.map(fish => {
            return {
                id: fish.id,
                name: fish.name
            }
        })

        return {
            id: id,
            name: species.name,
            fish: fishResult
        }
    }

}