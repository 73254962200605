import {Column, Row, Spacer} from "../../../core-anvil/Layouts";
import {CSSProperties} from "react";
import Card from "../../../common/theme/components/Card";
import {TitleSmall} from "../../../app/theme/Typography";
import {FishItem} from "../FishingSpeciesScreenState";
import useViewModel from "../../../core-theme/hooks/useViewModel";
import FishListViewModel from "./FishListViewModel";
import {DependencyContainer} from "tsyringe";

type FishListProps = {
    container: DependencyContainer
    fishItems: FishItem[],
    checkboxUncheckedColor: string,
    cardBackgroundColor: string,
    onClick: (fishId: number, isCaught: boolean) => void,
    style?: CSSProperties
}

export default function FishList(props: FishListProps) {
    const viewModel = useViewModel(props.container, FishListViewModel)

    let fishCards: JSX.Element[] = []

    props.fishItems.forEach(fish => {
        fishCards.push(
            <FishCard name={fish.name}
                      isChecked={fish.isChecked}
                      checkedColor="white"
                      uncheckedColor={props.checkboxUncheckedColor}
                      onClick={() => props.onClick(fish.id, !fish.isChecked)}
                      onInfoClick={() => viewModel.onInfoClick(fish.id)}
                      style={{
                          width: 300,
                          height: 50,
                          borderRadius: 10,
                          marginBottom: 12,
                          backgroundColor: props.cardBackgroundColor
                      }}/>
        )
    })

    return (
        <Column horizontalAlignment="center" style={props.style}>
            {fishCards}
        </Column>
    )
}

type FishCardProps = {
    name: string,
    isChecked: boolean
    checkedColor: string,
    uncheckedColor: string,
    onClick: () => void,
    onInfoClick: () => void,
    style?: CSSProperties
}

function FishCard(props: FishCardProps) {
    let checkboxColor = props.uncheckedColor
    if (props.isChecked) {
        checkboxColor = props.checkedColor
    }

    return (
        <Card style={props.style}>
            <Row style={{width: "100%", height: "100%"}} verticalAlignment="center">
                 <span className="material-icons" style={{marginLeft: 16, color: "white", fontSize: 22, cursor: "pointer"}}
                       onClick={props.onInfoClick}>info</span>
                <TitleSmall style={{marginLeft: 10, marginTop: 2}}>{props.name}</TitleSmall>
                <Spacer/>
                <span className="material-icons" style={{marginRight: 16, color: checkboxColor}}
                      onClick={props.onClick}>check_box</span>
            </Row>
        </Card>
    )
}