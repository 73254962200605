import { useTheme } from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";

type Props = {
    progressDecimal: number
    progressText: string,
    subtitleText: string
}

export default function SmallFishingCard(props: Props) {
    const theme = useTheme()

    return (
        <ActivityHeader
            width={310}
            progressDecimal={props.progressDecimal}
            progressText={props.progressText}
            title={"Fishing"}
            subtitle={props.subtitleText}
            backgroundColor={theme.colors.fishing}
        />
    )
}