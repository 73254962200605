import "./login.scss";

import { Button } from "@material-ui/core"
import { CircularProgress } from "@material-ui/core";
import LoginTextField from "./textfield";
import React from 'react';
import UserRepo from "app/data/UserRepository"
import app_icon from "common/resources/images/app_icon.svg"
import google_icon from "common/resources/images/btn_google_dark_normal.svg"
import google_play_badge from "common/resources/images/google-play-badge.png"
import { withStyles } from "@material-ui/core/styles";

const play_url = "https://play.google.com/store/apps/details?id=com.heyitsmedz.toontracker&utm_source=ToonTracker%20Web&utm_medium=badge&utm_campaign=Web%20Badge";

const emailRegex = /\S+@\S+\.\S+/

const SignInButton = withStyles(theme => ({
	root: {
		width: 340,
		height: 44,
		backgroundColor: "white",
		color: "#47039d",
		borderRadius: 6
	}
}))(Button)

let progressDisplay = "none"
if (window.innerWidth > 720)
	progressDisplay = "block"

const Progress = withStyles(theme => ({
	root: {
		color: "white",
		marginLeft: 16,
		marginRight: -44,
		marginTop: 12,
		display: progressDisplay
	}
}))(CircularProgress);

let fetchMethodQueue = []
let isRunning = false;

export default class Login extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: "",
			pass: "",
			confirmPass: "",
			userExists: null,
			signInMessage: null,
			processingEmail: false,
		};



		this.onSignInEmailClicked = this.onSignInEmailClicked.bind(this);
		this.onSignInGoogleClicked = this.onSignInGoogleClicked.bind(this);
	}

	render() {
		return (
			<div id="login-container">
				{this.renderInfoPanel()}
				<div id="login-divider" />
				{this.renderLoginPanel()}
				{this.renderDisclaimer()}
			</div>
		);
	}

	renderInfoPanel() {
		return (
			<div id="login-info-panel">
				{this.renderLoginInfoMessage()}
				{this.renderGooglePlayPanel()}
			</div>
		);
	}

	renderLoginInfoMessage() {
		return <div id="login-message-panel">
			<p className="headline3 unselectable" style={{ marginTop: 160 }}>Welcome!</p>
			<p className="headline4 unselectable" style={{ marginTop: 8 }}>It looks like you caught us early</p>
			<p className="headline6 unselectable" style={{ marginTop: 92 }}>We're not quite ready for more visitors yet,
				but register to join the Beta waitlist and we'll be sure to let you know when you can come in!</p>

			<p className="headline6 unselectable" style={{ marginTop: 72 }}>No ads, subscriptions, or any other forms of
				revenue</p>
			<p className="headline4 unselectable" style={{ marginTop: 48 }}>Free. Forever.</p>
		</div>;
	}

	renderGooglePlayPanel() {
		return <div style={{ position: "absolute", bottom: 0, marginLeft: 28, marginBottom: 80 }}>
			<a href={play_url} target="_blank" rel="noreferrer"><img src={google_play_badge} alt=""
				style={{ height: 72, float: "left" }} /></a>
			<div style={{ display: "inline-block", float: "left", marginTop: 19 }}>
				<p className="body1 unselectable">Download our Android app now with</p>
				<p className="body1 unselectable">all the great features ToonTracker has to offer!</p>
			</div>
		</div>;
	}

	renderLoginPanel() {
		return (
			<div id="login-panel">
				<img id="login-app-icon" alt="" src={app_icon} />
				<p className="headline2 unselectable" style={{ "margin-top": "4px" }}>ToonTracker</p>
				<p className="headline4 unselectable" style={{ "margin-top": "8px" }}>The ultimate unofficial Toontown companion</p>
				{this.renderSignInDetails()}
			</div>
		);
	}

	renderSignInDetails() {
		return (
			<div>
				{this.renderSignInDetailsFields()}

				<SignInButton variant="contained"
					disabled={!emailRegex.test(this.state.email)}
					size="large"
					onClick={this.onSignInEmailClicked}
				>

					{this.signInButtonText()}
				</SignInButton>

				{this.renderSignInMessage()}
				<p className="subtitle1 unselectable" style={{ fontWeight: 400, marginTop: 36 }}>Because who needs another password?</p>
				<div id="login-google-button" onClick={this.onSignInGoogleClicked}>
					<img className="unselectable" alt="" src={google_icon} />
					<p className="subtitle2 unselectable">Sign in with Google</p>
				</div>
			</div>
		);
	}

	onEmailChanged = async event => {
		const email = event.target.value;
		this.setState(state => {
			return {
				email: email,
				signInMethod: null,
				signInMessage: null
			};
		}
		);

		fetchMethodQueue[0] = { email: email, time: Date.now() };
		await this.processFetchMethodRequest();
	}

	async processFetchMethodRequest() {
		if (isRunning) {
			return
		}
		isRunning = true
		this.setState({ processingEmail: true });

		while (fetchMethodQueue.length > 0) {
			const req = fetchMethodQueue.pop();
			const email = req["email"];
			const time = req["time"];

			const sleepTime = Math.max(0, 750 - (Date.now() - time));
			await sleep(sleepTime);

			if (fetchMethodQueue.length === 0 && emailRegex.test(email)) {
				const exists = await UserRepo.userExists(email)
				this.setState({ userExists: exists });
			}
		}

		isRunning = false;
		this.setState({ processingEmail: false });
	}

	renderSignInDetailsFields() {
		const progressOpacity = this.state.processingEmail ? 1 : 0;

		let progressDisplay = "none";
		if (window.innerWidth >= 720) {
			progressDisplay = "inline-block"
		}

		const emailField =
			<div>
				<LoginTextField id="login-email-field" label="Email address" onChange={this.onEmailChanged} />
				<span style={{ opacity: progressOpacity, display: progressDisplay }}><Progress size={28} /></span>
			</div>

		let passField =
			<LoginTextField id="login-pass-field" label="Password" type="password" onChange={this.onPassChanged} />

		let confirmPassField =
			<LoginTextField id="login-confirm-field" label="Confirm Password" type="password" onChange={this.onConfirmPassChanged} />


		if (!emailRegex.test(this.state.email)) {
			passField = null;
			confirmPassField = null;

		} else if (this.state.userExists) {
			confirmPassField = null;
		}

		return (
			<div id="login-signin-details-fields">
				{emailField}
				<div style={{ marginTop: 16 }} />
				{passField}
				<div style={{ marginTop: 16 }} />
				{confirmPassField}
				<div style={{ marginTop: 16 }} />
			</div>
		);
	}

	onPassChanged = event => {
		if (this.state.user !== null) {
			this.setState({ user: null });
		}
		const pass = event.target.value;
		this.setState({ pass: pass, signInMessage: null });
	}

	onConfirmPassChanged = event => {
		if (this.state.user !== null) {
			this.setState({ user: null });
		}
		const confirmPass = event.target.value;
		this.setState(() => {
			return { confirmPass: confirmPass };
		}
		);
	}

	async onSignInEmailClicked() {
		const email = this.state.email;
		const pass = this.state.pass;

		UserRepo.signInByEmail(email, pass)
			.then(user => {
				UserRepo.fetchUser()
			})
			.catch(err => {
				if (err === "auth/wrong-password") {
					this.setState({ signInMessage: "incorrect-password" });
				}
			});
	}

	signInButtonText() {
		if (emailRegex.test(this.state.email)) {
			if (this.state.userExists) {
				return "Sign in";
			} else {
				return "Register"
			}
		} else {
			return "Sign in or Register"
		}
	}

	renderSignInMessage() {
		const messageProps = this.getMessageProps();

		// incorrect password message should disappear after a few seconds
		if (this.state.signInMessage === "incorrect-password") {
			waitThenExecute(5000, () => {
				if (this.state.signInMessage === "incorrect-password") {
					this.setState({ signInMessage: null });
				}
			});
		}

		return (
			<div id="login-signin-message-panel" className={messageProps.classes}
				style={{
					height: messageProps.height,
					marginTop: messageProps.marginTop,
					textAlign: "center",
				}}>
				<div id="beta-notice"
					style={{
						display: "inline-block",
						opacity: messageProps.opacity,
						textAlign: "center"
					}}>

					<p className="subtitle2 unselectable"
						style={{
							maxHeight: "100%",
							fontWeight: 400
						}}>
						{messageProps.top.message}
					</p>

					<p className={"body1 unselectable " + messageProps.bottom.classes}
						onClick={messageProps.bottom.onClick}
						style={{
							maxHeight: "100%",
							fontWeight: 400,
							marginTop: messageProps.top.margin
						}}>
						{messageProps.bottom.message}
					</p>
				</div>
			</div>
		);
	}

	getMessageProps() {
		let messageProps = {
			opacity: 0,
			height: 0,
			marginTop: 0,
			classes: "fade-out",
			buttons: {
				isVisible: false,
				opacity: 0
			},
			top: {
				message: "",
				margin: 8,

			},
			bottom: {
				message: "",
				classes: "",
				onClick: null
			}

		};

		switch (this.state.signInMessage) {
			case "alpha-only": {
				messageProps = this.getAlphaOnlyMessageProps(messageProps);
				break;
			}
			case "incorrect-password": {
				messageProps = this.getIncorrectPasswordMessageProps(messageProps);
				break;
			}
			case "sent-password-reset": {
				messageProps = this.getSentPasswordResetMessageProps(messageProps);
				break;
			}
			default:
				break;
		}

		return messageProps;
	}

	getAlphaOnlyMessageProps(props) {
		props.opacity = 1;
		props.height = 60;
		props.marginTop = 36;
		props.classes = "fade-in"

		props.top.message = "ToonTracker Web is only available for Alpha users at this time :(";
		props.bottom.message = "But not to worry! We'll let you know once we're ready for more users";

		return props;
	}

	getIncorrectPasswordMessageProps(props) {
		props.opacity = 1;
		props.height = 60;
		props.marginTop = 36;
		props.classes = "fade-in"

		props.top.message = "Incorrect password entered";
		props.bottom.message = "Forgot password?"
		props.bottom.classes = "clickable-text";
		props.bottom.onClick = () => {
			UserRepo.resetPassword(this.state.email);
			this.setState({ signInMessage: "sent-password-reset" });
		}

		return props;
	}

	getSentPasswordResetMessageProps(props) {
		props.opacity = 1;
		props.height = 60;
		props.marginTop = 36;
		props.classes = "fade-in"

		props.top.message = "Sent password reset email!";
		props.bottom.message = "Please go to your inbox to reset your password"

		return props;
	}

	async onSignInGoogleClicked() {
		UserRepo.signInWithGoogle()
			.then(user => {
				this.state.onSuccessfulSignIn(user)
			})
			.catch(reason => {
				if (reason === "user/not-alpha") {
					this.setState({ signInMessage: "alpha-only" });
				}
			})
	}

	renderDisclaimer() {
		return (
			<div id="login-disclaimer-layout">
				<p id="login-disclaimer" className="body2">
					While ToonTracker primarily targets Toontown Rewritten players—a community-revived version of Disney's Toontown Online, its development is in no way influenced or affiliated with TTR and is entirely fan-made.<br />
					Google Play and the Google Play logo are trademarks of Google LLC.
				</p>
			</div>
		);
	}
}

async function waitThenExecute(ms, callback) {
	await sleep(ms);
	callback();
}

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

