import GetFishBestSpotsResult, {GetFishBestSpotRodResult} from "./GetFishBestSpotsResult";
import FishRepository from "../../../../data-fishing/FishRepository";
import {FishingRod} from "../../../../entities/fishing/FishingRod";
import {neighborhoodEntityToResult} from "../../NeighborhoodResult";
import {injectable} from "tsyringe";
import {FishingSpotCatchRate} from "../../../../entities/fishing/FishingSpotCatchRate";

@injectable()
export default class GetFishBestSpots {

    constructor(private fishRepository: FishRepository) {
    }

    invoke(fishId: number): GetFishBestSpotsResult | null {

        const twig = this.fishRepository.getFishBestSpot(fishId, FishingRod.TWIG)
        const bamboo = this.fishRepository.getFishBestSpot(fishId, FishingRod.BAMBOO)
        const hardwood = this.fishRepository.getFishBestSpot(fishId, FishingRod.HARDWOOD)
        const steel = this.fishRepository.getFishBestSpot(fishId, FishingRod.STEEL)
        const gold = this.fishRepository.getFishBestSpot(fishId, FishingRod.GOLD)

        return {
            twig: this.createRodResult(twig),
            bamboo: this.createRodResult(bamboo),
            hardwood: this.createRodResult(hardwood),
            steel: this.createRodResult(steel),
            gold: this.createRodResult(gold)
        }
    }

    private createRodResult(spot: FishingSpotCatchRate | null): GetFishBestSpotRodResult | null {
        if(spot === null) return null

        return {
            neighborhood: neighborhoodEntityToResult(spot.neighborhood),
            area: spot.area,
            catchRate: spot.catchRate
        }
    }
}