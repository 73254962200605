import styled from "styled-components"
import BaseExpandedToonSelector from "../base/BaseExpandedToonSelector"
import BaseToonSelector from "../base/BaseToonSelector"
import {ToonListItem} from "../../../../../RootScreenState";

type Props = {
    isExpanded: boolean,
    laff: number,
    toonName: string,
    toonList: ToonListItem[],
    selectedToonId: string,
    marginRight: number,
    onSelectorClick: () => any,
    onToonSelected: (toonName: string) => any
}

export default function DefaultToonSelector(props: Props) {
    return (
        <StyledDefaultToonSelector marginRight={props.marginRight}>
            {getBaseToonSelector(props.isExpanded, props.laff, props.toonName, props.toonList, props.selectedToonId, props.onSelectorClick, props.onToonSelected)}
        </StyledDefaultToonSelector>
    )
}

function getBaseToonSelector(
    isExpanded: boolean,
    laff: number,
    toonName: string,
    toonList: ToonListItem[],
    selectedToonId: string,
    onSelectorClick: () => void,
    onToonSelected: (toonName: string) => any
) {
    if (isExpanded) {
        return <BaseExpandedToonSelector
            selectedToonId={selectedToonId}
            laff={laff}
            toonName={toonName}
            toonList={toonList}
            onToonSelected={onToonSelected}
            onSelectorClick={onSelectorClick}/>
    } else {
        return <BaseToonSelector laff={laff} toonName={toonName} onClick={onSelectorClick}/>
    }
}

type StyledDefaultToonSelectorProps = {
    marginRight: number
}

const StyledDefaultToonSelector = styled.div<StyledDefaultToonSelectorProps>`
  top: 10px;
  position: absolute;
  right: calc(80px + ${props => props.marginRight}px);
`