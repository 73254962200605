import { useTheme } from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";

type Props = {
    progressDecimal: number
    progressText: string,
    suitsUnlocked: number,
}

export default function SmallCogSuitsCard(props: Props) {
    const theme = useTheme()

    return (
        <ActivityHeader
            width={310}
            progressDecimal={props.progressDecimal}
            progressText={props.progressText}
            title={"Cog Suits"}
            subtitle={`You have ${props.suitsUnlocked} suits`}
            backgroundColor={theme.colors.cogSuits}
        />
    )
}