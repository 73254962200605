import Card from "common/theme/components/Card";
import {Link} from "react-router-dom";
import styled from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";
import LargeToontaskSlot, {ToontaskSlotState} from "./LargeToontaskSlot";
import ToontaskGroup from "../../../../../data-toontasks/ToontaskGroup";
import {ToontasksOverviewDestination} from "../../../../../feat-toontasks/routes/ToontasksOverviewRoute";

type Props = {
    progressDecimal: number
    progressText: string,
    group: ToontaskGroup,
    groupText: string,
    topLeftSlot: ToontaskSlotState,
    topRightSlot: ToontaskSlotState,
    bottomLeftSlot: ToontaskSlotState,
    bottomRightSlot: ToontaskSlotState
}

export default function LargeToontasksCard(props: Props) {
    return (
        <StyledLargeToontasksCard>
            <Link to={ToontasksOverviewDestination.createRoute(props.group)} style={{textDecoration: "none"}}>
                <ActivityHeader
                    width={360}
                    progressDecimal={props.progressDecimal}
                    progressText={props.progressText}
                    title={"Toontasks"}
                    subtitle={`You are currently in ${props.groupText}`}
                    backgroundColor={"#370F64"}
                />
            </Link>
            <StyledToontaskSlotGrid>
                <LargeToontaskSlot uiState={props.topLeftSlot}/>
                <LargeToontaskSlot uiState={props.topRightSlot}/>
                <LargeToontaskSlot uiState={props.bottomLeftSlot}/>
                <LargeToontaskSlot uiState={props.bottomRightSlot}/>
            </StyledToontaskSlotGrid>
        </StyledLargeToontasksCard>
    )
}

const StyledLargeToontasksCard = styled(Card)`
  width: 400px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.toontasksDark};
  border-radius: ${props => props.theme.dimens.cardCornerRadius}
`

const StyledToontaskSlotGrid = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
  column-gap: 20px;
`