import {Subtitle2} from "app/theme/Typography"
import styled from "styled-components"
import {ToonListItem} from "../../../RootScreenState";

type ToonRadioGroupProps = {
    toons: ToonListItem[],
    selectedToonId: string,
    onToonSelected: (toonId: string) => any
}

export default function ToonRadioGroup(props: ToonRadioGroupProps): JSX.Element {
    const rows = props.toons.map(toon => {
        return <ToonRadioGroupItem toonName={toon.name} isSelected={toon.id === props.selectedToonId}
                                   onClick={() => props.onToonSelected(toon.id)}/>
    })

    return (
        <StyledToonRadioGroup>
            {rows}
        </StyledToonRadioGroup>
    )
}

const StyledToonRadioGroup = styled.div`
  width: 100%;
  height: fit-content;
  padding: 4px 0;
`

type ToonRadioGroupItemProps = {
    toonName: string,
    isSelected: boolean,
    onClick: () => any
}

function ToonRadioGroupItem(props: ToonRadioGroupItemProps): JSX.Element {
    let radioButtonDot = null
    if (props.isSelected) {
        radioButtonDot = <StyledRadioButtonDot/>
    }

    return (
        <StyledToonRadioGroupItem onClick={props.onClick}>
            <StyledRadioButton>
                {radioButtonDot}
            </StyledRadioButton>
            <StyledToonName>{props.toonName}</StyledToonName>
        </StyledToonRadioGroupItem>
    )
}

const StyledToonRadioGroupItem = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  margin: 20px 20px;
`

const StyledRadioButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${props => props.theme.colors.primaryDark};
`

const StyledRadioButtonDot = styled.div`
  width: 8px;
  height: 8px;
  margin: 6px;
  border-radius: 4px;
  background: #ffffff;
  opacity: 0.9;
`

const StyledToonName = styled(Subtitle2)`
  color: white;
  margin-left: 24px;
  font-weight: 700;
`
