import CogType from "app/entities/invasions/cogs/CogType";
import {LabelLarge, LabelMedium } from "app/theme/Typography";
import { Column, Row, Spacer } from "core-anvil/Layouts";
import { FacilityRow } from "feat-cogsuits/CogSuitsScreenState";
import {CSSProperties} from "react";
import {useTheme} from "styled-components";

type Props = {
    cogType: CogType,
    pointsName: string,
    rows: FacilityRow[],
    style?: CSSProperties
}

const nameColumnLength = 120
const pointsColumnLength = 80
const promotionColumnLength = 100

export default function SmallFacilitiesTable(props: Props) {
    const theme = useTheme()

    return (
        <Column style={{
            backgroundColor: theme.colors.getCogTypeDarkColor(props.cogType),
            borderRadius: theme.dimens.cardCornerRadius,
            paddingLeft: 24,
            paddingTop: 8,
            paddingBottom: 12,
            paddingRight: 24,
            ...props.style
        }}>
            <Heading pointsName={props.pointsName}/>
            <Divider style={{marginTop: 4}}/>
            <Content rows={props.rows}/>
        </Column>
    )
}

type HeadingProps = {
    pointsName: string
}

function Heading(props: HeadingProps) {
    return (
        <Row verticalAlignment="center">
            <Spacer style={{width: nameColumnLength, margin: 0}}/>
            <LabelMedium style={{width: pointsColumnLength, textAlign: "center"}}>{props.pointsName}</LabelMedium>
            <LabelMedium style={{width: promotionColumnLength, textAlign: "center"}}>Required for
                Promotion</LabelMedium>
        </Row>
    )
}

type DividerProps = {
    style?: CSSProperties
}

function Divider(props: DividerProps) {
    return (<Row style={{width: "100%", height: "2px", backgroundColor: "white", ...props.style}}/>)
}

type ContentProps = {
    rows: FacilityRow[]
}

function Content(props: ContentProps) {
    return (
        <Column style={{paddingTop: 8}}>
            {createFacilitiesRows(props.rows)}
        </Column>
    )
}

function createFacilitiesRows(rows: FacilityRow[]): JSX.Element[] {
    return rows.map(row => {
        return (
            <Row verticalAlignment="center" style={{marginTop: 2, marginBottom: 2}}>
                <LabelMedium style={{width: nameColumnLength}}>{row.name}</LabelMedium>
                <LabelLarge style={{width: pointsColumnLength, textAlign: "center"}}>{row.points}</LabelLarge>
                <LabelLarge style={{width: promotionColumnLength, textAlign: "center"}}>{row.requiredCount}</LabelLarge>
            </Row>
        )
    })
}