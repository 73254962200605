import GameDataRepository from "app/data/GameDataRepository"
import React from "react"
import UserRepository from "app/data/UserRepository"
import jb_b from "common/resources/images/jellybean_blue.png"
import jb_c from "common/resources/images/jellybean_cyan.png"
import jb_g from "common/resources/images/jellybean_green.png"
import jb_o from "common/resources/images/jellybean_orange.png"
import jb_p from "common/resources/images/jellybean_pink.png"
import jb_r from "common/resources/images/jellybean_red.png"
import jb_v from "common/resources/images/jellybean_violet.png"
import jb_y from "common/resources/images/jellybean_yellow.png"
import styled from "styled-components"

interface Props {
    index: number,
    isUnlocked: boolean
}

export default class FlowerRow extends React.Component<Props> {
    render(): JSX.Element[] {
        let checkbox = null

        if (this.props.isUnlocked) {
            checkbox = "check_box"
        } else {
            checkbox = "check_box_outline_blank"
        }

        const flower = GameDataRepository.getFlowersData().getFlowerWithIndex(this.props.index)

        return (
            [
                <span className="fish-row__checkbox material-icons unselectable" onClick={() => { this.onFlowerChecked() }} style={{
                    color: "white",
                    textAlign: "center",
                    margin: "auto 0"
                }}>{checkbox}</span>,

                <FlowerField>
                    <FlowerName className="subtitle1">{flower.name}</FlowerName>
                    {this.getJellybeanLayout(flower.index)}
                </FlowerField>,

                <div />,

                <span className="fish-row__info-button material-icons-outlined unselectable">info</span>
            ]
        )
    }

    getJellybeanLayout(index: number) {
        const flower = GameDataRepository.getFlowersData().getFlowerWithIndex(index)

        let jbs = []
        for (let i = 0; i < flower.combo.length; i++) {
            jbs.push(
                <JellybeanIcon src={this.getJellybeanIcon(flower.combo.charAt(i))} />
            )
        }

        return (
            <div>
                {jbs}
            </div>
        )
    }

    getJellybeanIcon(char: string) {
        switch (char) {
            case "b": return jb_b

            case "c": return jb_c

            case "g": return jb_g

            case "o": return jb_o

            case "p": return jb_p

            case "r": return jb_r

            case "v": return jb_v

            case "y": return jb_y
        }
    }

    onFlowerChecked() {
        const user = UserRepository.getUser()
        let toon = user.getCurrentToon()
        toon.setFlowerUnlocked(this.props.index, !this.props.isUnlocked)

        UserRepository.updateUser(user)
    }
}

const FlowerField = styled.div`
    margin: auto 0;
`

const FlowerName = styled.p`
    font-weight: 300;
`

const JellybeanIcon = styled.img`
    height: 20px;
    margin-top: 3px;
    margin-right: 6px;
`