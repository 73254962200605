import { Headline5 } from "app/theme/Typography";
import ServerStatusTable from "./ServerStatusTable";
import styled from "styled-components";
import { ScreenSize } from "common/ScreenSize";

type Props = {
    servers: Server[],
    screenSize: ScreenSize
}

export type Server = {
    isUp: boolean,
    name: string,
    lastChange: string
}

export default function ServerStatusPanel(props: Props) {
    return (
        <StyledServerStatus>
            {Title()}
            <ServerStatusTable servers={props.servers} />
        </StyledServerStatus>
    )
}

const StyledServerStatus = styled.div`
    padding-top: 108px;
`

function Title() {
    return <StyledTitle>Server Status</StyledTitle>
}

const StyledTitle = styled(Headline5)`
    color: white;
`