import CogType from "./CogType";

export default class Cog {
    static readonly COLD_CALLER = new Cog(CogType.SELLBOT, "Cold Caller", 1)
    static readonly TELEMARKETER = new Cog(CogType.SELLBOT, "Telemarketer", 2)
    static readonly NAME_DROPPER = new Cog(CogType.SELLBOT, "Name Dropper", 3)
    static readonly GLAD_HANDER = new Cog(CogType.SELLBOT, "Glad Hander", 4)
    static readonly MOVER_SHAKER = new Cog(CogType.SELLBOT, "Mover & Shaker", 5)
    static readonly TWO_FACE = new Cog(CogType.SELLBOT, "Two-Face", 6)
    static readonly THE_MINGLER = new Cog(CogType.SELLBOT, "The Mingler", 7)
    static readonly MR_HOLLYWOOD = new Cog(CogType.SELLBOT, "Mr. Hollywood", 8)

    static readonly SHORT_CHANGE = new Cog(CogType.CASHBOT, "Short Change", 1)
    static readonly PENNY_PINCHER = new Cog(CogType.CASHBOT, "Penny Pincher", 2)
    static readonly TIGHTWAD = new Cog(CogType.CASHBOT, "Tightwad", 3)
    static readonly BEAN_COUNTER = new Cog(CogType.CASHBOT, "Bean Counter", 4)
    static readonly NUMBER_CRUNCHER = new Cog(CogType.CASHBOT, "Number Cruncher", 5)
    static readonly MONEY_BAGS = new Cog(CogType.CASHBOT, "Money Bags", 6)
    static readonly LOAN_SHARK = new Cog(CogType.CASHBOT, "Loan Shark", 7)
    static readonly ROBBER_BARON = new Cog(CogType.CASHBOT, "Robber Baron", 8)

    static readonly BOTTOM_FEEDER = new Cog(CogType.LAWBOT, "Bottom Feeder", 1)
    static readonly BLOODSUCKER = new Cog(CogType.LAWBOT, "Bloodsucker", 2)
    static readonly DOUBLE_TALKER = new Cog(CogType.LAWBOT, "Double Talker", 3)
    static readonly AMBULANCE_CHASER = new Cog(CogType.LAWBOT, "Ambulance Chaser", 4)
    static readonly BACK_STABBER = new Cog(CogType.LAWBOT, "Back Stabber", 5)
    static readonly SPIN_DOCTOR = new Cog(CogType.LAWBOT, "Spin Doctor", 6)
    static readonly LEGAL_EAGLE = new Cog(CogType.LAWBOT, "Legal Eagle", 7)
    static readonly BIG_WIG = new Cog(CogType.LAWBOT, "Big Wig", 8)

    static readonly FLUNKY = new Cog(CogType.BOSSBOT, "Flunky", 1)
    static readonly PENCIL_PUSHER = new Cog(CogType.BOSSBOT, "Pencil Pusher", 2)
    static readonly YESMAN = new Cog(CogType.BOSSBOT, "Yesman", 3)
    static readonly MICROMANAGER = new Cog(CogType.BOSSBOT, "Micromanager", 4)
    static readonly DOWNSIZER = new Cog(CogType.BOSSBOT, "Downsizer", 5)
    static readonly HEAD_HUNTER = new Cog(CogType.BOSSBOT, "Head Hunter", 6)
    static readonly CORPORATE_RAIDER = new Cog(CogType.BOSSBOT, "Corporate Raider", 7)
    static readonly THE_BIG_CHEESE = new Cog(CogType.BOSSBOT, "The Big Cheese", 8);

    static findCog(name: string): Cog | null {
        switch (name) {
            case "Cold Caller": return Cog.COLD_CALLER
            case "Telemarketer": return Cog.TELEMARKETER
            case "Name Dropper": return Cog.NAME_DROPPER
            case "Glad Hander": return Cog.GLAD_HANDER
            case "Mover & Shaker": return Cog.MOVER_SHAKER
            case "Two-Face": return Cog.TWO_FACE
            case "The Mingler": return Cog.THE_MINGLER
            case "Mr. Hollywood": return Cog.MR_HOLLYWOOD

            case "Short Change": return Cog.SHORT_CHANGE
            case "Penny Pincher": return Cog.PENNY_PINCHER
            case "Tightwad": return Cog.TIGHTWAD
            case "Bean Counter": return Cog.BEAN_COUNTER
            case "Number Cruncher": return Cog.NUMBER_CRUNCHER
            case "Money Bags": return Cog.MONEY_BAGS
            case "Loan Shark": return Cog.LOAN_SHARK
            case "Robber Baron": return Cog.ROBBER_BARON

            case "Bottom Feeder": return Cog.BOTTOM_FEEDER
            case "Bloodsucker": return Cog.BLOODSUCKER
            case "Double Talker": return Cog.DOUBLE_TALKER
            case "Ambulance Chaser": return Cog.AMBULANCE_CHASER
            case "Back Stabber": return Cog.BACK_STABBER
            case "Spin Doctor": return Cog.SPIN_DOCTOR
            case "Legal Eagle": return Cog.LEGAL_EAGLE
            case "Big Wig": return Cog.BIG_WIG

            case "Flunky": return Cog.FLUNKY
            case "Pencil Pusher": return Cog.PENNY_PINCHER
            case "Yesman": return Cog.YESMAN
            case "Micromanager": return Cog.MICROMANAGER
            case "Downsizer": return Cog.DOWNSIZER
            case "Head Hunter": return Cog.HEAD_HUNTER
            case "Corporate Raider": return Cog.CORPORATE_RAIDER
            case "The Big Cheese": return Cog.THE_BIG_CHEESE
            default: return null
        }
    }

    private constructor(public readonly cogType: CogType, public readonly name: string, public readonly tier: number) { }
}