import "common/theme/theme.scss"

import Dimensions from "common/theme/Dimensions"
import FlowersGrid from "app/ui/tracking/flowers/FlowersGrid"
import Header from "common/theme/Header"
import React from 'react';
import UserRepo from "app/data/UserRepository"
import styled from "styled-components"

export default class Flowers extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			width: window.innerWidth,
			toon: UserRepo.getUser().getCurrentToon(),
			flowerShown: null,
			isToonFetchJobRunning: false
		}

		this.onFlowerChecked = this.onFishChecked.bind(this)
		this.onFlowerInfoClicked = this.onFishInfoClicked.bind(this)
	}

	componentDidMount() {
		window.addEventListener("resize", () => {
			this.setState(() => {
				return { width: window.innerWidth };
			});
		});

		this.startToonUpdateJob()
	}

	componentWillUnmount() {
		this.setState({ isToonFetchJobRunning: false })
	}

	async startToonUpdateJob() {
		this.setState({ isToonFetchJobRunning: true })

		do {
			await new Promise(resolve => setTimeout(resolve, 5000))
			this.setState({ toon: UserRepo.getUser().getCurrentToon() })

		} while (this.state.isToonFetchJobRunning);
	}

	render() {
		return (
			<StyledFlowers>
				<Header pageStack={[
					{ title: "Tracking", url: "/tracking" },
					{ title: "Flowers", url: "/tracking/flowers" },
				]} />

				<FlowersGrid />

			</StyledFlowers>
		)
	}


	onFishChecked(index, isCaught) {
		this.setState(state => {
			let toon = state.toon
			toon.setFish(index, isCaught)

			let user = UserRepo.getUser()

			user.getCurrentToon().setFish(index, isCaught)

			UserRepo.updateUser(user)

			return { toon: toon }
		})
	}

	onFishInfoClicked(species, index) {
		this.setState({ fishShown: { species: species, index: index } })
	}
}

const StyledFlowers = styled.div`
	width: calc(100% - ${Dimensions.getContentHorizontalPadding()} * 2);
	height: auto;
	padding: 0 ${Dimensions.getContentHorizontalPadding()};
	min-height: 100%;
	background-image: linear-gradient(#df399d, #9a1966);
`