import ToontaskGroup from "data-toontasks/ToontaskGroup";
import {CSSProperties} from "react";
import ToontaskState from "../ToontaskState";
import ScreenSize from "../../../common/ScreenSize";
import Card from "../../../common/theme/components/Card";
import {Box, Column, Row, Spacer} from "../../../core-anvil/Layouts";
import {BodyMedium, LabelMedium, TitleMedium} from "../../../app/theme/Typography";
import {CircularProgressbar} from "react-circular-progressbar";
import {useTheme} from "styled-components";
import StepsCard, {StepsCardStep} from "./steps/StepsCard";

type Props = {
    screenSize: ScreenSize,
    toontaskTitle: string,
    toontaskReward: string,
    toontaskState: ToontaskState,
    group: ToontaskGroup,
    toontaskProgress: number,
    toontaskProgressText: string,
    currentStep: number,
    currentStepText: string,
    steps: StepsCardStep[],
    onToontaskStarted: () => void,
    onToontaskDropped: () => void,
    onToontaskCompleted: () => void,
    onToontaskReset: () => void,
    onNextStepClick: () => void,
    onUndoStepClick: () => void,
    style?: CSSProperties
}

export default function ToontaskInfoCard(props: Props) {
    const theme = useTheme()

    let cardWidth = 0

    switch (props.screenSize) {
        case ScreenSize.Small:
            cardWidth = 320
            break
        case ScreenSize.Medium:
            cardWidth = 320
            break
        case ScreenSize.Large:
            cardWidth = 440
            break
    }

    return (
        <Column>
            <Card style={{
                ...props.style,
                backgroundColor: theme.colors.getToontaskGroupDarkColor(props.group),
                width: cardWidth,
                padding: "20px 0",
                zIndex: 1,
            }}>
                <Column style={{margin: "0 28px"}}>
                    <Row>
                        <Column>
                            <TitleMedium>{props.toontaskTitle}</TitleMedium>
                            <BodyMedium style={{fontWeight: 300, marginTop: 4}}>{props.toontaskReward}</BodyMedium>
                        </Column>
                        <Spacer style={{flex: 1}}/>
                        <ToontasksProgressCircle toontaskProgress={props.toontaskProgress}
                                                 toontaskProgressText={props.toontaskProgressText}/>
                    </Row>
                    <div style={{position: "relative"}}>
                        <InfoCardActionButtons
                            group={props.group}
                            toontaskState={props.toontaskState}
                            onToontaskStarted={props.onToontaskStarted}
                            onToontaskDropped={props.onToontaskDropped}
                            onToontaskCompleted={props.onToontaskCompleted}
                            onToontaskReset={props.onToontaskReset}
                            style={{
                                marginTop: 16,
                            }}
                        />
                    </div>
                    <StepsProgress
                        style={{marginTop: 20}}
                        toontaskGroupColor={theme.colors.getToontaskGroupColor(props.group)}
                        currentStep={props.currentStep}
                        maxSteps={props.steps.length}/>
                </Column>
            </Card>
            <StepsCard
                currentStepIndex={props.currentStep}
                currentStepText={props.currentStepText}
                group={props.group}
                steps={props.steps}
                onNextStepClick={props.onNextStepClick}
                onUndoStepClick={props.onUndoStepClick}
                style={{
                    width: cardWidth,
                    marginTop: -32,
                    backgroundColor: theme.colors.getToontaskGroupColor(props.group),
                }}/>
        </Column>
    )
}

type ToontasksProgressCircleProps = {
    toontaskProgress: number,
    toontaskProgressText: string
}

function ToontasksProgressCircle(props: ToontasksProgressCircleProps) {
    return (
        <Box style={{width: 80, height: 80}} alignment="center">
            <CircularProgressbar
                maxValue={1}
                value={props.toontaskProgress}
                styles={{
                    path: {
                        stroke: "white",
                        strokeLinecap: "round"
                    },

                    trail: {
                        stroke: "#0003"
                    },

                    text: {
                        fill: "white",
                        fontSize: "30px",
                        fontFamily: "Roboto"
                    }
                }}
            />
            <TitleMedium>{props.toontaskProgressText}</TitleMedium>
        </Box>
    )
}

type InfoCardActionButtonsProps = {
    group: ToontaskGroup,
    toontaskState: ToontaskState,
    onToontaskStarted: () => void,
    onToontaskDropped: () => void,
    onToontaskCompleted: () => void,
    onToontaskReset: () => void,
    style?: CSSProperties
}

function InfoCardActionButtons(props: InfoCardActionButtonsProps) {
    const theme = useTheme()

    const buttonColor = theme.colors.getToontaskGroupColor(props.group)

    switch (props.toontaskState) {
        case ToontaskState.NotStarted:
            return (
                <Row style={props.style}>
                    <StartToontaskButton onClick={() => {
                        props.onToontaskStarted()
                    }} style={{backgroundColor: buttonColor}}/>
                    <CompleteToontaskButton onClick={() => {
                        props.onToontaskCompleted()
                    }} style={{backgroundColor: buttonColor, marginLeft: 12}}/>
                </Row>
            )
        case ToontaskState.Started:
            return (
                <Row style={props.style}>
                    <DropToontaskButton onClick={() => {
                        props.onToontaskDropped()
                    }} style={{backgroundColor: buttonColor}}/>
                    <CompleteToontaskButton onClick={() => {
                        props.onToontaskCompleted()
                    }} style={{backgroundColor: buttonColor, marginLeft: 12}}/>
                </Row>
            )
        case ToontaskState.Completed:
            return (
                <ResetToontaskButton onClick={() => {
                    props.onToontaskReset()
                }} style={{...props.style, backgroundColor: buttonColor}}/>
            )

    }
}

type ImplementedActionButtonProps = {
    onClick?: () => void,
    style?: CSSProperties
}

function StartToontaskButton(props: ImplementedActionButtonProps) {
    return <ActionButton materialIcon="add" label="Start Task" onClick={props.onClick} style={props.style}/>
}

function CompleteToontaskButton(props: ImplementedActionButtonProps) {
    return <ActionButton materialIcon="done_all" label="Complete Task" onClick={props.onClick} style={props.style}/>
}

function DropToontaskButton(props: ImplementedActionButtonProps) {
    return <ActionButton materialIcon="close" label="Drop Task" onClick={props.onClick} style={props.style}/>
}

function ResetToontaskButton(props: ImplementedActionButtonProps) {
    return <ActionButton materialIcon="undo" label="Reset Task" onClick={props.onClick} style={props.style}/>
}

type ActionButtonProps = {
    materialIcon: string,
    label: string,
    onClick?: () => void,
    style?: CSSProperties
}

function ActionButton(props: ActionButtonProps) {
    return (
        <Card onClick={props.onClick} style={{...props.style, height: 30, width: "fit-content"}}>
            <Row verticalAlignment="center" style={{height: "100%", paddingLeft: 8, paddingRight: 12}}>
                <span className="material-icons" style={{fontSize: 20, color: "white"}}>{props.materialIcon}</span>
                <LabelMedium style={{marginLeft: 4, marginTop: 1}}>{props.label}</LabelMedium>
            </Row>
        </Card>
    )
}

type StepsProgressProps = {
    currentStep: number,
    maxSteps: number,
    toontaskGroupColor: string,
    style?: CSSProperties
}

function StepsProgress(props: StepsProgressProps) {
    let elements: JSX.Element[] = []

    for (let i = 0; i < props.maxSteps; i++) {
        elements.push(<StepsProgressDot isFilled={i === props.currentStep} backgroundColor={props.toontaskGroupColor}/>)
        if (i + 1 < props.maxSteps) {
            elements.push(<StepsProgressLine/>)
        }
    }

    return (
        <Row verticalAlignment="center" style={props.style}>
            {elements}
        </Row>
    )
}

type StepsProgressDotProps = {
    isFilled: boolean,
    backgroundColor: string
}

function StepsProgressDot(props: StepsProgressDotProps) {
    let innerDot = props.isFilled ?
        <div style={{
            width: 10,
            height: 10,
            backgroundColor: "black",
            opacity: 0.7,
            borderRadius: 10
        }}/> : null

    return (
        <Card style={{width: 24, height: 24, backgroundColor: props.backgroundColor}}>
            <Box style={{width: 24, height: 24}} alignment="center">
                {innerDot}
            </Box>
        </Card>
    )
}

function StepsProgressLine() {
    return (
        <Row style={{height: 5, flex: 1, backgroundColor: "black", opacity: 0.3}}/>
    )
}