import {Row, Spacer} from "../../core-anvil/Layouts";
import CogTypeCard from "./CogTypeCard";
import CogType from "../../app/entities/invasions/cogs/CogType";
import {CSSProperties} from "react";
import {CogTypeCardState} from "../CogSuitsScreenState";
import ScreenSize from "../../common/ScreenSize";

type Props = {
    screenSize: ScreenSize,
    bossbot: CogTypeCardState,
    lawbot: CogTypeCardState,
    cashbot: CogTypeCardState,
    sellbot: CogTypeCardState,
    onCogTypeClick: (cogType: CogType) => void,
    onPromoteClick: (cogType: CogType) => void,
    onDemoteClick: (cogType: CogType) => void
    style?: CSSProperties
}

export default function MediumCogSuitTypesGrid(props: Props) {
    return (
        <Row horizontalArrangement="center" style={{marginTop: 24, ...props.style}}>
            <CogTypeCard
                screenSize={props.screenSize}
                cogType={CogType.BOSSBOT}
                cogName={props.bossbot.cogName}
                progress={props.bossbot.progress}
                levelText={props.bossbot.levelText}
                onClick={() => props.onCogTypeClick(CogType.BOSSBOT)}
                onPromoteClick={() => props.onPromoteClick(CogType.BOSSBOT)}
                onDemoteClick={() => props.onDemoteClick(CogType.BOSSBOT)}
            />
            <Spacer style={{width: 20, margin: 0}} />
            <CogTypeCard
                screenSize={props.screenSize}
                cogType={CogType.LAWBOT}
                cogName={props.lawbot.cogName}
                progress={props.lawbot.progress}
                levelText={props.lawbot.levelText}
                onClick={() => props.onCogTypeClick(CogType.LAWBOT)}
                onPromoteClick={() => props.onPromoteClick(CogType.LAWBOT)}
                onDemoteClick={() => props.onDemoteClick(CogType.LAWBOT)}
            />
            <Spacer style={{width: 20, margin: 0}} />
            <CogTypeCard
                screenSize={props.screenSize}
                cogType={CogType.CASHBOT}
                cogName={props.cashbot.cogName}
                progress={props.cashbot.progress}
                levelText={props.cashbot.levelText}
                onClick={() => props.onCogTypeClick(CogType.CASHBOT)}
                onPromoteClick={() => props.onPromoteClick(CogType.CASHBOT)}
                onDemoteClick={() => props.onDemoteClick(CogType.CASHBOT)}
            />
            <Spacer style={{width: 20, margin: 0}} />
            <CogTypeCard
                screenSize={props.screenSize}
                cogType={CogType.SELLBOT}
                cogName={props.sellbot.cogName}
                progress={props.sellbot.progress}
                levelText={props.sellbot.levelText}
                onClick={() => props.onCogTypeClick(CogType.SELLBOT)}
                onPromoteClick={() => props.onPromoteClick(CogType.SELLBOT)}
                onDemoteClick={() => props.onDemoteClick(CogType.SELLBOT)}
            />
        </Row>
    )
}