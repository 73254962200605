import {injectable, Lifecycle, scoped} from "tsyringe";
import {BehaviorSubject} from "rxjs";
import CogSuitsScreenModel from "../CogSuitsScreenModel";
import GetCogSuitPointsData from "../../application-game/usecases/getcogsuitpointsdata/GetCogSuitPointsData";
import {PointsEntry} from "./CogSuitsPointsTableProps";
import CogType from "../../app/entities/invasions/cogs/CogType";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export default class CogSuitsPointsTableViewModel {

    state = new BehaviorSubject<CogSuitPointsTableState>(createEmptyState())

    private cogType = new BehaviorSubject<CogType | null>(null)

    constructor(private cogSuitsScreenModel: CogSuitsScreenModel,
                private getCogSuitPointsData: GetCogSuitPointsData) {
        this.refreshCogSuitPointsData()
        this.listenForScreenState()
    }

    onCogTypeSelected(cogType: CogType | null) {
        if (cogType !== this.cogType.value) {
            this.cogType.next(cogType)
        }
    }

    onDismiss() {
        this.cogSuitsScreenModel.hidePointsTableDialog()
    }

    private refreshCogSuitPointsData() {
        this.cogType.subscribe(async cogType => {
            if (cogType === null) return

            const result = await this.getCogSuitPointsData.invoke(cogType)

            const entries: PointsEntry[] = result.cogSuits.map(cogSuit => {
                return {
                    cogName: cogSuit.cogName,
                    cogLevel: cogSuit.cogLevel,
                    points: cogSuit.pointsToPromote.toString()
                }
            })

            this.state.next({
                ...this.state.value,
                dialogTitle: this.getDialogTitle(cogType),
                pointsEntries: entries,
                pointsLabel: this.getPointsLabel(cogType)
            })
        })
    }

    private listenForScreenState() {
        this.cogSuitsScreenModel.state.subscribe(s => {
            this.state.next({
                ...this.state.value,
                isVisible: s.isPointsTableDialogVisible
            })
        })
    }

    private getPointsLabel(cogType: CogType): string {
        switch (cogType) {
            case CogType.SELLBOT: return "Merits"
            case CogType.CASHBOT: return "Cogbucks"
            case CogType.LAWBOT: return "Jury Notices"
            case CogType.BOSSBOT: return "Stock Options"
            default: return ""
        }
    }

    private getDialogTitle(cogType: CogType): string {
        switch (cogType) {
            case CogType.SELLBOT: return "Sellbot Promotion Table"
            case CogType.CASHBOT: return "Cashbot Promotion Table"
            case CogType.LAWBOT: return "Lawbot Promotion Table"
            case CogType.BOSSBOT: return "Bossbot Promotion Table"
            default: return ""
        }
    }

}

export type CogSuitPointsTableState = {
    isVisible: boolean,
    dialogTitle: string,
    pointsEntries: PointsEntry[],
    pointsLabel: string
}

function createEmptyState(): CogSuitPointsTableState {
    return {
        isVisible: false,
        dialogTitle: "",
        pointsEntries: [],
        pointsLabel: ""
    }
}