import { Headline6 } from "app/theme/Typography";
import styled from "styled-components";

type Props = {}

export default function SOSCard(props: Props) {
    return (
        <StyledSOSCard>
            <StyledCardTitle>SOS cards</StyledCardTitle>

            <StyledArrow className="material-icons">keyboard_arrow_right</StyledArrow>

        </StyledSOSCard>
    )
}

const StyledSOSCard = styled.div`
    width: 180px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    background-color: ${props => props.theme.colors.sos};
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledCardTitle = styled(Headline6)`
    color: white;
    margin-left: 20px;
    margin-top: 1px;
`

const StyledArrow = styled.span`
    font-size: 24px;
    color: white;
    margin-left: auto;
    margin-right: 12px;
`