import styled from "styled-components";

type Props = {
    progress: number,
    max: number,
    style?: React.CSSProperties
}

export default function ProgressBar(props: Props) {
    return(
        <StyledProgressBar style={props.style}>
            <StyledFilledBar width={`${props.progress / props.max * 100}%`}/>
        </StyledProgressBar>
    )
}

const StyledProgressBar = styled.div`
    height: 4px;
    background-color: #ffffff33;
    border-radius: 100px;
    display: flex;
`

type StyledFilledBarProps = {
    width: string
}

const StyledFilledBar = styled.div<StyledFilledBarProps>`
    width: ${props => props.width};
    height: 100%;
    background-color: white;
    border-radius: 100px;
`