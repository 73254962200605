import { Subtitle1 } from "app/theme/Typography"
import styled from "styled-components"

type Props = {
    toontasksProgress: string
}

export default function TrackingCardToontasks(props: Props) {
    return <Section>
        <SectionTitle>Toontasks</SectionTitle>
        <Subtitle1>{props.toontasksProgress}</Subtitle1>
    </Section>
}

const Section = styled.div`
	margin-top: 18px;
`
const SectionTitle = styled.p`
	margin-bottom: -5px;
	font-family: Catamaran;
	text-align: center;
	font-size: 15px;
	font-weight: 300;
	color: white;
`
