import Card from "common/theme/components/Card";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";
import MediumToontaskSlot, { ToontaskSlotState } from "./MediumToontaskSlot";
import ToontaskGroup from "../../../../../data-toontasks/ToontaskGroup";
import {ToontasksOverviewDestination} from "../../../../../feat-toontasks/routes/ToontasksOverviewRoute";

type Props = {
    progressDecimal: number
    progressText: string,
    group: ToontaskGroup,
    groupText: string,
    topLeftSlot: ToontaskSlotState,
    topRightSlot: ToontaskSlotState,
    bottomLeftSlot: ToontaskSlotState,
    bottomRightSlot: ToontaskSlotState
}

export default function MediumToontasksCard(props: Props) {
    return (
        <StyledMediumToontasksCard>
            <Link to={ToontasksOverviewDestination.createRoute(props.group)} style={{ textDecoration: "none" }}>
                <ActivityHeader
                    width={300}
                    progressDecimal={props.progressDecimal}
                    progressText={props.progressText}
                    title={"Toontasks"}
                    subtitle={`You are currently in ${props.groupText}`}
                    backgroundColor={"#370F64"}
                />
            </Link>
            <StyledToontaskSlotGrid>
                <MediumToontaskSlot uiState={props.topLeftSlot} />
                <MediumToontaskSlot uiState={props.topRightSlot} />
                <MediumToontaskSlot uiState={props.bottomLeftSlot} />
                <MediumToontaskSlot uiState={props.bottomRightSlot} />
            </StyledToontaskSlotGrid>
        </StyledMediumToontasksCard>
    )
}

const StyledMediumToontasksCard = styled(Card)`
    width: 340px;
    height: 350px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.colors.toontasksDark};
    border-radius: ${props => props.theme.dimens.cardCornerRadius}
`

const StyledToontaskSlotGrid = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
`