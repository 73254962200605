import bossbot_icon from "common/resources/images/ic_cog_bossbot_icon.png"
import cashbot_icon from "common/resources/images/ic_cog_cashbot_icon.png"
import lawbot_icon from "common/resources/images/ic_cog_lawbot_icon.png"
import sellbot_icon from "common/resources/images/ic_cog_sellbot_icon.png"

export default class CogType {
    static readonly SELLBOT = new CogType(sellbot_icon)
    static readonly CASHBOT = new CogType(cashbot_icon)
    static readonly LAWBOT = new CogType(lawbot_icon)
    static readonly BOSSBOT = new CogType(bossbot_icon)

    private constructor(public readonly icon: string) { }
}
