import React from "react";

import SmallTrackingCardToontasks from "./SmallTrackingCardToontasks";
import bossbot_icon from "app/res/icons/ic_cogtype_bossbot.png"
import styled from "styled-components";

type Props = {
    toontasksProgress: string
}

type State = {
    toontasksProgress: string
}

class SmallTrackingCard extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            toontasksProgress: props.toontasksProgress
        }


    }

    componentDidUpdate(lastProps: Props) {
        if (lastProps !== this.props) {
            this.setState({
                toontasksProgress: this.props.toontasksProgress
            })
        }
    }

    render() {
        return (

                <TrackingContent>
                    <SmallTrackingCardToontasks toontasksProgress={this.props.toontasksProgress} />

                    {/* Cog Suits */}
                    <Section>
                        <SectionTitle>Cog Suits</SectionTitle>
                        <div>
                            <LevelText style={{ display: "inline-block" }}>LVL</LevelText>
                            <ProgressText style={{ display: "inline-block", marginLeft: 4 }}>7</ProgressText>
                            <CogIcon src={bossbot_icon} />
                        </div>
                    </Section>

                    {/* Fishing */}
                    <Section>
                        <SectionTitle>Fishing</SectionTitle>
                        <div style={{ display: "flex" }}>
                            <ProgressText style={{ marginLeft: 4 }}>23</ProgressText>
                            <LevelText style={{ marginTop: 16, marginLeft: 4 }}>/ 70</LevelText>
                        </div>
                    </Section>
                </TrackingContent>

        )
    }
}

const TrackingContent = styled.div`
	height: auto;
	width: auto;
	margin-right: 8px;
    margin-bottom: 16px;
	display: flex;
	justify-content: space-evenly;
`
 
const Section = styled.div`
	margin-top: 18px;
`

const SectionTitle = styled.p`
	margin-bottom: -5px;
	font-family: Catamaran;
	text-align: center;
	font-size: 15px;
	font-weight: 300;
	color: white;
`

const ProgressText = styled.p`
	font-size: 27px;
	font-family: Catamaran;
	font-weight: 800;
	color: white;
	text-align: center;
`

const LevelText = styled.p`
	font-size: 13px;
	font-family: Catamaran;
	font-weight: 300;
	color: white;
`

const CogIcon = styled.img`
	width: 20px;
	height: 20px;
	display: "inline-block";
	margin-left: 2px;
	margin-bottom: -3px;
`

export default SmallTrackingCard;