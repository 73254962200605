import "./neighbourhood.scss";

import {Toontask} from "app/data/game/ToontasksData";
import LegacyToon from "app/data/user/LegacyToon";
import UserRepository from "app/data/UserRepository";
import {ScreenSize} from "common/ScreenSize";
import NeighbourhoodResources, {NEIGHBOURHOOD_COLOURS} from "common/theme/NeighbourhoodResources";
import ToontaskGroup from "data-toontasks/ToontaskGroup";
import NeighborhoodViewModel, {
    NeighborhoodScreenState,
    ToontaskListState
} from "feat-toontasks/neighbourhood/NeighborhoodViewModel";
import React, {useEffect, useRef, useState} from "react";
import 'react-circular-progressbar/dist/styles.css';
import {Link, useParams} from "react-router-dom";
import {container} from "tsyringe";
import NeighborhoodHeading from "./heading/NeighborhoodHeading";
import NeighborhoodToontasksList from "./toontasks/NeighborhoodToontasksList";
import GameDataRepository from "app/data/GameDataRepository";
import {ToontasksOverviewDestination} from "../routes/ToontasksOverviewRoute";
import ttc_playground_image from "../../common/resources/images/playground_ttc.png";
import dd_playground_image from "../../common/resources/images/playground_dd.png";
import dg_playground_image from "../../common/resources/images/playground_dg.png";
import mml_playground_image from "../../common/resources/images/playground_mml.png";
import brg_playground_image from "../../common/resources/images/playground_brg.png";
import ddl_playground_image from "../../common/resources/images/playground_ddl.png";
import lawbot_playground_image from "../../common/resources/images/playground_law.png";
import bossbot_playground_image from "../../common/resources/images/playground_boss.png";
import NavigationBanner from "../../core-theme/components/navigation/NavigationBanner";
import theme from "../../app/theme/Theme";
import {Box, Column} from "../../core-anvil/Layouts";

interface NeighbourhoodState {
    toon: LegacyToon
    toontaskShown: Toontask | null,
    toontasks: ToontaskListState,
    toontaskGroup: ToontaskGroup
}

export type Props = {
    screenSize: ScreenSize,
    onCompletedToontaskClick: (toontaskId: number) => void,
    onToontaskGroupClick: (group: ToontaskGroup) => void,
    onBackClick: () => void
}

export default function NeighborhoodScreen(props: Props) {
    const {group}: any = useParams()

    const toontaskGroup = ToontasksOverviewDestination.getToontaskGroup(group)

    const viewModel = useRef(container.resolve(NeighborhoodViewModel))

    viewModel.current.onToontaskGroupSelected(toontaskGroup)

    const [uiState, setUIState] = useState<NeighborhoodScreenState>(viewModel.current.uiState.value)

    useEffect(() => {
        const sub = viewModel.current.uiState.subscribe(setUIState)
        return () => sub.unsubscribe()
    }, [])

    return (
        <NeighborhoodScreenContent
            screenSize={props.screenSize}
            toontasks={uiState.toontasks}
            toontaskGroup={toontaskGroup}
            isNotStartedToontasksVisible={uiState.isNotStartedToontasksVisible}
            onCompletedToontaskClick={props.onCompletedToontaskClick}
            onToontaskGroupClick={props.onToontaskGroupClick}
            onBackClick={props.onBackClick}
        />
    )
}

type ContentProps = {
    screenSize: ScreenSize,
    toontasks: ToontaskListState,
    toontaskGroup: ToontaskGroup,
    isNotStartedToontasksVisible: boolean,
    onCompletedToontaskClick: (toontaskId: number) => void,
    onToontaskGroupClick: (group: ToontaskGroup) => void,
    onBackClick: () => void
}

export function NeighborhoodScreenContent(props: ContentProps) {
    return (
        <Neighbourhood {...props} />
    )
}

class Neighbourhood extends React.Component<ContentProps, NeighbourhoodState> {
    constructor(props: ContentProps) {
        super(props)

        this.state = {
            toon: UserRepository.getUser().getCurrentToon(),
            toontaskShown: null,
            toontaskGroup: props.toontaskGroup,
            toontasks: props.toontasks
        }
    }

    componentDidUpdate(prevProps: Readonly<ContentProps>, _prevState: Readonly<NeighbourhoodState>, _snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({})
        }
    }

    componentDidMount() {
        UserRepository.observeUser().subscribe(user => {
            this.setState({toon: user.getCurrentToon()})
        })
    }

    render() {
        const backgroundImage = getBackgroundImage(this.props.toontaskGroup)
        const tintColour = getTintColour(this.props.toontaskGroup)

        const neighborhoodToontasksListWidth =
            this.props.screenSize === ScreenSize.Small ? 320 :
                this.props.screenSize === ScreenSize.Medium ? 630 :
                    1000

        return (
            <Box alignment="topCenter" style={{
                width: "100%",
                height: "100%",
                backgroundColor: tintColour
            }}>
                <Box style={{width: "100%", height: "100%"}}>
                    <img style={{width: "100%", height: "100%"}} alt="" src={backgroundImage}/>
                    <div style={{width: "100%", height: "100%", backgroundColor: tintColour}}/>
                </Box>

                <Column style={{
                    width: "100%",
                    height: "100%",
                    overflow: "scroll"
                }}>
                    <Column horizontalAlignment="center" style={{
                        width: "100%"
                    }}>
                        <NavigationBanner
                            screenSize={this.props.screenSize}
                            backgroundColor={getToontaskGroupDarkColor(this.props.toontaskGroup)}
                            icon={"check_box"}
                            featureName={"Tracking"}
                            screenName={"Toontasks"}
                            previousScreenName={"Tracking"}
                            onBackClick={this.props.onBackClick}/>

                        <NeighborhoodHeading
                            screenSize={this.props.screenSize}
                            group={this.props.toontaskGroup}
                            onToontaskGroupClick={this.props.onToontaskGroupClick}
                            style={{marginTop: 36}}
                        />

                        <NeighborhoodToontasksList
                            screenSize={this.props.screenSize}
                            toontaskGroup={this.props.toontaskGroup}
                            toontasks={this.props.toontasks}
                            isNotStartedToontasksVisible={this.props.isNotStartedToontasksVisible}
                            onNotStartedToontaskClick={(toontaskId) => this.setState({toontaskShown: GameDataRepository.getToontasksData().getToontaskById(toontaskId)})}
                            onCompletedToontaskClick={this.props.onCompletedToontaskClick}
                            style={{width: neighborhoodToontasksListWidth}}
                        />
                        {this.renderToontaskNotStartedDialog()}
                    </Column>
                </Column>
            </Box>
        )
    }

    renderToontaskNotStartedDialog() {
        if (this.state.toontaskShown == null) return null

        const toontask = this.state.toontaskShown

        const primaryColour = NeighbourhoodResources.getPrimaryColour("ttc")

        return (
            <div className="neighbourhood-page__toontask-dialog-container">
                <div className="neighbourhood-page__toontask-dialog-background" onClick={() => {
                    this.setState({toontaskShown: null})
                }}/>
                <div className="neighbourhood-page__toontask-dialog" style={{backgroundColor: primaryColour}}>
                    <p className="neighbourhood-page__toontask-dialog-title headline4">{toontask.title}</p>
                    <p className="neighbourhood-page__toontask-dialog-reward subtitle2">{toontask.reward}</p>

                    <div className="neighbourhood-page__toontask-dialog-buttons-container">
                        <Link to={`/tracking/toontasks/toontask/${toontask.id}`} style={{textDecoration: "none"}}>
                            <div>
                                <div className="neighbourhood-page__toontask-dialog-button"
                                     onClick={() => this.onStartToontaskClicked()}>
                                    <p className="neighbourhood-page__toontask-dialog-button-label subtitle1">Start
                                        Toontask</p>
                                </div>
                                <p className="neighbourhood-page__toontask-dialog-button-hint body1">Assign to a
                                    Toontask slot</p>
                            </div>
                        </Link>

                        <div>
                            <div className="neighbourhood-page__toontask-dialog-button"
                                 onClick={() => this.onFinishToontaskClicked()}>
                                <p className="neighbourhood-page__toontask-dialog-button-label subtitle1">Finish
                                    Toontask</p>
                            </div>
                            <p className="neighbourhood-page__toontask-dialog-button-hint body1">Mark as already
                                finished</p>
                        </div>
                        <Link to={`/tracking/toontasks/toontask/${toontask.id}`} style={{textDecoration: "none"}}>
                            <div>
                                <div className="neighbourhood-page__toontask-dialog-button">
                                    <p className="neighbourhood-page__toontask-dialog-button-label subtitle1">Task
                                        Info</p>
                                </div>
                                <p className="neighbourhood-page__toontask-dialog-button-hint body1">Check steps and
                                    progress</p>
                            </div>
                        </Link>
                    </div>
                    <span className="material-icons" onClick={() => {
                        this.setState({toontaskShown: null})
                    }}
                          style={{
                              position: "absolute",
                              top: 14,
                              left: 14,
                              padding: 4,
                              color: "white",
                              cursor: "pointer"
                          }}>close</span>
                </div>
            </div>
        )
    }

    onStartToontaskClicked() {
        const toontask = this.state.toontaskShown
        if (toontask == null) return

        const id = toontask.id

        let user = UserRepository.getUser()
        let toon = user.getCurrentToon()
        toon.assignToontask(id)
        UserRepository.updateUser(user)

        this.setState({toon: toon, toontaskShown: null})
    }

    onFinishToontaskClicked() {
        const toontask = this.state.toontaskShown
        if (toontask == null) return

        const id = toontask.id

        let user = UserRepository.getUser()
        let toon = user.getCurrentToon()
        toon.setToontaskCompleted(id, true)
        UserRepository.updateUser(user)

        this.setState({toon: toon, toontaskShown: null})
    }
}

function getBackgroundImage(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return ttc_playground_image

        case ToontaskGroup.DonaldsDock:
            return dd_playground_image

        case ToontaskGroup.DaisyGardens:
            return dg_playground_image

        case ToontaskGroup.MinniesMelodyland:
            return mml_playground_image

        case ToontaskGroup.TheBrrrgh:
            return brg_playground_image

        case ToontaskGroup.DonaldsDreamland:
            return ddl_playground_image

        case ToontaskGroup.Lawbot:
            return lawbot_playground_image

        case ToontaskGroup.Bossbot:
            return bossbot_playground_image
    }
}

function getTintColour(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return NEIGHBOURHOOD_COLOURS.ttc.tint

        case ToontaskGroup.DonaldsDock:
            return NEIGHBOURHOOD_COLOURS.dd.tint

        case ToontaskGroup.DaisyGardens:
            return NEIGHBOURHOOD_COLOURS.dg.tint

        case ToontaskGroup.MinniesMelodyland:
            return NEIGHBOURHOOD_COLOURS.mml.tint

        case ToontaskGroup.TheBrrrgh:
            return NEIGHBOURHOOD_COLOURS.brg.tint

        case ToontaskGroup.DonaldsDreamland:
            return NEIGHBOURHOOD_COLOURS.ddl.tint

        case ToontaskGroup.Lawbot:
            return NEIGHBOURHOOD_COLOURS.lawbot.tint

        case ToontaskGroup.Bossbot:
            return NEIGHBOURHOOD_COLOURS.bossbot.tint
    }
}

function getToontaskGroupDarkColor(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return theme.colors.toontownCentralDark
        case ToontaskGroup.DonaldsDock:
            return theme.colors.donaldsDockDark
        case ToontaskGroup.DaisyGardens:
            return theme.colors.daisyGardensDark
        case ToontaskGroup.MinniesMelodyland:
            return theme.colors.minniesMelodylandDark
        case ToontaskGroup.TheBrrrgh:
            return theme.colors.thebrrghDark
        case ToontaskGroup.DonaldsDreamland:
            return theme.colors.donaldsDreamlandDark
        case ToontaskGroup.Lawbot:
            return theme.colors.lawbotDark
        case ToontaskGroup.Bossbot:
            return theme.colors.bossbotDark
    }
}