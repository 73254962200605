import firebase from "firebase/app";
import "firebase/auth"
import { BehaviorSubject } from "rxjs";
import AuthManager from "./AuthManager";

export default class FirebaseAuthManager implements AuthManager {
    userId = new BehaviorSubject<string | null>(this.getUserId())

    constructor() {
        firebase.auth().onAuthStateChanged(user => {
            this.userId.next(user?.uid ?? null)
        })
    }

    getUserId(): string | null {
        return firebase.auth().currentUser?.uid ?? null
    }
    isSignedIn(): boolean {
        return this.getUserId() !== null
    }
    getEmail(): string | null {
        return firebase.auth().currentUser?.email ?? null
    }
}