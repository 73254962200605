import React from "react"
import styled from "styled-components"

import UserRepository from "app/data/UserRepository"
import LegacyToon from "app/data/user/LegacyToon"

import TrophiesGrid from "app/ui/tracking/racing/TrophiesGrid"

import LaffProgress from "app/views/laff-progress"

import Header from "common/theme/Header"
import Dimensions from "common/theme/Dimensions"

interface State {
    toon: LegacyToon
}

export default class Racing extends React.Component<Readonly<{}>, State> {

    constructor(props: Readonly<{}>) {
        super(props)

        this.state = {
            toon: UserRepository.getUser().getCurrentToon()
        }
    }

    componentDidMount() {
        UserRepository.observeUser().subscribe(user => {
            this.setState({
                toon: user.getCurrentToon()
            })
        })
    }

    render(): JSX.Element {
        const toon = this.state.toon

        return (
            <StyledRacing>
                <Header pageStack={[
                    { title: "Tracking", url: "/tracking" },
                    { title: "Racing", url: "/tracking/racing" }
                ]} />

                <LaffContainer>
                    <LaffProgress progress={toon.getRacingTrophiesUnlockedCount()} maxProgress={30} laffMarkers={[10, 20, 30]} unfilledColour="#999" />
                </LaffContainer>

                <TrophiesGrid toon={toon} />
            </StyledRacing>
        )
    }

}

const StyledRacing = styled.div`
    width: calc(100% - ${Dimensions.getContentHorizontalPadding()} * 2);
    height: fit-content;
    min-height: 100%;
    padding: 0 ${Dimensions.getContentHorizontalPadding()};
    padding-bottom: 24px;
    background-color: #666;
`

const LaffContainer = styled.div`
    width: 500px;
    max-width: 100%;
    height: 80px;
    position: relative;
    margin: 24px auto;
    text-align: center;
`