import ScreenSize from "../../common/ScreenSize";

export default function useScreenSize(): ScreenSize {
    if (window.innerWidth >= ScreenSize.Large) {
        return ScreenSize.Large
    }

    if (window.innerWidth >= ScreenSize.Medium) {
        return ScreenSize.Medium
    }

    return ScreenSize.Small
}