import LegacyToon from "app/data/user/LegacyToon"
import UserRepo from "app/data/UserRepository"
import UserRepository from "app/data/users/UserRepository"
import "common/theme/theme.scss"
import React from 'react'
import {Link, useHistory} from "react-router-dom"
import GetAssignedToontasks from "feat-tracking/application/getAssignedToontasks/GetAssignedToontasks"
import CogSuitProgressEvaluator from "feat-tracking/entities/cogsuits/CogSuitProgressEvaluator"
import CogSuitsCard from "./cogsuits/CogSuitsCard"
import FishingCard from "./fishing/FishingCard"
import FlowersCard from "./flowers/FlowersCard"
import GolfCard from "./golf/GolfCard"
import RacingCard from "./racing/RacingCard"
import SOSCard from "./sos/SOSCard"
import ToontasksCard from "./toontasks/ToontasksCard"
import "./tracking.scss"
import TrackingUIState from "./TrackingUIState"
import TrackingViewModel from "./TrackingViewModel"
import {ScreenSize} from "common/ScreenSize"
import ToontaskGroup from "../../../data-toontasks/ToontaskGroup";
import background_tracking from "feat-tracking/assets/images/background_tracking.svg"
import {Box, Column} from "../../../core-anvil/Layouts";
import {FishingOverviewDestination} from "../../../feat-fishing/FishingNavigation";
import NavigationBanner from "../../../core-theme/components/navigation/NavigationBanner";
import GetTrackingProgressUseCase
    from "../../../application-users/usecases/getTrackingProgress/GetTrackingProgressUseCase";

export const TrackingDestination = {
    route: "/feat-tracking",
    createRoute: () => "/feat-tracking"
}

type Props = {
    screenSize: ScreenSize,
    setPage: any,
    viewModel: TrackingViewModel,
    userRepository: UserRepository,
    cogSuitsProgressEvaluator: CogSuitProgressEvaluator,
    getTrackingProgress: GetTrackingProgressUseCase,
    getAssignedToontasks: GetAssignedToontasks
}

type State = {
    setPage: any,
    toon: LegacyToon | null,
    uiState: TrackingUIState
}

export default function TrackingScreen(props: Props) {

    return <Tracking screenSize={props.screenSize}
                     setPage={props.setPage}
                     viewModel={props.viewModel}
                     userRepository={props.userRepository}
                     cogSuitsProgressEvaluator={props.cogSuitsProgressEvaluator}
                     getTrackingProgress={props.getTrackingProgress}
                     getAssignedToontasks={props.getAssignedToontasks}/>
}

class Tracking extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            setPage: props.setPage,
            /** @type {LegacyToon} */
            toon: null,
            uiState: this.props.viewModel.uiState.value
        }

        this.props.viewModel.uiState.subscribe(newValue => {
            this.setState({uiState: newValue})
        })
    }

    componentDidMount() {
        this.setState({toon: UserRepo.getUser().getCurrentToon()})
    }

    render() {
        if (this.state.toon === null) {
            return null
        }

        let backgroundSize = 0
        switch (this.props.screenSize) {
            case ScreenSize.Small:
                backgroundSize = 800
                break;
            case ScreenSize.Medium:
                backgroundSize = 1500
                break;
            case ScreenSize.Large:
                backgroundSize = 2000
                break;
        }

        return (
            <Box style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#27007D",
            }}>

                <Box style={{
                    width: "100%",
                    height: "100%",
                    marginLeft: `calc(-${backgroundSize} / 2)`,
                    backgroundRepeat: "repeat",
                    backgroundImage: `url(${background_tracking})`,
                    position: "fixed"
                }}/>

                <Column horizontalAlignment="center" style={{
                    width: "100%",
                    height: "100%",
                    overflowY: "scroll",
                }}>

                    <Column horizontalAlignment="center" style={{
                        width: "100%"
                    }}>

                        <NavBanner screenSize={this.props.screenSize}/>

                        <div id="tracking-grid" style={{marginTop: 24}}>
                            {/*Toontasks card*/}
                            <ToontasksCard
                                screenSize={this.props.screenSize}
                                progressDecimal={this.state.uiState.toontasks.progress}
                                progressText={this.state.uiState.toontasks.progressText}
                                group={ToontaskGroup.ToontownCentral}
                                topLeftSlot={this.state.uiState.toontasks.slots.topLeft}
                                topRightSlot={this.state.uiState.toontasks.slots.topRight}
                                bottomLeftSlot={this.state.uiState.toontasks.slots.bottomLeft}
                                bottomRightSlot={this.state.uiState.toontasks.slots.bottomRight}
                                groupText={this.state.uiState.toontasks.groupText}
                            />

                            {/*Cog Suits card*/}
                            <CogSuitsCard
                                screenSize={this.props.screenSize}
                                progressDecimal={this.state.uiState.cogSuits.progress}
                                progressText={this.state.uiState.cogSuits.progressText}
                                suitsUnlocked={this.state.uiState.cogSuits.cogSuitsUnlocked}
                                bossbot={this.state.uiState.cogSuits.bossbot}
                                lawbot={this.state.uiState.cogSuits.lawbot}
                                cashbot={this.state.uiState.cogSuits.cashbot}
                                sellbot={this.state.uiState.cogSuits.sellbot}
                                onPromoteClick={cogType => this.props.viewModel.onPromoteClick(cogType)}
                                onDemoteClick={cogType => this.props.viewModel.onDemoteClick(cogType)}
                            />

                            {/*Fishing card*/}
                            <Link to={FishingOverviewDestination.createRoute()} style={{textDecoration: "none"}}>
                                <FishingCard
                                    screenSize={this.props.screenSize}
                                    progressDecimal={this.state.uiState.fishing.progress}
                                    progressPercentText={this.state.uiState.fishing.progressPercentText}
                                    progressCountText={this.state.uiState.fishing.progressCountText}
                                    subtitleText={this.state.uiState.fishing.subtitleText}
                                    maxText={this.state.uiState.fishing.maxCount}
                                    extraDataLabel=""
                                    extraDataText=""
                                    trophiesText={this.state.uiState.fishing.trophiesText}
                                    nextLaffProgress={this.state.uiState.fishing.nextLaffAtProgress}
                                />
                            </Link>

                            {/*Flowers card*/}
                            <Link to="/tracking/flowers" style={{textDecoration: "none"}}>
                                <FlowersCard
                                    screenSize={this.props.screenSize}
                                    progressDecimal={this.state.uiState.flowers.progress}
                                    progressPercentText={this.state.uiState.flowers.progressPercentText}
                                    progressCountText={this.state.uiState.flowers.progressCountText}
                                    maxText={this.state.uiState.flowers.maxCount}
                                    nextLaffProgress={this.state.uiState.flowers.nextLaffAtProgress}
                                    subtitleText={this.state.uiState.flowers.subtitleText}
                                    extraDataLabel=""
                                    extraDataText={this.state.uiState.flowers.shovelText}
                                    trophiesText={this.state.uiState.flowers.trophiesText}
                                />
                            </Link>

                            {/*Racing card*/}
                            <Link to="/tracking/racing" style={{textDecoration: "none"}}>
                                <RacingCard
                                    progressDecimal={this.state.uiState.racing.progress}
                                    progressPercentText={this.state.uiState.racing.progressText}
                                    subtitleText={this.state.uiState.racing.subtitleText}
                                    screenSize={this.props.screenSize}
                                    progressCountText={this.state.uiState.racing.trophiesText}
                                    maxText={this.state.uiState.racing.maxCountText}
                                    cupsText={this.state.uiState.racing.cupsText}
                                    trophiesText={this.state.uiState.racing.trophiesText}
                                    nextLaffProgress={this.state.uiState.racing.nextLaffAtProgress}/>
                            </Link>

                            {/*Golf card*/}
                            <Link to="/tracking/golf" style={{textDecoration: "none"}}>
                                <GolfCard
                                    screenSize={this.props.screenSize}
                                    progressDecimal={this.state.uiState.golf.progress}
                                    progressPercentText={this.state.uiState.golf.progressPercentText}
                                    progressCountText={this.state.uiState.golf.progressCountText}
                                    maxText={this.state.uiState.golf.maxText}
                                    nextLaffProgress={this.state.uiState.golf.nextLaffAtProgress}
                                    cupsText={this.state.uiState.golf.cupsText}
                                    trophiesText={this.state.uiState.golf.trophiesText}
                                    subtitleText={this.state.uiState.golf.subtitleText}
                                />
                            </Link>
                        </div>
                        <div style={{marginTop: 20}}/>
                        <SOSCard/>
                    </Column>
                </Column>
            </Box>
        )
    }
}

type NavBannerProps = {
    screenSize: ScreenSize
}

function NavBanner(props: NavBannerProps) {
    const history = useHistory()
    return <NavigationBanner screenSize={props.screenSize}
                             backgroundColor="#6200ee"
                             icon="check_box"
                             featureName="Tracking"
                             screenName="Overview"
                             previousScreenName="Home"
                             onBackClick={() => history.push("/home")}
    />
}