import {GetFacilitiesResult} from "./GetFacilitiesResult";
import {injectable} from "tsyringe";
import FacilitiesRepository from "../../../data-cogsuits/FacilitiesRepository";
import CogType from "../../../app/entities/invasions/cogs/CogType";

@injectable()
export default class GetFacilities {

    constructor(private facilitiesRepository: FacilitiesRepository) {
    }

    invoke(): GetFacilitiesResult {
        return {
            bossbot: this.facilitiesRepository.getFacilities().filter(facility => facility.cogType === CogType.BOSSBOT),
            lawbot: this.facilitiesRepository.getFacilities().filter(facility => facility.cogType === CogType.LAWBOT),
            cashbot: this.facilitiesRepository.getFacilities().filter(facility => facility.cogType === CogType.CASHBOT),
            sellbot: this.facilitiesRepository.getFacilities().filter(facility => facility.cogType === CogType.SELLBOT),
        }
    }

}