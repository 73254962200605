import GameDataRepository from "app/data/GameDataRepository";
import CogType from "app/entities/invasions/cogs/CogType";
import CogSuit from "feat-tracking/entities/cogsuits/CogSuit";
import CogSuitsRepository from "./CogSuitsRepository";
import {injectable} from "tsyringe";

@injectable()
export default class TTRCogSuitsRepository implements CogSuitsRepository {
    async getCogSuit(cogType: CogType, index: number): Promise<CogSuit> {
        const cogSuits = GameDataRepository.getCogSuitsData()
        let cogTypeKey = ""
        switch (cogType) {
            case CogType.BOSSBOT:
                cogTypeKey = "bossbot"
                break
            case CogType.LAWBOT:
                cogTypeKey = "lawbot"
                break
            case CogType.CASHBOT:
                cogTypeKey = "cashbot"
                break
            case CogType.SELLBOT:
                cogTypeKey = "sellbot"
                break
        }

        const cogSuit = cogSuits.getCogSuit(cogTypeKey, index)

        return {
            name: cogSuit.name,
            level: cogSuit.level,
            index: index,
            pointsForPromo: cogSuit.points
        }
    }

    async getCogSuits(cogType: CogType): Promise<CogSuit[]> {
        const cogSuitsData = GameDataRepository.getCogSuitsData()
        let cogTypeKey = ""
        switch (cogType) {
            case CogType.BOSSBOT:
                cogTypeKey = "bossbot"
                break
            case CogType.LAWBOT:
                cogTypeKey = "lawbot"
                break
            case CogType.CASHBOT:
                cogTypeKey = "cashbot"
                break
            case CogType.SELLBOT:
                cogTypeKey = "sellbot"
                break
            default: throw Error()
        }

        let cogSuits: CogSuit[] = []

        for(let i = 1; i < 79; i++) {
            const data = cogSuitsData.getCogSuit(cogTypeKey, i)
            cogSuits.push({
                index: i,
                name: data.name,
                level: data.level,
                pointsForPromo: data.points,
            })
        }

        return cogSuits
    }
}