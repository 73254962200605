import { InvasionsResponseItem } from "./api/InvasionsResponse";

import { BehaviorSubject } from "rxjs";
import Invasion from "app/entities/invasions/Invasions";
import InvasionsAPI from "./api/InvasionsAPI";
import InvasionsRepository from "./InvasionsRepository";

const REFRESH_INTERVAL = 60 * 1000

export default class TTRInvasionsRepository implements InvasionsRepository {
    #api: InvasionsAPI
    #isRunning: Boolean = false

    invasions: BehaviorSubject<Invasion[]> = new BehaviorSubject<Invasion[]>([])

    constructor(api: InvasionsAPI) {
        this.#api = api
    }

    start() {
        if (!this.#isRunning) {
            this.#isRunning = true
            this.startFetchJob()
        }
    }

    private startFetchJob() {
        this.refreshInvasions()
        setInterval(this.refreshInvasions, REFRESH_INTERVAL)
    }

    private async refreshInvasions() {
        const response = await this.#api.getInvasions()
        let invasions: Invasion[] = []
        response.forEach(invasion => {
            invasions.push(this.convertToInvasion(invasion))
        })

        this.invasions.next(invasions)
    }

    private convertToInvasion(responseInvasion: InvasionsResponseItem) {
        return {
            cog: responseInvasion.cog,
            district: responseInvasion.district,
            startTime: responseInvasion.startTime,
            finishTime: responseInvasion.estimatedFinish
        }
    }
}