import ToontaskGroup from "data-toontasks/ToontaskGroup";
import {BehaviorSubject, Subscription} from "rxjs";
import {ToontaskInfoScreenState} from "./ToontaskInfoScreen";
import ToontaskState from "./ToontaskState";
import type GetToontask from "../../application-game/usecases/getToontask/GetToontask";
import {GetToontaskResult} from "../../application-game/usecases/getToontask/GetToontask";
import {inject, injectable} from "tsyringe";
import StartToontask from "application-users/usecases/startToontask/StartToontask";
import type GetToontasksProgress from "../../application-users/usecases/getToontasksProgress/GetToontasksProgress";
import {GetToontasksProgressResult} from "../../application-users/usecases/getToontasksProgress/GetToontasksProgress";
import CompleteToontask from "../../application-users/usecases/completeToontask/CompleteToontask";
import DropToontask from "../../application-users/usecases/dropToontask/DropToontask";
import ProgressToontaskStep from "../../application-users/usecases/progressToontaskStep/ProgressToontaskStep";
import {StepsCardStep} from "./info/steps/StepsCard";
import {StepsPathStep} from "./steps/ToontaskStepsPath";
import UndoToontaskStep from "../../application-users/usecases/undoToontaskStep/UndoToontaskStep";
import ResetToontask from "../../application-users/usecases/resetToontask/ResetToontask";

@injectable()
export default class ToontaskInfoViewModel {

    private getToontasksProgressSubscription: Subscription | null = null

    constructor(@inject("GetToontask") private getToontask: GetToontask,
                @inject("GetToontasksProgress") private getToontasksProgress: GetToontasksProgress,
                private startToontask: StartToontask,
                private completeToontask: CompleteToontask,
                private dropToontask: DropToontask,
                private progressToontaskStep: ProgressToontaskStep,
                private undoToontaskStep: UndoToontaskStep,
                private resetToontask: ResetToontask
    ) {
    }

    private toontask: GetToontaskResult | null = null

    uiState = new BehaviorSubject<ToontaskInfoScreenState>({
        toontaskTitle: "",
        toontaskProgress: 0,
        toontaskProgressText: "",
        toontaskReward: "",
        toontaskState: ToontaskState.NotStarted,
        group: ToontaskGroup.ToontownCentral,
        currentStep: 0,
        currentStepText: "",
        stepsCardSteps: [],
        stepsPathSteps: []
    })

    async onToontaskSelected(id: number) {
        if (id !== this.toontask?.id) {
            const toontask = await this.getToontask.invoke(id)
            this.toontask = toontask

            const stepsCardSteps: StepsCardStep[] = toontask.steps.map((step) => {
                return {
                    options: step.options.map(option => option.name)
                }
            })

            const stepsPathSteps: StepsPathStep[] = toontask.steps.map((step) => {
                return {
                    title: step.options[0].name,
                    isMini: step.options[0].name.includes("Visit"),
                }
            })

            this.uiState.next({
                ...this.uiState.value,
                toontaskTitle: toontask.title,
                toontaskReward: toontask.reward,
                stepsCardSteps: stepsCardSteps,
                stepsPathSteps: stepsPathSteps,
            })
            this.listenForToontasksProgress()
        }
    }

    onToontaskStarted() {
        const toontask = this.toontask
        if (toontask === null) throw Error("Toontask is null")

        // noinspection JSIgnoredPromiseFromCall
        this.startToontask.invoke(toontask.id)
    }

    onToontaskCompleted() {
        const toontask = this.toontask
        if (toontask === null) throw Error("Toontask is null")

        // noinspection JSIgnoredPromiseFromCall
        this.completeToontask.invoke(toontask.id)
    }

    onToontaskDropped() {
        const toontask = this.toontask
        if (toontask === null) throw Error("Toontask is null")

        // noinspection JSIgnoredPromiseFromCall
        this.dropToontask.invoke(toontask.id)
    }

    onNextStepClick() {
        const toontask = this.toontask
        if (toontask === null) throw Error("Toontask is null")

        // noinspection JSIgnoredPromiseFromCall
        this.progressToontaskStep.invoke(toontask.id, 0)
    }

    onUndoStepClick() {
        const toontask = this.toontask
        if (toontask === null) throw Error("Toontask is null")

        // noinspection JSIgnoredPromiseFromCall
        this.undoToontaskStep.invoke(toontask.id)
    }

    onResetClick() {
        const toontask = this.toontask
        if(toontask === null) throw Error("Toontask is null")

        // noinspection JSIgnoredPromiseFromCall
        this.resetToontask.invoke(toontask.id)
    }

    private listenForToontasksProgress() {
        this.getToontasksProgressSubscription?.unsubscribe()
        this.getToontasksProgressSubscription = this.getToontasksProgress.invoke().subscribe(result => {
            const toontask = this.toontask
            if (toontask === null) throw Error("Toontask is null")

            const toontaskId = toontask.id
            const stepsCompleted = getStepsCompleted(toontaskId, result) ?? 0

            let toontaskState = ToontaskState.NotStarted
            let toontaskProgress = 0

            if (isToontaskStarted(toontaskId, result)) {
                toontaskState = ToontaskState.Started
                toontaskProgress = stepsCompleted / this.uiState.value.stepsCardSteps.length
            } else if (isToontaskCompleted(toontaskId, result)) {
                toontaskState = ToontaskState.Completed
                toontaskProgress = 1
            }


            this.uiState.next({
                ...this.uiState.value,
                toontaskState: toontaskState,
                toontaskProgress: toontaskProgress,
                toontaskProgressText: (toontaskProgress * 100).toFixed(0) + "%",
                currentStep: stepsCompleted,
                currentStepText: this.uiState.value.stepsCardSteps[stepsCompleted].options[0]
            })
        })
    }
}

function isToontaskStarted(toontaskId: number, result: GetToontasksProgressResult): boolean {
    return toontaskId === result.started.topLeft?.toontaskId ||
        toontaskId === result.started.topRight?.toontaskId ||
        toontaskId === result.started.bottomLeft?.toontaskId ||
        toontaskId === result.started.bottomRight?.toontaskId
}

function isToontaskCompleted(toontaskId: number, result: GetToontasksProgressResult): boolean {
    return result.completedIds.find(id => id === toontaskId) !== undefined
}

function getStepsCompleted(toontaskId: number, result: GetToontasksProgressResult): number | null {
    if (toontaskId === result.started.topLeft?.toontaskId) {
        return result.started.topLeft.stepsCompleted
    }
    if (toontaskId === result.started.topRight?.toontaskId) {
        return result.started.topRight.stepsCompleted
    }
    if (toontaskId === result.started.bottomLeft?.toontaskId) {
        return result.started.bottomLeft.stepsCompleted
    }
    if (toontaskId === result.started.bottomRight?.toontaskId) {
        return result.started.bottomRight.stepsCompleted
    }

    return null
}