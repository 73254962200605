import CogType from "app/entities/invasions/cogs/CogType";
import { ScreenSize } from "common/ScreenSize";
import LargeCogSuitsCard from "./large/LargeCogSuitsCard";
import MediumCogSuitsCard, { CogSuitSlotState } from "./medium/MediumCogSuitsCard";
import SmallCogSuitsCard from "./small/SmallCogSuitsCard";

type Props = {
    screenSize: ScreenSize,
    progressDecimal: number
    progressText: string,
    suitsUnlocked: number,
    bossbot: CogSuitSlotState,
    lawbot: CogSuitSlotState,
    cashbot: CogSuitSlotState,
    sellbot: CogSuitSlotState,
    onPromoteClick: (cogType: CogType) => void,
    onDemoteClick: (cogType: CogType) => void
}

export default function CogSuitsCard(props: Props) {
    if (props.screenSize === ScreenSize.Large) {
        return <LargeCogSuitsCard
            progress={props.progressDecimal}
            progressText={props.progressText}
            suitsUnlocked={props.suitsUnlocked}
            bossbotSlot={props.bossbot}
            lawbotSlot={props.lawbot}
            cashbotSlot={props.cashbot}
            sellbotSlot={props.sellbot}
            onPromoteClick={cogType => props.onPromoteClick(cogType)}
            onDemoteClick={cogType => props.onDemoteClick(cogType)}
        />
    }

    if (props.screenSize === ScreenSize.Medium) {
        return <MediumCogSuitsCard
            progress={props.progressDecimal}
            progressText={props.progressText}
            suitsUnlocked={props.suitsUnlocked}
            bossbotSlot={props.bossbot}
            lawbotSlot={props.lawbot}
            cashbotSlot={props.cashbot}
            sellbotSlot={props.sellbot}
            onPromoteClick={cogType => props.onPromoteClick(cogType)}
            onDemoteClick={cogType => props.onDemoteClick(cogType)}
        />
    }

    return (
        <SmallCogSuitsCard
            progressDecimal={props.progressDecimal}
            progressText={props.progressText}
            suitsUnlocked={props.suitsUnlocked}
        />
    )
}