import UserRepository from "app/data/users/UserRepository";
import CogType from "app/entities/invasions/cogs/CogType";
import {BehaviorSubject} from "rxjs";
import DemoteCogSuit from "feat-tracking/application/demoteCogSuit/DemoteCogSuit";
import GetAssignedToontasks, {
    AssignedToontaskSlot,
    Result
} from "feat-tracking/application/getAssignedToontasks/GetAssignedToontasks";
import GetCogSuitsProgress from "feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgress";
import PromoteCogSuit from "feat-tracking/application/promoteCogSuit/PromoteCogSuit";
import CogSuitProgressEvaluator from "feat-tracking/entities/cogsuits/CogSuitProgressEvaluator";
import {ToontaskSlotPosition, ToontaskSlotState} from "./toontasks/medium/MediumToontaskSlot";
import TrackingUIState from "./TrackingUIState";
import GetTrackingProgressUseCase
    from "../../../application-users/usecases/getTrackingProgress/GetTrackingProgressUseCase";


export default class TrackingViewModel {

    #getAssignedToontasks: GetAssignedToontasks
    #getTrackingProgress: GetTrackingProgressUseCase
    #userRepository: UserRepository
    #cogSuitsProgressEvaluator: CogSuitProgressEvaluator
    #getCogSuitsProgress: GetCogSuitsProgress
    #promoteCogSuit: PromoteCogSuit
    #demoteCogSuit: DemoteCogSuit

    uiState = new BehaviorSubject<TrackingUIState>({
        toontasks: {
            progress: 0,
            progressText: "0%",
            groupText: "",
            slots: {
                topLeft: this.#createToontaskSlotState(ToontaskSlotPosition.TOP_LEFT, null),
                topRight: this.#createToontaskSlotState(ToontaskSlotPosition.TOP_RIGHT, null),
                bottomLeft: this.#createToontaskSlotState(ToontaskSlotPosition.BOTTOM_LEFT, null),
                bottomRight: this.#createToontaskSlotState(ToontaskSlotPosition.BOTTOM_RIGHT, null)
            }
        },
        cogSuits: {
            progressText: "0%",
            progress: 0,
            cogSuitsUnlocked: 0,
            bossbot: {progress: 0, cogText: "", pointsText: ""},
            lawbot: {progress: 0, cogText: "", pointsText: ""},
            cashbot: {progress: 0, cogText: "", pointsText: ""},
            sellbot: {progress: 0, cogText: "", pointsText: ""}
        },
        fishing: {
            progress: 0,
            progressPercentText: "0%",
            subtitleText: "",
            progressCountText: "",
            maxCount: "70",
            trophiesText: "",
            nextLaffAtProgress: null,
        },
        racing: {
            progress: 0,
            progressText: "0%",
            subtitleText: "",
            cupsText: "",
            maxCountText: "30",
            trophiesText: "",
            nextLaffAtProgress: 0,
        },
        golf: {
            progress: 0,
            progressPercentText: "",
            progressCountText: "",
            maxText: "30",
            nextLaffAtProgress: 0,
            cupsText: "",
            trophiesText: "",
            subtitleText: ""
        },
        flowers: {
            progress: 0,
            progressPercentText: "0%",
            progressCountText: "",
            maxCount: "40",
            nextLaffAtProgress: null,
            subtitleText: "",
            shovelText: "",
            trophiesText: "",
        }
    })

    constructor(
        userRepository: UserRepository,
        cogSuitsProgressEvaluator: CogSuitProgressEvaluator,
        getTrackingProgress: GetTrackingProgressUseCase,
        getAssignedToontasks: GetAssignedToontasks,
        getCogSuitsProgress: GetCogSuitsProgress,
        promoteCogSuit: PromoteCogSuit,
        demoteCogSuit: DemoteCogSuit
    ) {
        this.#getAssignedToontasks = getAssignedToontasks
        this.#userRepository = userRepository
        this.#cogSuitsProgressEvaluator = cogSuitsProgressEvaluator
        this.#getTrackingProgress = getTrackingProgress
        this.#getCogSuitsProgress = getCogSuitsProgress
        this.#promoteCogSuit = promoteCogSuit
        this.#demoteCogSuit = demoteCogSuit

        this.#listenToUser()
        this.#listenToTrackingProgress()
        this.#listenToAssignedToontasks()
        this.#listenToGetCogSuitsProgress()
    }

    onPromoteClick(cogType: CogType) {
        this.#promoteCogSuit.invoke(cogType)
    }

    onDemoteClick(cogType: CogType) {
        this.#demoteCogSuit.invoke(cogType)
    }

    async #listenToUser() {
        this.#userRepository.observeUser().subscribe(async user => {
            const toontasksProgressDecimal = user.getCurrentToon().getToontasksProgress()
            const toontasksProgress = `${(toontasksProgressDecimal * 100).toFixed(0)}%`
            const currentNeighborhood = this.#convertToTitle(user.getCurrentToon().getCurrentNeighbourhood()!!)

            const sellbotIndex = user.getCurrentToon().getCogSuitIndexWithType("sellbot")!!
            const cashbotIndex = user.getCurrentToon().getCogSuitIndexWithType("cashbot")!!
            const lawbotIndex = user.getCurrentToon().getCogSuitIndexWithType("lawbot")!!
            const bossbotIndex = user.getCurrentToon().getCogSuitIndexWithType("bossbot")!!

            const cogSuitsProgressDecimal = (this.#cogSuitsProgressEvaluator.evaluate(sellbotIndex) + this.#cogSuitsProgressEvaluator.evaluate(cashbotIndex)
                + this.#cogSuitsProgressEvaluator.evaluate(lawbotIndex) + this.#cogSuitsProgressEvaluator.evaluate(bossbotIndex)) / 4

            const cogSuitsProgress = `${(cogSuitsProgressDecimal * 100).toFixed(0)}%`

            let cogSuitsUnlocked = 0
            if (sellbotIndex > 0) {
                cogSuitsUnlocked++
            }
            if (cashbotIndex > 0) {
                cogSuitsUnlocked++
            }
            if (lawbotIndex > 0) {
                cogSuitsUnlocked++
            }
            if (bossbotIndex > 0) {
                cogSuitsUnlocked++
            }

            const newState: TrackingUIState = {
                ...this.uiState.value,
                toontasks: {
                    ...this.uiState.value.toontasks,
                    progressText: toontasksProgress,
                    progress: toontasksProgressDecimal,
                    groupText: currentNeighborhood,
                },
                cogSuits: {
                    ...this.uiState.value.cogSuits,
                    progress: cogSuitsProgressDecimal,
                    progressText: cogSuitsProgress,
                    cogSuitsUnlocked: cogSuitsUnlocked
                }
            }

            this.uiState.next(newState)
        })
    }

    async #listenToTrackingProgress() {
        this.#getTrackingProgress.invoke().subscribe(async trackingProgress => {
            const newState: TrackingUIState = {
                ...this.uiState.value,
                fishing: {
                    ...this.uiState.value.fishing,
                    progress: trackingProgress.fishingProgress,
                    progressPercentText: `${(trackingProgress.fishingProgress * 100).toFixed(0)}%`,
                    subtitleText: `You have caught ${trackingProgress.fishCaught} fish`,
                    progressCountText: trackingProgress.fishCaught.toString(),
                    trophiesText: trackingProgress.fishingTrophies.toString(),
                    nextLaffAtProgress: trackingProgress.nextFishingLaffAtProgress,
                },
                flowers: {
                    ...this.uiState.value.flowers,
                    progress: trackingProgress.flowersProgress,
                    progressPercentText: `${(trackingProgress.flowersProgress * 100).toFixed(0)}%`,
                    progressCountText: trackingProgress.flowersCollected.toString(),
                    nextLaffAtProgress: trackingProgress.nextFlowersLaffAtProgress,
                    trophiesText: trackingProgress.flowersTrophies.toString(),
                    subtitleText: `You have collected ${trackingProgress.flowersCollected} flowers`
                },
                racing: {
                    ...this.uiState.value.racing,
                    progress: trackingProgress.racingProgress,
                    progressText: `${(trackingProgress.racingProgress * 100).toFixed(0)}%`,
                    subtitleText: `You have unlocked ${trackingProgress.racingTrophies} trophies`,
                    cupsText: trackingProgress.racingCups.toString(),
                    trophiesText: trackingProgress.racingTrophies.toString(),
                    nextLaffAtProgress: trackingProgress.nextRacingLaffAtProgress,
                },
                golf: {
                    ...this.uiState.value.golf,
                    progress: trackingProgress.golf.progress,
                    progressPercentText: `${(trackingProgress.golf.progress * 100).toFixed(0)}%`,
                    progressCountText: trackingProgress.golf.trophies.toString(),
                    nextLaffAtProgress: trackingProgress.golf.nextLaffProgress,
                    cupsText: trackingProgress.golf.cups.toString(),
                    trophiesText: trackingProgress.golf.trophies.toString(),
                    subtitleText: `You have unlocked ${trackingProgress.golf.trophies} trophies`
                }
            }

            this.uiState.next(newState)
        })
    }

    async #listenToAssignedToontasks() {
        this.#getAssignedToontasks.invoke().subscribe(result => {
            this.#handleGetAssignedToontasksResult(result)
        })
    }

    #handleGetAssignedToontasksResult(result: Result | null) {
        if (result === null) return

        const topLeftSlot = this.#createToontaskSlotState(ToontaskSlotPosition.TOP_LEFT, result.topLeft)
        const topRightSlot = this.#createToontaskSlotState(ToontaskSlotPosition.TOP_RIGHT, result.topRight)
        const bottomLeftSlot = this.#createToontaskSlotState(ToontaskSlotPosition.BOTTOM_LEFT, result.bottomLeft)
        const bottomRightSlot = this.#createToontaskSlotState(ToontaskSlotPosition.BOTTOM_RIGHT, result.bottomRight)

        const newState: TrackingUIState = {
            ...this.uiState.value,
            toontasks: {
                ...this.uiState.value.toontasks,
                slots: {
                    topLeft: topLeftSlot,
                    topRight: topRightSlot,
                    bottomLeft: bottomLeftSlot,
                    bottomRight: bottomRightSlot
                }
            }
        }

        this.uiState.next(newState)
    }

    #convertToTitle(neighborhood: string): string {
        switch (neighborhood) {
            case "ttc":
                return "Toontown Central"
            case "dd":
                return "Donald's Dock"
            case "dg":
                return "Daisy Gardens"
            case "mml":
                return "MML"
            case "brg":
                return "The Brrrgh"
            case "ddl":
                return "Donald's Dreamland"
            case "law":
                return "Lawbot"
            case "boss":
                return "Bossbot"
            case null:
                return "Bossbot"
            default:
                return ""
        }
    }

    #createToontaskSlotState(position: ToontaskSlotPosition, assignedToontask: AssignedToontaskSlot | null): ToontaskSlotState {
        if (assignedToontask === null) {
            return {
                position: position,
                progress: 0,
                isFilled: false,
                progressText: "",
                toontaskTitleText: "",
                rewardText: "",
                stepText: ""
            }
        }

        return {
            position: position,
            progress: assignedToontask.progress,
            isFilled: true,
            progressText: `${(assignedToontask.progress * 100).toFixed(0)}%`,
            toontaskTitleText: assignedToontask.title,
            rewardText: assignedToontask.reward,
            stepText: assignedToontask.currentStepName
        }
    }

    #listenToGetCogSuitsProgress() {
        this.#getCogSuitsProgress.invoke().subscribe(result => {
            const bossbotCogText = result.bossbot !== null
                ? `${result.bossbot.cogSuitName} Lvl ${result.bossbot.cogSuitLevel}`
                : "Not unlocked"

            const lawbotCogText = result.lawbot !== null
                ? `${result.lawbot.cogSuitName} Lvl ${result.lawbot.cogSuitLevel}`
                : "Not unlocked"

            const cashbotCogText = result.cashbot !== null
                ? `${result.cashbot.cogSuitName} Lvl ${result.cashbot.cogSuitLevel}`
                : "Not unlocked"

            const sellbotCogText = result.sellbot !== null
                ? `${result.sellbot.cogSuitName} Lvl ${result.sellbot.cogSuitLevel}`
                : "Not unlocked"

            this.uiState.next({
                ...this.uiState.value,
                cogSuits: {
                    ...this.uiState.value.cogSuits,
                    bossbot: {
                        progress: result.bossbot?.progress ?? 0,
                        cogText: bossbotCogText,
                        pointsText: result.bossbot?.pointsLeftForPromo.toString() ?? "-"
                    },
                    lawbot: {
                        progress: result.lawbot?.progress ?? 0,
                        cogText: lawbotCogText,
                        pointsText: result.lawbot?.pointsLeftForPromo.toString() ?? "-"
                    },
                    cashbot: {
                        progress: result.cashbot?.progress ?? 0,
                        cogText: cashbotCogText,
                        pointsText: result.cashbot?.pointsLeftForPromo.toString() ?? "-"
                    },
                    sellbot: {
                        progress: result.sellbot?.progress ?? 0,
                        cogText: sellbotCogText,
                        pointsText: result.sellbot?.pointsLeftForPromo.toString() ?? "-"
                    }
                }
            })
        })
    }
}
