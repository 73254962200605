import {Row, Spacer} from "../../core-anvil/Layouts";
import Card from "../../common/theme/components/Card";
import {LabelLarge} from "../../app/theme/Typography";
import CogType from "../../app/entities/invasions/cogs/CogType";
import {useTheme} from "styled-components";
import {CSSProperties} from "react";

type Props = {
    cogType: CogType,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function PromotionButtons(props: Props) {
    return (
        <Row horizontalArrangement="center" style={{...props.style}}>
            <DemoteButton cogType={props.cogType} onClick={props.onDemoteClick}/>
            <span style={{width: 16}}/>
            <PromoteButton cogType={props.cogType} onClick={props.onPromoteClick}/>
        </Row>
    )
}

type ButtonProps = {
    cogType: CogType,
    onClick: () => void
}

function PromoteButton(props: ButtonProps) {
    const theme = useTheme()

    const cogTypeColor = theme.colors.getCogTypeDarkColor(props.cogType)

    return (
        <Card
            onClick={props.onClick}
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 100,
                backgroundColor: "white",
                cursor: "pointer"
            }}>
            <Row verticalAlignment="center" style={{userSelect: "none"}}>
                <span className="material-icons" style={{color: cogTypeColor}}>add</span>
                <Spacer style={{margin: 0, width: 4}}/>
                <LabelLarge style={{fontSize: 18, marginTop: 2, color: cogTypeColor}}>Promote</LabelLarge>
            </Row>
        </Card>
    )
}

function DemoteButton(props: ButtonProps) {

    const theme = useTheme()

    return (
        <Card
            onClick={props.onClick}
            style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 100,
                backgroundColor: theme.colors.getCogTypeDarkColor(props.cogType),
                cursor: "pointer"
            }}>
            <Row verticalAlignment="center" style={{userSelect: "none"}}>
                <span className="material-icons" style={{color: "white"}}>remove</span>
                <Spacer style={{margin: 0, width: 4}}/>
                <LabelLarge style={{fontSize: 18, marginTop: 2}}>Demote</LabelLarge>
            </Row>
        </Card>
    )
}