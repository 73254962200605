import "./invasion-details.scss";

import React from "react";

class InvasionDetails extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			remaining: this.props.cogsRemaining,
			remainingCountdownJobRunning: true
		};
	}

	componentDidMount() {
		this.startRemainingCountdownJob();
	}

	async startRemainingCountdownJob() {
		let remaining = this.state.remaining;

		while(true) {
			remaining--;
			// eslint-disable-next-line no-loop-func
			this.setState(() => {return {remaining: Math.max(0, remaining)}});

			await sleep(60 / this.props.overallRate * 1000);

			if(!this.state.remainingCountdownJobRunning) {
				break;
			}
		}
	}

	render() {
		return (
			<div id="invasion-details">
				<p id="cpm" className="headline6 unselectable">Cogs per minute</p>
				<div id="cpm-layout">
					<div id="current-cpm-layout">
						<p id="current-cpm" className="headline2 unselectable">{this.props.currentRate}</p>
						<p className="body2 unselectable">This Invasion</p>
					</div>
					<div id="overall-cpm-layout">
						<p id="overall-cpm" className="headline2 unselectable">{this.props.overallRate}</p>
						<p className="body1 unselectable">Estimate for Whole Invasion</p>
					</div>
					<div id="historical-cpm-layout">
						<p id="historical-cpm" className="headline2 unselectable">{this.props.historicalRate}</p>
						<p className="body2 unselectable">Historical</p>
					</div>
				</div>
				<div id="remaining-layout" >
					<p id="cogs-remaining" className="headline2 unselectable">{this.state.remaining}</p>
					<p className="body1 unselectable">Cogs Remaining</p>
				</div>
			</div>

		);
	}
}

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export default InvasionDetails