import GetToontask, {GetToontaskResult} from "./GetToontask";
import {inject, injectable} from "tsyringe";
import type ToontasksRepository from "data-toontasks/ToontasksRepository";

@injectable()
export default class GetToontaskUseCase implements GetToontask {

    constructor(@inject("ToontasksRepository") private toontasksRepository: ToontasksRepository) {
    }

    async invoke(toontaskId: number): Promise<GetToontaskResult> {
        return this.toontasksRepository.getToontask(toontaskId)
    }
}