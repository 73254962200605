import "./laff-progress.scss"

import React from "react"

export default class LaffProgress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style: props.style,
            maxProgress: props.maxProgress,
            laffMarkers: props.laffMarkers,
            markerValues: props.markerValues,
            unfilledColour: props.unfilledColour,
            markerWidth: 3,
            progressHeight: 8,
            fontSize: "calc(17 * 0.0625rem)",
            hideLabels: props.hideLabels
        }

        if (props.markerWidth !== undefined) {
            this.state.markerWidth = props.markerWidth
        }

        if (props.progressHeight !== undefined) {
            this.state.progressHeight = props.progressHeight
        }
    }

    render() {
        let fontSize = 17
        if (this.props.fontSize !== undefined) {
            fontSize = this.props.fontSize
        }

        this.setState({
            fontSize: `calc(${fontSize} * 0.0625rem)`,
            laffFontSize: `calc(${fontSize * 23 / 17} * 0.0625rem)`
        })

        return (
            <div className="laff-progress" style={this.state.style}>
                <div style={{ height: "100%", width: "80%", marginLeft: "10%", position: "absolute" }}>
                    <div className="laff-progress__progress-bar" style={{ height: this.state.progressHeight, backgroundColor: this.state.unfilledColour }} />
                    {this.renderLaffMarkers()}
                    <div className="laff-progress__progress-bar--real" style={{ height: this.state.progressHeight, width: `${this.props.progress / this.state.maxProgress * 100}%` }} />
                    {this.renderLabels()}
                </div>
            </div>
        );
    }

    renderLaffMarkers() {
        let markerDivs = []

        for (const index in this.state.laffMarkers) {
            let marker = this.state.laffMarkers[index]

            let position = marker / this.state.maxProgress

            let colour = this.state.unfilledColour
            if (this.props.progress >= marker) {
                colour = "#ffffff"
            }

            let margin = `${((this.props.fontSize - 17) * -1) - 28}px` 

            let label = marker

            if(this.state.markerValues !== undefined) {
                label = this.state.markerValues[index]
            }

            markerDivs.push(
                <div className="laff-progress__laff-marker" style={{ width: this.state.markerWidth, right: `${(1 - position) * 100}%`, backgroundColor: colour }}>
                    <p className="laff-progress__laff-point" style={{ color: colour, fontSize: this.state.laffFontSize, top: margin }}>+1</p>
                    <p className="laff-progress__threshold" style={{ color: colour, fontSize: this.state.fontSize, bottom: margin }}>{label}</p>
                </div>
            )
        }

        return markerDivs
    }

    renderLabels() {
        let labelMargin = `calc(${(1 - this.state.laffMarkers[0] / this.state.maxProgress) * 100}% + 6%)`

        if(this.state.hideLabels) {
            return null
        } else {
            return [
                <p className="laff-progress__laff-boost-label" style={{ right: labelMargin }}>Laff Boost</p>,
                <p className="laff-progress__marker-label" style={{ right: labelMargin }}>Fish</p>
            ]
        }
    }
}