import { Subtitle1, Subtitle2 } from "app/theme/Typography";
import styled, { ThemeContext } from "styled-components"

import ActivityCard from "./ActivityCard"
import { useContext } from 'react';

type Props = {
    progress: string,
    onClick: () => void
}

export default function ToontasksCard(props: Props) {
    const theme = useContext(ThemeContext)
    return (
        <ActivityCard title="Toontasks" backgroundColor={theme.colors.toontasks} onClick={props.onClick}>
            <StyledToontasksCardContent>
                <StyledToontasksValue>{props.progress}</StyledToontasksValue>
                <StyledProgressLabel>Progress</StyledProgressLabel>
            </StyledToontasksCardContent>
        </ActivityCard>
    )
}

const StyledToontasksCardContent = styled.p`
    width: 100%;
    height: 100%;
    diplay: flex;
    flex-direction: column;
    align-items: center;
`

const StyledToontasksValue = styled(Subtitle1)`
    margin-top: 12px;
`

const StyledProgressLabel = styled(Subtitle2)`
    width: min-content;
    font-weight: 300;
    margin-top: -6px;
    margin-left: auto;
    margin-right: auto;
`