import {injectable} from "tsyringe";
import {BehaviorSubject} from "rxjs";
import FishingSpeciesScreenModel from "../FishingSpeciesScreenModel";
import GetFish from "../../../application-game/usecases/fishing/getfish/GetFish";

@injectable()
export default class FishInsightsDialogViewModel {

    uiState = new BehaviorSubject(createEmptyState())

    constructor(private fishingSpeciesScreenModel: FishingSpeciesScreenModel, private getFish: GetFish) {
        fishingSpeciesScreenModel.state.subscribe(screenState => {
            this.uiState.next({
                ...this.uiState.value,
                isVisible: screenState.dialog.isVisible
            })
            if(screenState.dialog.fishId !== null) {
                this.refresh(screenState.dialog.fishId)
            }
        })
    }

    onDismiss() {
        this.fishingSpeciesScreenModel.hideFishInsightsDialog()
    }

    private refresh(fishId: number) {
        const fish = this.getFish.invoke(fishId)

        if(fish === null) return

        this.uiState.next({
            ...this.uiState.value,
            fishName: fish.name,
            rarity: fish.rarity,
            rarityLabel: this.getRarityLabel(fish.rarity)
        })
    }

    private getRarityLabel(rarity: number): string {
        switch (rarity) {
            case 10:
                return "Ultra Rare"
            case 9 :
                return "Super Rare"
            case 8:
                return "Very Rare"
            case 7:
                return "Rare"
            case  6:
                return "Rare"
            case  5:
                return "Uncommon"
            case  4:
                return "Uncommon"
            case  3:
                return "Common"
            case  2:
                return "Common"
            case  1:
                return "Common"
            default:
                return ""
        }
    }
}

type FishInsightsDialogState = {
    isVisible: boolean,
    rarity: number,
    rarityLabel: string,
    fishName: string
}

function createEmptyState(): FishInsightsDialogState {
    return {
        isVisible: false,
        rarity: 1,
        rarityLabel: "Loading...",
        fishName: "Loading..."
    }
}