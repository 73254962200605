import OverallServerStatusInfo from "./status/OverallServerStatusInfo"
import { Server } from "./status/ServerStatusPanel"
import ServerStatusTable from "./status/ServerStatusTable"
import styled from "styled-components"

type Props = {
    statusIcon: any,
    overallStatusText: string,
    servers: Server[],
    isExpanded: boolean
    onStatusInfoClick: () => any
}

export default function MiniInfoPanel(props: Props) {
    let table
    if(props.isExpanded) {
        table = <ServerStatusTable servers={props.servers} />
    } else {
        table = null
    }
    
    return(
        <StyledMiniInfoPanel isExpanded={props.isExpanded}>
            <OverallServerStatusInfo 
            icon={props.statusIcon}
             overallStatusText={props.overallStatusText}
             onStatusInfoClick={props.onStatusInfoClick}
             />
             {table}
        </StyledMiniInfoPanel>
    )
}

type StyledMiniInfoPanelProps = {
    isExpanded: boolean
}

const StyledMiniInfoPanel = styled.div<StyledMiniInfoPanelProps>`
    width: 100%;
    height: ${props => props.isExpanded === true ? 260 : 70}px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #6200EE;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
`