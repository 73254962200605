import CogType from "app/entities/invasions/cogs/CogType"
import { Body2, Subtitle1, Subtitle2 } from "app/theme/Typography"
import Card from "common/theme/components/Card"
import ProgressBar from "common/theme/components/ProgressBar"
import styled, { useTheme } from "styled-components"

type Props = {
    cogType: CogType,
    progress: number,
    cogText: string,
    pointsText: string,
    onPromoteClick: (cogType: CogType) => any,
    onDemoteClick: (cogType: CogType) => any
}

export default function LargeToontaskSlot(props: Props) {
    const theme = useTheme()

    let backgroundColor = ""
    switch (props.cogType) {
        case CogType.BOSSBOT: backgroundColor = theme.colors.bossbot
            break
        case CogType.LAWBOT: backgroundColor = theme.colors.lawbot
            break
        case CogType.CASHBOT: backgroundColor = theme.colors.cashbot
            break
        case CogType.SELLBOT: backgroundColor = theme.colors.sellbot
            break
    }

    let cogTypeText = ""
    switch (props.cogType) {
        case CogType.BOSSBOT: cogTypeText = "Bossbot"
            break
        case CogType.LAWBOT: cogTypeText = "Lawbot"
            break
        case CogType.CASHBOT: cogTypeText = "Cashbot"
            break
        case CogType.SELLBOT: cogTypeText = "Sellbot"
            break
    }

    let promotionLabel = ""
    switch (props.cogType) {
        case CogType.BOSSBOT: promotionLabel = "Stock options for next promotion"
            break
        case CogType.LAWBOT: promotionLabel = "Jury notices for next promotion"
            break
        case CogType.CASHBOT: promotionLabel = "Cogbucks for next promotion"
            break
        case CogType.SELLBOT: promotionLabel = "Merits for next promotion"
            break
    }

    return (
        <StyledLargeCogSuitSlot style={{ backgroundColor: backgroundColor }}>
            <CogIcon src={props.cogType.icon} />
            <StyledDetails>
                <CogTypeText>{cogTypeText}</CogTypeText>
                <ProgressBar progress={props.progress} max={1} style={{ marginTop: 2 }} />
                <CogText>{props.cogText}</CogText>
                <BottomSection>
                    <PromotionSection>
                    <PromotionLabel>{promotionLabel}</PromotionLabel>
                    <PointsText>{props.pointsText}</PointsText>
                    </PromotionSection>
                    <LevelButtons>
                        <DemoteButton onClick={() => props.onDemoteClick(props.cogType)}><LevelIcon className="material-icons">remove</LevelIcon></DemoteButton>
                        <PromoteButton onClick={() => props.onPromoteClick(props.cogType)}><LevelIcon className="material-icons">add</LevelIcon></PromoteButton>
                    </LevelButtons>
                </BottomSection>
            </StyledDetails>

        </StyledLargeCogSuitSlot>
    )
}

const StyledLargeCogSuitSlot = styled(Card)`
    width: 328px;
    height: 116px;
    background-color: #7a20de;
    display: flex;
    flex-direction: row;
    padding: 16px 16px 8px 16px;
`

const StyledDetails = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 4px;
`

const CogIcon = styled.img`
    width: 44px;
    height: 44px;
`

const BottomSection = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`

const PromotionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`

const PromotionLabel = styled(Body2)``

const PointsText = styled(Subtitle1)`
    font-size: 25px;
    line-height: 25px;
`

const LevelButtons = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4px;
`

const PromoteButton = styled.div`
    width: 22px;
    height: 22px;
    padding: 2px;
    background-color: #ffffff26;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const DemoteButton = styled.div`
    width: 20px;
    height: 20px;
    padding: 2px;
    background-color: #00000026;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;
`

const CogTypeText = styled(Subtitle2)`
    margin-top: 6px;
`

const LevelIcon = styled.span`
    font-size: 20px;
    color: #eeeeee;
`

const CogText = styled(Body2)`
    height: 18px;
    margin-top: 4px;
    white-space: nowrap;
`