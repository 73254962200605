import Flowers from "app/data/game/flowers.json"

/**
 * @typedef {Object} Flower
 * @property name {string} Name of the cog for this cog suit
 * @property index {number} Index of flower
 * @property combo {string} Jelly combination to plant flower following this list:
 * - b = blue
 * - c = cyan
 * - g = green
 * - o = orange
 * - p = pink
 * - r = red
 * - v = violet
 * - y = yellow
 * 
 * @property colour {string} Colour this flower should be associated with for effects
 */
export type Flower = {
    name: string,
    index: number,
    combo: string,
    colour: string
}



export default class FlowersData {
    #flowersList: Flower[]

    constructor() {
        this.#flowersList = []
    }

    async init() {
        for (const flower of Flowers) {
            this.#flowersList.push(flower)
        }
    }

    getFlowers() {
        return this.#flowersList
    }

    getFlowersByGroup() {
        let groups: Flower[][] = []
        for (let i = 0; i < 8; i++) {
            groups.push([])
        }

        for (const flower of this.#flowersList) {
            const jbCount = flower.combo.length
            groups[jbCount - 1].push(flower)
        }

        return groups
    }

    getFlowerWithIndex(index: number) {
        return this.#flowersList[index]
    }

    getFlowersWithJbCount(jbCount: number) {
        return this.getFlowersByGroup()[jbCount - 1]
    }

    getEmptySet() {
        let map: any = {}

        for (let i = 0; i < 30; i++) {
            map[`${i}`] = false
        }

        return map
    }
}