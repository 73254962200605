import React, {CSSProperties} from "react";
import styled from "styled-components";

type ColumnProps = {
    horizontalAlignment?: "start" | "center" | "end",
    verticalArrangement?: "start" | "center" | "end"
    style?: CSSProperties,
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

export function Column(props: React.PropsWithChildren<ColumnProps>) {
    const alignItems = props.horizontalAlignment === "start" ? "start" :
        props.horizontalAlignment === "center" ? "center" :
            props.horizontalAlignment === "end" ? "end" :
                undefined

    let justifyContent = undefined
    switch (props.verticalArrangement) {
        case "start":
            justifyContent = "start"
            break
        case "center":
            justifyContent = "center"
            break
        case "end":
            justifyContent = "end"
            break
    }

    return (
        <StyledColumn style={{...props.style, alignItems: alignItems, justifyContent: justifyContent}}
                      onClick={props.onClick}>
            {props.children}
        </StyledColumn>
    )
}

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

type RowProps = {
    verticalAlignment?: "top" | "center" | "bottom",
    horizontalArrangement?: "start" | "center" | "space-between"
    onClick?: () => void
    style?: CSSProperties
}

export function Row(props: React.PropsWithChildren<RowProps>) {
    const alignItems = props.verticalAlignment === "top" ? "start" :
        props.verticalAlignment === "center" ? "center" :
            props.verticalAlignment === "bottom" ? "end" :
                "start"

    const justifyContent = props.horizontalArrangement === "start" ? "left" :
        props.horizontalArrangement === "center" ? "center" :
            props.horizontalArrangement === "space-between" ? "space-between" :
                "left"

    return (
        <StyledRow style={{...props.style, alignItems: alignItems, justifyContent: justifyContent}}
                   onClick={props.onClick}>
            {props.children}
        </StyledRow>
    )
}

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`

type BoxProps = {
    alignment?: "topStart" | "topCenter" | "center" | "centerStart",
    style?: CSSProperties,
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

export function Box(props: React.PropsWithChildren<BoxProps>) {
    let justifyContent = "center"
    let alignItems = "center"

    if (props.alignment === "topStart") {
        justifyContent = "flex-start"
        alignItems = "flex-start"
    }

    if (props.alignment === "topCenter") {
        justifyContent = "center"
        alignItems = "flex-start"
    }

    if (props.alignment === "center") {
        justifyContent = "center"
        alignItems = "center"
    }

    if (props.alignment === "centerStart") {
        justifyContent = "flex-start"
        alignItems = "center"
    }

    const children = React.Children.map(props.children, (child) => {
        return (
            <div style={{
                display: "flex",
                justifyContent: justifyContent,
                alignItems: alignItems,
                position: "absolute",
                width: "100%",
                height: "100%"
            }}>
                {child}
            </div>
        )
    })

    return (
        <StyledBox style={{...props.style}} onClick={props.onClick}>
            {children}
        </StyledBox>
    )
}

const StyledBox = styled.div`
  position: relative;
`

export const Spacer = styled.div`
  margin: auto;
`

export const Center = styled.div`
  align-self: center;
`