import { ScreenSize } from "common/ScreenSize";
import LargeToontasksCard from "./large/LargeToontasksCard";
import MediumToontasksCard from "./medium/MediumToontasksCard";
import { ToontaskSlotState } from "./medium/MediumToontaskSlot";
import SmallToontasksCard from "./small/SmallToontasksCard";
import ToontaskGroup from "../../../../data-toontasks/ToontaskGroup";

type Props = {
    screenSize: ScreenSize,
    progressDecimal: number
    progressText: string,
    group: ToontaskGroup,
    groupText: string,
    topLeftSlot: ToontaskSlotState,
    topRightSlot: ToontaskSlotState,
    bottomLeftSlot: ToontaskSlotState,
    bottomRightSlot: ToontaskSlotState
}

export default function ToontasksCard(props: Props) {
    if (props.screenSize === ScreenSize.Large) {
        return (
            <LargeToontasksCard
                progressDecimal={props.progressDecimal}
                progressText={props.progressText}
                group={props.group}
                topLeftSlot={props.topLeftSlot}
                topRightSlot={props.topRightSlot}
                bottomLeftSlot={props.bottomLeftSlot}
                bottomRightSlot={props.bottomRightSlot}
                groupText={props.groupText}
            />
        )
    }

    if (props.screenSize === ScreenSize.Medium) {
        return (
            <MediumToontasksCard
                progressDecimal={props.progressDecimal}
                progressText={props.progressText}
                group={props.group}
                topLeftSlot={props.topLeftSlot}
                topRightSlot={props.topRightSlot}
                bottomLeftSlot={props.bottomLeftSlot}
                bottomRightSlot={props.bottomRightSlot}
                groupText={props.groupText}
            />
        )
    }

    return (
        <SmallToontasksCard
            progressDecimal={props.progressDecimal}
            progressText={props.progressText}
            group={props.group}
            groupText={props.groupText}
        />
    )
}