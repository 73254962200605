import {singleton} from "tsyringe";
import FishJsonFile from "app/data/game/fish.json"
import Species from "../entities/fishing/Species";
import Fish from "../entities/fishing/Fish";
import {FishingSpotCatchRate} from "../entities/fishing/FishingSpotCatchRate";
import Neighborhood from "../entities/Neighborhood";
import {FishingRod} from "../entities/fishing/FishingRod";

type FishJsonFileSpecies = {
    type: string,
    name: string,
    fish: FishJsonFileFish[]
}

type FishJsonFileFish = {
    name: string,
    index: number,
    rarity: number,
    fishingSpots: FishingSpots
}

type FishingSpots = {
    ttc: { [key: string]: number[] }
    dd: { [key: string]: number[] }
    dg: { [key: string]: number[] }
    mml: { [key: string]: number[] }
    brg: { [key: string]: number[] }
    ddl: { [key: string]: number[] }
    estate: { [key: string]: number[] }
    'acorn acres': { [key: string]: number[] }
}

@singleton()
export default class FishRepository {

    private fishData = FishJsonFile as unknown as FishJsonFileSpecies[]

    getFish(id: number): Fish | null {
        let matchingFish: Fish | null = null

        this.getAllSpecies().forEach(species => {
            species.fish.forEach(fish => {
                if (fish.id === id) {
                    matchingFish = fish
                }
            })
        })

        return matchingFish
    }

    getFishBestSpot(fishId: number, rod: FishingRod): FishingSpotCatchRate | null {
        let bestNeighborhood = ""
        let bestArea = ""
        let bestCatchRate = 0

        const fish =
            this.fishData.flatMap(species => species.fish)
            .find(fish => fish.index === fishId)!!

        for (const neighborhood in fish.fishingSpots) {
            // @ts-ignore
            for (const area in fish.fishingSpots[neighborhood]) {
                // @ts-ignore
                const catchRate = fish.fishingSpots[neighborhood][area][rod]

                if (catchRate > bestCatchRate) {
                    bestNeighborhood = neighborhood
                    bestArea = area
                    bestCatchRate = catchRate
                }
            }
        }

        if (bestCatchRate === 0) return null

        const neighborhood = this.getNeighborhoodFromString(bestNeighborhood)
        if (neighborhood === null) throw Error()

        // @ts-ignore
        const catchRates = fish.fishingSpots[bestNeighborhood][bestArea]

        return {
            fishId: 0,
            neighborhood: neighborhood,
            area: bestArea,
            rod: rod,
            catchRate: catchRates[rod],
        }
    }

    getAllSpecies(): Species[] {
        return this.fishData.map((species, index) => {
            return {
                id: index,
                name: species.name,
                fish: species.fish.map(fish => {
                    return {
                        id: fish.index,
                        name: fish.name,
                        rarity: fish.rarity
                    }
                })
            }
        })
    }

    private getNeighborhoodFromString(string: string): Neighborhood | null {
        switch (string) {
            case "ttc":
                return Neighborhood.TOONTOWN_CENTRAL
            case "dd":
                return Neighborhood.DONALDS_DOCK
            case "dg":
                return Neighborhood.DAISY_GARDENS
            case "mml":
                return Neighborhood.MINNIES_MELODYLAND
            case "brg":
                return Neighborhood.THE_BRRRGH
            case "ddl":
                return Neighborhood.DONALDS_DREAMLAND
            case "estate":
                return Neighborhood.ESTATE
            case "acorn acres":
                return Neighborhood.ACORN_ACRES
            default:
                return null
        }
    }
}