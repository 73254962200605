import {CompletedStep} from "../../../../feat-tracking/entities/toontasks/Toontask";
import ToonAssignedToontasks from "./ToonAssignedToontasks";

export enum AssignedToontaskPosition {
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT
}

export default class ToonToontasks {

    toontasks: Map<number, boolean> = new Map()

    #assignedToontasks: ToonAssignedToontasks = {
        topLeft: null,
        topRight: null,
        bottomLeft: null,
        bottomRight: null
    }

    setToontaskCompleted(toontaskId: number, isCompleted: boolean) {
        this.unassignToontask(toontaskId)
        this.toontasks.set(toontaskId, isCompleted)
    }

    getNotStartedToontaskIds(): number[] {
        let notStartedIds: number[] = []

        for (let [toontaskId, isCompleted] of this.toontasks.entries()) {
            if (!isCompleted && !this.isToontaskAssigned(toontaskId)) {
                notStartedIds.push(toontaskId)
            }
        }

        return notStartedIds
    }

    isToontaskAssigned(toontaskId: number): boolean {
        return this.#assignedToontasks.topLeft?.toontaskId === toontaskId ||
            this.#assignedToontasks.topRight?.toontaskId === toontaskId ||
            this.#assignedToontasks.bottomLeft?.toontaskId === toontaskId ||
            this.#assignedToontasks.bottomRight?.toontaskId === toontaskId
    }

    getCompletedToontaskIds(): number[] {
        let completedIds: number[] = []

        for (let [toontaskId, isCompleted] of this.toontasks.entries()) {
            if (isCompleted) {
                completedIds.push(toontaskId)
            }
        }

        return completedIds
    }

    getAssignedToontasks(): ToonAssignedToontasks {
        return this.#assignedToontasks
    }

    setAssignedToontask(position: AssignedToontaskPosition, toontaskId: number) {
        switch (position) {
            case AssignedToontaskPosition.TOP_LEFT:
                this.#assignedToontasks.topLeft = {toontaskId: toontaskId, currentOption: 0, stepsCompleted: []}
                break
            case AssignedToontaskPosition.TOP_RIGHT:
                this.#assignedToontasks.topRight = {toontaskId: toontaskId, currentOption: 0, stepsCompleted: []}
                break
            case AssignedToontaskPosition.BOTTOM_LEFT:
                this.#assignedToontasks.bottomLeft = {toontaskId: toontaskId, currentOption: 0, stepsCompleted: []}
                break
            case AssignedToontaskPosition.BOTTOM_RIGHT:
                this.#assignedToontasks.bottomRight = {toontaskId: toontaskId, currentOption: 0, stepsCompleted: []}
                break
        }
    }

    unassignToontask(toontaskId: number) {
        if (this.#assignedToontasks.topLeft?.toontaskId === toontaskId) {
            this.#assignedToontasks.topLeft = null
        } else if (this.#assignedToontasks.topRight?.toontaskId === toontaskId) {
            this.#assignedToontasks.topRight = null
        } else if (this.#assignedToontasks.bottomLeft?.toontaskId === toontaskId) {
            this.#assignedToontasks.bottomLeft = null
        } else if (this.#assignedToontasks.bottomRight?.toontaskId === toontaskId) {
            this.#assignedToontasks.bottomRight = null
        }
    }

    addCompletedStep(position: AssignedToontaskPosition, step: CompletedStep) {
        switch (position) {
            case AssignedToontaskPosition.TOP_LEFT:
                this.#assignedToontasks.topLeft!.stepsCompleted.push(step)
                this.#assignedToontasks.topLeft!.currentOption = 0
                break
            case AssignedToontaskPosition.TOP_RIGHT:
                this.#assignedToontasks.topRight!.stepsCompleted.push(step)
                this.#assignedToontasks.topRight!.currentOption = 0
                break
            case AssignedToontaskPosition.BOTTOM_LEFT:
                this.#assignedToontasks.bottomLeft!.stepsCompleted.push(step)
                this.#assignedToontasks.bottomLeft!.currentOption = 0
                break
            case AssignedToontaskPosition.BOTTOM_RIGHT:
                this.#assignedToontasks.bottomRight!.stepsCompleted.push(step)
                this.#assignedToontasks.bottomRight!.currentOption = 0
                break
        }
    }

    undoToontaskStep(toontaskId: number) {
        const assignedToontask = this.#assignedToontasks.topLeft?.toontaskId === toontaskId ? this.#assignedToontasks.topLeft :
            this.#assignedToontasks.topRight?.toontaskId === toontaskId ? this.#assignedToontasks.topRight :
                this.#assignedToontasks.bottomLeft?.toontaskId === toontaskId ? this.#assignedToontasks.bottomLeft :
                    this.#assignedToontasks.bottomRight?.toontaskId === toontaskId ? this.#assignedToontasks.bottomRight :
                        null

        if (assignedToontask === null) return

        if (assignedToontask.stepsCompleted.length === 0) {
            this.setToontaskCompleted(toontaskId, false)
        } else {
            assignedToontask.stepsCompleted.pop()
        }
    }

    setCurrentOption(position: AssignedToontaskPosition, optionId: number) {
        switch (position) {
            case AssignedToontaskPosition.TOP_LEFT:
                this.#assignedToontasks.topLeft!.currentOption = optionId
                break
            case AssignedToontaskPosition.TOP_RIGHT:
                this.#assignedToontasks.topRight!.currentOption = optionId
                break
            case AssignedToontaskPosition.BOTTOM_LEFT:
                this.#assignedToontasks.bottomLeft!.currentOption = optionId
                break
            case AssignedToontaskPosition.BOTTOM_RIGHT:
                this.#assignedToontasks.bottomRight!.currentOption = optionId
                break
        }
    }
}