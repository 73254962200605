import React from "react"
import styled from "styled-components"

import TrophyCard from "app/ui/tracking/racing/TrophyCard"

import GameDataRepository from "app/data/GameDataRepository"
import LegacyToon from "app/data/user/LegacyToon"

import CupsView from "app/ui/tracking/racing/CupsView"

interface TrophiesGridProps {
    toon: LegacyToon
}

export default class TrophiesGrid extends React.Component<TrophiesGridProps> {
    render(): JSX.Element {
        return (
            <StyledTrophiesGrid>
                <CupsView trophyCount={this.props.toon.getRacingTrophiesUnlockedCount()} />
                {this.renderTrophyCards()}
            </StyledTrophiesGrid>
        )
    }

    renderTrophyCards(): JSX.Element[] {
        const toon = this.props.toon
        const trophyGroups = GameDataRepository.getRacingData().getTrophiesByGroup()

        let trophyCards: JSX.Element[] = []

        for (const group of trophyGroups) {
            trophyCards.push(
                <TrophyCard toon={toon} trophyGroup={group} />
            )
        }

        return trophyCards
    }
}

const StyledTrophiesGrid = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 16px;
    column-gap: 16px;
`