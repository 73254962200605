import React from "react"
import styled from "styled-components"

import { RacingTrophyGroup } from "app/data/game/RacingData"

import LegacyToon from "app/data/user/LegacyToon"
import TrophySelector from "app/ui/tracking/racing/TrophySelector"

import Card from "common/theme/components/Card"


interface TrophyCardProps {
    toon: LegacyToon,
    trophyGroup: RacingTrophyGroup
}

export default class TrophyCard extends React.Component<TrophyCardProps> {
    render(): JSX.Element {
        const toon = this.props.toon
        const group = this.props.trophyGroup

        return (
            <StyledTrophyCard>
                <TitleContainer>
                    <TrophyTitle>{group.name}</TrophyTitle>
                </TitleContainer>
                <TrophySelector toon={toon} trophyGroup={group} />
            </StyledTrophyCard>
        )
    }
}

const StyledTrophyCard = styled(Card)`
    width: 420px;
    max-width: 100%;
    height: fit-content;
    padding-bottom: 24px;
`

const TitleContainer = styled.div`
    height: 72px;
    width: 100%;
    display: flex;
    margin: 16px auto 12px auto;
`

const TrophyTitle = styled.p`
    width: 86%;
    margin: auto;
    text-align: center;
    font-size: calc(30 * 0.0625rem);
    font-family: ImpressBT, serif;
`