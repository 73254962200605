import CogType from "app/entities/invasions/cogs/CogType";
import Card from "common/theme/components/Card";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import ActivityHeader from "../../common/ActivityHeader";
import { CogSuitSlotState } from "../medium/MediumCogSuitsCard";
import LargeCogSuitSlot from "./LargeCogSuitSlot";

type Props = {
    progress: number,
    progressText: string,
    suitsUnlocked: number,
    bossbotSlot: CogSuitSlotState,
    lawbotSlot: CogSuitSlotState,
    cashbotSlot: CogSuitSlotState,
    sellbotSlot: CogSuitSlotState,
    onPromoteClick: (cogType: CogType) => void,
    onDemoteClick: (cogType: CogType) => void
}

export default function LargeToontasksCard(props: Props) {
    const theme = useTheme()

    return (
        <StyledLargeCogSuitsCard>
            <Link to="/tracking/cog-suits" style={{ textDecoration: "none" }}>
                <ActivityHeader
                    width={360}
                    progressDecimal={props.progress}
                    progressText={props.progressText}
                    title={"Cog Suits"}
                    subtitle={`You have ${props.suitsUnlocked} suits`}
                    backgroundColor={theme.colors.cogSuitsDark}
                />
            </Link>
            <StyledCogSuitSlotGrid>
                <LargeCogSuitSlot
                    cogType={CogType.BOSSBOT}
                    progress={props.bossbotSlot.progress}
                    cogText={props.bossbotSlot.cogText}
                    pointsText={props.bossbotSlot.pointsText}
                    onPromoteClick={cogType => props.onPromoteClick(cogType)}
                    onDemoteClick={cogType => props.onDemoteClick(cogType)}
                />
                <LargeCogSuitSlot
                    cogType={CogType.LAWBOT}
                    progress={props.lawbotSlot.progress}
                    cogText={props.lawbotSlot.cogText}
                    pointsText={props.lawbotSlot.pointsText}
                    onPromoteClick={cogType => props.onPromoteClick(cogType)}
                    onDemoteClick={cogType => props.onDemoteClick(cogType)}
                />
                <LargeCogSuitSlot
                    cogType={CogType.CASHBOT}
                    progress={props.cashbotSlot.progress}
                    cogText={props.cashbotSlot.cogText}
                    pointsText={props.cashbotSlot.pointsText}
                    onPromoteClick={cogType => props.onPromoteClick(cogType)}
                    onDemoteClick={cogType => props.onDemoteClick(cogType)}
                />
                <LargeCogSuitSlot
                    cogType={CogType.SELLBOT}
                    progress={props.sellbotSlot.progress}
                    cogText={props.sellbotSlot.cogText}
                    pointsText={props.sellbotSlot.pointsText}
                    onPromoteClick={cogType => props.onPromoteClick(cogType)}
                    onDemoteClick={cogType => props.onDemoteClick(cogType)} />
            </StyledCogSuitSlotGrid>
        </StyledLargeCogSuitsCard>
    )
}

const StyledLargeCogSuitsCard = styled(Card)`
    width: 400px;
    height: 740px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.colors.cogSuits};
    border-radius: ${props => props.theme.dimens.cardCornerRadius}
`

const StyledCogSuitSlotGrid = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
`