import styled, {useTheme} from "styled-components";
import {Column, Row, Spacer} from "../../../../core-anvil/Layouts";
import React, {CSSProperties} from "react";
import ToontaskGroup, {getToontaskGroups} from "../../../../data-toontasks/ToontaskGroup";
import ic_ttc from "../../../assets/images/ic_ttc.svg";
import ic_dd from "../../../assets/images/ic_dd.svg";
import ic_dg from "../../../assets/images/ic_dg.svg";
import ic_mml from "../../../assets/images/ic_mml.svg";
import ic_brg from "../../../assets/images/ic_brg.svg";
import ic_ddl from "../../../assets/images/ic_ddl.svg";
import ic_lawbot from "../../../assets/images/ic_lawbot.png";
import ic_bossbot from "../../../assets/images/ic_bossbot.png";
import {Headline5} from "../../../../app/theme/Typography";
import {NeighborhoodHeadingProps} from "../NeighborhoodHeading";

export default function SmallNeighborhoodHeading(props: NeighborhoodHeadingProps) {
    const theme = useTheme()
    const primaryColour = `${theme.colors.getToontaskGroupColor(props.group)}99`
    const stylisedName = getToontaskGroupName(props.group)

    return (
        <Column style={{width: 320, ...props.style}}>
            <Row verticalAlignment="center" style={{height: 40, backgroundColor: primaryColour, borderRadius: 50}}>
                <GroupIcon src={getNeighborhoodIcon(props.group)} alt=""/>
                <NeighborhoodName style={{marginTop: 4}}>{stylisedName}</NeighborhoodName>
            </Row>
            <GroupsNavigation
                group={props.group}
                onPreviousClick={() => props.onToontaskGroupClick(getPreviousToontaskGroup(props.group)!!)}
                onNextClick={() => props.onToontaskGroupClick(getNextToontaskGroup(props.group)!!)}
                style={{marginTop: 32, marginLeft: -12, marginRight: -12}}
            />
        </Column>
    )
}

function getNeighborhoodIcon(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return ic_ttc
        case ToontaskGroup.DonaldsDock:
            return ic_dd
        case ToontaskGroup.DaisyGardens:
            return ic_dg
        case ToontaskGroup.MinniesMelodyland:
            return ic_mml
        case ToontaskGroup.TheBrrrgh:
            return ic_brg
        case ToontaskGroup.DonaldsDreamland:
            return ic_ddl
        case ToontaskGroup.Lawbot:
            return ic_lawbot
        case ToontaskGroup.Bossbot:
            return ic_bossbot
    }
}

const GroupIcon = styled.img`
  width: 80px;
  height: 80px;
`

const NeighborhoodName = styled(Headline5)`
  color: white;
  flex: 1;
  text-align: center;
  margin-right: 16px;
`

function getToontaskGroupName(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return "Toontown Central"
        case ToontaskGroup.DonaldsDock:
            return "Donald's Dock"
        case ToontaskGroup.DaisyGardens:
            return "Daisy Gardens"
        case ToontaskGroup.MinniesMelodyland:
            return "Minnie's Melodyland"
        case ToontaskGroup.TheBrrrgh:
            return "The Brrrgh"
        case ToontaskGroup.DonaldsDreamland:
            return "Donald's Dreamland"
        case ToontaskGroup.Lawbot:
            return "Lawbot"
        case ToontaskGroup.Bossbot:
            return "Bossbot"
    }
}

type GroupsNavigationProps = {
    group: ToontaskGroup,
    onPreviousClick: () => void,
    onNextClick: () => void,
    style?: CSSProperties
}

function GroupsNavigation(props: GroupsNavigationProps) {
    return (
        <Row style={props.style}>
            <PreviousGroupButton group={props.group} onClick={props.onPreviousClick}/>
            <Spacer/>
            <NextGroupButton group={props.group} onClick={props.onNextClick}/>
        </Row>
    )
}

type GroupButtonProps = {
    group: ToontaskGroup,
    onClick: () => void,
    style?: CSSProperties
}

function PreviousGroupButton(props: GroupButtonProps) {
    const previousToontaskGroup = getPreviousToontaskGroup(props.group)
    if (previousToontaskGroup === null) return null

    return (
        <Row verticalAlignment="center" onClick={props.onClick}>
            <span className="material-icons" style={{color: "white", fontSize: 32}}>chevron_left</span>
            <img src={getNeighborhoodIcon(previousToontaskGroup)} alt="" style={{width: 56, height: 56}}/>
        </Row>
    )
}

function NextGroupButton(props: GroupButtonProps) {
    const nextToontaskGroup = getNextToontaskGroup(props.group)
    if (nextToontaskGroup === null) return null

    return (
        <Row verticalAlignment="center" onClick={props.onClick}>
            <img src={getNeighborhoodIcon(nextToontaskGroup)} alt="" style={{width: 56, height: 56}}/>
            <span className="material-icons" style={{color: "white", fontSize: 32}}>chevron_right</span>
        </Row>
    )
}

function getPreviousToontaskGroup(group: ToontaskGroup): ToontaskGroup | null {
    const groups = getToontaskGroups()
    const prevIndex = groups.indexOf(group) - 1
    if (prevIndex >= 0) {
        return groups[prevIndex]
    } else {
        return null
    }
}

function getNextToontaskGroup(group: ToontaskGroup): ToontaskGroup | null {
    const groups = getToontaskGroups()
    const nextIndex = groups.indexOf(group) + 1
    if (nextIndex < groups.length) {
        return groups[nextIndex]
    } else {
        return null
    }
}
