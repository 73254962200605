import LegacyToon from "app/data/user/LegacyToon"
import User from "../user/LegacyUser"

const KEY_USER = "user"

interface StoredUser {
    id: string | null
    email: string | null
    isVerified: boolean
    toons: StoredToon[]
    currentToonId: string
}

interface StoredToon {
    id: string,
    name: string,
    laff: string,
    toontasks: { [key: string]: boolean } | undefined,
    assignedToontasks: Object,
    cogSuits: { "0": number; "1": number; "2": number; "3": number; } | undefined,
    fish: { } | undefined,
    flowers: Object,
    racingTrophies: Object | undefined,
    golfTrophies: Object | undefined
}

class LocalUserData {
    fetchUser(): User {
        const value = localStorage.getItem(KEY_USER)

        if (value === null) {
            return new User(null, "Local User", false, [])
        }

        const cached: StoredUser = JSON.parse(value)

        let toons: LegacyToon[] = []
        for (const cachedToon of cached.toons) {
            let toon = new LegacyToon(cachedToon.id, cachedToon.name, cachedToon.laff)
            toon.setToontasksMap(cachedToon.toontasks)
            toon.setAssignedToontasksMap(cachedToon.assignedToontasks)
            toon.setCogSuitsMap(cachedToon.cogSuits)
            toon.setFishMap(cachedToon.fish)
            toon.setFlowersMap(cachedToon.flowers)
            toon.setRacingTrophiesMap(cachedToon.racingTrophies)
            toon.setGolfTrophiesMap(cachedToon.golfTrophies)
        }

        let user = new User(cached.id, cached.email, cached.isVerified, toons)

        return user
    }

    updateUser(user: User) {
        let cachedToons: StoredToon[] = []

        for (const toon of user.getToons()) {
            let cachedToon: StoredToon = {
                id: toon.getId(),
                name: toon.getName(),
                laff: toon.getLaff(),
                toontasks: toon.getToontasksMap(),
                assignedToontasks: toon.getAssignedToontasksMap(),
                cogSuits: toon.getCogSuitsMap(),
                fish: toon.getFishMap(),
                flowers: toon.getFlowersMap(),
                racingTrophies: toon.getRacingTrophiesMap(),
                golfTrophies: toon.getGolfTrophiesMap()
            }

            cachedToons.push(cachedToon)
        }

        const cachedUser: StoredUser = {
            id: user.getId(),
            email: user.getEmail(),
            isVerified: user.isVerified(),
            toons: cachedToons,
            currentToonId: user.getCurrentToon().getId()
        }

        localStorage.setItem(KEY_USER, JSON.stringify(cachedUser))
    }

    clearUser() {
        localStorage.removeItem(KEY_USER)
    }

}

const singleton = new LocalUserData()
export default singleton