import {injectable} from "tsyringe";
import {BehaviorSubject} from "rxjs";
import GetFishingProgressResult, {SpeciesResult} from "./GetFishingProgressResult";
import UserRepository from "../../../data-users/UserRepository";
import FishRepository from "../../../data-fishing/FishRepository";
import FishingProgressEvaluator from "../../../feat-tracking/entities/fishing/FishingProgressEvaluator";

@injectable()
export default class GetFishingProgress {

    private result = new BehaviorSubject<GetFishingProgressResult>({
        species: new Map<number, SpeciesResult>(),
        trophies: 0,
        fishCaught: 0,
        nextLaffAtProgress: null,
        progress: 0,
        fish: new Map<number, boolean>()
    })

    constructor(
        private userRepository: UserRepository,
        private fishRepository: FishRepository,
        private fishingProgressEvaluator: FishingProgressEvaluator
    ) {
        this.listenForCurrentToon()
    }

    invoke(): BehaviorSubject<GetFishingProgressResult> {
        return this.result
    }

    private listenForCurrentToon() {
        this.userRepository.currentToon.subscribe(toon => {
            if (toon === null) return

            const fishing = this.fishingProgressEvaluator.invoke(toon.fishCaught)

            const speciesMap = new Map<number, SpeciesResult>()

            const fishMap = new Map<number, boolean>()

            let totalFishCaught = 0

            this.fishRepository.getAllSpecies().forEach(species => {
                const fishInSpecies = species.fish.length
                let fishCaught = 0
                for (let fish of species.fish) {
                    const isCaught = toon.fishCaught.get(fish.id) ?? false
                    fishMap.set(fish.id, isCaught)
                    if (isCaught) {
                        fishCaught++
                        totalFishCaught++
                    }
                }

                speciesMap.set(species.id, {
                    progress: fishCaught / fishInSpecies
                })
            })

            const trophiesUnlocked = Math.trunc(totalFishCaught / 10)

            this.result.next({
                species: speciesMap,
                trophies: trophiesUnlocked,
                fishCaught: fishing.fishCaught,
                nextLaffAtProgress: 1,
                progress: fishing.fishingProgress,
                fish: fishMap
            })
        })
    }
}