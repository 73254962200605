import CogType from "app/entities/invasions/cogs/CogType";
import UserRepository from "data-users/UserRepository";
import PromoteCogSuit from "./PromoteCogSuit";
import {injectable} from "tsyringe";

@injectable()
export default class PromoteCogSuitUseCase implements PromoteCogSuit {
    #userRepository: UserRepository

    constructor(
        userRepository: UserRepository
    ) {
        this.#userRepository = userRepository
    }

    async invoke(cogType: CogType): Promise<void> {
        const toon = this.#userRepository.currentToon.value

        if (toon === null) {
            return Promise.reject()
        }

        switch (cogType) {
            case CogType.BOSSBOT:
                toon.cogSuits.bossbot.index = Math.min(78, toon.cogSuits.bossbot.index + 1)
                break
            case CogType.LAWBOT:
                toon.cogSuits.lawbot.index = Math.min(78, toon.cogSuits.lawbot.index + 1)
                break
            case CogType.CASHBOT:
                toon.cogSuits.cashbot.index = Math.min(78, toon.cogSuits.cashbot.index + 1)
                break
            case CogType.SELLBOT:
                toon.cogSuits.sellbot.index = Math.min(78, toon.cogSuits.sellbot.index + 1)
                break
        }

        this.#userRepository.updateToon(toon)
    }
}