import ServerStatusPanel, { Server } from "app/ui/home/info/status/ServerStatusPanel"

import { Headline2 } from "app/theme/Typography"
import Laff from "./laff/Laff"
import styled from "styled-components"
import { ScreenSize } from "common/ScreenSize"

type Props = {
    toonName: String
    laff: number,
    servers: Server[],
    screenSize: ScreenSize
}

export default function InfoPanel(props: Props) {
    return <StyledInfoPanel>
        <StyledInfoPanelContent screenSize={props.screenSize}>
            <Column screenSize={props.screenSize}>
                <ToonNameText>{props.toonName}</ToonNameText>
                <Laff laff={props.laff} />
            </Column>
            <Column screenSize={props.screenSize}>
                <ServerStatusPanel servers={props.servers} screenSize={props.screenSize} />
            </Column>
        </StyledInfoPanelContent>
    </StyledInfoPanel>

}

const StyledInfoPanel = styled.div`
  width: 100%;
  height: 365px;
  padding-bottom: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #6200EE;
  border-radius: 0px 0px 60px 60px;
`

type InfoPanelContentProps = {
    screenSize: ScreenSize
}
const StyledInfoPanelContent = styled.div<InfoPanelContentProps>`
    margin: auto;
    width: ${props => getInfoPanelContentWidth(props.screenSize)};
    display: flex;
    justify-content: space-evenly;
`

function getInfoPanelContentWidth(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Large) {
        return "1120px"
    } else {
        return "680px"
    }
}

type ColumnProps = {
    screenSize: ScreenSize
}

const Column = styled.div<ColumnProps>`
    width: ${props => getColumnWidth(props.screenSize)};
    display: flex;
    flex-direction: column;
    align-items: center;
`
function getColumnWidth(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Large) {
        return "560px"
    } else {
        return "340px"
    }
}

const ToonNameText = styled(Headline2)`
    margin-top: 28px;
    text-align: center;
    color: white;
`