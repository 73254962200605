import FlowersProgressEvaluatorResult from "./FlowersProgressEvalutorResult";
import {injectable} from "tsyringe";

const TOTAL_FLOWERS = 40

@injectable()
export default class FlowersProgressEvaluator {

    evaluate(flowers: Map<number, boolean>): FlowersProgressEvaluatorResult {
        let count = 0

        flowers.forEach((isCollected, index) => {
            if(isCollected) {
                count++
            }  
        })

        const nextLaffAtCount = count < TOTAL_FLOWERS ? (Math.floor(count / 10) + 1) * 10 : null
        const nextLaffAtProgress = nextLaffAtCount ? nextLaffAtCount / TOTAL_FLOWERS : null
        
        return {
            flowersProgress: count / TOTAL_FLOWERS,
            flowersCollected: count,
            nextLaffAtCount: nextLaffAtCount,
            nextLaffAtProgress: nextLaffAtProgress,
            trophies: Math.floor(count / 10)
        }
    }

}