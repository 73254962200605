import FeatureCard from "app/ui/home/FeatureCard";
import styled, { ThemeContext } from "styled-components";
import { useContext } from "react";

type Props = {
    width: number,
    onClick: () => void
}

export default function MatchmakingCard(props: Props) {
    const theme = useContext(ThemeContext)

    return (
        <FeatureCard
            title="Matchmaking"
            titleColor="#000000cc"
            smallWidth={props.width}
            mediumWidth={props.width}
            largeWidth={props.width}
            headerBackgroundColor={theme.colors.matchmaking}
            contentBackgroundColor={theme.colors.matchmakingDark}
            onClick={props.onClick}
        >

            <MatchmakingContent />

        </FeatureCard>
    )
}

const MatchmakingContent = styled.div`
    height: 100px;
`