import {injectable, Lifecycle, scoped} from "tsyringe";
import {BehaviorSubject} from "rxjs";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export default class CogSuitsScreenModel {

    state = new BehaviorSubject<CogSuitsScreenState>({
        isPointsTableDialogVisible: false
    })

    showPointsTableDialog() {
        this.state.next({
            ...this.state.value,
            isPointsTableDialogVisible: true
        })
    }

    hidePointsTableDialog() {
        this.state.next({
            ...this.state.value,
            isPointsTableDialogVisible: false
        })
    }

}

export type CogSuitsScreenState = {
    isPointsTableDialogVisible: boolean
}