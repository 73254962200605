import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions"
import "firebase/firestore"

export function isSignedIn() {
	return getAuthUser() !== null
}

export function getAuthUser() {
	return firebase.auth().currentUser
}

export function observeAuthUser(observer) {
	firebase.auth().onAuthStateChanged(observer)
}

export async function fetchSignInMethod(email) {
	return new Promise(resolve => {
		firebase.auth().fetchSignInMethodsForEmail(email)
			.then(methods => {
				if (methods.length > 0) {
					resolve(methods[0]);
				} else {
					resolve("");
				}
			})
			.catch(() => {
				// if email is malformed
				resolve(null);
			});
	});
}

export async function signInEmailUser(email, pass) {
	return new Promise(async (resolve, reject) => {
		let exists = await fetchSignInMethod(email)

		if (exists) {
			firebase.auth().signInWithEmailAndPassword(email, pass)
				.then(result => {
					// addBetaUser(result.user.email, "password");
					resolve(result.user);
				})
				.catch(err => {
					let errorCode = err.code;
					let errorMessage = err.message;

					console.error(errorMessage);
					reject(errorCode);
				});

		} else {
			firebase.auth().createUserWithEmailAndPassword(email, pass)
				.then(result => {
					// addBetaUser(result.user.email, "password");
					resolve(result.user);
				})
				.catch(err => {
					let errorCode = err.code;
					let errorMessage = err.message;

					console.error(errorMessage);
					reject(errorCode);
				});
		}
	});
}

export async function sendPasswordReset(email) {
	await firebase.auth().sendPasswordResetEmail(email);
}

export async function signInGoogleUser() {
	return new Promise(async (resolve, reject) => {
		const provider = new firebase.auth.GoogleAuthProvider();
		provider.setCustomParameters({
			prompt: "select_account"
		});

		if (window.innerWidth < 840) {
			await firebase.auth().signInWithRedirect(provider);

			let result = await firebase.auth().getRedirectResult();
			const user = result.user;

			resolve(user);

		} else {
			let result = await firebase.auth().signInWithPopup(provider);
			const user = result.user;

			resolve(user);
		}
	});
}

export async function signOut() {
	await firebase.auth().signOut();
}