import {ScreenSize} from "common/ScreenSize"
import {Redirect, Route, Switch, useHistory} from "react-router-dom"
import styled from "styled-components"
import ToontaskInfoScreen from "./toontask/ToontaskInfoScreen";
import ToontasksOverviewRoute, {ToontasksOverviewDestination} from "./routes/ToontasksOverviewRoute";
import React from "react";
import ToontaskGroup from "../data-toontasks/ToontaskGroup";

export const ToontaskInfoDestination = {
    route: "/feat-tracking/toontasks/toontask/:id",
    createRoute: (id: number) => `/tracking/toontasks/toontask/${id}`
}


export type Props = {
    screenSize: ScreenSize
}

export default function ToontasksNavigation(props: Props) {
    const history = useHistory()

    return (
        <StyledToontasksNavGraph>
            <Switch>
                    <Route path="/tracking/toontasks/toontask/:id">
                        <ToontaskInfoScreen
                            screenSize={props.screenSize}
                            onBackClick={() => history.push(`/tracking/toontasks/ttc`)}/>
                    </Route>
                    <ToontasksOverviewRoute screenSize={props.screenSize}/>
                    <Route path="/tracking/toontasks">
                        <Redirect to={ToontasksOverviewDestination.createRoute(ToontaskGroup.ToontownCentral)} />
                    </Route>
            </Switch>
        </StyledToontasksNavGraph>
    )
}

const StyledToontasksNavGraph = styled.div`
  width: 100%;
  height: 100%;
`