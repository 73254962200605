import { BehaviorSubject } from "rxjs"
import OverallServerStatus from "./OverallServerStatus"
import { ServerStatus } from "./ServerStatus"
import firebase from "firebase"
import sleep from "sleep-promise"

export default class StatusRepository {
    private isRunning = false
    
    serverStatus: BehaviorSubject<OverallServerStatus | {}> = new BehaviorSubject({})

    start() {
        if(!this.isRunning) {
            this.startFetchJob()
        }
    }

    startFetchJob() {        
        (async () => {
            this.isRunning = true
            while(this.isRunning) {
                const getServerStatus = firebase.functions().httpsCallable("getServerStatus")
                getServerStatus().then(result => {
                    const response = result.data
                    this.serverStatus.next(
                        new OverallServerStatus(
                            convertToServerStatus(response["mainSite"]),
                            convertToServerStatus(response["accountServer"]),
                            convertToServerStatus(response["gameServer1"]),
                            convertToServerStatus(response["gameServer2"]),
                            convertToServerStatus(response["gameServer3"]),
                            convertToServerStatus(response["updateServer1"]),
                            convertToServerStatus(response["updateServer2"]),
                            convertToServerStatus(response["updateServer3"]),
                        )
                    )
                })

                await sleep(60000)
            }
        })()
    }
}

function convertToServerStatus(server: any): ServerStatus {
    const currentTime = Date.now() / 1000

    return {
        name: server["name"],
        isUp: server["status"],
        latency: server["latency"],
        minutesSince: (currentTime - server["lastChange"]) / 60
    }
}