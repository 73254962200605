import CogSuits from "app/data/game/cog-suits.json"

/**
 * @typedef {Object} CogSuit
 * @property name Name of the cog for this cog suit
 * @property level Level this suit is
 * @property type Defines type of cog. Can be any of:
 * - Bossbot
 * - Lawbot
 * - Cashbot
 * - Sellbot
 * @property points Number of merits/cog bucks/jury notices/stock options needed to promote this cog suit to the next
 */


export default class CogSuitsData {
    /** @type {CogSuit[]} */
    #sellbotSuits
    /** @type {CogSuit[]} */
    #cashbotSuits
    /** @type {CogSuit[]} */
    #lawbotSuits
    /** @type {CogSuit[]} */
    #bossbotSuits

    constructor() {
        this.#sellbotSuits = []
        this.#cashbotSuits = []
        this.#lawbotSuits = []
        this.#bossbotSuits = []
    }

    async init() {
        for (const suit of CogSuits) {
            if (suit.type === "Sellbot") {
                this.#sellbotSuits.push(suit)
            }

            if (suit.type === "Cashbot") {
                this.#cashbotSuits.push(suit)
            }

            if (suit.type === "Lawbot") {
                this.#lawbotSuits.push(suit)
            }

            if (suit.type === "Bossbot") {
                this.#bossbotSuits.push(suit)
            }
        }

    }

    /**
     * 
     * @param {String} cogType This can be either:
     * - Sellbot
     * - Cashbot
     * - Lawbot
     * - Bossbot
     * @param {Number} index Index of the suit
     * @returns {CogSuit} Cog suit with the give cog type and index
     */
    getCogSuit(cogType, index) {
        switch (cogType) {
            case "sellbot":
            case "Sellbot":
                return this.#sellbotSuits[index]

            case "cashbot":
            case "Cashbot":
                return this.#cashbotSuits[index]

            case "lawbot":
            case "Lawbot":
                return this.#lawbotSuits[index]

            case "bossbot":
            case "Bossbot":
                return this.#bossbotSuits[index]

            default:
                return -1
        }
    }

    getEmptySet() {
        return {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0
        }
    }
}