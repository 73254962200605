class Dimensions {
    getContentHorizontalPadding() {
        if (window.innerWidth >= 1024) {
            return "24px"
        } else {
            return "20px"
        }
    }
}

const singleton = new Dimensions()
export default singleton