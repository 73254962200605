export enum ToontaskGroup {
    ToontownCentral,
    DonaldsDock,
    DaisyGardens,
    MinniesMelodyland,
    TheBrrrgh,
    DonaldsDreamland,
    Lawbot,
    Bossbot
}

export function getToontaskGroups(): ToontaskGroup[] {
    return Object.values(ToontaskGroup).map(group => {
            if (typeof group !== "string") {
                return group
            } else {
                return null
            }
        }
    ).filter(group => group !== null) as ToontaskGroup[]
}

export default ToontaskGroup