import Facility from "../entities/Facility";
import {injectable} from "tsyringe";
import CogType from "../app/entities/invasions/cogs/CogType";

@injectable()
export default class FacilitiesRepository {

    getFacilities(cogType?: CogType): Facility[] {
        const facilities = [
            ...this.getBossbotFacilities(),
            ...this.getLawbotFacilities(),
            ...this.getCashbotFacilities(),
            ...this.getSellbotFacilities()
        ]

        if(cogType !== undefined) {
            return facilities.filter(facility => facility.cogType === cogType)
        } else {
            return facilities
        }
    }

    private getBossbotFacilities(): Facility[] {
        return [
            {
                cogType: CogType.BOSSBOT,
                index: 0,
                name: "Front Three",
                points: 764
            },
            {
                cogType: CogType.BOSSBOT,
                index: 1,
                name: "Middle Six",
                points: 1874
            },
            {
                cogType: CogType.BOSSBOT,
                index: 2,
                name: "Back Nine",
                points: 3350
            }
        ]
    }

    private getLawbotFacilities(): Facility[] {
        return [
            {
                cogType: CogType.LAWBOT,
                index: 0,
                name: "Office A",
                points: 564
            },
            {
                cogType: CogType.LAWBOT,
                index: 1,
                name: "Office B",
                points: 944
            },
            {
                cogType: CogType.LAWBOT,
                index: 2,
                name: "Office C",
                points: 1370
            },
            {
                cogType: CogType.LAWBOT,
                index: 3,
                name: "Office D",
                points: 1842
            },
        ]
    }

    private getCashbotFacilities(): Facility[] {
        return [
            {
                cogType: CogType.CASHBOT,
                index: 0,
                name: "Coin Mint",
                points: 356
            },
            {
                cogType: CogType.CASHBOT,
                index: 1,
                name: "Dollar Mint",
                points: 679
            },
            {
                cogType: CogType.CASHBOT,
                index: 2,
                name: "Bullion Mint",
                points: 1202
            },
        ]
    }

    private getSellbotFacilities(): Facility[] {
        return [
            {
                cogType: CogType.SELLBOT,
                index: 0,
                name: "Short Factory",
                points: 480
            },
            {
                cogType: CogType.SELLBOT,
                index: 1,
                name: "Long Factory",
                points: 776
            },
        ]
    }

}