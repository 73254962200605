import ToontasksCard from "./ToontasksCard"
import styled from "styled-components"
import CogSuitsCard from "./CogSuitsCard"
import FishingCard from "./FishingCard"
import FlowersCard from "./FlowersCard"
import RacingCard from "./RacingCard"
import GolfCard from "./GolfCard"

type Props = {
    toontasksProgress: string,
    bossbotLevel: number,
    lawbotLevel: number,
    cashbotLevel: number,
    sellbotLevel: number,
    fishingProgress: string,
    flowersUnlocked: number,
    racingTrophies: number,
    golfTrophies: number,
    onToontasksClick: () => void,
    onCogSuitsClick: () => void,
    onFishingClick: () => void,
    onFlowersClick: () => void,
    onRacingClick: () => void,
    onGolfClick: () => void
}

export default function LargeTrackingCardContent(props: Props) {
    return (
        <StyledLargeTrackingCardContent>
            <ToontasksCard progress={props.toontasksProgress} onClick={props.onToontasksClick}/>
            <CogSuitsCard bossbotLevel={props.bossbotLevel}
                          lawbotLevel={props.lawbotLevel}
                          cashbotLevel={props.cashbotLevel}
                          sellbotLevel={props.sellbotLevel}
                          onClick={props.onCogSuitsClick}
            />
            <FishingCard progress={props.fishingProgress} onClick={props.onFishingClick} />
            <FlowersCard progress={props.flowersUnlocked} onClick={props.onFlowersClick}/>
            <RacingCard progress={props.racingTrophies} onClick={props.onRacingClick} />
            <GolfCard progress={props.golfTrophies} onClick={props.onGolfClick}/>
        </StyledLargeTrackingCardContent>
    )
}

const StyledLargeTrackingCardContent = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;    
    padding: 20px;
    flex-wrap: wrap;
`