import React, {useEffect, useRef, useState} from "react";

import GetInvasions from "app/application/invasions/getInvasions/GetInvasions";
import HomeViewModel from "app/ui/home/HomeViewModel";
import InfoPanel from "./info/InfoPanel";
import InvasionCardState from "./invasions/InvasionCardState";
import InvasionsCard from "./invasions/InvasionsCard";
import MiniInfoPanel from "./info/MiniInfoPanel";
import {Server} from "./info/status/ServerStatusPanel";
import StatusRepository from "app/data/status/StatusRepository";
import TrackingCard from "./tracking/TrackingCard";
import UserRepository from "app/data/users/UserRepository";
import styled from "styled-components";
import MatchmakingCard from "./matchmaking/MatchmakingCard";
import HomeUIState from "./HomeUIState";
import SillyMeterCard from "./sillymeter/SillyMeterCard";
import ToonGuideCard from "./guide/ToonGuideCard";
import {ScreenSize} from "common/ScreenSize";
import {Column} from "../../../core-anvil/Layouts";

type Props = {
    userRepository: UserRepository,
    statusRepository: StatusRepository,
    getInvasions: GetInvasions,
    screenSize: ScreenSize,
    onTrackingClick: () => void,
    onToontasksClick: () => void,
    onCogSuitsClick: () => void,
    onFishingClick: () => void,
    onRacingClick: () => void,
    onGolfClick: () => void,
    onFlowersClick: () => void,
    onInvasionsClick: () => void,
    onSillyMeterClick: () => void,
    onToonGuideClick: () => void
}

export default function HomeScreen(props: Props) {
    const viewModel = useRef(new HomeViewModel(props.userRepository, props.statusRepository, props.getInvasions))

    const [uiState, setUIState] = useState<HomeUIState>(viewModel.current.uiState.value)

    const [toonName, setToonName] = useState("")
    const [laff, setLaff] = useState(0)
    const [invasionCardState, setInvasionCardState] = useState<InvasionCardState>([])
    const [servers, setServers] = useState<Server[]>([])
    const [toontasksProgress, setToontasksProgress] = useState("")
    const [overallStatusIcon, setOverallStatusIcon] = useState(null)
    const [overallStatusText, setOverallStatusText] = useState("")
    const [isExpandedServerStatusVisible, setIsExpandedServerStatusVisible] = useState(false)

    useEffect(() => {
        viewModel.current.onScreenSizeChange(props.screenSize)
    }, [props.screenSize])

    useEffect(() => {
        const vm = viewModel.current
        vm.uiState.subscribe(setUIState)
        return () => vm.uiState.unsubscribe()
    }, [])


    viewModel.current.toonName.subscribe(newName => {
        if (newName !== toonName) {
            setToonName(newName)
        }
    })

    viewModel.current.laff.subscribe(newLaff => {
        if (newLaff !== laff) {
            setLaff(newLaff)
        }
    })

    viewModel.current.servers.subscribe(newServers => {
        if (newServers !== servers) {
            setServers(newServers)
        }
    })

    viewModel.current.toontasksProgress.subscribe(newToontasksProgress => {
        if (newToontasksProgress !== toontasksProgress) {
            setToontasksProgress(newToontasksProgress)
        }
    })

    viewModel.current.overallStatusIcon.subscribe(newOverallStatusIcon => {
        if (newOverallStatusIcon !== overallStatusIcon) {
            setOverallStatusIcon(newOverallStatusIcon)
        }
    })

    viewModel.current.overallStatusText.subscribe(newOverallStatusText => {
        if (newOverallStatusText !== overallStatusText) {
            setOverallStatusText(newOverallStatusText)
        }
    })

    viewModel.current.isExpandedServerStatusVisible.subscribe(newValue => {
        if (newValue !== isExpandedServerStatusVisible) {
            setIsExpandedServerStatusVisible(newValue)
        }
    })

    viewModel.current.invasionCardState.subscribe(newValue => {
        if (newValue !== invasionCardState) {
            setInvasionCardState(newValue)
        }
    })

    if (props.screenSize === ScreenSize.Small) {
        return (
            <StyledHome>
                <MiniInfoPanel
                    isExpanded={isExpandedServerStatusVisible}
                    servers={servers}
                    statusIcon={overallStatusIcon}
                    overallStatusText={overallStatusText}
                    onStatusInfoClick={() => viewModel.current.onStatusInfoClick()}/>
                <StyledHomeContent>
                    <TrackingCard toontasksProgress={toontasksProgress} screenSize={props.screenSize}
                                  bossbotLevel={uiState.trackingCard.cogSuits.bossbotLevel}
                                  lawbotLevel={uiState.trackingCard.cogSuits.lawbotLevel}
                                  cashbotLevel={uiState.trackingCard.cogSuits.cashbotLevel}
                                  sellbotLevel={uiState.trackingCard.cogSuits.sellbotLevel}
                                  fishingProgress={uiState.trackingCard.fishingProgress}
                                  flowersUnlocked={uiState.trackingCard.flowersUnlocked}
                                  racingTrophies={uiState.trackingCard.racingTrophies}
                                  golfTrophies={uiState.trackingCard.golfTrophies}
                                  onClick={props.onTrackingClick}
                                  onToontasksClick={props.onToontasksClick}
                                  onCogSuitsClick={props.onCogSuitsClick}
                                  onFishingClick={props.onFishingClick}
                                  onRacingClick={props.onRacingClick}
                                  onGolfClick={props.onGolfClick}
                                  onFlowersClick={props.onFlowersClick}
                    />
                    <InvasionsCard invasions={invasionCardState} screenSize={props.screenSize}
                                   onClick={props.onInvasionsClick}/>
                    <MatchmakingCard width={uiState.matchmakingCardWidth} onClick={() => {
                    }}/>
                    <SillyMeterCard width={uiState.sillyMeterCardWidth} onClick={props.onSillyMeterClick}/>
                    <ToonGuideCard width={uiState.toonGuideCardWidth} onClick={props.onToonGuideClick}/>
                </StyledHomeContent>
            </StyledHome>
        )
    } else if (props.screenSize === ScreenSize.Medium) {
        return (
            <StyledHome>
                <InfoPanel toonName={toonName} laff={laff} servers={servers} screenSize={props.screenSize}/>
                <StyledHomeContent>
                    <TrackingCard toontasksProgress={toontasksProgress} screenSize={props.screenSize}
                                  bossbotLevel={uiState.trackingCard.cogSuits.bossbotLevel}
                                  lawbotLevel={uiState.trackingCard.cogSuits.lawbotLevel}
                                  cashbotLevel={uiState.trackingCard.cogSuits.cashbotLevel}
                                  sellbotLevel={uiState.trackingCard.cogSuits.sellbotLevel}
                                  fishingProgress={uiState.trackingCard.fishingProgress}
                                  flowersUnlocked={uiState.trackingCard.flowersUnlocked}
                                  racingTrophies={uiState.trackingCard.racingTrophies}
                                  golfTrophies={uiState.trackingCard.golfTrophies}
                                  onClick={props.onTrackingClick}
                                  onToontasksClick={props.onToontasksClick}
                                  onCogSuitsClick={props.onCogSuitsClick}
                                  onFishingClick={props.onFishingClick}
                                  onRacingClick={props.onRacingClick}
                                  onGolfClick={props.onGolfClick}
                                  onFlowersClick={props.onFlowersClick}

                    />
                    <InvasionsCard invasions={invasionCardState} screenSize={props.screenSize}
                                   onClick={props.onInvasionsClick}/>
                    <MatchmakingCard width={uiState.matchmakingCardWidth} onClick={() => {
                    }}/>
                    <SillyMeterCard width={uiState.sillyMeterCardWidth} onClick={props.onSillyMeterClick}/>
                    <ToonGuideCard width={uiState.toonGuideCardWidth} onClick={props.onToonGuideClick}/>
                </StyledHomeContent>
            </StyledHome>
        )
    } else {
        return (
            <Column horizontalAlignment="center" style={{width: "100%", height: "100%", overflowY: "scroll"}}>
                <InfoPanel toonName={toonName} laff={laff} servers={servers} screenSize={props.screenSize}/>
                <StyledHomeContent>
                    <TrackingCard toontasksProgress={toontasksProgress} screenSize={props.screenSize}
                                  bossbotLevel={uiState.trackingCard.cogSuits.bossbotLevel}
                                  lawbotLevel={uiState.trackingCard.cogSuits.lawbotLevel}
                                  cashbotLevel={uiState.trackingCard.cogSuits.cashbotLevel}
                                  sellbotLevel={uiState.trackingCard.cogSuits.sellbotLevel}
                                  fishingProgress={uiState.trackingCard.fishingProgress}
                                  flowersUnlocked={uiState.trackingCard.flowersUnlocked}
                                  racingTrophies={uiState.trackingCard.racingTrophies}
                                  golfTrophies={uiState.trackingCard.golfTrophies}
                                  onClick={props.onTrackingClick}
                                  onToontasksClick={props.onToontasksClick}
                                  onCogSuitsClick={props.onCogSuitsClick}
                                  onFishingClick={props.onFishingClick}
                                  onRacingClick={props.onRacingClick}
                                  onGolfClick={props.onGolfClick}
                                  onFlowersClick={props.onFlowersClick}
                    />
                    <InvasionsCard invasions={invasionCardState}
                                   screenSize={props.screenSize}
                                   onClick={props.onInvasionsClick}/>
                    <MatchmakingCard width={uiState.matchmakingCardWidth}
                                     onClick={() => {
                                     }}
                    />
                    <SillyMeterToonGuideContainer>
                        <SillyMeterCard width={uiState.sillyMeterCardWidth} onClick={props.onSillyMeterClick}/>
                        <ToonGuideCard width={uiState.toonGuideCardWidth} onClick={props.onToonGuideClick}/>
                    </SillyMeterToonGuideContainer>
                </StyledHomeContent>
            </Column>
        )
    }
}

const StyledHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledHomeContent = styled.div`
  width: 320px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 20px;

  @media (min-width: 720px) {
    width: 640px;
    margin-top: 40px;
  }

  @media (min-width: 1440px) {
    width: auto;
    margin-top: 40px;
  }
`

const SillyMeterToonGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`