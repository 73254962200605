import {injectable} from "tsyringe";
import UserRepository from "../../../data-users/UserRepository";
import {AssignedToontaskPosition} from "../../model/toons/toontasks/ToonToontasks";

@injectable()
export default class StartToontask {

    constructor(private userRepository: UserRepository) {
    }

    async invoke(toontaskId: number) {
        const toon = this.userRepository.currentToon.value
        if (!toon) {
            throw Error("Toon was null")
        }

        const assignedToontasks = toon.toontasks.getAssignedToontasks()
        if (assignedToontasks.topLeft === null) {
            toon.toontasks.setAssignedToontask(AssignedToontaskPosition.TOP_LEFT, toontaskId)
        } else if (assignedToontasks.topRight === null) {
            toon.toontasks.setAssignedToontask(AssignedToontaskPosition.TOP_RIGHT, toontaskId)
        } else if (assignedToontasks.bottomLeft === null) {
            toon.toontasks.setAssignedToontask(AssignedToontaskPosition.BOTTOM_LEFT, toontaskId)
        } else if (assignedToontasks.bottomRight === null) {
            toon.toontasks.setAssignedToontask(AssignedToontaskPosition.BOTTOM_RIGHT, toontaskId)
        } else {
            throw Error("There were no slots to assign toontask: " + toontaskId)
        }
        // noinspection ES6MissingAwait
        this.userRepository.updateToon(toon)
    }

}