import {Box, Column} from "../../../core-anvil/Layouts";
import Card from "../../../common/theme/components/Card";
import {CSSProperties} from "react";
import {TitleSmall} from "../../../app/theme/Typography";
import ToontaskGroup from "../../../data-toontasks/ToontaskGroup";
import {useTheme} from "styled-components";

export type StepsPathStep = {
    title: string,
    isMini: boolean
}

type Props = {
    steps: StepsPathStep[],
    currentStep: number,
    group: ToontaskGroup,
    style?: CSSProperties
}

export default function ToontaskStepsPath(props: Props) {
    const theme = useTheme()

    return (
        <Column horizontalAlignment="center" style={props.style}>
            {createStepCards(
                props.steps,
                props.currentStep,
                theme.colors.getToontaskGroupDarkColor(props.group),
                theme.colors.getToontaskGroupColor(props.group))}
        </Column>
    )
}

function createStepCards(
    steps: StepsPathStep[],
    currentStepIndex: number,
    cardBackgroundColor: string,
    highlightedCardBackgroundColor: string
): JSX.Element[] {
    return steps.flatMap((step) => {
        let array = []
        let backgroundColor = cardBackgroundColor

        if (step === steps[currentStepIndex]) {
            backgroundColor = highlightedCardBackgroundColor
        }

        if (step.isMini) {
            array.push(<MiniStepCard label={step.title} style={{backgroundColor: backgroundColor}}/>)
        } else {
            array.push(<StepCard label={step.title} style={{backgroundColor: backgroundColor}}/>)
        }

        if (step !== steps[steps.length - 1]) {
            array.push(<PathLine/>)
        }

        return array
    })
}

type StepCardProps = {
    label: string,
    style?: CSSProperties
}

function StepCard(props: StepCardProps) {
    return (
        <Card style={{...props.style, width: "100%", height: 80, borderRadius: 40}}>
            <Box style={{width: "100%", height: "100%"}}>
                <TitleSmall style={{textAlign: "center", marginLeft: 12, marginRight: 12}}>{props.label}</TitleSmall>
            </Box>
        </Card>
    )
}

function MiniStepCard(props: StepCardProps) {
    return (
        <Card style={{...props.style, width: "70%", height: 40, borderRadius: 20}}>
            <Box style={{width: "100%", height: "100%"}}>
                <TitleSmall style={{textAlign: "center"}}>{props.label}</TitleSmall>
            </Box>
        </Card>
    )
}

function PathLine() {
    return (
        <Column style={{height: 36, width: 4, backgroundColor: "black", opacity: 0.55}}/>
    )
}