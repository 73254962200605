import { Body1, Body2, Subtitle2 } from "app/theme/Typography"
import Card from "common/theme/components/Card"
import styled from "styled-components"
import LaffProgress from "./LaffProgress"

export type Props = {
    titleText: string,
    progress: number,
    progressText: string,
    maxProgress: number,
    maxText: string,
    nextLaffProgress: number | null,
    extraDataLabel: string,
    extraDataText: string,
    trophiesText: string,
    backgroundColor: string,
    swapInfoSections?: boolean
}

export default function SimpleProgressCard(props: Props) {
    const extraDataLabel = props.swapInfoSections ? "Trophies" : props.extraDataLabel
    const extraDataText = props.swapInfoSections ? props.trophiesText : props.extraDataText

    const trophiesLabel = props.swapInfoSections ? props.extraDataLabel : "Trophies"
    const trophiesText  = props.swapInfoSections ? props.extraDataText : props.trophiesText



    return (
        <StyledSimpleProgressCard style={{ backgroundColor: props.backgroundColor }}>
            <Header>
                <Title>{props.titleText}</Title>
                <NavigateIcon className="material-icons">keyboard_arrow_right</NavigateIcon>
            </Header>
            <LaffProgress
                progress={props.progress}
                max={props.maxProgress}
                progressText={props.progressText}
                nextLaffProgress={props.nextLaffProgress}
                maxText={props.maxText}
                style={{ marginTop: 8 }} />
            <ExtraDataSection>
                <ExtraDataLabel>{extraDataLabel}</ExtraDataLabel>
                <ExtraDataText>{extraDataText}</ExtraDataText>
            </ExtraDataSection>
            <TrophiesSection>
                <TrophiesLabel>{trophiesLabel}</TrophiesLabel>
                <TrophiesText>{trophiesText}</TrophiesText>
            </TrophiesSection>
        </StyledSimpleProgressCard>
    )
}

const StyledSimpleProgressCard = styled(Card)`
    width: calc(280px - 20px - 20px);
    height: calc(175px - 20px - 20px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
`

const Header = styled.div`
    width: 100%;
    height: 30px;
    background-color: #0000004d;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Title = styled(Subtitle2)`
    font-size: 18px;
    margin-left: 12px;
`

const NavigateIcon = styled.span`
    color: white;
    flex: 1;
    text-align: right;
    margin-right: 12px;
`

const ExtraDataSection = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 12px;
`

const ExtraDataLabel = styled(Body2)``

const ExtraDataText = styled(Body1)``

const TrophiesSection = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: end;
    bottom: 12px;
    right: 16px;
`

const TrophiesLabel = styled(Body2)``

const TrophiesText = styled(Body1)``