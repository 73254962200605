import { ScreenSize } from "common/ScreenSize";
import { useTheme } from "styled-components";
import SimpleProgressCard from "../common/SimpleProgressCard";
import SmallRacingCard from "./small/SmallRacingCard";

type Props = {
    screenSize: ScreenSize,
    progressDecimal: number,
    progressPercentText: string,
    progressCountText: string,
    maxText: string,
    nextLaffProgress: number | null,
    cupsText: string,
    trophiesText: string,
    subtitleText: string
}

export default function RacingCard(props: Props) {
    const theme = useTheme()

    if (props.screenSize === ScreenSize.Small) {
        return (
            <SmallRacingCard
                progressDecimal={props.progressDecimal}
                progressText={props.progressPercentText}
                subtitleText={props.subtitleText}
            />
        )
    } else {
        return (
            <SimpleProgressCard
                titleText={"Racing"}
                progress={props.progressDecimal}
                progressText={props.progressCountText}
                maxProgress={1}
                maxText={props.maxText}
                nextLaffProgress={props.nextLaffProgress}
                extraDataLabel="Cups"
                extraDataText={props.cupsText}
                trophiesText={props.trophiesText}
                backgroundColor={theme.colors.racing} 
                swapInfoSections={true}/>

        )
    }
}