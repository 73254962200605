import {injectable} from "tsyringe";
import Facility from "./Facility";
import CogType from "../app/entities/invasions/cogs/CogType";

export type FacilitiesRequiredEvaluatorResult = {
    bossbot: {
        frontThree: number,
        middleSix: number,
        backNine: number
    },
    lawbot: {
        officeA: number,
        officeB: number,
        officeC: number,
        officeD: number
    },
    cashbot: {
        coinMint: number,
        dollarMint: number,
        bullionMint: number
    },
    sellbot: {
        shortFactory: number,
        longFactory: number
    }
}

@injectable()
export default class FacilitiesRequiredEvaluator {

    evaluate(pointsRemaining: number, facilities: Facility[]): FacilitiesRequiredEvaluatorResult {
        const cogType = facilities[0].cogType
        const orderedFacilities = facilities.sort((lhs, rhs) => rhs.points - lhs.points)

        let facilitiesRequired = [0, 0, 0, 0]

        let remainder = pointsRemaining

        let index = 0
        while (remainder > 0) {
            const facilityIndex = orderedFacilities[index].index
            const facilityPoints = orderedFacilities[index].points

            while (remainder > facilityPoints) {
                const previousFacility = orderedFacilities[(Math.max(index - 1, 0))]

                facilitiesRequired[previousFacility.index]++

                remainder -= previousFacility.points
            }

            const smallestFacilityIndex = orderedFacilities[orderedFacilities.length - 1].index
            if (facilityIndex === smallestFacilityIndex && remainder > 0) {
                facilitiesRequired[smallestFacilityIndex] = 1
                remainder -= facilityPoints
            }

            index++
        }

        return this.createResult(cogType, facilitiesRequired)
    }

    private createResult(cogType: CogType, requiredArray: number[]): FacilitiesRequiredEvaluatorResult {
        return {
            bossbot: {
                frontThree: cogType === CogType.BOSSBOT ? requiredArray[0] : 0,
                middleSix: cogType === CogType.BOSSBOT ? requiredArray[1] : 0,
                backNine: cogType === CogType.BOSSBOT ? requiredArray[2] : 0
            },
            lawbot: {
                officeA: cogType === CogType.LAWBOT ? requiredArray[0] : 0,
                officeB: cogType === CogType.LAWBOT ? requiredArray[1] : 0,
                officeC: cogType === CogType.LAWBOT ? requiredArray[2] : 0,
                officeD: cogType === CogType.LAWBOT ? requiredArray[3] : 0,
            },
            cashbot: {
                coinMint: cogType === CogType.CASHBOT ? requiredArray[0] : 0,
                dollarMint: cogType === CogType.CASHBOT ? requiredArray[1] : 0,
                bullionMint: cogType === CogType.CASHBOT ? requiredArray[2] : 0,
            },
            sellbot: {
                shortFactory: cogType === CogType.SELLBOT ? requiredArray[0] : 0,
                longFactory: cogType === CogType.SELLBOT ? requiredArray[1] : 0
            },
        }
    }
}