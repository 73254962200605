import Bossbot from "app/data/game/toontasks/bossbot.json"
import DaisyGardens from "app/data/game/toontasks/dg.json"
import DonaldsDock from "app/data/game/toontasks/dd.json"
import DonaldsDreamland from "app/data/game/toontasks/ddl.json"
import Lawbot from "app/data/game/toontasks/lawbot.json"
import MinniesMelodyland from "app/data/game/toontasks/mml.json"
import TheBrrrgh from "app/data/game/toontasks/brg.json"
import ToontownCentral from "app/data/game/toontasks/ttc.json"

type Step = string[]

export interface Toontask {
    id: number
    title: string,
    reward: string,
    neighbourhood: string,
    relatedTasks: number[],
    steps: Step[]
}

export default class ToontasksData {
    #toontasks: { [key: string]: Toontask[] }
    #idMap: { [key: string]: string }

    constructor() {
        this.#toontasks = {}
        this.#idMap = {}
    }

    async init() {
        this.#toontasks.ttc = ToontownCentral
        this.#toontasks.dd = DonaldsDock
        this.#toontasks.dg = DaisyGardens
        this.#toontasks.mml = MinniesMelodyland
        this.#toontasks.brg = TheBrrrgh
        this.#toontasks.ddl = DonaldsDreamland
        this.#toontasks.law = Lawbot
        this.#toontasks.boss = Bossbot

        for (const toontask of ToontownCentral) {
            this.#idMap[`${toontask.id}`] = "ttc"
        }

        for (const toontask of DonaldsDock) {
            this.#idMap[`${toontask.id}`] = "dd"
        }

        for (const toontask of DaisyGardens) {
            this.#idMap[`${toontask.id}`] = "dg"
        }

        for (const toontask of MinniesMelodyland) {
            this.#idMap[`${toontask.id}`] = "mml"
        }

        for (const toontask of TheBrrrgh) {
            this.#idMap[`${toontask.id}`] = "brg"
        }

        for (const toontask of DonaldsDreamland) {
            this.#idMap[`${toontask.id}`] = "ddl"
        }

        for (const toontask of Lawbot) {
            this.#idMap[`${toontask.id}`] = "law"
        }

        for (const toontask of Bossbot) {
            this.#idMap[`${toontask.id}`] = "boss"
        }

    }

    getToontasks(neighbourhood: string): Toontask[] {
        switch (neighbourhood) {
            case "ttc": return this.#toontasks.ttc
            case "dd": return this.#toontasks.dd
            case "dg": return this.#toontasks.dg
            case "mml": return this.#toontasks.mml
            case "brg": return this.#toontasks.brg
            case "ddl": return this.#toontasks.ddl
            case "law": return this.#toontasks.law
            case "boss": return this.#toontasks.boss
        }

        let combined = []
        for (const groupKey in this.#toontasks) {
            const neighbourhood = this.#toontasks[groupKey]
            for (const toontask of neighbourhood) {
                combined.push(toontask)
            }
        }

        return combined
    }

    getToontaskById(id: number): Toontask {
        const playground = this.#idMap[id.toString()]
        if (playground === undefined) {
            throw Error(`Toontask with id does not exist: ${id}`)
        }

        for (const toontask of this.#toontasks[playground]) {
            if (id === toontask.id) {
                return toontask
            }
        }

        throw Error(`Toontask with id does not exist: ${id}`)
    }

    getNeighbourhoodByToontask(toontask: Toontask) {
        return this.#idMap[toontask.id.toString()]
    }

    getEmptySet() {
        let emptySet: { [key: string]: boolean } = {}

        for (const groupKey in this.#toontasks) {
            for (const toontaskId in this.#toontasks[groupKey]) {
                emptySet[toontaskId] = false
            }
        }

        return emptySet
    }
}