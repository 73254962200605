import React from "react"
import styled from "styled-components"

import GameData from "app/data/GameDataRepository"
import UserRepository from "app/data/UserRepository"
import LegacyToon from "app/data/user/LegacyToon"

import FlowerGroupCard from "app/ui/tracking/flowers/cards/FlowerGroupCard"



interface Props {

}

interface State {
    width: number,
    toon: LegacyToon
}

export default class FlowersGrid extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            width: 0,
            toon: UserRepository.getUser().getCurrentToon()
        }
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState(() => {
                return { width: window.innerWidth };
            });
        });

        UserRepository.observeUser().subscribe(user => {
            this.setState({
                toon: user.getCurrentToon()
            })
        })
    }

    render(): JSX.Element {
        return (
            <StyledFlowersGrid>
                {this.getFlowerColumns()}
            </StyledFlowersGrid>
        )
    }

    getFlowerColumns(): JSX.Element[] {
        let columns: JSX.Element[][] = [[]]

        if (this.state.width >= 1024) {
            columns.push([])
        }

        if (this.state.width >= 1680) {
            columns.push([])
        }

        const columnCount = columns.length
        const numberOfGroups = GameData.getFlowersData().getFlowersByGroup().length

        for (let i = 0; i < numberOfGroups; i++) {
            let col = i % columnCount

            columns[col].push(this.getGroupCard(i + 1))
        }

        let layouts = []
        layouts.push(
            <GroupColumn marginRight={columnCount > 1 ? 8 : 0}>
                {columns[0]}
            </GroupColumn>
        )

        if (columnCount >= 2) {
            layouts.push(
                <GroupColumn marginLeft={8} marginRight={columnCount > 2 ? 8 : 0}>
                    {columns[1]}
                </GroupColumn>
            )
        }

        if (columnCount === 3) {
            layouts.push(
                <GroupColumn marginLeft={8}>
                    {columns[2]}
                </GroupColumn>
            )
        }

        return layouts
    }

    getGroupCard(jbCount: number): JSX.Element {
        return (
            <FlowerGroupCard jbCount={jbCount} toon={this.state.toon} />
        )
    }

}

const StyledFlowersGrid = styled.div`
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: center;
`

interface GroupColumnProps {
    marginLeft?: number,
    marginRight?: number
}

const GroupColumn = styled.div`
    width: 100%;
    margin-left: ${(props: GroupColumnProps) => props.marginLeft || 0}px;
    margin-right: ${(props: GroupColumnProps) => props.marginRight || 0}px;
`