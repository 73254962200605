import { Body2 } from "app/theme/Typography";
import ProgressBar from "common/theme/components/ProgressBar";
import styled from "styled-components";

export type Props = {
    progress: number,
    max: number,
    progressText: string,
    maxText: string,
    nextLaffProgress: number | null,
    isLarge?: boolean
    style?: React.CSSProperties,
    width?: number,
}

export default function LargeLaffProgress(props: Props) {
    const width = (props.width ?? 240)
    const progressTextPosition = props.progress / props.max * width - 14

    const laffMarkerPosition = props.nextLaffProgress
        ? props.nextLaffProgress / props.max * width - 14
        : 0

    const laffMarker = props.nextLaffProgress
        ? <LaffMarker style={{ left: laffMarkerPosition }}>
            <LaffLine />
            <LaffText>+1</LaffText>
        </LaffMarker>
        : null

    return (
        <StyledLaffProgress style={{width: props.width, ...props.style}}>
            <ProgressBar progress={props.progress} max={props.max} style={{ height: 10, width: "100%" }} />
            <ProgressText style={{left: progressTextPosition}}>{props.progressText}</ProgressText>
            {laffMarker}
            <MaxText>{props.maxText}</MaxText>
        </StyledLaffProgress>
    )

}

const StyledLaffProgress = styled.div`
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
`

const ProgressText = styled.p`
    width: 28px;
    text-align: center;
    font-family: 'ImpressBT';
    color: white;
    font-size: 24px;
    position: absolute;
    top: 0;
`

const LaffMarker = styled.div`
    position: absolute;
    top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LaffLine = styled.div`
    height: 28px;
    width: 4px;
    background-color: white;
    border-radius: 20px;
`

const LaffText = styled.p`
    width: 28px;
    text-align: center;
    font-family: 'Minnie';
    color: white;
    fint-size: 17px;
    margin-top: 8px;
`

const MaxText = styled(Body2)`
    position: absolute;
    right: -6px;
    top: 6px;
    font-size: 16px;
`