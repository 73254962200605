import { ScreenSize } from "common/ScreenSize";
import { useTheme } from "styled-components";
import SimpleProgressCard from "../common/SimpleProgressCard";
import SmallFlowersCard from "./small/SmallFlowersCard";

type Props = {
    screenSize: ScreenSize,
    progressDecimal: number
    progressPercentText: string,
    progressCountText: string,
    maxText: string,
    subtitleText: string,
    extraDataLabel: string,
    extraDataText: string,
    trophiesText: string,
    nextLaffProgress: number | null
}

export default function FlowersCard(props: Props) {
    const theme = useTheme()

    if (props.screenSize === ScreenSize.Small) {
        return (
            <SmallFlowersCard
                progressDecimal={props.progressDecimal}
                progressText={props.progressPercentText}
                subtitleText={props.subtitleText} />
        )
    } else {
        return <SimpleProgressCard
            titleText={"Flowers"}
            progress={props.progressDecimal}
            progressText={props.progressCountText}
            maxProgress={1}
            maxText={props.maxText}
            nextLaffProgress={props.nextLaffProgress}
            extraDataLabel={props.extraDataLabel}
            extraDataText={props.extraDataText}
            trophiesText={props.trophiesText}
            backgroundColor={theme.colors.flowers} />
    }

}
