import ToontaskGroup from "data-toontasks/ToontaskGroup"
import styled from "styled-components"
import { AssignedToontaskCardState, AssignedToontasksState, ToontaskSlotPosition } from "../StartedToontasks"
import MediumToontaskSlot from "./MediumToontaskSlot"

export type Props = {
    group: ToontaskGroup,
    assigned: AssignedToontasksState
}

export default function MediumStartedToontasksGrid(props: Props) {
    return (
        <StyledMediumStartedToontasksGrid>
            {createStartedToontaskCard(ToontaskSlotPosition.TOP_LEFT, props.group, props.assigned.topLeft)}
            {createStartedToontaskCard(ToontaskSlotPosition.TOP_RIGHT, props.group, props.assigned.topRight)}
            {createStartedToontaskCard(ToontaskSlotPosition.BOTTOM_LEFT, props.group, props.assigned.bottomLeft)}
            {createStartedToontaskCard(ToontaskSlotPosition.BOTTOM_RIGHT, props.group, props.assigned.bottomRight)}
        </StyledMediumStartedToontasksGrid>
    )
}

function createStartedToontaskCard(position: ToontaskSlotPosition, group: ToontaskGroup, assigned: AssignedToontaskCardState | null) {
    if (assigned != null) {
        return (
            <MediumToontaskSlot
                position={position}
                isFilled={true}
                progress={assigned.progress}
                progressText={assigned.progressText}
                toontaskTitleText={assigned.title}
                rewardText={assigned.reward}
                stepText={assigned.step}
                toontaskGroup={group}
            />
        )
    } else {
        return (
            <MediumToontaskSlot
                position={position}
                isFilled={false}
                progress={0}
                progressText={""}
                toontaskTitleText={""}
                rewardText={""}
                stepText={""}
                toontaskGroup={group}
            />
        )
    }
}

const StyledMediumStartedToontasksGrid = styled.div`
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: fit-content(1rem);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
`