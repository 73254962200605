import Trophies from "app/data/game/racing.json"

export type RacingTrophyGroup = {
    name: string,
    trophies: RacingTrophy[]
}

export type RacingTrophy = {
    title: string,
    id: number,
    type: string
}

export default class RacingData {
    #racingTrophies: RacingTrophyGroup[]

    constructor() {
        this.#racingTrophies = []
    }

    async init() {
        for (const trophyGroup of Trophies) {
            this.#racingTrophies.push(trophyGroup)
        }
    }

    getTrophiesByGroup() {
        return this.#racingTrophies
    }

    getEmptySet() {
        let map: any = {}

        for (let i = 0; i < 30; i++) {
            map[`${i}`] = false
        }

        return map
    }
}