import {inject, injectable} from "tsyringe";
import GetCogSuitPointsDataResult, {CogSuitPointsResult} from "./GetCogSuitPointsDataResult";
import type CogSuitsRepository from "../../../data-cogsuits/cogsuits/CogSuitsRepository";
import CogType from "../../../app/entities/invasions/cogs/CogType";

@injectable()
export default class GetCogSuitPointsData {

    constructor(@inject("CogSuitsRepository") private cogSuitsRepository: CogSuitsRepository) {
    }

    async invoke(cogType: CogType): Promise<GetCogSuitPointsDataResult> {
        const cogSuits: CogSuitPointsResult[] = (await this.cogSuitsRepository.getCogSuits(cogType)).map(cogSuit => {
            return {
                cogName: cogSuit.name,
                cogLevel: cogSuit.level,
                pointsToPromote: cogSuit.pointsForPromo
            }
        })

        return {
            cogSuits: cogSuits
        }
    }
}