import ToontaskGroup from "data-toontasks/ToontaskGroup"
import React, {CSSProperties} from "react";
import ScreenSize from "../../../common/ScreenSize";
import SmallNeighborhoodHeading from "./small/SmallNeighborhoodHeading";
import MediumNeighborhoodHeading from "./medium/MediumNeighborhoodHeading";
import LargeNeighborhoodHeading from "./large/LargeNeighborhoodHeading";

export type NeighborhoodHeadingProps = {
    screenSize: ScreenSize,
    group: ToontaskGroup,
    onToontaskGroupClick: (group: ToontaskGroup) => void,
    style?: CSSProperties
}

export default function NeighborhoodHeading(props: NeighborhoodHeadingProps) {
    const mediumSideMargin = 40

    switch (props.screenSize) {
        case ScreenSize.Small:
            return <SmallNeighborhoodHeading
                screenSize={props.screenSize}
                group={props.group}
                onToontaskGroupClick={props.onToontaskGroupClick}
                style={props.style}/>
        case ScreenSize.Medium:
            return <MediumNeighborhoodHeading
                screenSize={props.screenSize}
                group={props.group}
                onToontaskGroupClick={props.onToontaskGroupClick}
                style={{
                    width: `calc(100% - ${mediumSideMargin * 2}px)`,
                    marginLeft: mediumSideMargin,
                    marginRight: mediumSideMargin,
                    ...props.style
                }}/>
        case ScreenSize.Large:
            return <LargeNeighborhoodHeading
                screenSize={props.screenSize}
                group={props.group}
                onToontaskGroupClick={props.onToontaskGroupClick}
                style={{
                    width: `calc(100% - ${mediumSideMargin * 2}px)`,
                    marginLeft: mediumSideMargin,
                    marginRight: mediumSideMargin,
                    ...props.style
                }}/>
    }
}
