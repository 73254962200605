import { Body1, Body2, Subtitle2 } from "app/theme/Typography"
import Card from "common/theme/components/Card"
import ProgressBar from "common/theme/components/ProgressBar"
import styled from "styled-components"

export enum ToontaskSlotPosition {
    TOP_LEFT = 0,
    TOP_RIGHT = 1,
    BOTTOM_LEFT = 2,
    BOTTOM_RIGHT = 3
}

export type ToontaskSlotState = {
    position: ToontaskSlotPosition,
    isFilled: Boolean,
    progress: number,
    progressText: string,
    toontaskTitleText: string,
    rewardText: string,
    stepText: string
}

type Props = {
    uiState: ToontaskSlotState
}

export default function LargeToontaskSlot(props: Props) {
    if (props.uiState.isFilled) {
        return (
            <StyledLargeToontaskSlot>
                <PositionDots highlightedPosition={props.uiState.position} />
                <StyledDetails>
                    <ToontaskTitle>{props.uiState.toontaskTitleText}</ToontaskTitle>
                    <ProgressBar progress={props.uiState.progress} max={1} style={{ marginTop: 2 }} />
                    <RewardText>{props.uiState.rewardText}</RewardText>
                    <StyledStepText>{props.uiState.stepText}</StyledStepText>
                </StyledDetails>
            </StyledLargeToontaskSlot>
        )
    } else {
        return (
            <StyledEmptyLargeToontaskSlot />
        )
    }


}

const StyledLargeToontaskSlot = styled(Card)`
    width: 312px;
    height: 92px;
    background-color: #7a20de;
    display: flex;
    flex-direction: row;
    padding: 24px;
`

const StyledEmptyLargeToontaskSlot = styled.div`
    width: 312px;
    height: 92px;
    padding: 22px;
    border-radius: ${props => props.theme.dimens.cardCornerRadius};
    border-style: solid;
    border-color: #ffffff44;
    border-width: 2px;
`

const StyledDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`

const ToontaskTitle = styled(Subtitle2)`
    margin-top: -4px;
`

type PositionDotsProps = {
    highlightedPosition: ToontaskSlotPosition
}

function PositionDots(props: PositionDotsProps) {

    let positionDots = []
    for (let i = 0; i < 4; i++) {
        const isHighlighted = i === props.highlightedPosition.valueOf()
        positionDots.push(
            <StyledPositionDot
                color={isHighlighted ? "white" : "black"}
                opacity={isHighlighted ? "1" : "0.2"}
            />
        )
    }


    return (
        <StyledPositionDots>
            {positionDots}
        </StyledPositionDots>
    )
}

const StyledPositionDots = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
`

type StyledPositionDotProps = {
    color: string,
    opacity: string
}

const StyledPositionDot = styled.div<StyledPositionDotProps>`
    width: 10px;
    height: 10px;
    background-color: ${props => props.color};
    opacity: ${props => props.opacity};
    border-radius: 4px;
`

const RewardText = styled(Body2)`
    margin-top: 4px;
`

const StyledStepText = styled(Body1)`
    margin-top: 12px;
`