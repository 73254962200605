import CogType from "app/entities/invasions/cogs/CogType";
import {BehaviorSubject} from "rxjs";
import type CogSuitsRepository from "data-cogsuits/cogsuits/CogSuitsRepository";
import UserRepository from "data-users/UserRepository";
import CogSuitProgressEvaluator from "feat-tracking/entities/cogsuits/CogSuitProgressEvaluator";
import GetCogSuitsProgress, {CogTypeResult, GetCogSuitsProgressResult} from "./GetCogSuitsProgress";
import {inject, injectable} from "tsyringe";

@injectable()
export default class GetCogSuitsProgressUseCase implements GetCogSuitsProgress {

    #userRepository: UserRepository
    #cogSuitsRepository: CogSuitsRepository
    #cogSuitProgressEvaluator: CogSuitProgressEvaluator

    #result: BehaviorSubject<GetCogSuitsProgressResult> = new BehaviorSubject(this.#createEmptyResult())

    constructor(
        userRepository: UserRepository,
        @inject("CogSuitsRepository") cogSuitsRepository: CogSuitsRepository,
        cogSuitProgressEvaluator: CogSuitProgressEvaluator
    ) {
        this.#userRepository = userRepository
        this.#cogSuitProgressEvaluator = cogSuitProgressEvaluator
        this.#cogSuitsRepository = cogSuitsRepository
        this.#listenToUserRepository()
    }

    invoke(): BehaviorSubject<GetCogSuitsProgressResult> {
        return this.#result
    }

    #listenToUserRepository() {
        this.#userRepository.currentToon.subscribe(async toon => {
            if (toon === null) return

            const bossbotSuit = await this.#cogSuitsRepository.getCogSuit(CogType.BOSSBOT, toon.cogSuits.bossbot.index)
            const lawbotSuit = await this.#cogSuitsRepository.getCogSuit(CogType.LAWBOT, toon.cogSuits.lawbot.index)
            const cashbotSuit = await this.#cogSuitsRepository.getCogSuit(CogType.CASHBOT, toon.cogSuits.cashbot.index)
            const sellbotSuit = await this.#cogSuitsRepository.getCogSuit(CogType.SELLBOT, toon.cogSuits.sellbot.index)

            const bossbot: CogTypeResult | null = bossbotSuit.index > 0 ? {
                progress: this.#cogSuitProgressEvaluator.evaluate(bossbotSuit.index),
                cogSuitName: bossbotSuit.name,
                cogSuitLevel: bossbotSuit.level,
                cogSuitIndex: bossbotSuit.index,
                pointsLeftForPromo: bossbotSuit.pointsForPromo
            } : null

            const lawbot: CogTypeResult | null = lawbotSuit.index > 0 ? {
                progress: this.#cogSuitProgressEvaluator.evaluate(lawbotSuit.index),
                cogSuitName: lawbotSuit.name,
                cogSuitLevel: lawbotSuit.level,
                cogSuitIndex: lawbotSuit.index,
                pointsLeftForPromo: lawbotSuit.pointsForPromo
            } : null

            const cashbot: CogTypeResult | null = cashbotSuit.index > 0 ? {
                progress: this.#cogSuitProgressEvaluator.evaluate(cashbotSuit.index),
                cogSuitName: cashbotSuit.name,
                cogSuitLevel: cashbotSuit.level,
                cogSuitIndex: cashbotSuit.index,
                pointsLeftForPromo: cashbotSuit.pointsForPromo
            } : null

            const sellbot: CogTypeResult | null = toon.cogSuits.sellbot.index > 0 ? {
                progress: this.#cogSuitProgressEvaluator.evaluate(sellbotSuit.index),
                cogSuitName: sellbotSuit.name,
                cogSuitLevel: sellbotSuit.level,
                cogSuitIndex: sellbotSuit.index,
                pointsLeftForPromo: sellbotSuit.pointsForPromo
            } : null

            this.#result.next({
                bossbot: bossbot,
                lawbot: lawbot,
                cashbot: cashbot,
                sellbot: sellbot
            })
        })
    }

    #createEmptyResult(): GetCogSuitsProgressResult {
        return {
            bossbot: null,
            lawbot: null,
            cashbot: null,
            sellbot: null
        }
    }
}