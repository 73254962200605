import styled, {useTheme} from "styled-components";
import {Column, Row} from "../../../../core-anvil/Layouts";
import ToontaskGroup from "../../../../data-toontasks/ToontaskGroup";
import ic_ttc from "../../../assets/images/ic_ttc.svg";
import ic_dd from "../../../assets/images/ic_dd.svg";
import ic_dg from "../../../assets/images/ic_dg.svg";
import ic_mml from "../../../assets/images/ic_mml.svg";
import ic_brg from "../../../assets/images/ic_brg.svg";
import ic_ddl from "../../../assets/images/ic_ddl.svg";
import ic_lawbot from "../../../assets/images/ic_lawbot.png";
import ic_bossbot from "../../../assets/images/ic_bossbot.png";
import {HeadlineMedium} from "../../../../app/theme/Typography";
import {NeighborhoodHeadingProps} from "../NeighborhoodHeading";
import ToontaskGroupSelector from "../common/ToontaskGroupSelector";
import React from "react";

export default function MediumNeighborhoodHeading(props: NeighborhoodHeadingProps) {
    const theme = useTheme()
    const primaryColour = `${theme.colors.getToontaskGroupColor(props.group)}99`
    const stylisedName = getToontaskGroupName(props.group)

    return (
        <Column style={{...props.style}}>
            <Row verticalAlignment="center" style={{height: 80, backgroundColor: primaryColour, borderRadius: 50}}>
                <GroupIcon src={getNeighborhoodIcon(props.group)} alt=""/>
                <NeighborhoodName style={{marginTop: 4}}>{stylisedName}</NeighborhoodName>
            </Row>
            <ToontaskGroupSelector
                group={props.group}
                onToontaskGroupClick={props.onToontaskGroupClick}
                style={{marginTop: 24}}
                selectedIconSize={32}
                iconSize={56}
            />
        </Column>
    )
}

function getNeighborhoodIcon(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return ic_ttc
        case ToontaskGroup.DonaldsDock:
            return ic_dd
        case ToontaskGroup.DaisyGardens:
            return ic_dg
        case ToontaskGroup.MinniesMelodyland:
            return ic_mml
        case ToontaskGroup.TheBrrrgh:
            return ic_brg
        case ToontaskGroup.DonaldsDreamland:
            return ic_ddl
        case ToontaskGroup.Lawbot:
            return ic_lawbot
        case ToontaskGroup.Bossbot:
            return ic_bossbot
    }
}

const GroupIcon = styled.img`
  width: 120px;
  height: 120px;
`

const NeighborhoodName = styled(HeadlineMedium)`
  color: white;
  margin-left: 24px;
`

function getToontaskGroupName(group: ToontaskGroup): string {
    switch (group) {
        case ToontaskGroup.ToontownCentral:
            return "Toontown Central"
        case ToontaskGroup.DonaldsDock:
            return "Donald's Dock"
        case ToontaskGroup.DaisyGardens:
            return "Daisy Gardens"
        case ToontaskGroup.MinniesMelodyland:
            return "Minnie's Melodyland"
        case ToontaskGroup.TheBrrrgh:
            return "The Brrrgh"
        case ToontaskGroup.DonaldsDreamland:
            return "Donald's Dreamland"
        case ToontaskGroup.Lawbot:
            return "Lawbot"
        case ToontaskGroup.Bossbot:
            return "Bossbot"
    }
}
