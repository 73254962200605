import {BehaviorSubject} from "rxjs";
import CogSuitsScreenState, {CogSuitDetailsState, CogTypeCardState, FacilityRow} from "./CogSuitsScreenState";
import CogType from "../app/entities/invasions/cogs/CogType";
import {inject, injectable, Lifecycle, scoped} from "tsyringe";
import type GetCogSuitsProgress from "../feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgress";
import {
    CogTypeResult,
    GetCogSuitsProgressResult
} from "../feat-tracking/application/getCogSuitsProgress/GetCogSuitsProgress";
import type PromoteCogSuit from "../feat-tracking/application/promoteCogSuit/PromoteCogSuit";
import type DemoteCogSuit from "../feat-tracking/application/demoteCogSuit/DemoteCogSuit";
import GetFacilities from "../application-game/usecases/getFacilities/GetFacilities";
import {FacilityResult} from "../application-game/usecases/getFacilities/GetFacilitiesResult";
import GetFacilitiesForPromo from "../application-game/usecases/getFacilitiesForPromo/GetFacilitiesForPromo";
import GetFacilitiesForPromoResult
    from "../application-game/usecases/getFacilitiesForPromo/GetFacilitiesForPromoResult";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export default class CogSuitsViewModel {

    uiState = new BehaviorSubject<CogSuitsScreenState>({
        bossbot: {
            cogName: "",
            progress: 0,
            levelText: ""
        },
        lawbot: {
            cogName: "",
            progress: 0,
            levelText: ""
        },
        cashbot: {
            cogName: "",
            progress: 0,
            levelText: ""
        },
        sellbot: {
            cogName: "",
            progress: 0,
            levelText: ""
        },
        cogSuitDetails: null,
    })

    constructor(
        @inject("GetCogSuitsProgress") private getCogSuitsProgress: GetCogSuitsProgress,
        @inject("PromoteCogSuit") private promoteCogSuit: PromoteCogSuit,
        @inject("DemoteCogSuit") private demoteCogSuit: DemoteCogSuit,
        private getFacilities: GetFacilities,
        private getFacilitiesForPromo: GetFacilitiesForPromo
    ) {
        this.listenForCogSuitsProgress()
    }

    onPromoteClick(cogType: CogType) {
        this.promoteCogSuit.invoke(cogType)
    }

    onDemoteClick(cogType: CogType) {
        this.demoteCogSuit.invoke(cogType)
    }

    async onPointsChange(points: string) {
        try {
            let cogSuitDetails = this.uiState.value.cogSuitDetails

            if (cogSuitDetails === null) return

            const progress = this.getCogSuitsProgress.invoke().value

            cogSuitDetails = await this.createCogSuitDetailsState(cogSuitDetails.cogType, points, progress)

            this.uiState.next({
                ...this.uiState.value,
                cogSuitDetails: cogSuitDetails
            })
        } catch (error) {
            // do nothing
        }
    }

    async onCogTypeClick(cogType: CogType) {
        const cogSuitsProgress = this.getCogSuitsProgress.invoke().value

        this.uiState.next({
            ...this.uiState.value,
            cogSuitDetails: await this.createCogSuitDetailsState(cogType, "0", cogSuitsProgress)
        })
    }

    private listenForCogSuitsProgress() {
        this.getCogSuitsProgress.invoke().subscribe(async result => {
            if (result == null) return

            const bossbot = result.bossbot !== null ? this.createCogTypeCardState(result.bossbot) :
                this.getNoCogSuitCogTypeCardState()

            const lawbot = result.lawbot !== null ? this.createCogTypeCardState(result.lawbot) :
                this.getNoCogSuitCogTypeCardState()

            const cashbot = result.cashbot !== null ? this.createCogTypeCardState(result.cashbot) :
                this.getNoCogSuitCogTypeCardState()

            const sellbot = result.sellbot !== null ? this.createCogTypeCardState(result.sellbot) :
                this.getNoCogSuitCogTypeCardState()

            let cogSuitDetails = this.uiState.value.cogSuitDetails
            if (cogSuitDetails !== null) {
                cogSuitDetails = await this.createCogSuitDetailsState(
                    cogSuitDetails.cogType,
                    cogSuitDetails.points,
                    result
                )
            }


            this.uiState.next({
                ...this.uiState.value,
                bossbot: bossbot,
                lawbot: lawbot,
                cashbot: cashbot,
                sellbot: sellbot,
                cogSuitDetails
            })
        })
    }

    private createCogTypeCardState(result: CogTypeResult): CogTypeCardState {
        return {
            cogName: result.cogSuitName,
            levelText: `Level ${result.cogSuitLevel}`,
            progress: result.progress
        }
    }

    private getNoCogSuitCogTypeCardState(): CogTypeCardState {
        return {
            cogName: "No Cog Disguise",
            levelText: "-",
            progress: 0
        }
    }

    private async createCogSuitDetailsState(cogType: CogType, points: string, result: GetCogSuitsProgressResult): Promise<CogSuitDetailsState> {
        const cogTypeResult = cogType === CogType.BOSSBOT ? result.bossbot :
            cogType === CogType.LAWBOT ? result.lawbot :
                cogType === CogType.CASHBOT ? result.cashbot :
                    result.sellbot

        let pointsName = ""
        switch (cogType) {
            case CogType.BOSSBOT:
                pointsName = "Stock Options"
                break
            case CogType.LAWBOT:
                pointsName = "Jury Notices"
                break
            case CogType.CASHBOT:
                pointsName = "Cogbucks"
                break
            case CogType.SELLBOT:
                pointsName = "Merits"
                break
        }

        if (cogTypeResult === null) {
            return {
                cogType: cogType,
                cogName: "Not unlocked",
                progress: 0,
                levelText: "-",
                points: points.toString(),
                pointsLabel: this.getPointsLabel(cogType),
                requiredPoints: "-",
                facilities: {
                    pointsName: pointsName,
                    rows: await this.createFacilityRows(cogType, 0)
                }
            }
        }

        let pointsInt = parseInt(points)
        let pointsText = pointsInt.toString()

        if (pointsInt < 0) {
            pointsInt = 0
            pointsText = "0"
        } else if (isNaN(pointsInt)) {
            pointsInt = 0
            pointsText = ""
        }

        return {
            cogType: cogType,
            cogName: cogTypeResult.cogSuitName,
            progress: cogTypeResult.progress,
            levelText: `Level ${cogTypeResult.cogSuitLevel}`,
            points: pointsText,
            pointsLabel: this.getPointsLabel(cogType),
            requiredPoints: cogTypeResult.pointsLeftForPromo.toString(),
            facilities: {
                pointsName: pointsName,
                rows: await this.createFacilityRows(cogType, pointsInt)
            }
        }
    }

    private async createFacilityRows(cogType: CogType, points: number): Promise<FacilityRow[]> {

        const facilities = this.getFacilitiesForCogType(cogType)
        const cogSuitIndex = this.getCurrentCogSuitIndex(cogType)
        const facilitiesRequired = await this.getFacilitiesForPromo.invoke(cogType, cogSuitIndex, points)

        return facilities.map(facility => {
            const requiredCount = this.extractFacilitiesRequiredCount(cogType, facility.index, facilitiesRequired)

            return {
                name: facility.name,
                points: facility.points,
                requiredCount: requiredCount === 0 ? "-" : requiredCount.toString()
            }
        }).reverse()
    }

    private getFacilitiesForCogType(cogType: CogType): FacilityResult[] {
        const facilities = this.getFacilities.invoke()

        switch (cogType) {
            case CogType.BOSSBOT:
                return facilities.bossbot
            case CogType.LAWBOT:
                return facilities.lawbot
            case CogType.CASHBOT:
                return facilities.cashbot
            case CogType.SELLBOT:
                return facilities.sellbot
        }

        throw Error()
    }

    private getCurrentCogSuitIndex(cogType: CogType): number {
        const progress = this.getCogSuitsProgress.invoke().value

        switch (cogType) {
            case CogType.BOSSBOT:
                return progress.bossbot!!.cogSuitIndex
            case CogType.LAWBOT:
                return progress.lawbot!!.cogSuitIndex
            case CogType.CASHBOT:
                return progress.cashbot!!.cogSuitIndex
            case CogType.SELLBOT:
                return progress.sellbot!!.cogSuitIndex
        }

        throw Error()
    }

    private extractFacilitiesRequiredCount(cogType: CogType, index: number, facilitiesRequired: GetFacilitiesForPromoResult): number {
        switch (cogType) {
            case CogType.BOSSBOT:
                return this.extractBossbotFacilitiesRequiredCount(index, facilitiesRequired)
            case CogType.LAWBOT:
                return this.extractLawbotFacilitiesRequiredCount(index, facilitiesRequired)
            case CogType.CASHBOT:
                return this.extractCashbotFacilitiesRequiredCount(index, facilitiesRequired)
            case CogType.SELLBOT:
                return this.extractSellbotFacilitiesRequiredCount(index, facilitiesRequired)
        }

        throw Error()
    }

    private extractBossbotFacilitiesRequiredCount(index: number, facilitiesRequired: GetFacilitiesForPromoResult): number {
        switch (index) {
            case 0:
                return facilitiesRequired.bossbot.frontThree
            case 1:
                return facilitiesRequired.bossbot.middleSix
            case 2:
                return facilitiesRequired.bossbot.backNine
        }

        throw Error()
    }

    private extractLawbotFacilitiesRequiredCount(index: number, facilitiesRequired: GetFacilitiesForPromoResult): number {
        switch (index) {
            case 0:
                return facilitiesRequired.lawbot.officeA
            case 1:
                return facilitiesRequired.lawbot.officeB
            case 2:
                return facilitiesRequired.lawbot.officeC
            case 3:
                return facilitiesRequired.lawbot.officeD
        }

        throw Error()
    }

    private extractCashbotFacilitiesRequiredCount(index: number, facilitiesRequired: GetFacilitiesForPromoResult): number {
        switch (index) {
            case 0:
                return facilitiesRequired.cashbot.coinMint
            case 1:
                return facilitiesRequired.cashbot.dollarMint
            case 2:
                return facilitiesRequired.cashbot.bullionMint
        }

        throw Error()
    }

    private extractSellbotFacilitiesRequiredCount(index: number, facilitiesRequired: GetFacilitiesForPromoResult): number {
        switch (index) {
            case 0:
                return facilitiesRequired.sellbot.shortFactory
            case 1:
                return facilitiesRequired.sellbot.longFactory
        }

        throw Error()
    }

    private getPointsLabel(cogType: CogType): string {
        switch(cogType) {
            case CogType.BOSSBOT:
                return "Current Stock Options"
            case CogType.LAWBOT:
                return "Current Jury Notices"
            case CogType.CASHBOT:
                return "Current Cogbucks"
            case CogType.SELLBOT:
                return "Current Merits"
        }

        throw Error()
    }

}
