import Card from "../../../common/theme/components/Card";
import {CSSProperties} from "react";
import {TitleLarge, TitleMedium} from "../../../app/theme/Typography";
import CogType from "../../../app/entities/invasions/cogs/CogType";
import {useTheme} from "styled-components";
import {Column, Row, Spacer} from "../../../core-anvil/Layouts";
import ProgressBar from "../../../common/theme/components/ProgressBar";
import CogSuitPoints from "../CogSuitPoints";
import FacilitiesTable from "../FacilitiesTable";
import {FacilitiesTableState} from "../../CogSuitsScreenState";
import PromotionButtons from "../PromotionButtons";
import PointsTableButton from "../PointsTableButton";
import {DependencyContainer} from "tsyringe";
import useScreenSize from "../../../core-theme/hooks/useScreenSize";

type Props = {
    container: DependencyContainer,
    pointsLabel: string,
    cogType: CogType,
    cogName: string,
    progress: number,
    levelText: string,
    points: string,
    requiredPoints: string,
    facilities: FacilitiesTableState,
    onPointsChange: (points: string) => void,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function LargeCogSuitDetails(props: Props) {
    const theme = useTheme()
    const screenSize = useScreenSize()

    const backgroundColor = theme.colors.getCogTypeColor(props.cogType)

    return (
        <Card style={{...props.style, backgroundColor: backgroundColor}}>
            <Column style={{marginLeft: 40, marginRight: 40, marginTop: 32, marginBottom: 32}}>
                <TitleLarge style={{fontSize: 34}}>{props.cogName}</TitleLarge>
                <ProgressBar progress={props.progress} max={1} style={{height: 12, marginTop: 20}}/>
                <TitleMedium style={{marginTop: 24, fontSize: 24}}>{props.levelText}</TitleMedium>
                <Row style={{marginLeft: 300}}>
                    <Column horizontalAlignment="center">
                        <PromotionButtons
                            cogType={props.cogType}
                            onPromoteClick={props.onPromoteClick}
                            onDemoteClick={props.onDemoteClick}
                            style={{width: "100%"}}
                        />
                        <CogSuitPoints
                            screenSize={screenSize}
                            pointsLabel={props.pointsLabel}
                            points={props.points}
                            requiredPoints={props.requiredPoints}
                            onPointsChange={props.onPointsChange}
                            style={{marginTop: 36}}
                        />
                        <PointsTableButton container={props.container} style={{marginTop: 24}}/>
                    </Column>
                    <Spacer/>
                    <FacilitiesTable
                        screenSize={screenSize}
                        cogType={props.cogType}
                        pointsName={props.facilities.pointsName}
                        rows={props.facilities.rows}
                        style={{marginTop: 32, marginLeft: 36}}/>
                </Row>
            </Column>
        </Card>
    )
}