import {Box, Column, Row, Spacer} from "../../../core-anvil/Layouts";
import {CSSProperties} from "react";
import {LabelMedium, TitleLarge, TitleMedium} from "../../../app/theme/Typography";
import ScreenSize from "../../../common/ScreenSize";

type Props = {
    screenSize: ScreenSize,
    backgroundColor: string,
    icon: string,
    featureName: string,
    screenName: string,
    previousScreenName: string,
    onBackClick: () => void,
    style?: CSSProperties
}

export default function NavigationBanner(props: Props) {
    const height = props.screenSize === ScreenSize.Small ? 100 : 116
    const leftMargin = props.screenSize === ScreenSize.Small ? 24 : 40

    return (
        <Row verticalAlignment="center" style={{
            width: "100%",
            height: height,
            borderBottomLeftRadius: 60,
            borderBottomRightRadius: 60,
            backgroundColor: props.backgroundColor,
            ...props.style
        }}>
            <Spacer style={{width: leftMargin, margin: 0}}/>
            <MaterialIcon icon={props.icon}/>
            <Column style={{marginLeft: 12}}>
                <Heading featureName={props.featureName} screenName={props.screenName}/>
                <Spacer style={{height: 4}}/>
                <BackButton previousScreenName={props.previousScreenName} onClick={props.onBackClick}/>
            </Column>

        </Row>
    )
}

type IconProps = {
    icon: string
}

function MaterialIcon(props: IconProps) {
    return (
        <Box style={{width: 50, height: 50, borderRadius: 50, border: "3px solid white"}}>
            <span style={{color: "white", fontSize: 36}} className="material-icons">{props.icon}</span>
        </Box>
    )
}

type HeadingProps = {
    featureName: string,
    screenName: string
}

function Heading(props: HeadingProps) {
    return (
        <Row verticalAlignment="center">
            <TitleLarge>{props.featureName}</TitleLarge>
            <Spacer style={{width: 8}}/>
            <TitleLarge style={{fontWeight: 300}}>|</TitleLarge>
            <Spacer style={{width: 8}}/>
            <TitleMedium style={{fontSize: 25, fontWeight: 300}}>{props.screenName}</TitleMedium>
        </Row>
    )
}

type BackProps = {
    previousScreenName: string,
    onClick: () => void
}

function BackButton(props: BackProps) {
    return (
        <Row onClick={props.onClick}>
            <span style={{color: "white", fontSize: 24, marginLeft: -2}} className="material-icons">chevron_left</span>
            <LabelMedium style={{marginTop: 4, fontWeight: 300}}>Go back to {props.previousScreenName}</LabelMedium>
        </Row>
    )
}