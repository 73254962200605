import Card from "../../../common/theme/components/Card";
import {CSSProperties} from "react";
import {TitleLarge, TitleMedium} from "../../../app/theme/Typography";
import CogType from "../../../app/entities/invasions/cogs/CogType";
import {useTheme} from "styled-components";
import {Column} from "../../../core-anvil/Layouts";
import ProgressBar from "../../../common/theme/components/ProgressBar";
import FacilitiesTable from "../FacilitiesTable";
import {FacilitiesTableState} from "../../CogSuitsScreenState";
import CogSuitPoints from "../CogSuitPoints";
import ScreenSize from "../../../common/ScreenSize";
import PromotionButtons from "../PromotionButtons";
import PointsTableButton from "../PointsTableButton";
import {DependencyContainer} from "tsyringe";

type Props = {
    container: DependencyContainer,
    cogType: CogType,
    pointsLabel: string,
    cogName: string,
    progress: number,
    levelText: string,
    points: string,
    requiredPoints: string,
    facilities: FacilitiesTableState,
    onPointsChange: (points: string) => void,
    onPromoteClick: () => void,
    onDemoteClick: () => void,
    style?: CSSProperties
}

export default function SmallCogSuitDetails(props: Props) {
    const theme = useTheme()

    const backgroundColor = theme.colors.getCogTypeColor(props.cogType)

    return (
        <Card style={{...props.style, backgroundColor: backgroundColor}}>
            <Column style={{marginLeft: 20, marginRight: 16, marginTop: 20, marginBottom: 16}}>
                <TitleLarge>{props.cogName}</TitleLarge>
                <ProgressBar progress={props.progress} max={1} style={{height: 8, marginTop: 10}}/>
                <TitleMedium style={{marginTop: 12}}>{props.levelText}</TitleMedium>
                <PromotionButtons
                    cogType={props.cogType}
                    onPromoteClick={props.onPromoteClick}
                    onDemoteClick={props.onDemoteClick}
                    style={{width: "100%", marginTop: 40}}
                />
                <PointsTableButton container={props.container} style={{marginTop: 20, width: "fit-content", alignSelf: "center"}}/>
                <CogSuitPoints screenSize={ScreenSize.Small} pointsLabel={props.pointsLabel}
                               points={props.points} requiredPoints={props.requiredPoints}
                               onPointsChange={props.onPointsChange} style={{marginTop: 56}}/>
                <FacilitiesTable
                    screenSize={ScreenSize.Small}
                    cogType={props.cogType}
                    pointsName={props.facilities.pointsName}
                    rows={props.facilities.rows}
                    style={{marginTop: 32}}/>
            </Column>
        </Card>
    )
}