import {injectable} from "tsyringe";
import FishingSpeciesScreenModel from "../FishingSpeciesScreenModel";

@injectable()
export default class FishListViewModel {

    constructor(private fishingSpeciesScreenModel: FishingSpeciesScreenModel) {
    }

    onInfoClick(fishId: number) {
        this.fishingSpeciesScreenModel.showFishInsightsDialog(fishId)
    }

}