import FeatureCard from "../FeatureCard";
import LargeTrackingCardContent from "./large/LargeTrackingCardContent";
import SmallTrackingCardContent from "./small/SmallTrackingCardContent";
import {ThemeContext} from "styled-components";
import {useContext} from "react";
import {ScreenSize} from "common/ScreenSize";

type Props = {
    toontasksProgress: string,
    bossbotLevel: number,
    lawbotLevel: number,
    cashbotLevel: number,
    sellbotLevel: number,
    fishingProgress: string,
    flowersUnlocked: number,
    racingTrophies: number,
    golfTrophies: number
    screenSize: ScreenSize,
    onClick: () => void,
    onToontasksClick: () => void,
    onCogSuitsClick: () => void,
    onFishingClick: () => void,
    onRacingClick: () => void,
    onGolfClick: () => void,
    onFlowersClick: () => void
}

export default function TrackingCard(props: Props) {
    const theme = useContext(ThemeContext)

    if (props.screenSize === ScreenSize.Small) {
        return (
            <FeatureCard
                title="Tracking"
                smallWidth={320}
                mediumWidth={0}
                largeWidth={0}
                headerBackgroundColor={theme.colors.tracking}
                contentBackgroundColor={theme.colors.trackingDark}
                onClick={props.onClick}
            >
                <SmallTrackingCardContent toontasksProgress={props.toontasksProgress}/>
            </FeatureCard>
        )
    } else {
        return (
            <FeatureCard
                title="Tracking"
                smallWidth={0}
                mediumWidth={340}
                largeWidth={340}
                headerBackgroundColor={theme.colors.tracking}
                contentBackgroundColor={theme.colors.trackingDark}
                onClick={props.onClick}
            >
                <LargeTrackingCardContent
                    toontasksProgress={props.toontasksProgress}
                    bossbotLevel={props.bossbotLevel}
                    lawbotLevel={props.lawbotLevel}
                    cashbotLevel={props.cashbotLevel}
                    sellbotLevel={props.sellbotLevel}
                    fishingProgress={props.fishingProgress}
                    flowersUnlocked={props.flowersUnlocked}
                    racingTrophies={props.racingTrophies}
                    golfTrophies={props.golfTrophies}
                    onToontasksClick={props.onToontasksClick}
                    onCogSuitsClick={props.onCogSuitsClick}
                    onFishingClick={props.onFishingClick}
                    onRacingClick={props.onRacingClick}
                    onGolfClick={props.onGolfClick}
                    onFlowersClick={props.onFlowersClick}
                />
            </FeatureCard>
        )
    }
}