import AccountInfo from "app/ui/app/toolbar/user/AccountInfo"
import { Body1 } from "app/theme/Typography";
import Paper from "@material-ui/core/Paper";
import ToonSelector from "app/ui/app/toolbar/user/toons/ToonSelector";
import ic_app_icon from "app/res/images/ic_app_icon.svg"
import styled from "styled-components";
import ScreenSize from "common/ScreenSize";
import {ToonListItem} from "../../RootScreenState";

const toolbarHeight = 60

type Props = {
    selectedToonId: string,
    laff: number,
    currentToonName: string,
    toons: ToonListItem[],
    email: string,
    screenSize: ScreenSize,
    isToonSelectorExpanded: boolean,
    onToonSelectorClick: () => any,
    onToonSelected: (toonName: string) => any
    onMenuClick: () => any,
}

export default function Toolbar(props: Props) {
    return (
        <StyledToolbar elevation={2} square={true} isToonSelectorExpanded={props.isToonSelectorExpanded}>
            <StyledLeftSide>
                {MenuIcon(props.screenSize, props.onMenuClick)}
                {Logo(props.screenSize)}
                {AppNameText(props.screenSize)}
                {TLDText(props.screenSize)}
                {Divider(props.screenSize)}
                {SloganText(props.screenSize)}
            </StyledLeftSide>
            <StyledRightSide>
                <ToonSelector
                    selectedToonId={props.selectedToonId}
                    laff={props.laff}
                    toonName={props.currentToonName}
                    screenSize={props.screenSize}
                    toonList={props.toons}
                    onToonSelected={props.onToonSelected}
                    isExpanded={props.isToonSelectorExpanded}
                    onSelectorClick={props.onToonSelectorClick}
                />
                <AccountInfo email={props.email} screenSize={props.screenSize} />
            </StyledRightSide>
        </StyledToolbar>
    )
}

type StyledToolbarProps = {
    isToonSelectorExpanded: boolean
}

const StyledToolbar = styled(Paper)<StyledToolbarProps>`
&& {
    width: 100%;
    height: ${toolbarHeight}px;
    background-color: ${props => props.theme.colors.primary};
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: ${props => props.isToonSelectorExpanded ? 3 : 2};
}
`

const StyledLeftSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledRightSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

function MenuIcon(screenSize: ScreenSize, onClick: () => any) {
    if (screenSize !== ScreenSize.Large) {
        return (
            <StyledMenuIcon id="menu-button" className="material-icons" onClick={onClick}>menu</StyledMenuIcon>
        )
    } else {
        return
    }
}

const StyledMenuIcon = styled.span`
    margin-left: 18px;
    color: white;
`

function Logo(screenSize: ScreenSize) {
    if (screenSize !== ScreenSize.Small) {
        return (
            <StyledLogo src={ic_app_icon} />
        )
    } else {
        return
    }
}

const StyledLogo = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 16px;
`

function AppNameText(screenSize: ScreenSize) {
    if (screenSize !== ScreenSize.Small) {
        return (
            <StyledAppNameText>ToonTracker</StyledAppNameText>
        )
    } else {
        return
    }
}

const StyledAppNameText = styled.p`
    font-family: 'ImpressBT';
    font-size: 22px;
    color: white;
    margin-left: 12px;
    margin-bottom: 3px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
`

function TLDText(screenSize: ScreenSize) {
    if (screenSize !== ScreenSize.Small) {
        return (
            <StyledTLDText>.app</StyledTLDText>
        )
    } else {
        return
    }
}

const StyledTLDText = styled.p`
    font-family: 'ImpressBT';
    font-size: 22px;
    color: white;
    margin-left: 0px;
    margin-bottom: 3px;
    opacity: 40%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
`

function Divider(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Large) {
        return <StyledDivider />
    } else {
        return
    }
}

const StyledDivider = styled.div`
    width: 2px;
    height: 22px;
    margin-left: 12px;
    background-color: white;
`

function SloganText(screenSize: ScreenSize) {
    if (screenSize === ScreenSize.Large) {
        return (
            <StyledSloganText>The ultimate unofficial ToonTown companion!</StyledSloganText>
        )
    } else {
        return
    }

}

const StyledSloganText = styled(Body1)`
    margin-left: 14px;
    margin-top: 2px;
`
