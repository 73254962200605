import {Column} from "../core-anvil/Layouts";
import FishingOverviewScreen from "./overview/FishingOverviewScreen";
import React from "react";
import ScreenSize from "../common/ScreenSize";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import FishingSpeciesScreen from "./species/FishingSpeciesScreen";

export const FishingNavigationDestination = {
    route: "/tracking/fishing",
    createRoute: () => "/tracking/fishing"
}

export const FishingOverviewDestination = {
    route: "/tracking/fishing/overview",
    createRoute: () => "/tracking/fishing/overview"
}

type FishingSpeciesDestinationArgs = {
    speciesId: number
}

export const FishingSpeciesDestination = {
    route: "/tracking/fishing/species/:id",
    createRoute: (id: number) => `/tracking/fishing/species/${id}`,
    getArgs(params: any): FishingSpeciesDestinationArgs {
        return {speciesId: parseInt(params.id)}
    }
}

type Props = {
    screenSize: ScreenSize
}

export default function FishingNavigation(props: Props) {
    const history = useHistory()

    return (
        <Column style={{width: "100%", height: "100%"}}>
            <Switch>
                <Route path={FishingOverviewDestination.route}>
                    <FishingOverviewScreen screenSize={props.screenSize}
                                           navigateToSpecies={id => history.push(FishingSpeciesDestination.createRoute(id))}
                    />
                </Route>
                <Route path={FishingSpeciesDestination.route}>
                    <FishingSpeciesScreen screenSize={props.screenSize}
                                          navigateToFishingOverview={() => history.push(FishingOverviewDestination.createRoute())}/>
                </Route>
                <Route path={FishingNavigationDestination.route}>
                    <Redirect to={FishingOverviewDestination.createRoute()}/>
                </Route>
            </Switch>
        </Column>
    )
}