import {CSSProperties} from "react";
import {Column, Spacer} from "../../../core-anvil/Layouts";
import {LabelLarge, LabelSmall} from "../../../app/theme/Typography";
import TextField from "../../../core-anvil/TextField";

type Props = {
    points: string,
    requiredPoints: string,
    onPointsChange: (points: string) => void,
    style?: CSSProperties
}

export default function MediumCogSuitPoints(props: Props) {
    return (
        <Column horizontalAlignment="center" style={{...props.style}}>
            <CurrentPoints style={{width: 120}} points={props.points} onPointsChange={props.onPointsChange}/>
            <Spacer style={{margin: 0, height: 32}}/>
            <RequiredPoints points={props.requiredPoints} style={{width: 120}}/>
        </Column>
    )
}

type CurrentPointsProps = {
    points: string,
    onPointsChange: (points: string) => void,
    style?: CSSProperties
}

function CurrentPoints(props: CurrentPointsProps) {
    return (
        <Column horizontalAlignment="center" style={{...props.style}}>
            <LabelSmall>Current Points</LabelSmall>
            <TextField style={{width: 100, fontSize: 36, fontWeight: 300}} value={props.points} onTextChange={value => props.onPointsChange(value)}/>
        </Column>
    )
}

type RequiredPointsProps = {
    points: string,
    style?: CSSProperties
}

function RequiredPoints(props: RequiredPointsProps) {
    return (
        <Column horizontalAlignment="center"  style={{...props.style}}>
            <LabelSmall>Points for Promotion</LabelSmall>
            <LabelLarge style={{fontSize: 36, marginTop: 18}}>{props.points}</LabelLarge>
        </Column>
    )
}