import {Body2, Headline5} from "app/theme/Typography"
import {CircularProgressbar} from "react-circular-progressbar"
import styled from "styled-components"

type Props = {
    width: number,
    progressDecimal: number,
    progressText: string,
    title: string,
    subtitle: string,
    backgroundColor: string,
}

export default function ActivityHeader(props: Props) {
    return (
        <StyledActivityHeaderCard width={props.width} backgroundColor={props.backgroundColor}>
            <ActivityProgress progressDecimal={props.progressDecimal} progressText={props.progressText}/>

            <StyledCardInfo>
                <StyledCardTitle>{props.title}</StyledCardTitle>
                <StyledCardSubtitle>{props.subtitle}</StyledCardSubtitle>
            </StyledCardInfo>

            <StyledArrow className="material-icons">keyboard_arrow_right</StyledArrow>

        </StyledActivityHeaderCard>
    )

}

type StyledActivityHeaderCardProps = {
    width: number,
    backgroundColor: string
}

const StyledActivityHeaderCard = styled.div<StyledActivityHeaderCardProps>`
  width: ${props => props.width}px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: row;
`

type ActivityProgressProps = {
    progressDecimal: number,
    progressText: string,
}

function ActivityProgress(props: ActivityProgressProps) {
    return (
        <StyledCircularProgressBar
            maxValue={1}
            value={props.progressDecimal}
            text={props.progressText}
            styles={{
                path: {
                    stroke: "white",
                    strokeLinecap: "butt"
                },

                trail: {
                    stroke: "#0003"
                },

                text: {
                    fill: "white",
                    fontSize: "30px",
                    fontWeight: 500,
                    fontFamily: "Catamaran"
                }
            }}
        />
    )
}

const StyledCircularProgressBar = styled(CircularProgressbar)`
  width: 60px;
  width: 60px;
`

const StyledCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  padding-top: 5px;
`

const StyledCardTitle = styled(Headline5)`
  color: white;
  margin-top: 6px;
`

const StyledCardSubtitle = styled(Body2)`
  color: white;
  margin-top: 2px;
  font-weight: lighter;
`

const StyledArrow = styled.span`
  font-size: 36px;
  color: white;
  margin-left: auto;
  margin-right: 4px;
  margin-top: auto;
  margin-bottom: auto;
`