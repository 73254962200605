import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

interface HeaderProps {
    dark?: any,
    pageStack: {    // last element is current screen
        title: string,
        url: string
    }[]
}

const StyledHeader = styled.div`
    width: 100%;
    height: auto;
    padding-top: 16px;
    display: flex;
    flex-direction: row;
`

const BackButton = styled.div`
    width: 26px;
    height: 26px;
    float: left;
    margin-top: 12px;
    margin-right: 14px;
    margin-left: -8px;
    padding: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #0008;
    border-radius: 40px;
    cursor: pointer;

`

export default class Header extends React.Component<HeaderProps> {
    render(): JSX.Element {
        const pageStack = this.props.pageStack
        const hasParent = pageStack.length > 1
        let backButton = null
        let textColour = "white"

        if (this.props.dark) {
            textColour = "#333"
        }

        if (pageStack.length > 1) {
            const secondLastPage = pageStack[pageStack.length - 2]
            backButton = this.renderBackButton(secondLastPage.url)
        }

        const lastPage = pageStack[pageStack.length - 1]
        const currentTitle = lastPage?.title

        return (
            <StyledHeader>
                {backButton}
                <div>
                    {this.renderParentElements(textColour)}
                    <p className="headline3" style={{
                        position: "relative",
                        color: textColour,
                        marginTop: !hasParent ? 15 : 0,
                        fontSize: !hasParent ? 45 : ""
                    }}>{currentTitle}</p>
                </div>
            </StyledHeader>
        )
    }

    renderParentElements(textColour: string) {
        const pageStack = this.props.pageStack

        let parentElements = []
        for (let i = 0; i < pageStack.length - 1; i++) {
            const parent = pageStack[i]
            const isLast = i === pageStack.length - 2

            parentElements.push(
                <p className="overline" style={{ color: textColour, textAlign: "center" }}>{parent.title.toUpperCase()}</p>
            )

            if (!isLast) {
                parentElements.push(
                    <span className="material-icons" style={{ color: textColour, fontSize: 24, opacity: 0.5 }}>navigate_next</span>
                )
            }
        }

        const divHeight = parentElements.length > 0 ? 24 : 0

        return (
            <div style={{
                height: divHeight,
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}>
                {parentElements}
            </div>
        )
    }

    renderBackButton(url: string): JSX.Element {
        return (
            <Link to={url}>
                <BackButton>
                    <span className="material-icons" style={{ color: "white", fontSize: 26 }}>arrow_back</span>
                </BackButton>
            </Link>
        )
    }
}