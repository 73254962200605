import styled from "styled-components"

interface Props {
    small?: any,
    backgroundColor?: string
}

const FloatingActionButton = styled.div`
    width: ${(props: Props) => props.small ? 36 : 48}px;
    height: ${(props: Props) => props.small ? 36 : 48}px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props: Props) => props.backgroundColor || "white"};
    border-radius: 100px;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.16);
`

export default FloatingActionButton