// Themes

import "./invasion-card.scss";

import { Card } from "@material-ui/core";
import { default as ambulance_chaser, default as bottom_feeder } from "common/resources/images/cog_ambulance_chaser.png";
import back_stabber from "common/resources/images/cog_back_stabber.png";
import bean_counter from "common/resources/images/cog_bean_counter.png";
import big_wig from "common/resources/images/cog_big_wig.png";
import bloodsucker from "common/resources/images/cog_bloodsucker.png";
import cold_caller from "common/resources/images/cog_cold_caller.png";
import corporate_raider from "common/resources/images/cog_corporate_raider.png";
import double_talker from "common/resources/images/cog_double_talker.png";
import downsizer from "common/resources/images/cog_downsizer.png";
import flunky from "common/resources/images/cog_flunky.png";
import glad_hander from "common/resources/images/cog_glad_hander.png";
import head_hunter from "common/resources/images/cog_head_hunter.png";
import legal_eagle from "common/resources/images/cog_legal_eagle.png";
import loan_shark from "common/resources/images/cog_loan_shark.png";
import micro_manager from "common/resources/images/cog_micromanager.png";
import money_bags from "common/resources/images/cog_money_bags.png";
import mover_shaker from "common/resources/images/cog_mover_shaker.png";
import mr_hollywood from "common/resources/images/cog_mr_hollywood.png";
import name_dropper from "common/resources/images/cog_name_dropper.png";
import number_cruncher from "common/resources/images/cog_number_cruncher.png";
import pencil_pusher from "common/resources/images/cog_pencil_pusher.png";
import penny_pincher from "common/resources/images/cog_penny_pincher.png";
import robber_baron from "common/resources/images/cog_robber_baron.png";
import short_change from "common/resources/images/cog_short_change.png";
import spin_doctor from "common/resources/images/cog_spin_doctor.png";
import telemarketer from "common/resources/images/cog_telemarketer.png";
import the_big_cheese from "common/resources/images/cog_the_big_cheese.png";
import the_mingler from "common/resources/images/cog_the_mingler.png";
import tightwad from "common/resources/images/cog_tightwad.png";
import two_face from "common/resources/images/cog_two_face.png";
import yesman from "common/resources/images/cog_yesman.png";
import React from "react";
import InvasionDetails from "./invasion-details";

// Images
































// Classes

// Components



const cogIcons = {
	"Ambulance Chaser": ambulance_chaser,
	"Back Stabber": back_stabber,
	"Bean Counter": bean_counter,
	"Big Wig": big_wig,
	"Bloodsucker": bloodsucker,
	"Bottom Feeder": bottom_feeder,
	"Cold Caller": cold_caller,
	"Corporate Raider": corporate_raider,
	"Double Talker": double_talker,
	"Downsizer": downsizer,
	"Flunky": flunky,
	"Glad Hander": glad_hander,
	"Head Hunter": head_hunter,
	"Legal Eagle": legal_eagle,
	"Loan Shark": loan_shark,
	"Micromanager": micro_manager,
	"Money Bags": money_bags,
	"Mover & Shaker": mover_shaker,
	"Mr. Hollywood": mr_hollywood,
	"Name Dropper": name_dropper,
	"Number Cruncher": number_cruncher,
	"Pencil Pusher": pencil_pusher,
	"Penny Pincher": penny_pincher,
	"Robber Baron": robber_baron,
	"Short Change": short_change,
	"Spin Doctor": spin_doctor,
	"Telemarketer": telemarketer,
	"The Big Cheese": the_big_cheese,
	"The Mingler": the_mingler,
	"Tightwad": tightwad,
	"Two-Face": two_face,
	"Yesman": yesman
}

class InvasionCard extends React.Component {
	constructor(props) {
		super(props);

		this.cardRef = React.createRef();
		this.expandIconRef = React.createRef();
		this.state = {
			isExpanded: false
		};
	}

	timeRemainingText(estimatedFinishTime) {
		let currentTime = Math.floor(Date.now() / 1000);
		let remainingSeconds = Math.max(0, estimatedFinishTime - currentTime);

		let hours = Math.floor(remainingSeconds / 3600)
		let minutes = Math.floor(remainingSeconds / 60) - (hours * 60)
		let seconds = Math.floor(remainingSeconds - (hours * 3600) - (minutes * 60))

		let estimateText = ""


		if (hours > 1) {
			estimateText += `${hours} hours`
		}
		if (hours === 1) {
			estimateText += `1 hour`
		}
		if (hours > 0 && (minutes > 0 || seconds > 0)) {
			estimateText += " "
		}

		if (minutes > 1) {
			estimateText += `${minutes} minutes`
		}
		if (minutes === 1) {
			estimateText += `1 minute`
		}


		if (hours === 0) {
			if (seconds > 0) {
				estimateText += " "
			}

			if (seconds > 1) {
				estimateText += `${seconds} seconds`
			}
			if (seconds === 1) {
				estimateText += "1 second"
			}
		}

		if (remainingSeconds > 0) {
			estimateText += " left"
		} else {
			estimateText = "Almost finished!"
		}

		return estimateText;
	}

	finishTime() {
		const finishTime = this.props.estimatedFinish;
		const date = new Date(finishTime * 1000);

		let result = ""

		let hour = date.getHours() % 12

		if (hour === 0) {
			result = "12:"
		} else {
			result += hour + ":"
		}

		if (date.getMinutes() >= 10) {
			result += date.getMinutes();
		} else {
			result += "0" + date.getMinutes();
		}

		if (date.getHours() > 12) {
			result += "pm";
		} else {
			result += "am";
		}

		return result;
	}

	onClick() {
		const ref = this.cardRef.current;
		ref.classList.toggle("expanded");

		this.setState(state => ({ isExpanded: !state.isExpanded }));

		const iconRef = this.expandIconRef.current;
		iconRef.classList.toggle("rotated-180");
	}

	render() {
		return (
			<Card id="invasion-card" ref={this.cardRef} onClick={() => this.onClick()}>
				<img id="cog-icon" alt="" src={cogIcons[this.props.cog]} />
				<p className="cog-text headline5 unselectable" alt="">{this.props.cog}</p>
				<span id="expand-icon" className="material-icons" ref={this.expandIconRef} >expand_more</span>

				<p className="body1 unselectable" id="district">District: {this.props.district}</p>
				<div>
					<p className="body2 unselectable" id="time-remaining">{this.timeRemainingText(this.props.estimatedFinish)}</p>
					<p className="body1 unselectable" id="finish-time">{this.finishTime()}</p>
				</div>


				<InvasionDetails currentRate={this.props.currentRate}
					overallRate={this.props.overallRate}
					historicalRate={this.props.historicalRate}
					cogsRemaining={this.props.cogsRemaining} />
			</Card>
		);
	}
}

export default InvasionCard;