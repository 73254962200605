import CogType from "app/entities/invasions/cogs/CogType";
import UserRepository from "data-users/UserRepository";
import DemoteCogSuit from "./DemoteCogSuit";
import {injectable} from "tsyringe";

@injectable()
export default class DemoteCogSuitUseCase implements DemoteCogSuit {
    #userRepository: UserRepository

    constructor(userRepository: UserRepository) {
        this.#userRepository = userRepository
    }

    invoke(cogType: CogType): void {
        const toon = this.#userRepository.currentToon.value

        if(toon === null) return 

        switch (cogType) {
            case CogType.BOSSBOT:
                toon.cogSuits.bossbot.index = Math.max(toon.cogSuits.bossbot.index - 1, 0)
                break
            case CogType.LAWBOT:
                toon.cogSuits.lawbot.index = Math.max(toon.cogSuits.lawbot.index - 1, 0)
                break
            case CogType.CASHBOT:
                toon.cogSuits.cashbot.index = Math.max(toon.cogSuits.cashbot.index - 1, 0)
                break
            case CogType.SELLBOT:
                toon.cogSuits.sellbot.index = Math.max(toon.cogSuits.sellbot.index - 1, 0)
                break
        }

        this.#userRepository.updateToon(toon)
    }
}