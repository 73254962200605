import {Body1, Body2, Headline6, Subtitle2} from "app/theme/Typography"
import Card from "common/theme/components/Card"
import ProgressBar from "common/theme/components/ProgressBar"
import ToontaskGroup from "data-toontasks/ToontaskGroup"
import styled, {useTheme} from "styled-components"
import {ToontaskSlotPosition} from "../StartedToontasks"
import {Box} from "../../../../../core-anvil/Layouts";

type Props = {
    position: ToontaskSlotPosition,
    isFilled: Boolean,
    progress: number,
    progressText: string,
    toontaskTitleText: string,
    rewardText: string,
    stepText: string,
    toontaskGroup: ToontaskGroup
}

export default function MediumToontaskSlot(props: Props) {
    const theme = useTheme()

    if (props.isFilled) {
        return (
            <StyledSmallToontaskSlot style={{backgroundColor: theme.colors.getToontaskGroupColor(props.toontaskGroup)}}>
                <StyledTopRow>
                    <PositionDots highlightedPosition={props.position}/>
                    <StyledProgressText>{props.progressText}</StyledProgressText>
                </StyledTopRow>
                <ProgressBar progress={props.progress} max={1} style={{marginTop: 10}}/>
                <StyledTitleText>{props.toontaskTitleText}</StyledTitleText>
                <StyledStepText>{props.stepText}</StyledStepText>
            </StyledSmallToontaskSlot>
        )
    } else {
        return (
            <Card style={{
                width: 118,
                height: 104,
                padding: 18,
                backgroundColor: theme.colors.getToontaskGroupDarkColor(props.toontaskGroup),
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box alignment="center" style={{width: "100%", height: "100%"}}>
                    <EmptySlotText>Empty slot</EmptySlotText>
                </Box>
            </Card>
        )
    }
}

const StyledSmallToontaskSlot = styled(Card)`
  width: 118px;
  height: 104px;
  background-color: #7a20de;
  display: flex;
  flex-direction: column;
  padding: 18px;
`

const StyledTopRow = styled.div`
  display: flex;
  flex-direction: row;
`

type PositionDotsProps = {
    highlightedPosition: ToontaskSlotPosition
}

function PositionDots(props: PositionDotsProps) {

    let positionDots = []
    for (let i = 0; i < 4; i++) {
        const isHighlighted = i === props.highlightedPosition.valueOf()
        positionDots.push(
            <StyledPositionDot
                color={isHighlighted ? "white" : "black"}
                opacity={isHighlighted ? "1" : "0.2"}
            />
        )
    }


    return (
        <StyledPositionDots>
            {positionDots}
        </StyledPositionDots>
    )
}

const StyledPositionDots = styled.div`
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 4px;
`

type StyledPositionDotProps = {
    color: string,
    opacity: string
}

const StyledPositionDot = styled.div<StyledPositionDotProps>`
  width: 8px;
  height: 8px;
  background-color: ${props => props.color};
  opacity: ${props => props.opacity};
  border-radius: 4px;
`

const StyledProgressText = styled(Subtitle2)`
  flex: 1;
  text-align: end;
`

const StyledStepText = styled(Body2)`
  margin-top: 8px;
`

const StyledTitleText = styled(Body1)`
  margin-top: 12px;
`

const EmptySlotText = styled(Headline6)`
  color: white;
  font-weight: 300;
  opacity: 0.6;
`