import { BehaviorSubject } from "rxjs";

import GetInvasions from "./GetInvasions";
import Invasion from "app/entities/invasions/Invasions";
import InvasionInfo from "../InvasionInfo";
import InvasionsRepository from "app/data/invasions/InvasionsRepository";

export default class GetInvasionsUseCase implements GetInvasions {
    #repository: InvasionsRepository
    #invasions: BehaviorSubject<InvasionInfo[]> = new BehaviorSubject<InvasionInfo[]>([])

    constructor(repository: InvasionsRepository) {
        this.#repository = repository

        this.#repository.invasions.subscribe(invasions => {
            let invasionInfoList: InvasionInfo[] = []
            invasions.forEach(invasion => {
                invasionInfoList.push(this.convertToInvasionInfo(invasion))
            })
            this.#invasions.next(invasionInfoList)
        })
    }

    invoke(): BehaviorSubject<InvasionInfo[]> {
        return this.#invasions
    }

    private convertToInvasionInfo(invasion: Invasion): InvasionInfo {
        return {
            cog: invasion.cog,
            district: invasion.district,
            startTime: invasion.startTime,
            finishTime: invasion.finishTime
        }
    }
}