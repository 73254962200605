import { NavigationScreen } from "app/ui/app/navigation/NavigationDrawer";
import React from "react";
import { Subtitle2 } from "app/theme/Typography";
import styled from "styled-components";

type Props = {
	enabled: boolean,
	type: NavigationScreen,
	onClick: () => any
}

type State = {
	icon: string,
	text: string
}

export default class NavigationItem extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		
		if(props.type === NavigationScreen.HOME) {
			this.state = { icon: "home", text: "Home" }
		}

		if(props.type === NavigationScreen.INVASIONS) {
			this.state = { icon: "flash_on", text: "Invasions" }
		}

		if(props.type === NavigationScreen.TRACKING) {
			this.state = { icon: "check_box", text: "Tracking"}
		}
	}

	render() {
		return (
			<StyledNavigationItem isCurrent={this.props.enabled} onClick={this.props.onClick}>
				<StyledIcon className="material-icons">{this.state.icon}</StyledIcon>
				<StyledText>{this.state.text}</StyledText>
			</StyledNavigationItem>
		);
	}
}

type StyledNavigationItemProps = {
	isCurrent: boolean
}

const StyledNavigationItem = styled.div<StyledNavigationItemProps>`
	width: 200px;
	height: 40px;
	display: flex;
	align-items: center;
	border-radius: 18px;
	opacity: ${props => getStyledNavigationItemOpacity(props.isCurrent)};
	background-color: ${props => getStyledNavigationItemBackgroundColor(props.isCurrent)};
`

function getStyledNavigationItemOpacity(isCurrent: boolean) {
	if(isCurrent) {
		return 1
	} else {
		return 0.6
	}
}

function getStyledNavigationItemBackgroundColor(isCurrent: boolean) {
	if(isCurrent) {
		return "#8337f1"
	} else {
		return ""
	}
}

const StyledIcon = styled.i`
	margin: 8px 0 8px 28px;
	float: left;
	display: inline-block;
	color: white;
`

const StyledText = styled(Subtitle2)`
	margin-left: 28px;
	margin-top: 2px;
	text-decoration: none;
`