import {CSSProperties} from "react";
import styled, {useTheme} from "styled-components";
import ToontaskGroup from "../../../../data-toontasks/ToontaskGroup";
import {ToontaskCard} from "../ToontaskCard";
import {Headline5} from "../../../../app/theme/Typography";

type ToontaskCardState = {
    id: number,
    title: string,
    reward: string
}

type Props = {
    toontasks: ToontaskCardState[],
    group: ToontaskGroup,
    onToontaskClick: (toontaskId: number) => void,
    style?: CSSProperties | undefined;
}

export default function NotStartedToontasks(props: Props) {
    const theme = useTheme()

    return (
        renderToontasksNotStarted(props.toontasks, theme.colors.getToontaskGroupColor(props.group), props.onToontaskClick, props.style)
    )
}

export function renderToontasksNotStarted(toontasks: ToontaskCardState[], color: string, onToontaskClick: (toontaskId: number) => void, style?: CSSProperties | undefined) {
    return (
        <StyledNotStartedToontasks style={{...style}}>
            <Headline5 style={{color: "white"}}>Not Started</Headline5>
            <div className="neighbourhood-page__toontasks-grid">
                {renderToontasksNotStartedCards(toontasks, color, onToontaskClick)}
            </div>
        </StyledNotStartedToontasks>
    )
}

export function renderToontasksNotStartedCards(toontasks: ToontaskCardState[], color: string, onToontaskClick: (toontaskId: number) => void) {
    let cards = []

    for (const toontask of toontasks) {
        cards.push(
            <ToontaskCard title={toontask.title} reward={toontask.reward} color={color}
                          onClick={() => onToontaskClick(toontask.id)}/>
        )
    }

    return cards
}

const StyledNotStartedToontasks = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 32px;
`