import {Box, Column, Row, Spacer} from "../../core-anvil/Layouts";
import NavigationBanner from "../../core-theme/components/navigation/NavigationBanner";
import ScreenSize from "../../common/ScreenSize";
import {useTheme} from "styled-components";
import {useHistory} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {container} from "tsyringe";
import FishingOverviewScreenState, {SpeciesState} from "./FishingOverviewScreenState";
import FishingOverviewViewModel from "./FishingOverviewViewModel";
import animated_bubbles from "../assets/fishing_bubbles.gif"
import TrophiesProgress from "./TrophiesProgress";
import SpeciesGrid from "./SpeciesGrid";
import LaffProgress from "../../feat-tracking/ui/overview/common/LaffProgress";
import LargeLaffProgress from "./LargeLaffProgress";

type Props = {
    screenSize: ScreenSize,
    navigateToSpecies: (id: number) => void
}

export default function FishingOverviewScreen(props: Props) {
    const viewModel = useRef(container.resolve(FishingOverviewViewModel))

    const [uiState, setUIState] = useState<FishingOverviewScreenState>(viewModel.current.uiState.value)

    useEffect(() => {
        const sub = viewModel.current.uiState.subscribe(setUIState)
        return () => sub.unsubscribe()
    }, [])

    return (
        <FishingOverviewContent {...props} {...uiState} onSpeciesClick={props.navigateToSpecies} />
    )
}

type ContentProps = {
    screenSize: ScreenSize,
    species: SpeciesState[],
    trophiesUnlocked: number,
    maxTrophies: number,
    progress: number,
    progressText: string,
    nextLaffProgress: number | null,
    onSpeciesClick: (id: number) => void
}

function FishingOverviewContent(props: ContentProps) {
    const theme = useTheme()
    const history = useHistory()

    let progressBarWidth = 300
    if (props.screenSize === ScreenSize.Medium) {
        progressBarWidth = 490
    }
    if (props.screenSize === ScreenSize.Large) {
        progressBarWidth = 680
    }

    let progressBar =
        <LaffProgress
            progress={props.progress}
            max={1}
            progressText={props.progressText}
            maxText="70"
            nextLaffProgress={props.nextLaffProgress}
            width={progressBarWidth}
            style={{marginTop: 16}}
        />

    if (props.screenSize >= ScreenSize.Medium) {
        progressBar =
            <LargeLaffProgress
                progress={props.progress}
                max={1}
                progressText={props.progressText}
                maxText="70"
                nextLaffProgress={props.nextLaffProgress}
                width={progressBarWidth}
            />
    }

    let header =
        <Column horizontalAlignment="center">
            <TrophiesProgress progressCount={props.trophiesUnlocked} max={props.maxTrophies}/>
            {progressBar}
        </Column>

    if (props.screenSize >= ScreenSize.Large) {
        header =
            <Row verticalAlignment="center">
                <TrophiesProgress progressCount={props.trophiesUnlocked} max={props.maxTrophies}/>
                <Spacer style={{marginLeft: 60}}/>
                {progressBar}
            </Row>
    }

    return (
        <Box style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#1b187e",
        }}>
            <Box style={{
                width: "100%",
                height: "100%",
                opacity: 0.3,
                backgroundRepeat: "repeat",
                backgroundImage: `url(${animated_bubbles})`
            }}/>
            <Column style={{
                width: "100%",
                height: "100%",
                overflowY: "scroll",
            }}>
                <Column horizontalAlignment="center" style={{
                    width: "100%"
                }}>
                    <NavigationBanner
                        screenSize={props.screenSize}
                        backgroundColor={theme.colors.trackingDark}
                        icon="check_box"
                        featureName="Tracking"
                        screenName="Fishing"
                        previousScreenName="Tracking"
                        onBackClick={() => {
                            history.push("/tracking")
                        }}
                    />
                    <Spacer style={{marginTop: 24}}/>
                    {header}
                    <Spacer style={{marginTop: 20}}/>
                    <SpeciesGrid screenSize={props.screenSize} species={props.species}
                                 onSpeciesClick={props.onSpeciesClick}/>
                    <Spacer style={{marginTop: 20}}/>
                </Column>
            </Column>
        </Box>
    )
}