import {BehaviorSubject} from "rxjs"
import LegacyUserRepository from "app/data/users/UserRepository"
import UserRepository from "data-users/UserRepository";

export default class MainViewModel {

    #userRepository: LegacyUserRepository

    toontownTime = new BehaviorSubject<String>("")

    user = this.userRepository.user

    constructor(legacyUserRepository: LegacyUserRepository, private userRepository: UserRepository) {
        this.#userRepository = legacyUserRepository
        this.#userRepository.init()

        this.toontownTime.next(this.getToontownTime())

        setInterval(() => {
            this.toontownTime.next(this.getToontownTime())
        }, 60 * 1000)
    }

    signInWithEmail(email: string, password: string) {
        this.#userRepository.signInByEmail(email, password)
    }

    signInWithGoogle() {
        this.#userRepository.signInWithGoogle()
    }

    observeUser() {
        return this.#userRepository.observeUser()
    }

    getToontownTime(): string {
        const date = new Date()
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/Los_Angeles'
        })
    }

    setCurrentToon(toonName: string) {
        const toon = this.userRepository.user.value?.toons.find(toon => toon.name === toonName)

        if(toon === undefined) return
        this.userRepository.updateCurrentToonId(toon.id)
    }
}